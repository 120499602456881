import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaAppCardFighterService {

  constructor(private http: HttpClient, private globals: Globals) { }
  private fightcardUrl = this.globals.baseUrl + 'taappfightcard';  // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
	/** POST: add a new Country to the server */
  createFightCard(cardFighter: any): Observable<any> {
    return this.http.post(this.fightcardUrl, cardFighter, this.httpOptions);
  }
  updateFightCard(cardFighter: any): Observable<any> {
    return this.http.post<any>(this.fightcardUrl+'/update', cardFighter);
  }
	public getListFightCard(searchParams=null):Observable<any>{
    const body = JSON.stringify(searchParams);
    return this.http.post(this.fightcardUrl+"/gets",body);
  }
  public deleteFightcard(datas):Observable<any>{
    return this.http.post(this.fightcardUrl+'/delete',datas);
  }
  public unpublishFightcard(datas):Observable<any>{
    return this.http.post(this.fightcardUrl+'/unpublish',datas);
  }
  public publishFightcard(datas):Observable<any>{
    return this.http.post(this.fightcardUrl+'/publish',datas);
  }
  public sendMessageToAllFighters(datas):Observable<any>{
    return this.http.post(this.fightcardUrl+'/sendMessageToAllFighters', datas);
  }
	private log(message: string) {
  	}
}
