<app-navbar *ngIf="!subscription.paypal_subscription_id && !isLoading && selectedSubscription" [mustSubscribe]="true"></app-navbar>
<app-navbar *ngIf="subscription.paypal_subscription_id || !selectedSubscription"></app-navbar>
<div class="container">
  <!--loader-->
  <div class="d-flex justify-content-center" *ngIf="isLoading">
  	<div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
  		<span class="sr-only">Loading...</span>
  	</div>
  </div>
  <div *ngIf="!showError">
    <div class="mt-5" *ngIf="subscription.paypal_subscription_id">
      <mdb-card>
        <mdb-card-body>
          <mdb-card-title>
            <h4>{{'TEXT.YOUR_SUBSCRIPTION'|translate}}</h4>
          </mdb-card-title>
          <mdb-card-text>
            <p>{{subscription.subscription_label}} : {{subscription.subscription_price_excl}} (€HT)<br/>
            {{'TEXT.subscribedSince'|translate}} : {{subscription.subscription_start_date}}
            </p>
          </mdb-card-text>
          <mdb-card-text *ngIf="!subscription.subscription_end_date"> {{'TEXT.wantCancelSubscription'|translate}}  </mdb-card-text>
          <a mdbBtn color="primary" style="color: white;" (click)="modalUnsubscribe.show()" *ngIf="!unsubscribing && !subscription.subscription_end_date">{{'BUTTON.cancelSubscription'|translate}}</a>
          <mdb-card-text *ngIf="subscription.subscription_end_date"> {{'TEXT.wantActivateSubscription'|translate}}  </mdb-card-text>
          <a mdbBtn color="primary" style="color: white;" (click)="activate()" *ngIf="subscription.subscription_end_date && !subscribing">{{'BUTTON.activate'|translate}}</a>
        </mdb-card-body>
      </mdb-card>
      <!--DISPLAY PAYMENTS-->
      <mdb-card class="mt-3 mb-3">
        <mdb-card-body>
          <ion-text>
            <h3>{{'TEXT.yourPayments'|translate}}</h3>
          </ion-text>
          <div class="ion-text-center" *ngIf="isLoading">
            <!--<app-avatar-skeleton></app-avatar-skeleton>-->
            <ion-col class="ion-text-center">
              <ion-spinner name="crescent"></ion-spinner>
            </ion-col>
          </div>
          <div *ngIf="ordersList">
            <div *ngFor="let order of ordersList">
              <hr>
              <h5 style="display: inline">{{order.subscription_label}}</h5> 
              <span> ({{order.amount_paid_incl}}€)</span>
              <div>
                <!--<button type="button" mdbBtn color="primary" (click)="downloadPdf(order.id, order.time)">-->
                <button type="button" mdbBtn color="primary" (click)="downloadPdf(order.id_order)">
                  <mdb-icon fas icon="cloud-download-alt"></mdb-icon>
                  PDF
                </button>
                <span> {{order.time | date}}</span>
              </div>
            </div>
          </div>
          <div lass="ion-text-center" *ngIf="!ordersList && !isLoading">
            <hr>
            <ion-text>{{'TEXT.noPayment'|translate}}</ion-text>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  

  <!--DISPLAY ERROR MESSAGE-->
  <div *ngIf="showError">
    <div class="alert alert-error" role="alert">
      <h4 class="alert-heading">{{'ERROR.TITLE'|translate}}</h4>
      <p *ngIf="!errorMessage">
        {{'ERROR.SUBSCRIPTION_LOADING'|translate}}
      </p>
      <p *ngIf="errorMessage">
        errorMessage
      </p>
    </div>
  </div>
</div>

</div> <!--Fonctionnait sans cette balise fermante, vérifier que tous fonctionne toujours bien-->

<!--PAS D'ABONNEMENT-->
<div *ngIf="!subscription.paypal_subscription_id && !isLoading && selectedSubscription">
  <div style="background-image: url(../../../assets/img/site-bg.jpg); padding-top: 4rem; min-height: 100vh;">
    <div class="card-subscription">
      <img src="../../../assets/img/logo_mma_orga.png" style="width: 50%; height: auto; padding-bottom: 2rem;">
      <div class="btn-list">
        <div [ngClass]="(this.listSubscription[0].id_subscription == this.selectedSubscription.id_subscription) ? 'btn-choose-subscription-active' : 'btn-choose-subscription-unactive'"
          (click)="this.selectedSubscription = this.listSubscription[0]" style="border-radius: 0.25rem 0 0 0.25rem;">{{listSubscription[0].subscription_label}}</div>
        <div [ngClass]="(this.listSubscription[1].id_subscription == this.selectedSubscription.id_subscription) ? 'btn-choose-subscription-active' : 'btn-choose-subscription-unactive'"
          (click)="this.selectedSubscription = this.listSubscription[1]" style="border-radius: 0 0.25rem 0.25rem 0;">{{listSubscription[1].subscription_label}}</div>
      </div>
      <p *ngIf="selectedSubscription.free_period == 1" class="free-time">{{ "TEXT.1_free_mounth" | translate }}</p>
      <p *ngIf="selectedSubscription.free_period > 1" class="free-time">{{selectedSubscription.free_period}} {{ "TEXT.free_mounth" | translate }}</p>
      <p *ngIf="selectedSubscription.subscription_duration == 1" class="price">{{ "TEXT.then" | translate }} {{selectedSubscription.subscription_price}}€ {{ "TEXT.per_mounth" | translate }}</p>
      <p *ngIf="selectedSubscription.subscription_duration == 12" class="price">{{ "TEXT.then" | translate }} {{selectedSubscription.subscription_price}}€ {{ "TEXT.per_year" | translate }}</p>
      <p class="price">{{ "TEXT.cancel_without_charge" | translate }}</p>
      <div class="btn-subscribe" (click)="openPaypalModal()">{{ "BUTTON.go_subscribe" | translate }}</div>
      <div [innerHTML]="selectedSubscription.description | keepHtml" style="text-align: initial;"></div>
    </div>
  </div>
  <!--Pourquoi choisir mma connection-->
  <div  style="text-align: center; padding-top: 1rem; padding-bottom: 1rem;">
    <div style="font-size: 3em; font-weight: bold; padding-bottom: 1rem;">{{ "TEXT.choose_mma_connection" | translate }}</div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_1" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_1" | translate }}
        </div>
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_2" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_2" | translate }}
        </div>
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_3" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_3" | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_4" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_4" | translate }}
        </div>
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_5" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_5" | translate }}
        </div>
        <div class="col-sm">
          <div class="choose-title">{{ "TEXT.choose_mma_connection_title_6" | translate }}</div>
          {{ "TEXT.choose_mma_connection_text_6" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal validation résiliation abonnement -->
<div mdbModal #modalUnsubscribe="mdbModal" class="modal fade top" id="modalUnsubscribe" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.unsubscribe" | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalUnsubscribe.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.unsubscribe_text" | translate }}
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button type="button" aria-label="Close" (click)="modalUnsubscribe.hide()" mdbBtn color="danger" outline="true">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button type="submit" mdbBtn color="danger" class="relative" (click)="unsubscribe()" [disabled]="unsubscribing">
          {{ "BUTTON.unsubscribe" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Modal validation résiliation abonnement -->
<div mdbModal #paypalModal="mdbModal" class="modal fade top" id="paypalModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead modal-header-text-color">{{ "TEXT.subscribe" | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="paypalModal.hide()">
          <span aria-hidden="true" class="grey-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.subscribe_text" | translate }}
          <div style="padding-top: 1rem;">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button type="button" aria-label="Close" class="btn-close" (click)="paypalModal.hide()" mdbBtn outline="true">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>