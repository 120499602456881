import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Globals } from '../../../globals'
import { AlertService } from '../../../services/alert.service';
import { ManagersService } from '../../../services/managers.service';
import { TranslateService } from '../../../translate';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ChatRoomService } from "../../../services/chat-room.service";
import { ContactService } from "../../../services/contact.service";
import { ChatService } from '../../../services/chat.service';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @ViewChild("contactModal") contactModal: ModalDirective;
  @ViewChild("messageModal") messageModal: ModalDirective;
  manager = null;
  fightersList = null;
  isLoadingFighters = false;

  contactForm: FormGroup;
  messageForm: FormGroup;
  isSending: boolean = false;
  contact_validation_messages = {
    message: null,
    subject: null,
  };
  errorResult: boolean;
  successResult: boolean;
  isLoading: boolean = true;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private alertService : AlertService,
    private managerService : ManagersService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    private contactService: ContactService,
    private chatRoomService: ChatRoomService,
    private chatService : ChatService
  )
  {
    this.contactForm = new FormGroup({
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
    });
    this.messageForm = new FormGroup({
      message: new FormControl("", Validators.required),
    });
    this.contact_validation_messages = {
      message: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
      subject: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
    };
  }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.managerService.getProfile(id).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.manager = jsonResponse.rows[0];
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }

    },error=>{
      this.alertService.alertError(error.status);
    });
    this.managerService.getFighters(id).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.fightersList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }

    },error=>{
      this.alertService.alertError(error.status);
    });
  }
  showContactForm(){
    this.contactModal.show();
  }
  showMessageForm(){
    this.messageModal.show();
  }
  submitContactForm() {
    this.isSending = true;
    let datas = this.contactForm.getRawValue();
    datas.contactType = ["manager"];
    datas.id_manager_profile = this.manager.id_manager_profile;
    this.contactService.send(datas).subscribe(
      (jsonResponse) => {
        this.isSending = false;
        if (jsonResponse.success) {
          this.contactForm.reset();
          this.successResult = true;
        } else {
          this.errorResult = true;
        }
      },
      (error) => {
        this.errorResult = true;
        this.alertService.alertError(error.status);
        this.isSending = false;
      }
    );

  }
  submitMessageForm() {
    this.isSending = true;
    let datas = this.messageForm.getRawValue();
    datas.id_manager_profile =this.manager.id_manager_profile;
    datas.contactType = 'manager';
    //Récupération de la room en fonction du contact sélectionné
    this.chatService.searchRoomForUser(datas).subscribe(jsonResponse=>{
      this.isSending = false;
    if(jsonResponse.success){
      //on envoie le message dans la room déjà créée
      if(jsonResponse.total > 0 && jsonResponse.rows[0].id_chat_room !=undefined){
        datas = {
          message : this.messageForm.get('message').value,
          user_creator_room_id : jsonResponse.rows[0].user_id_creator,
          id_chat_room : jsonResponse.rows[0].id_chat_room,
          user_id_receiver : jsonResponse.rows[0].user_id_receiver
        };
        this.sendInChatRoom(datas);
      }
      //on créée une nouvelle room et on envoie le message
      else{
        //stockage du roomId du user à contacter. On reçoit les informations sur le contact uniquement s'il n'y a pas de room existante
        let userRoomId = jsonResponse.rows[0].room_id;
        this.chatRoomService.createRoom(jsonResponse.rows[0].room_id).subscribe(jsonResponse=>{
          //succes création du message
          if(jsonResponse.success){
            datas = {
              message : this.messageForm.get('message').value,
              user_creator_room_id : jsonResponse.obj.user_creator_room_id,
              id_chat_room : jsonResponse.id,
              user_id_receiver : userRoomId
            };
            //envoi du message
            this.sendInChatRoom(datas);
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        },()=>{});
      }
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
    },error=>{
      this.isSending = false;
    this.alertService.alertError(error.status);
    },()=>{});
  }
  sendInChatRoom(datas){
    let formDatas:FormData = new FormData();
    formDatas.append('id_chat_room', datas.id_chat_room);
    formDatas.append('message', datas.message);
    formDatas.append('receiver_room_id', datas.user_id_receiver);
    this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.chatService.sendMessage(
          { roomId: datas.id_chat_room,
            message: datas.message,
            user_room_id:"",
            receiver_room_id : datas.user_id_receiver,
            create_datetime: new Date(),
            imageFromSocket : null
          }
        );
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','MESSAGE_SENT'), this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }


}
