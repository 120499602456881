<form (ngSubmit)="createFighter()" #fighterForm="ngForm">
  <button type="button" mdbBtn color="primary" name="addPicture" (click)="photoField.click()" *ngIf="!imageChangedEvent"><mdb-icon fas icon="plus"></mdb-icon>&nbsp;{{'BUTTON.picture'|translate}}</button>
  <button type="button" mdbBtn color="danger" (click)="croppedImage=null;imageChangedEvent=null" *ngIf="imageChangedEvent"><mdb-icon fas icon="times"></mdb-icon>&nbsp;{{'BUTTON.DELETE'|translate}}</button>
  <input #photoField style="display:none;" accept="image/*" type="file" (change)="fileChangeEvent($event)" />
  <div *ngIf="imageChangedEvent">
    <image-cropper style="max-width:250px;max-height:250px;"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>


  <div class="form-row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <input type="text" mdbValidate name="name" id="name" #name="ngModel" placeholder="" [(ngModel)]="credentials.name" class="form-control" mdbInput required>
        <label  for="name">{{'FORM.name'|translate}}<sup>*</sup></label>
        <mdb-error *ngIf="!name.valid">{{'ERROR.completeField'|translate}}</mdb-error>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <input type="text" name="firstname" id="firstname" #firstname="ngModel" placeholder="" [(ngModel)]="credentials.firstname" class="form-control" mdbInput required>
        <label  for="firstname">{{'FORM.firstname'|translate}}<sup>*</sup></label>
        <mdb-error *ngIf="!firstname.valid">{{'ERROR.completeField'|translate}}</mdb-error>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <input type="text" name="nickname" id="nickname" placeholder="" [(ngModel)]="credentials.nickname" [disabled]="credentials.is_valid" class="form-control" mdbInput>
        <label  for="nickname">{{'FORM.nickname'|translate}}</label>
      </div>
    </div>
    <!--<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-4">
      <mdb-checkbox name="is_pro" [(ngModel)]="credentials.is_pro">{{'FORM.proFighter'|translate}}</mdb-checkbox>
    </div>-->
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <mdb-select-2 [outline]="true" #proSelector="ngModel"  placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.FIGHT_LEVEL' | translate}}" [(ngModel)]="credentials.is_pro" id="is_pro" name="is_pro"
          [allowClear]="true" required mdbValidate>
          <mdb-select-option value="1">{{ 'FORM.proFighter' | translate }}</mdb-select-option>
          <mdb-select-option value="2">{{ 'FORM.ROOKIE' | translate }}</mdb-select-option>
        </mdb-select-2>
        <mdb-error *ngIf="!proSelector.valid">{{'ERROR.completeField'|translate}}</mdb-error>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div *ngIf="credentials.is_pro == '1'" style="width: 100%;">
      <div class="col-12">
        <h5>{{"TEXT.palmares"|translate}}</h5>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_victory" placeholder="" name="number_victory" id="number_victory" class="form-control" mdbInput>
          <label for="number_victory">{{'FORM.nbVictories'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_defeat" placeholder="" name="number_defeat" id="number_defeat" class="form-control" mdbInput>
          <label for="number_defeat">{{'FORM.nbDefeat'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_null" placeholder="" name="number_null" id="number_null" class="form-control" mdbInput>
          <label for="number_null">{{'FORM.nbDraw'|translate}}</label>
        </div>
      </div>
    </div>
    <div  *ngIf="credentials.is_pro == '2'" style="width: 100%;">
      <div class="col-12">
        <h5>{{"TEXT.palmares"|translate}}</h5>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" #expAmateurSelector="ngModel" placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.EXP_ROOKIE' | translate}}" [(ngModel)]="credentials.id_exp_amateur" id="id_exp_amateur" name="id_exp_amateur"
            [allowClear]="true" required mdbValidate>
            <mdb-select-option *ngFor="let exp of exp_amateurList"
              [value]="exp.id_exp_amateur">{{ exp.label_exp_amateur }}
            </mdb-select-option>
          </mdb-select-2>
          <mdb-error *ngIf="!expAmateurSelector.valid">{{'ERROR.completeField'|translate}}</mdb-error>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_victory_amateur" placeholder="" name="number_victory_amateur" id="number_victory_amateur" class="form-control" mdbInput>
          <label for="number_victory_amateur">{{'FORM.nbVictories'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_defeat_amateur" placeholder="" name="number_defeat_amateur" id="number_defeat_amateur" class="form-control" mdbInput>
          <label for="number_defeat">{{'FORM.nbDefeat'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" [(ngModel)]="credentials.number_draw_amateur" placeholder="" name="number_draw_amateur" id="number_draw_amateur" class="form-control" mdbInput>
          <label for="number_draw_amateur">{{'FORM.nbDraw'|translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
      <div class="col-12">
        <h5>{{"TEXT.informations"|translate}}</h5>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" #sexSelector="ngModel"  placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.CHOOSE_SEX' | translate}}" [(ngModel)]="credentials.id_sex" id="id_sex" name="id_sex"
            [allowClear]="true" (selected)="filterWeightCategories($event)" required mdbValidate>
            <mdb-select-option value="1">{{ 'FORM.LABEL_SEX_MALE' | translate }}</mdb-select-option>
            <mdb-select-option value="2">{{ 'FORM.LABEL_SEX_FEMALE' | translate }}</mdb-select-option>
          </mdb-select-2>
          <mdb-error *ngIf="!sexSelector.valid">{{'ERROR.completeField'|translate}}</mdb-error>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <label for="birth_date">{{'FORM.birthDate'|translate}}</label>
          <input type="date" name="birth_date" id="birth_date" placeholder="" [(ngModel)]="credentials.birth_date"  class="form-control" mdbInput>
        </div>
      </div>
  </div>

    <div class="form-row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input inputmode="tel" type="tel" name="phone" id="phone" placeholder="" [(ngModel)]="credentials.phone" class="form-control" mdbInput>
          <label  for="phone">{{'FORM.PHONE'|translate}}</label>
        </div>
      </div>
    <!--LINK-->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="url" inputmode="url" name="tapology" placeholder="" id="tapology" [(ngModel)]="credentials.tapology" placeholder="https://" class="form-control" mdbInput>
          <label  for="tapology">{{'FORM.palmaresLink'|translate}}</label>
        </div>
      </div>
    </div>
    <!--End general informations-->
    <!--Start location informations-->
    <div class="form-row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" #countrySelector="ngModel" placeholder="{{'FORM.selectElement'|translate}}" [(ngModel)]="credentials.id_living_country" name="id_living_country"
            label="{{'FORM.CHOOSE_COUNTRY' | translate}}" [allowClear]="true" required mdbValidate>
            <mdb-select-option *ngFor="let country of countriesList" [value]="country.id_country">{{
              country.label_country }}</mdb-select-option>
          </mdb-select-2>
          <mdb-error *ngIf="!countrySelector.valid">{{'ERROR.completeField'|translate}}</mdb-error>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" #nayionnalitySelector="ngModel" placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.CHOOSE_NATIONNALITY' | translate}}"
            [(ngModel)]="credentials.id_nationnality" name="id_nationnality" [allowClear]="true" required mdbValidate>
            <mdb-select-option *ngFor="let nationnality of nationnalitiesList"
              [value]="nationnality.id_nationnality">{{ nationnality.label_nationnality }}
            </mdb-select-option>
          </mdb-select-2>
          <mdb-error *ngIf="!nayionnalitySelector.valid">{{'ERROR.completeField'|translate}}</mdb-error>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="text" name="living_city" id="living_city" placeholder="" [(ngModel)]="credentials.living_city" class="form-control" mdbInput>
          <label  for="living_city">{{'FORM.city'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="text" name="facebook" id="facebook" placeholder="" [(ngModel)]="credentials.facebook" class="form-control" mdbInput>
          <label  for="facebook">{{'FORM.LINK_FACEBOOK'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="text" name="instagram" id="instagram" placeholder="" [(ngModel)]="credentials.instagram" class="form-control" mdbInput>
          <label  for="instagram">{{'FORM.LINK_INSTAGRAM'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="text" name="twitter" id="twitter" placeholder="" [(ngModel)]="credentials.twitter" class="form-control" mdbInput>
          <label  for="twitter">{{'FORM.LINK_TWITTER'|translate}}</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="text" name="video_link" id="video_link" placeholder="" [(ngModel)]="credentials.video_link" class="form-control" mdbInput>
          <label  for="video_link">{{'FORM.LINK_VIDEO'|translate}}</label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <h5>{{'FORM.height'|translate}}</h5>
      </div>
  <!--END location informations-->
  <!--Start phycal informations-->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" name="height_feet" id="height_feet" [(ngModel)]="credentials.height_feet" placeholder="" class="form-control" (change)="convertHeight('cm')" mdbInput>
          <label  for="height_feet">{{'FORM.height'|translate}} (ft)</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" name="height_cm" id="height_cm"  [(ngModel)]="credentials.height_cm" placeholder="" class="form-control" (change)="convertHeight('ft')" mdbInput>
          <label  for="height_cm">{{'FORM.height'|translate}} (cm)</label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <h5>{{'FORM.weight'|translate}}</h5>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" name="weight_lbs" id="weight_lbs" [(ngModel)]="credentials.weight_lbs" placeholder="" class="form-control" (change)="convertWeightTo('kg')" mdbInput>
          <label  for="weight_lbs">{{'FORM.weight'|translate}} (lbs)</label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="md-form md-outline">
          <input type="number" name="weight_kg" id="weight_kg" [(ngModel)]="credentials.weight_kg" placeholder="" class="form-control" (change)="convertWeightTo('lbs')" mdbInput>
          <label  for="weight_kg">{{'FORM.weight'|translate}} (kg)</label>
        </div>
      </div>
    </div>
  <!--END phycal informations-->
  <!--Fight weight-->
  <div class="form-row">
    <div class="col-12">
      <h5>{{'FORM.fightWeight'|translate}}</h5>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <mdb-select-2 [outline]="true" #categorySelector="ngModel" placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.CHOOSE_WEIGHT' | translate}} 1"
          [(ngModel)]="credentials.id_weight_category_1" name="id_weight_category_1" [allowClear]="true" (click)="controlSexSelection()" [disabled]="!credentials.id_sex" required mdbValidate>
          <mdb-select-option *ngFor="let weight of fightWeightList" [value]="weight.id_weight_category">{{
            weight.label_weight_category }}</mdb-select-option>
        </mdb-select-2>
        <mdb-error>
          <span *ngIf="!sexSelector.valid">{{'ERROR.selectSex'|translate}}</span>
          <span *ngIf="!categorySelector.valid">{{'ERROR.completeField'|translate}}</span>
        </mdb-error>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="md-form md-outline">
        <mdb-select-2 [outline]="true" placeholder="{{'FORM.selectElement'|translate}}" label="{{'FORM.CHOOSE_WEIGHT' | translate}} 2"
          [(ngModel)]="credentials.id_weight_category_2" name="id_weight_category_2" [allowClear]="true" (click)="controlSexSelection()" [disabled]="!credentials.id_sex">
          <mdb-select-option *ngFor="let weight of fightWeightList" [value]="weight.id_weight_category">{{
            weight.label_weight_category }}</mdb-select-option>
        </mdb-select-2>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 text-center">
        <button type="submit" mdbBtn color="primary" [disabled]="(!fighterForm.valid || isSaving)"><i *ngIf="isSaving" class="fas fa-circle-notch fa-spin"></i>&nbsp;{{'BUTTON.SAVE'|translate}}</button>
    </div>
    <!--END FORM ROW-->
    </div>
  </form>
