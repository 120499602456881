<app-navbar></app-navbar>
<div class="container mt-5">
  <div *ngIf="!fighter && !isLoading" class="text-center">
  	<h3 class="align-middle">{{'TEXT.NO_RESULTS' | translate}}</h3>
  </div>
  <!--loader-->
  <div class="d-flex justify-content-center" *ngIf="isLoading">
  <div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
    <span class="sr-only">Loading...</span>
  </div>
  </div>
  <div class="row" *ngIf="fighter != null" class="card-deck">
    <div class="col-md-5 col-xl-4 col-lg-4 col-sm-6 mt-4 mb-2">
      <mdb-card class="testimonial-card">
        <!--Bacground color-->
        <div class="card-up unique-color-dark">
          <mdb-badge *ngIf="!fighter.is_pro" style="background-color:var(--mma-blue);">AM</mdb-badge><span *ngIf="!fighter.is_pro" style="color: white;"> {{fighter.label_exp_amateur}}</span><mdb-badge *ngIf="fighter.is_pro" style="background-color:var(--mma-red);">PRO</mdb-badge>
        </div>
        <!--Avatar-->
        <div  class="avatar mx-auto">
  	      	<img *ngIf="fighter.profile_pic" src="{{globals.publicPathImage}}/pictures/{{fighter.profile_pic}}" class="rounded-circle">
  	      	<img *ngIf="!fighter.profile_pic" src="./assets/img/logo-white-250.png" class="rounded-circle">
        </div>
    <mdb-card-body class="card-body ">
      <!--Name-->
      <mdb-card-title>
          <div class="text-center mb-3">
              <h4 class="mb-0"><a routerLink="/fighters/{{fighter.id_fighter_profile}}">{{fighter.name}} {{fighter.firstname}}</a></h4>
                <span *ngIf="fighter.nickname">({{fighter.nickname}})</span>
            </div>
            <div class="d-flex">
              <!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
              <div class="alert alert-warning flex-grow-1" role="alert" *ngIf="fighter.id_user && !fighter.id_card_file">
                {{'TEXT.UNVERRIFIED_PROFILE'|translate}}
             </div>
             <div class="alert alert-success flex-grow-1" role="alert" *ngIf="fighter.id_user && fighter.id_card_file && fighter.is_valid">
               {{'TEXT.VERRIFIED_PROFILE'|translate}}
            </div>
            <div class="alert alert-danger flex-grow-1" role="alert" *ngIf="!fighter.id_user">
              {{'TEXT.creatingProfile'|translate}}</div>
              <!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
               <div class="ml-1">
                  <div class="btn-group" mdbDropdown>
                    <button mdbDropdownToggle type="button" class="contact-btn" mdbBtn>
                      <mdb-icon far icon="paper-plane" class="mr-1"></mdb-icon>{{'BUTTON.write'|translate}}
                    </button>
                    <div class="dropdown-menu dropdown-dark ">
                      <!--<a class="dropdown-item" (click)="showAddToFightModal()">{{'BUTTON.ADD_TO_FIGHT'| translate}} </a>
                      <a class="dropdown-item" (click)="showContactForm(['manager'])">{{'BUTTON.MAIL_MANAGER'| translate}} </a>
                      <a class="dropdown-item" (click)="showContactForm(['fighter','manager'])">{{'BUTTON.MAIL_MANAGER_AND_FIGHTER'| translate}} </a>-->
                      <a class="dropdown-item" *ngIf="fighter.id_manager_profile" (click)="showMessageForm('manager',fighter.id_fighter_profile,fighter.id_manager_profile)">{{'BUTTON.MESSAGE_MANAGER'| translate}} </a>
                      <a class="dropdown-item" *ngIf="!fighter.id_manager_profile" style="color:var(--mma-red);">{{ "BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}</a>
                      <a class="dropdown-item" (click)="showMessageForm('fighter',fighter.id_fighter_profile,fighter.id_manager_profile)">{{'BUTTON.MESSAGE_FIGHTER'| translate}} </a>
                    </div>
                  </div>
                </div>
            </div>
          <h5 class="country"><i class="fas fa-map-marker-alt"></i>&nbsp;{{fighter.label_country}} / {{fighter.living_city}} <img src="assets/flags/{{fighter.flag_country|lowercase}}.png" style="padding-bottom:3px;"/></h5>
          <div *ngIf="fighter.is_pro" class="progress">
            <div class="progress-bar bg-success" role="progressbar"
              style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory}}</div>
            <div class="progress-bar bg-danger" role="progressbar"
              style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat}}</div>
            <div class="progress-bar bg-warning" role="progressbar"
              style="width: 33.33%" aria-valuenow="{{fighter.null_percent}}">D{{fighter.number_null}}</div>
          </div>
          <div *ngIf="!fighter.is_pro" class="progress">
            <div class="progress-bar bg-success" role="progressbar"
              style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory_amateur}}</div>
            <div class="progress-bar bg-danger" role="progressbar"
              style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat_amateur}}</div>
            <div class="progress-bar bg-warning" role="progressbar"
              style="width: 33.33%">D{{fighter.number_draw_amateur}}</div>
          </div>
          <br/>
          <a href="{{fighter.tapology}}" target="_blank" *ngIf="fighter.tapology">{{'TEXT.palmares'|translate}}</a>
          <span *ngIf="!fighter.tapology">{{'TEXT.NO_SHERDOG'|translate}}</span>

      </mdb-card-title>

    <div class="text-left">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">{{'FORM.SEARCH_CLUB'|translate}} : <span *ngIf="fighter.id_club != null">{{fighter.club_label}}</span><span *ngIf="fighter.id_club == null">{{'TEXT.NOT_SPECIFIED'|translate}}</span></li>
        <li class="list-group-item" *ngIf="!fighter.living_city"><mdb-icon fas icon="map-marker-alt"></mdb-icon> - </li>
        <li class="list-group-item" *ngIf="fighter.birth_date"><mdb-icon far icon="calendar-alt"></mdb-icon> {{fighter.birth_date}} {{fighter.age}}</li>
        <li class="list-group-item" *ngIf="!fighter.birth_date"><mdb-icon far icon="calendar-alt"></mdb-icon> -</li>
        <li class="list-group-item">
          <img src='/assets/icone/poids.svg' style="height: 20px; weight:20px;">
          {{fighter.weight_lbs}}lbs ({{fighter.weight_kg}}kg)<br/>
          <small>{{'TEXT.updatedAt' | translate}} : {{fighter.lastupdate_weight_date | date:'shortDate':undefined : locale}}</small>
        </li>
        <li class="list-group-item"><img src='/assets/icone/human-male-height.svg'> <span *ngIf="fighter.height_feet">{{fighter.height_feet}}" ({{fighter.height_cm}} cm)</span><span *ngIf="!fighter.height_feet"> -</span></li>
        <li class="list-group-item"><img src='/assets/icone/gloves-grey.svg' style="height: 20px; weight:20px;"> {{fighter.label_weight_category_1}} {{fighter.label_weight_category_2}}</li>
        <li class="list-group-item" *ngIf="fighter.show_informations && fighter.phone"><mdb-icon fas icon="phone-alt"></mdb-icon>&nbsp;<a href="tel:{{fighter.phone}}">{{fighter.phone}}</a></li>
        <li class="list-group-item" *ngIf="fighter.show_informations && !fighter.phone"><mdb-icon fas icon="phone-alt"></mdb-icon>&nbsp;-</li>
        <li class="list-group-item" *ngIf="fighter.show_informations && fighter.email"><mdb-icon far icon="envelope"></mdb-icon>&nbsp;<a href="mailto:{{fighter.email}}">{{fighter.email}}</a></li>
        <li class="list-group-item" *ngIf="fighter.show_informations && !fighter.email"><mdb-icon far icon="envelope"></mdb-icon>&nbsp;-</li>
      </ul>
      </div>
      <div class="row mt-2 mb-3 d-flex justify-content-center">
        <!--Facebook-->
        <a href="{{fighter.facebook}}" target="_blank" *ngIf="fighter.facebook" class="fa-lg p-2 m-2 fb-ic">
          <mdb-icon fab icon="facebook" size="lg" class="grey-text" ></mdb-icon>
        </a>
        <!--Twitter-->
        <a href="https://www.twitter.com/{{fighter.twitter}}" target="_blank"  *ngIf="fighter.twitter" class="fa-lg p-2 m-2 tw-ic">
          <mdb-icon fab icon="twitter" size="lg" class="grey-text"></mdb-icon>
        </a>
        <!--Instagram +-->
        <a  href="{{fighter.instagram}}" target="_blank"  *ngIf="fighter.instagram" class="fa-lg p-2 m-2 gplus-ic">
        <mdb-icon fab icon="instagram" size="lg" class="grey-text"></mdb-icon>
        </a>
        <!--Youtube +-->
        <a  href="https://www.youtube.com/{{fighter.youtube}}" target="_blank"  *ngIf="fighter.youtube" class="fa-lg p-2 m-2 gplus-ic">
        <mdb-icon fab icon="youtube" size="lg" class="grey-text"></mdb-icon>
        </a>
      </div>
      <hr>
        <h5>{{'TEXT.MANAGER'|translate}}</h5>
        <div class="row mt-2 mb-3 d-flex justify-content-center" *ngIf="fighter.id_manager_profile">
          <div class="col-4">
            <a routerLink="/managers/{{fighter.id_manager_profile}}"><img *ngIf="fighter.manager_profile_pic" src="{{globals.publicPathImage}}/pictures/{{fighter.manager_profile_pic}}" class="img-fluid"></a>
            <img *ngIf="!fighter.manager_profile_pic" src="{{globals.publicPathImage}}/pictures/default.png" class="img-fluid">
          </div>
          <div class="col-8 text-left mt-3">
              <a routerLink="/managers/{{fighter.id_manager_profile}}" class="text-dark">{{fighter.manager_enterprise_name}}<br/>({{fighter.manager_name}} {{fighter.manager_firstname}})</a>
          </div>
        </div>
        <div class="row mt-2 mb-3 d-flex justify-content-center text-danger" *ngIf="!fighter.id_manager_profile">
            {{"BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}
          </div>
    </mdb-card-body>
    </mdb-card>
  </div>
  <!--COL FIGHTS-->
  <div class="col-md-7 col-xl-8 col-lg-8 col-sm-6 mt-4 mb-2">
    <h4>{{'TEXT.UNAVAILABILITY_DATES'|translate}}</h4>
    <ng-template #loading>
      <div class="text-center">
        <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
        Loading events...
      </div>
    </ng-template>
    <div>
    <div class="row">
      <div class="col-5 text-left">
          <div
            class="btn btn-outline-dark"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            {{'TEXT.TODAY'|translate}}
          </div>
          <a><mdb-icon fas icon="chevron-left" mwlCalendarPreviousView
          style="font-size:24px;margin-right:15px;margin-left:20px;"
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"></mdb-icon></a>
          <a><mdb-icon fas icon="chevron-right"
            style="font-size:24px;"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
          </mdb-icon></a>
      </div>
      <div class="col-md-7 text-left">
          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}</h3>
      </div>
      </div>
      <div>
        <mwl-calendar-month-view
        [viewDate]="viewDate"
        [locale]="locale"
        [events]="events"
        [refresh]="refresh"
        (beforeViewRender)="beforeMonthViewRender($event)"
        ></mwl-calendar-month-view>
      </div>
      </div>
  </div>
  </div>
</div>

<!-- MODAL CONTACT-->
<div mdbModal #contactModal="mdbModal" class="modal fade left" id="contactModal" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MESSAGE'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="contactModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="contactForm">
					<div class="md-form md-outline mb-5">
						<mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
						<input type="text" id="subject" class="form-control" mdbInput mdbValidate
							formControlName="subject">
						<label for="subject">{{'FORM.SUBJECT'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.subject">
							<mdb-error
								*ngIf="contactForm.get('subject').hasError(validation.type) && contactForm.get('subject').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="message" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="message">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="contactForm.get('message').hasError(validation.type) && contactForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn color="light" (click)='contactModal.hide()'>{{'BUTTON.CLOSE'|translate}}
				</button>
				<button mdbBtn color="dark" (click)='submitContactForm()' [disabled]="!contactForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>{{'BUTTON.SEND'|translate}}
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>
<!-- MODAL AJOUT A COMBAT-->
<div mdbModal #addToFightModal="mdbModal" class="modal" id="addToFightModal" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: scroll">
	<div class="modal-dialog modal-fluid" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold" *ngIf="fighter">Ajouter
					{{fighter.firstname}} {{fighter.name}} à un combat</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="addToFightModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="addToFightForm">
						<div class="form-row">
							<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div class="md-form md-outline mb-5">
								<mdb-select-2 [outline]="true" formControlName="id_fightcard"
									placeholder="{{'FORM.CHOOSE_FIGHTCARD' | translate}}">
									<mdb-select-option *ngFor="let fightcard of fightcardList"
										[value]="fightcard.id_fightcard">{{ fightcard.event_name }}</mdb-select-option>
								</mdb-select-2>
								<mdb-error
									*ngIf="addToFightForm.get('id_fightcard').hasError('required') && addToFightForm.get('id_fightcard').touched">
									{{'ERROR.SELECT_FIGHTCARD'}}</mdb-error>
							</div>
							<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<button mdbBtn class="btn-mma-blue" (click)='getFightList()'
									[disabled]="!addToFightForm.valid || isSending">
									<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>
									{{'BUTTON.SEE_FIGHT'|translate}}
								</button>
							</div>
						</div>
					</div>
				</form>

				<div *ngIf="fightList">
          <p *ngIf="!fightList.length">{{'TEXT.NO_FIGHT_IN_THIS_FIGHTCARD' | translate}}</p>
					<div *ngFor="let fight of fightList">
						<app-fight [fight]='fight' [fighter]='fighter' class="row"></app-fight>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

<!--DELETE FIGHTER FROM FIGHT-->

<!-- MODAL MESSAGE CONTACT-->
<div mdbModal #messageModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MESSAGE'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="messageModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="messageForm">

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="message" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="message">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="messageForm.get('message').hasError(validation.type) && messageForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn class="btn-mma-blue" (click)='submitMessageForm()' [disabled]="!messageForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>Send
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>
