import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// import Components

import {LoginComponent} from './components/users/login/login.component';
import {RegisterComponent} from './components/users/register/register.component';
import { MobileAppSubscriptionComponent } from './components/mobile-app-subscription/mobile-app-subscription.component';
//import {HomeComponent} from './components/users/home/home.component';



const routes: Routes = [
{ path: '', redirectTo: '/home', pathMatch: 'full'},
{ path: 'login', component: LoginComponent },
//{ path: 'home', component: HomeComponent, canActivate: [AuthGuardModule]   },
{ path: 'register', component: RegisterComponent},
{ path: 'mobile-subscription/:bearer', component: MobileAppSubscriptionComponent},
];
@NgModule({
declarations: [],
imports: [
CommonModule,
RouterModule.forRoot(routes)
],
exports: [RouterModule]
})
export class AppRoutingModule { }
