import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TaAppFightService } from '../../../services/ta-app-fight.service';
import {TaAppFightClaimService} from '../../../services/ta-app-fight-claim.service';
import { TranslateService } from '../../../translate';
import {AlertService} from '../../../services/alert.service';
import { ActivatedRoute, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { TaAppCardFighterService } from '../../../services/ta-app-card-fighter.service';
import {ReferentielService} from '../../../services/referentiel.service';
import { FightCard } from '../../../models/fightCard';
import {ContactService} from '../../../services/contact.service';
import { Globals } from '../../../globals';
import {MDBModalRef, MDBModalService,ModalDirective} from 'ng-uikit-pro-standard';
import {ModalAddFighterComponent} from '../modal-add-fighter/modal-add-fighter.component';
import {ModalDelFighterFromFightComponent} from '../modal-del-fighter-from-fight/modal-del-fighter-from-fight.component';
import { ChatRoomService } from '../../../services/chat-room.service';
import { ChatService } from '../../../services/chat.service';
import {FighterService} from '../../../services/fighters.service';
import { TaAppFightcardCandidateService } from '../../../services/ta-app-fightcard-candidate.service';
import * as moment from 'moment';
@Component({
  selector: 'app-fight-list',
  templateUrl: './fight-list.component.html',
  styleUrls: ['./fight-list.component.scss']
})
export class FightListComponent implements OnInit {
  @ViewChild('modalNextStep') modalNextStep:ModalDirective;
  @ViewChild('modalFighterDetails') modalFighterDetails:ModalDirective;
  @ViewChild('modalDeleteFight') modalDeleteFight:ModalDirective;
  @ViewChild('modalEditFight') modalEditFight:ModalDirective;
  @ViewChild('contactModal') contactModal:ModalDirective;
  @ViewChild("modalDisplayMedicalFolder") modalDisplayMedicalFolder;
  @ViewChild("modalSendMessage") modalSendMessage;
  @ViewChild("modalResults") modalResults;
  @ViewChild('modalCandidateDetails') modalCandidateDetails:ModalDirective;
  public erreurMessage: string;
  fightForm: FormGroup;
  fightCard: FightCard = <any>{};
  msgNoResult: any;
  listFights: any = [];
  modalRef: MDBModalRef;
  isLoading : boolean = true;

  //data = this.transfereService.getData();
  private filter = {
			id_fightcard : null
		  }
  fightClaimList: any;
  claim: any=null;
  fight: any;
  selectedFight : any;

  isSending: boolean;
  selectedFighter: any;
  selectedManager: any;
  contactType: any;
  contact_validation_messages = {
        "message" : null
        //"subject" : null
      };
  fight_form_validation_messages = {
        //'weight_category' : null,
        'sex' :null,
        "type_fight" : null,
      };
  contactForm: FormGroup;
  successResult: boolean;
  errorResult: boolean;
  weightCategoryList: any;
  sexList: any;
  fightTypeList: any;
  isSaving : boolean = false;
  filterWeightCategoriesList: any;
  mealList: any;
  lastLicenseText: string = null;
  showNbMinWinField: boolean = true;
  showMedicalLoader: boolean = false;
  showErrorMedicalInformation: boolean=false;
  fighter: any;
  isDeclining: boolean;
  sendMessageForm : FormGroup;
  listFile : Array<File> = [];
  sendMessageEnCour: boolean;
  sendMessageSuccess = false;
  totalFilesSize : number = 0;
  canEditedResults : boolean = false; // Si on peux rentrer les résultats (combat passé mais depuis moins de 30 jours)
  resultsSuccess : boolean = false; // Afficher le message de succès dans la modal
  resultsForm : FormGroup;
  exp_rookie_list : any;
  deleteLoading: boolean = false;
  candidateList: Array<any>;
  selectedFighterForDetails : any;
  searchForm = {
    name: null,
    id_living_country: null,
    id_sex: null,
    id_nationnality: null,
    id_weight_category_1: null,
    id_weight_category_2 : null,
    is_rookie: false,
    min_fight: null,
    max_fight :null,
    id_club : null
  };
  constructor(
    public fb: FormBuilder,
    public translateService: TranslateService,
			private fightService: TaAppFightService,
      private referentielService : ReferentielService,
      private fightClaimService : TaAppFightClaimService,
      private contactService : ContactService,
			private router:Router,  private route: ActivatedRoute,
			public fightCardService: TaAppCardFighterService,
			private modalService: MDBModalService,
			public globals: Globals,
      private alertService : AlertService,
      private chatRoomService: ChatRoomService,
      private chatService: ChatService,
      private fighterProfileService : FighterService,
      private fightcardCandidateService : TaAppFightcardCandidateService
			/*private routeSegement: RouteSegment*/

			) {
        this.contactForm = new FormGroup({
              //subject: new FormControl('', Validators.required),
              message: new FormControl('', Validators.required)
            });
            this.fightForm =
              new FormGroup({
                'id_fight' : new FormControl(null),
                'id_weight_category' : new FormControl(null,Validators.compose([
                  Validators.required
                ])),
                'id_fightcard' : new FormControl(null),
                'id_sex' : new FormControl(null,Validators.compose([
                  Validators.required
                ])),
                'nb_round' : new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
                'time_round_m' : new FormControl(null, Validators.compose([Validators.min(0), Validators.max(59), Validators.required])),
                //'time_round_s' : new FormControl(0, Validators.compose([Validators.min(0), Validators.max(59), Validators.required])),
                'description' : new FormControl(null),
                'is_public' : new FormControl(false),
                'is_rookie' : new FormControl(false),
                'min_cash_prize' : new FormControl(null),
                'max_cash_prize' : new FormControl(null),
                'catchweight_lbs' : new FormControl(null),
                'catchweight_kg' : new FormControl(null),
                'is_catchweight' : new FormControl(false),
                'certif_medical' : new FormControl(null),
                'blood_test' : new FormControl(null),
                'brain_test' : new FormControl(null),
                'stress_test' : new FormControl(null),
                'eye_test' : new FormControl(null),
                'angio_mri_cerebral_test' : new FormControl(null),
                'ecg_test' : new FormControl(null),
                //'cash_prize' : new FormControl(null, Validators.compose([Validators.min(0)])),
                'hotel' : new FormControl(null),
                'transport' : new FormControl(null),
                'meal' : new FormControl(null),
                'accompanying' : new FormControl(null),
                'license' : new FormControl(null),
                'license_informations' : new FormControl(null),
                'nb_min_win' : new FormControl(0),
                'nb_min_fight' : new FormControl(0),
                'nb_max_fight' : new FormControl(0),
                'min_age' : new FormControl(0),
                'max_age' : new FormControl(0),
                'id_exp_amateur' : new FormControl(null)
              });
              this.fight_form_validation_messages = {
                /*'weight_category': [
                  { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
                ],*/
                'type_fight': [
                  { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
                ],
                'sex': [
                  { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
                ]
              };
            this.contact_validation_messages = {
              'message': [
                { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
              ],
              /*'subject': [
                { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
              ]*/
            };
            this.sendMessageForm = new FormGroup({'message': new FormControl('', Validators.compose([Validators.minLength(1), Validators.required]))});
            this.sendMessageEnCour = false;
            this.resultsForm = new FormGroup({
              'winner' : new FormControl(null, Validators.compose([Validators.required])),
              message: new FormControl('', Validators.required)
            });
			}

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.filter.id_fightcard = id;
    this.getFights();
    this.getFightClaims();
    //Weight category
    this.referentielService.getWeightCategories().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.weightCategoryList = jsonResponse.rows;
        this.filterWeightCategoriesList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
    //Type de combat
    this.referentielService.getFightType().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.fightTypeList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
    //Repas
    this.referentielService.getMeals().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.mealList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
    // exp_amateur
    this.referentielService.getExpRookie().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.exp_rookie_list = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }

  //récupération des postulants aux combats
  getFightClaims() {
    this.fightClaimService.getListFightClaim(this.filter).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        this.fightClaimList = jsonResponse.rows;
      }else{
        this.erreurMessage = jsonResponse.msg;
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }
  //Récupération des combats
	getFights(){
    this.isLoading = true;
		this.listFights = [];
		this.fightService
				.getListFights(this.filter)
				.subscribe(jsonResponse => {
          this.isLoading = false;
					if (jsonResponse.success === true) {
						this.listFights = jsonResponse.rows;
						if(jsonResponse.total > 0){
              	this.listFights.forEach(element => {
                  //mise en pourcentage des victoires/defaites/null pour l'affichage en progresbar
  									element.victory_percent_fighter_1 = ((element.number_victory_fighter_1 != null)?element.number_victory_fighter_1/element.total_fight_fighter_1 * 100:0);
  									element.defeat_percent_fighter_1 = ((element.number_defeat_fighter_1 != null)?element.number_defeat_fighter_1/element.total_fight_fighter_1 * 100:0);
  									element.null_percent_fighter_1 = ((element.number_null_fighter_1 != null)?element.number_null_fighter_1/element.total_fight_fighter_1 * 100:0);
  									element.victory_percent_fighter_2 = ((element.number_victory_fighter_2 != null)?element.number_victory_fighter_2/element.total_fight_fighter_2 * 100:0);
  									element.defeat_percent_fighter_2 = ((element.number_defeat_fighter_2 !=null)?element.number_defeat_fighter_2/element.total_fight_fighter_2 * 100:0);
  									element.null_percent_fighter_2 = ((element.number_null_fighter_2 != null)?element.number_null_fighter_2/element.total_fight_fighter_2 * 100:0);
  									element.victory_percent_fighter_3 = ((element.number_victory_fighter_3 != null)?element.number_victory_fighter_3/element.total_fight_fighter_3 * 100:0);
  									element.defeat_percent_fighter_3 = ((element.number_defeat_fighter_3!=null)?element.number_defeat_fighter_3/element.total_fight_fighter_3 * 100:0);
  									element.null_percent_fighter_3 = ((element.number_null_fighter_3 != null)?element.number_null_fighter_3/element.total_fight_fighter_3 * 100:0);
              });
              if(this.fightCard){
                this.fightCard.id_fightcard = this.listFights[0].id_fightcard;
                this.fightCard.event_name = this.listFights[0].event_name;
                this.fightCard.datetime_event = this.listFights[0].datetime_event;
                this.fightCard.place = this.listFights[0].place;
                this.fightCard.logo = this.listFights[0].logo;
                this.fightCard.description = this.listFights[0].desc_fightcard;
                this.fightCard.nb_round = this.listFights[0].nb_round;
                if(this.listFights[0].time_round){
                  let times = this.listFights[0].time_round.split(':');
                  this.fightCard.time_round_m = parseInt(times[1]);
                  //this.fightCard.time_round_s = parseInt(times[2]);
                }else{
                  this.fightCard.time_round_m = 0;
                  //this.fightCard.time_round_s = 0;
                }
                this.fightCard.price = this.listFights[0].price;
                this.canEditedResults = this.getCanEditedResults(this.fightCard.datetime_event);
                this.getCandidates();
              }
						}else{
							this.fightCardService
							.getListFightCard(this.filter)
							.subscribe(jsonResponse => {
								if (jsonResponse.success === true) {
									this.fightCard = jsonResponse.rows[0];
                  this.canEditedResults = this.getCanEditedResults(this.fightCard.datetime_event);
                  this.getCandidates();
								}else{
                  //normalement la seule erreur possible est l'accès à une fightcard qui ne lui appartient pas
                  this.erreurMessage = jsonResponse.msg;
                  //on laisse 3s l'erreur puis on redirige à l'accueil
                  setTimeout(function(){
                    window.location.replace('/home');
                  },3000);
                }
							},error=>{
                this.isLoading = false;
                this.alertService.alertError(error.status);
              },()=>{}//complete
            );

						}
					} else {


					}
					// console.log(localStorage.getItem('token'));
				});
	}
	routerOnActivate(){

	}

	addFightertofight(fight: any, num_fighter: any){
    let modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    scroll : true,
    class: 'modal-notify modal-info modal-lg',
    ignoreBackdropClick: true,
    containerClass: '',
    animated: true,
    data : {
      fight : fight
    }
  }
		this.modalRef = this.modalService.show(ModalAddFighterComponent,modalOptions);
	    this.modalRef.content.saveButtonClicked.subscribe((fighter: any) => {
			this.addFightertofightRequest(fight,fighter,num_fighter);
	        // lance la fonction d'ajout
	        //this.Add(newElement);
	    });
	}

	addFightertofightRequest(fight,fighter:any, num_fighter: number){
		let params = {
      id_fight : fight.id_fight,
			id_fighter_1: fight.id_fighter_1,
			id_fighter_2: fight.id_fighter_2,
			id_fighter_reserve: fight.id_fighter_reserve,
      id_fight_claim : ((this.claim)?this.claim.id_fight_claim:null)
		  }
		switch(num_fighter) {
			   case 1: {
			      //statements;
				  params.id_fighter_1 = fighter.id_fighter_profile;
			      break;
			   }
			   case 2: {
				  params.id_fighter_2 = fighter.id_fighter_profile;
			      //statements;
			      break;
			   }
			  case 3: {
			      //statements;
				  params.id_fighter_reserve = fighter.id_fighter_profile;
			      break;
			   }
			   default: {
			      //statements;
			      break;
			   }
		}
		this.fightService.updateFighterFromFight(params).subscribe(jsonResponse => {
								if (jsonResponse.success == true) {
									this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHTER_ADDED'),this.translateService.instant('SUCCESS','TITLE'));
                  switch(num_fighter) {
                  case 1: {
                    fight.id_fighter_1 = fighter.id_fighter_profile;
          				  fight.profile_pic_fighter_1 = fighter.profile_pic;
                    fight.firstname_fighter_1 = fighter.firstname;
                    fight.name_fighter_1 = fighter.name;
                    fight.nickname_fighter_1 = fighter.nickname;
                    fight.total_fight_fighter_1 = fighter.total_fight;
                    fight.number_victory_fighter_1 = fighter.number_victory;
                    fight.number_defeat_fighter_1 = fighter.number_defeat;
                    fight.victory_percent_fighter_1 = (fighter.number_victory/fighter.total_fight)*100;;
                    fight.defeat_percent_fighter_1 = (fighter.number_defeat/fighter.total_fight)*100;;
                    fight.null_percent_fighter_1 = (fighter.number_null/fighter.total_fight)*100;
                    fight.number_null_fighter_1 = fighter.number_null;
          			      break;
          			   }
          			   case 2: {
                     fight.id_fighter_2 = fighter.id_fighter_profile;
                     fight.profile_pic_fighter_2 = fighter.profile_pic;
                     fight.firstname_fighter_2 = fighter.firstname;
                     fight.nickname_fighter_2 = fighter.nickname;
                     fight.name_fighter_2 = fighter.name;
                     fight.total_fight_fighter_2 = fighter.total_fight;
                     fight.number_victory_fighter_2 = fighter.number_victory;
                     fight.number_defeat_fighter_2 = fighter.number_defeat;
                     fight.victory_percent_fighter_2 = (fighter.number_victory/fighter.total_fight)*100;;
                     fight.defeat_percent_fighter_2 = (fighter.number_defeat/fighter.total_fight)*100;;
                     fight.null_percent_fighter_2 = (fighter.number_null/fighter.total_fight)*100;
                     fight.number_null_fighter_2 = fighter.number_null;
          			      break;
          			   }
          			  case 3: {
          				  fight.id_fighter_reserve = fighter;
          			      break;
          			   }
          			   default: {
          			      //statements;
          			      break;
          			   }
                }
              }else{
                this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
              }
            },error=>{
              this.alertService.alertError(error.status);
            });

	}

	removeFighterFromFight(fight,position: number,fighterId,firstname,name){
    //this.modalNextStep.show();
    let modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: 'modal-notify modal-danger',
    containerClass: '',
    animated: true,
    data: {
        id_fight: fight.id_fight,
        position : position,
        fighterIdentity : firstname+' '+name,
        fighterId : fighterId
    }
  }
    this.modalRef = this.modalService.show(ModalDelFighterFromFightComponent,modalOptions);
    //récupération de la valeur après le click sur le bouton
    this.modalRef.content.deleteButtonClicked.subscribe((jsonResponse)=>{
      if(jsonResponse.success){
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHTER_DELETED'),this.translateService.instant('SUCCESS','TITLE'));
        switch(position) {
          case 1: {
             //statements;
           fight.id_fighter_1 = null;
           fight.profile_pic_fighter_1 = null;
           fight.id_manager_profile_fighter_1 = null;
           fight.firstname_fighter_1 = null;
           fight.name_fighter_1 = null;
           fight.nickname_fighter_1 = null;
           fight.number_victory_fighter_1 = null;
           fight.number_defeat_fighter_1 = null;
           fight.victory_percent_fighter_1 = null;
           fight.defeat_percent_fighter_1 = null;
           fight.null_percent_fighter_1 = null;
           fight.number_null_fighter_1 = null;
             break;
          }
          case 2: {
           fight.id_fighter_2 = null;
           fight.profile_pic_fighter_2 = null;
           fight.id_manager_profile_fighter_2= null;
           fight.firstname_fighter_2 = null;
           fight.name_fighter_2 = null;
           fight.nickname_fighter_2 = null;
           fight.number_victory_fighter_2 = null;
           fight.number_defeat_fighter_2 = null;
           fight.victory_percent_fighter_2 = null;
           fight.defeat_percent_fighter_2 = null;
           fight.null_percent_fighter_2 = null;
           fight.number_null_fighter_2 = null;
             //statements;
             break;
          }
         case 3: {
             //statements;
           fight.id_fighter_reserve = null;
           fight.profile_pic_fighter_reserve  = null;
           fight.id_manager_profile_fighter_reserve  = null;
           fight.firstname_fighter_reserve  = null;
           fight.name_fighter_reserve  = null;
           fight.nickname_fighter_reserve  = null;
           fight.number_victory_fighter_reserve  = null;
           fight.number_defeat_fighter_reserve  = null;
           fight.victory_percent_fighter_reserve  = null;
           fight.defeat_percent_fighter_reserve  = null;
           fight.null_percent_fighter_reserve  = null;
           fight.number_null_fighter_reserve  = null;
             break;
          }
   			   default: {
   			      //statements;
   			      break;
   			   }
         }
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    });

	}

goToAddFightPage(){
  //create-fight
  this.router.navigateByUrl('create-fight/'+this.fightCard.id_fightcard);
}
showFighterDetails(fight, claim){
  this.claim = claim;
  this.fight = fight;
  //calcul des pourcentage pour affichage dans la vue
  this.claim.victory_percent = (this.claim.number_victory/this.claim.total_fight)*100;
  this.claim.defeat_percent = (this.claim.number_defeat/this.claim.total_fight)*100;
  this.claim.null_percent = (this.claim.number_null/this.claim.total_fight)*100;
  this.modalFighterDetails.show();
}
showEditFightModal(fight){
  this.selectedFight = fight;
  if(fight.time_round){
    let times = this.selectedFight.time_round.split(':');
    this.selectedFight.time_round_m = parseInt(times[1]);
    //this.selectedFight.time_round_s = parseInt(times[2]);
  }else{
    this.selectedFight.time_round_m = 0;
    //this.selectedFight.time_round_s = 0;
  }

  let varCatchweight = false;
  if(fight.catchweight_lbs != null || fight.catchweight_kg!= null){
    varCatchweight = true;
  }

  let varRookie = 'false';
  if(fight.is_rookie == true){
    varRookie = 'true';
  }

  this.fightForm.setValue({
    id_fight : fight.id_fight,
    id_weight_category : fight.id_weight_category,
    id_fightcard : fight.id_fightcard,
    id_sex : fight.id_sex,
    description : fight.description,
    nb_round: fight.nb_round,
    time_round_m: fight.time_round_m,
    //time_round_s: fight.time_round_s,
    license : fight.license,
    license_informations : fight.license_informations,
    is_public : fight.is_public,
    is_rookie : varRookie,
    min_cash_prize : fight.min_cash_prize,
    max_cash_prize : fight.max_cash_prize,
    //is_catchweight : (fight.catchweight_lbs || fight.catchweight_kg)?'true':'false', // Ne fonctionne plus
    is_catchweight : varCatchweight,
    catchweight_lbs : fight.catchweight_lbs,
    catchweight_kg : fight.catchweight_kg,
    certif_medical : fight.certif_medical,
    blood_test : fight.blood_test,
    brain_test : fight.brain_test,
    stress_test : fight.stress_test,
    eye_test : fight.eye_test,
    angio_mri_cerebral_test : fight.angio_mri_cerebral_test,
    ecg_test : fight.ecg_test,
    hotel : fight.hotel,
    transport : fight.transport,
    meal : fight.meal,
    id_exp_amateur : fight.id_exp_amateur,
    accompanying : fight.accompanying,
    nb_min_win : fight.nb_min_win,
    nb_min_fight : fight.nb_min_fight,
    nb_max_fight : fight.nb_max_fight,
    min_age : fight.min_age,
    max_age : fight.max_age
  });

  /*
    this.fightForm =
      new FormGroup({
    'id_fight' : new FormControl(fight.id_fight),
    'id_weight_category' : new FormControl(fight.id_weight_category,Validators.compose([
      Validators.required
    ])),
    'id_fightcard' : new FormControl(fight.id_fightcard),
    'id_sex' : new FormControl(fight.id_sex,Validators.compose([
      Validators.required
    ])),
    'description' : new FormControl(fight.description),
    'license' : new FormControl(fight.license),
    'license_informations' : new FormControl(fight.license_informations),
    'is_public' : new FormControl(fight.is_public),
    'is_rookie' : new FormControl((fight.is_rookie)?'true':'false'),
    'is_reserve' : new FormControl(fight.is_reserve),
    'min_cash_prize' : new FormControl(fight.min_cash_prize),
    'max_cash_prize' : new FormControl(fight.max_cash_prize),
    'catchweight_lbs' : new FormControl(fight.catchweight_lbs),
    'catchweight_kg' : new FormControl(fight.catchweight_kg),
    'is_catchweight' : ((fight.id_weight_category)?new FormControl(false):new FormControl(true)),
    'certif_medical' : new FormControl(fight.certif_medical),
    'blood_test' : new FormControl(fight.blood_test),
    'brain_test' : new FormControl(fight.brain_test),
    'stress_test' : new FormControl(fight.stress_test),
    'eye_test' : new FormControl(fight.eye_test),
    'angio_mri_cerebral_test' : new FormControl(fight.angio_mri_cerebral_test),
    'ecg_test' : new FormControl(fight.ecg_test),
    'hotel' : new FormControl(fight.hotel),
    'transport' : new FormControl(fight.transport),
    'meal' : new FormControl(fight.meal),
    'accompanying' : new FormControl(fight.accompanying),
    'nb_min_win' : new FormControl(fight.nb_min_win),
    'nb_min_fight' : new FormControl(fight.nb_min_fight),
    'nb_max_fight' : new FormControl(fight.nb_max_fight),
    'min_age' : new FormControl(fight.min_age,Validators.min(12)),
    'max_age' : new FormControl(fight.max_age)
});*/

    //on filtre la liste des poids de combats
    this.filterWeightCategories(null);
    this.modalEditFight.show();
}
editFight(){
this.isSaving = true;
  let datas = this.fightForm.getRawValue();
  datas.time_round = '00:' + moment(this.fightForm.get('time_round_m').value.toString() + ':' + "00", "mm:ss").format("mm:ss");
  this.fightService.update(datas).subscribe(jsonResponse=>{
    this.isSaving = false;
    if(jsonResponse.success == true){
      this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHT_UPDATED'),this.translateService.instant('SUCCESS','TITLE'));
    this.modalEditFight.hide();
    //récupération et mise à jour
    let index = this.listFights.indexOf(this.selectedFight);
    this.listFights[index].id_weight_category = datas.id_weight_category;
    this.listFights[index].id_type_fight = datas.id_type_fight;
    this.listFights[index].id_fightcard = datas.id_fightcard;
    this.listFights[index].id_sex = datas.id_sex;
    this.listFights[index].description = datas.description;
    this.listFights[index].nb_round = datas.nb_round;
    this.listFights[index].time_round = datas.time_round;
    let times = datas.time_round.split(':');
    this.listFights[index].time_round_m = times[1];
    //this.listFights[index].time_round_s = times[2];
    this.listFights[index].license = datas.license;
    this.listFights[index].license_informations = datas.license_informations;
    this.listFights[index].is_public = datas.is_public;
    if(datas.is_rookie == "true"){ // Eviter que is_rookie soit undifined au lieu de false
      this.listFights[index].is_rookie = true;
    }else{
      this.listFights[index].is_rookie = false;
    }
    this.listFights[index].is_reserve = datas.is_reserve;
    this.listFights[index].min_cash_prize = datas.min_cash_prize;
    this.listFights[index].max_cash_prize = datas.max_cash_prize;
    this.listFights[index].catchweight_lbs = datas.catchweight_lbs;
    this.listFights[index].catchweight_kg = datas.catchweight_kg;
    this.listFights[index].certif_medical = datas.certif_medical;
    this.listFights[index].blood_test = datas.blood_test;
    this.listFights[index].brain_test = datas.brain_test;
    this.listFights[index].stress_test = datas.stress_test;
    this.listFights[index].eye_test = datas.eye_test;
    this.listFights[index].angio_mri_cerebral_test = datas.angio_mri_cerebral_test;
    this.listFights[index].ecg_test = datas.ecg_test;
    this.listFights[index].hotel = datas.hotel;
    this.listFights[index].transport = datas.transport;
    this.listFights[index].meal = datas.meal;
    this.listFights[index].id_exp_amateur = datas.id_exp_amateur;
    this.listFights[index].accompanying = datas.accompanying;
    this.listFights[index].nb_min_win = datas.nb_min_win;
    this.listFights[index].nb_min_fight = datas.nb_min_fight;
    this.listFights[index].nb_max_fight = datas.nb_max_fight;
    this.listFights[index].min_age = datas.min_age;
    this.listFights[index].max_age = datas.max_age;

    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
  },error=>{
    this.isSaving = false;
    this.alertService.alertError(error.status);
  });
}
deleteFight(){
  this.deleteLoading = true;
  //Requête
  this.fightService.delete(this.selectedFight.id_fight).subscribe(jsonResponse=>{
    //Succès
    if(jsonResponse.success){
      //Suppresion dans la vue
      let index = this.listFights.indexOf(this.selectedFight);
      this.listFights.splice(index, 1);
      //Fermeture de la modal
      this.modalDeleteFight.hide();
      this.deleteLoading = false;
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      this.deleteLoading = false;
    }
  },error=>{
    this.alertService.alertError(error.status);
    this.deleteLoading = false;
  });
}
addToFight(){
  //default
  let position = null;
  //TODO
  if(!this.fight.id_fighter_1 && !this.fight.id_fighter_2){
    this.addFightertofightRequest(this.fight,this.claim,1);
  }
  //si déjà un combatant en palce 1 => position = 2
  if(this.fight.id_fighter_1 && this.fight.id_fighter_1 != this.claim.id_fighter_profile){
    position = 2;
    //les données ont été enregistré à l'affichage de la modal
    this.addFightertofightRequest(this.fight,this.claim,position);
  }
  if(this.fight.id_fighter_2 && this.fight.id_fighter_2 != this.claim.id_fighter_profile){
    position = 1;
    //les données ont été enregistré à l'affichage de la modal
    this.addFightertofightRequest(this.fight,this.claim,position);
  }
  if(this.fight.id_fighter_2 && this.fight.id_fighter_1){
    this.alertService.showError(this.translateService.instant('ERROR','DELETE_FIGHTER_BEFORE_ADDING'),this.translateService.instant('ERROR','TITLE'));
    return;
  }

}
showContactForm(contactType,fighterId,managerId){
  this.selectedFighter = fighterId;
  this.selectedManager = managerId;
  this.contactType = contactType;
  this.contactModal.show();
}
submitContactForm(){
  this.isSending = true;
  let datas = this.contactForm.getRawValue();
datas.id_fighter_profile = this.selectedFighter;
datas.id_manager_profile =this.selectedManager;
datas.contactType = this.contactType;
//Récupération de la room en fonction du contact sélectionné
this.chatService.searchRoomForUser(datas).subscribe(jsonResponse=>{
  this.isSending = false;
  if(jsonResponse.success){
    //on envoie le message dans la room déjà créée
    if(jsonResponse.total > 0 && jsonResponse.rows[0].id_chat_room !=undefined){
      datas = {
        message : this.contactForm.get('message').value,
        user_creator_room_id : jsonResponse.rows[0].user_id_creator,
        id_chat_room : jsonResponse.rows[0].id_chat_room,
        user_id_receiver : jsonResponse.rows[0].user_id_receiver
      };
      this.sendInChatRoom(datas);
    }
    //on créée une nouvelle room et on envoie le message
    else{
      //stockage du roomId du user à contacter. On reçoit les informations sur le contact uniquement s'il n'y a pas de room existante
      let userRoomId = jsonResponse.rows[0].room_id;
      this.chatRoomService.createRoom(jsonResponse.rows[0].room_id).subscribe(jsonResponse=>{
        //succes création du message
        if(jsonResponse.success){
          datas = {
            message : this.contactForm.get('message').value,
            user_creator_room_id : jsonResponse.obj.user_creator_room_id,
            id_chat_room : jsonResponse.id,
            user_id_receiver : userRoomId
          };
          //envoi du message
          this.sendInChatRoom(datas);
        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
      },()=>{});
    }
  }else{
    this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
  }
},error=>{
  this.alertService.alertError(error.status);
},()=>{});

}
sendInChatRoom(datas){
  let formDatas:FormData = new FormData();
  formDatas.append('id_chat_room', datas.id_chat_room);
  formDatas.append('message', datas.message);
  formDatas.append('receiver_room_id', datas.user_id_receiver);
  this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
    if(jsonResponse.success == true){
      this.chatService.sendMessage(
        { roomId: datas.id_chat_room,
          message: datas.message,
          user_room_id:"",
          receiver_room_id : datas.user_id_receiver,
          create_datetime: new Date(),
          imageFromSocket : null
        }
      );
      this.alertService.showSuccess(this.translateService.instant('SUCCESS','MESSAGE_SENT'), this.translateService.instant('SUCCESS','TITLE'));
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
  },error=>{
    this.alertService.alertError(error.status);
  });
}
//Filtre les catégories de poids de combats homme/femme
filterWeightCategories(event){
  if(this.fightForm.get('id_sex').value == 1){
    this.filterWeightCategoriesList =  this.weightCategoryList.filter(function(item){
      //item trouvé
        if(item.for_men == true){
          return item;
        }
    });
  }
  if(this.fightForm.get('id_sex').value == 2){
    this.filterWeightCategoriesList =  this.weightCategoryList.filter(function(item){
      //item trouvé
        if(item.for_women == true){
          return item;
        }
    });
  }
}
convertWeightTo(to){
  if(to == 'kg'){
    this.fightForm.patchValue({
      catchweight_kg : this.fightForm.get('catchweight_lbs').value *0.453592
    });
  }if(to == 'lbs'){
    this.fightForm.patchValue({
      catchweight_lbs : this.fightForm.get('catchweight_kg').value *2.20462
    });
  }
}
onSwitch(){
  //si catchweight activé
  if(this.fightForm.get('is_catchweight').value){
    //clear weight_Categorie
    this.fightForm.controls['id_weight_category'].setValue(null);
    //disabled weight_categorie
    this.fightForm.controls['id_weight_category'].disable();
    this.fightForm.controls['catchweight_lbs'].enable();
    this.fightForm.controls['catchweight_kg'].enable();
  }else{
    //enable weight_categorie
    this.fightForm.controls['id_weight_category'].enable();
    //disabme and clear catchweight fields
    this.fightForm.controls['catchweight_lbs'].disable();
    this.fightForm.controls['catchweight_lbs'].setValue(null);
    this.fightForm.controls['catchweight_kg'].disable();
    this.fightForm.controls['catchweight_kg'].setValue(null);
  }
}

/**
 * Arrondie le nombre à la virgule voulue
 * @param inputName String: le champs du formulaire à arrondire
 * @param decimal Number: le nombre de chiffre après la virgule à garder
 * @param autoNull Boolean: si la valeur est égale à 0, met la valeur à null
 * @param negative Boolean: converti le chiffre en positif si il est négatif
 */
 roundInput(inputName, decimal, autoNull, negative){
  let value = this.fightForm.controls[inputName].value;
  if(!negative && value < 0){
    value = value * (-1);
  }
  value = value * Math.pow(10, decimal);
  value = Math.round(value);
  value = value/Math.pow(10, decimal);
  if(autoNull && value == 0){
    this.fightForm.controls[inputName].setValue(null);
  }else{
    this.fightForm.controls[inputName].setValue(value);
  }
}
switchLicense(){
  if(this.fightForm.get('license').value){
   this.fightForm.controls['license_informations'].enable();
   this.fightForm.controls['license_informations'].setValue(this.lastLicenseText);
 }else{
   this.fightForm.controls['license_informations'].disable();
   this.lastLicenseText = this.fightForm.controls['license_informations'].value;
   this.fightForm.controls['license_informations'].setValue(null);
 }
}

showMedicalModal(fighterId){
  //get fighter Profile
  this.showMedicalLoader = true;
  this.fighterProfileService.getProfile(fighterId).subscribe(jsonResponse=>{
    this.showMedicalLoader = false;
    if(jsonResponse.success){
      this.fighter = jsonResponse.rows[0];
    }else{
      this.showErrorMedicalInformation = true;
    }
  },error=>{
    this.showMedicalLoader = false;
    this.showErrorMedicalInformation = true;
  },()=>{});
  //dhow modal
  this.modalDisplayMedicalFolder.show();
}
refuseFighter(){
  this.isDeclining = true;
  this.fightService.declineFighterProposal(this.claim.id_fight_claim).subscribe(jsonResponse=>{
    this.isDeclining = false;
    if(jsonResponse.success){
      this.claim.is_refused_by_organizer = true;
      this.modalFighterDetails.hide();
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
  },error=>{
    this.isDeclining = false;
      this.alertService.alertError(error.status);
  },()=>{});
}
setRequiredFieldForAmateur(event){
  if(this.fightForm.get('is_rookie').value == 'true'){
    this.fightForm.controls['min_age'].setValidators(Validators.min(12));
  }else{
    this.fightForm.controls['min_age'].setValidators(Validators.min(0));
  }
}

scrollLeft(event){
  //récupération du div contenant les postulants
  let element;
  //clique sur l'icone, il faut remonter d'un niveau de plus
if(event.target.className == "fas fa-chevron-left"){
  element = event.target.parentNode.nextSibling;
}else{
  element = event.target.nextSibling;
}

    element.scrollTo({ left: (element.scrollLeft - 150), behavior: 'smooth' });
    //console.log("scrollLeft : ",this.widgetsContent.nativeElement.scrollLeft);
  }

  scrollRight(event){
    let element;
    //clique sur l'icone, il faut remonter d'un niveau de plus
  if(event.target.className == "fas fa-chevron-right"){
    element = event.target.parentNode.previousSibling;
  }else{
    element = event.target.previousSibling;
  }
  element.scrollTo({ left: (element.scrollLeft + 150), behavior: 'smooth' });
  }

  showSendMessageModal(){
    this.sendMessageSuccess = false;
    this.modalSendMessage.show();
  }

  sendMessage(){
    this.sendMessageEnCour = true;
    let formDatas = new FormData();
    formDatas.append('id_fightcard',this.fightCard.id_fightcard);
    formDatas.append('message',this.sendMessageForm.get('message').value);

    // Ajouter nb de fichiers
    formDatas.append('nbFiles', this.listFile.length.toString());
    // Ajouter les fichiers
    for (let i = 0; i < this.listFile.length; i++) {
      formDatas.append('messageFile'+i, this.listFile[i], this.listFile[i].name);

    }

    this.fightCardService.sendMessageToAllFighters(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        //this.modalSendMessage.hide();
        this.sendMessageSuccess = true;
        this.sendMessageForm.reset();
        this.listFile = [];
        this.totalFilesSize = 0;
        //this.alertService.showSuccess(this.translateService.instant("SUCCESS","mails_sent"),this.translateService.instant("SUCCESS","TITLE"));
        this.sendMessageEnCour = false;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
        this.sendMessageEnCour = false;
      }
    },error=>{
      this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
      this.sendMessageEnCour = false;
    },()=>{});
  }

    // Ajouter les fichiers sélectionner à la liste des fichiers
    fileChange(event){
      for (var i = 0; i < event.target.files.length; i++) {
        this.listFile.push(event.target.files[i]);
        this.totalFilesSize += event.target.files[i].size;
      }
    }

    deleteSendMessageFile(index){
      this.totalFilesSize -= this.listFile[index].size;
      this.listFile.splice(index, 1);
    }

    displayTimeRound(fight){
      if(fight.time_round != null){
        let times = fight.time_round.split(':');
        return times[1]+':00';
      }
    }

    getCanEditedResults(datetime_event){
      return (moment(datetime_event, "YYYY-MM-DD").isBefore(moment()) && moment(datetime_event, "YYYY-MM-DD").isAfter(moment().subtract(30, 'days'), 'day'));
    }

    showResultsModal(){
      this.modalResults.show();
    }

    closeResultsModal(){
      this.modalResults.hide();
    }

    getCandidates(){
      this.fightcardCandidateService.getCandidates(this.fightCard.id_fightcard).subscribe(jsonResponse=>{
        if(jsonResponse.success){
          this.candidateList = Object.values(jsonResponse.rows);
        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
        }
      },error=>{
        this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
      },()=>{});
    }

    showCandidateDetailsModal(fighter){
      this.selectedFighterForDetails = fighter;
      //calcul des pourcentage pour affichage dans la vue
      this.selectedFighterForDetails.victory_percent = (this.selectedFighterForDetails.number_victory/this.selectedFighterForDetails.total_fight)*100;
      this.selectedFighterForDetails.defeat_percent = (this.selectedFighterForDetails.number_defeat/this.selectedFighterForDetails.total_fight)*100;
      this.selectedFighterForDetails.null_percent = (this.selectedFighterForDetails.number_null/this.selectedFighterForDetails.total_fight)*100;
      this.modalCandidateDetails.show();
    }
    controlSexSelection(){
      if(!this.searchForm.id_sex){
        this.alertService.showError(this.translateService.instant("ERROR","selectSex"),this.translateService.instant('ERROR','TITLE'));
      }
    }
}
