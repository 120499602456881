import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class ManagersService {

  constructor(
    private httpClient: HttpClient,
    private globals: Globals) { }

  getList(searchParams):Observable<any>{
    const body = JSON.stringify(searchParams);
    return this.httpClient.post(this.globals.baseUrl+"searchmanager",body);
    }
    public getProfile(id):Observable<any>{
      return this.httpClient.get(this.globals.baseUrl+"taappmanagerprofile/"+id);
    }
    public getFighters(managerId):Observable<any>{
      return this.httpClient.get(this.globals.baseUrl+"taappmanagerprofile/getfighters/"+managerId);
    }
}
