<app-navbar></app-navbar>

<!--Grid row-->
<div class="container mt-5">
	<div class="row">
		<!--FORMULAIRE DE RECHERCHE-->
		<div class="col-12">
			<!-- form -->
			<form #f="ngForm">
				<!--LIGNE NOM PRENOM-->
				<div class="row mt-1">
					<div class="input-group md-form form-sm form-1 pl-0 mt-0 mb-0">
						<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<div class="input-group-prepend">
								<span class="input-group-text black lighten-3" id="basic-text1"><i
										class="fas fa-search text-white" aria-hidden="true"></i></span>
								<input class="form-control" mdbInput [(ngModel)]="searchForm.name" name="name" type="text" placeholder="{{'FORM.SEARCH' | translate}}">
							</div>

						</div>
						<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<button type="button" class="btn filter-btn btn-sm" (click)="filters.toggle()">
								{{'BUTTON.MORE_FILTERS'|translate}}</button>
							<button type="submit" class="btn btn-primary btn-sm" (click)="getFighters()">{{'BUTTON.SEARCH' | translate}}</button>
							<button type="button" class="btn btn-danger btn-sm" (click)="resetFilter()">
								<mdb-icon fas icon="times"></mdb-icon></button>
						</div>
					</div>
				</div>
				<div>
					<!--<mdb-icon fas icon="angle-down" (click)="filters.toggle()"></mdb-icon><span
						(click)="filters.toggle()">&nbsp;Filtres</span>-->
				</div>
				<!--LIGNE + de critères-->

				<div class="form-row mt-4" mdbCollapse #filters="bs-collapse">
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<mdb-select-2 [(ngModel)]="searchForm.id_living_country" name="id_living_country"
							placeholder="{{'FORM.CHOOSE_COUNTRY' | translate}}" [allowClear]="true">
							<mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{
								country.label_country }}</mdb-select-option>
						</mdb-select-2>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<mdb-select-2 placeholder="{{'FORM.CHOOSE_NATIONNALITY' | translate}}"
							[(ngModel)]="searchForm.id_nationnality" name="id_nationnality" [allowClear]="true">
							<mdb-select-option *ngFor="let nationnality of listNationnality"
								[value]="nationnality.id_nationnality">{{ nationnality.label_nationnality }}
							</mdb-select-option>
						</mdb-select-2>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<mdb-select-2 placeholder="{{'FORM.CHOOSE_SEX' | translate}}" [(ngModel)]="searchForm.id_sex" name="id_sex"
							[allowClear]="true" (selected)="filterWeightCategories($event)">
							<mdb-select-option value="1">{{ 'FORM.LABEL_SEX_MALE' | translate }}</mdb-select-option>
							<mdb-select-option value="2">{{ 'FORM.LABEL_SEX_FEMALE' | translate }}</mdb-select-option>
						</mdb-select-2>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
						<mdb-select-2 placeholder="{{'FORM.CHOOSE_WEIGHT' | translate}}"
							[(ngModel)]="searchForm.id_weight_category_1" name="id_weight_category_1" [allowClear]="true" (click)="controlSexSelection()" [disabled]="!searchForm.id_sex">
							<mdb-select-option *ngFor="let weight of listWeights" [value]="weight.id_weight_category">{{
								weight.label_weight_category }}</mdb-select-option>
						</mdb-select-2>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
							<!--<mdb-checkbox mdbValidate [(ngModel)]="searchForm.is_rookie" name="is_rookie">{{'FORM.ROOKIE'|translate}}
							</mdb-checkbox>-->
							<mdb-select-2 [(ngModel)]="searchForm.fighter_level" name="fighter_level"
								placeholder="{{'FORM.CHOOSE_LEVEL' | translate}}" allowClear="true">
								<mdb-select-option value="1">{{'FORM.CHOOSE_AMATEUR_LEVEL' | translate}}</mdb-select-option>
	                <mdb-select-option value="2">{{'FORM.CHOOSE_PRO_LEVEL' | translate}}</mdb-select-option>
	                  <mdb-select-option  value="3">{{'FORM.CHOOSE_BOTH_LEVEL' | translate}}</mdb-select-option>
							</mdb-select-2>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<div class="md-form mt-3">
							<input type="number" [(ngModel)]="searchForm.min_fight" name="min_fight"
								placeholder="{{'FORM.FIGHT_MIN'|translate}}">
						</div>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<div class="md-form mt-3">
							<input type="number" [(ngModel)]="searchForm.max_fight" name="max_fight"
								placeholder="{{'FORM.FIGHT_MAX'|translate}}">
						</div>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
						<div class="md-form mt-3">
						  <input
						    type="text"
								name="searchClub"
						    class="completer-input mdb-autocomplete"
						    [ngModel]="searchClub | async"
						    (ngModelChange)="searchClub.next($event)"
						    [mdbAutoCompleter]="auto"
						    placeholder="{{'FORM.SEARCH_CLUB'|translate}}"
								(clearBtnClicked)="clearClubSelection()"
						  />
						  <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="{{'ERROR.NO_RESULT'|translate}}">
						    <mdb-option *ngFor="let club of resultsSearchClub | async" [value]="club.club_label" (click)="onClubSelect(club.id_club)">
						      <div class="d-flex flex-column">
						        <strong>{{ club.club_label }}</strong>
						      </div>
						    </mdb-option>
						  </mdb-auto-completer>
						</div>
					</div>
					<div class="col-sm-12 d-md-none d-lg-none d-xl-none">
						<button type="submit" class="btn btn-primary btn-sm" (click)="getFighters()">{{'BUTTON.SEARCH' | translate}}</button>
						<button type="button" class="btn btn-danger btn-sm" (click)="resetFilter()">
							<mdb-icon fas icon="times"></mdb-icon></button>
					</div>
				</div>

			</form>
		</div>
	</div>
	<div class="row" *ngIf="listFighters != null && !isLoading" class="card-deck">
		<!--START ROW FIGHTERS-->
		<div *ngFor="let fighter of listFighters" class="col-md-6 col-xl-4 col-lg-4 col-sm-6 mt-4 mb-2">
			<!--Grid column-->
			<mdb-card class="testimonial-card">
				<!--Bacground color-->
				<div class="card-up unique-color-dark">
					<mdb-badge *ngIf="!fighter.is_pro" info="true">AM</mdb-badge><span *ngIf="!fighter.is_pro" style="color: white;"> {{fighter.label_exp_amateur}}</span>
					<mdb-badge *ngIf="fighter.is_pro" danger="true">PRO</mdb-badge>
				</div>


				<!--Avatar-->
				<div class="avatar mx-auto">
					<img *ngIf="fighter.profile_pic" src="{{globals.publicPathImage}}/pictures/{{fighter.profile_pic}}"
						class="rounded-circle">
					<img *ngIf="!fighter.profile_pic" src="./assets/img/logo-white-250.png"
						class="rounded-circle">
				</div>

				<mdb-card-body class="card-body pt-0">
					<!--Name-->
					<mdb-card-title>
							<div class="text-center mb-3">
									<h4 class="mb-0"><a routerLink="/fighters/{{fighter.id_fighter_profile}}">{{fighter.name}} {{fighter.firstname}}</a></h4>
										<span *ngIf="fighter.nickname">({{fighter.nickname}})</span>
								</div>
								<div class="d-flex">
									<!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
									<div class="alert alert-warning flex-grow-1" role="alert" *ngIf="fighter.id_user && !fighter.id_card_file">
			  						{{'TEXT.UNVERRIFIED_PROFILE'|translate}}
								 </div>
								 <div class="alert alert-success flex-grow-1" role="alert" *ngIf="fighter.id_user && fighter.id_card_file && fighter.is_valid">
									 {{'TEXT.VERRIFIED_PROFILE'|translate}}
								</div>
								<div class="alert alert-danger flex-grow-1" role="alert" *ngIf="!fighter.id_user">
									{{'TEXT.creatingProfile'|translate}}</div>
									<!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
									 <div class="ml-1">
		 									<div class="btn-group" mdbDropdown>
		 										<button mdbDropdownToggle type="button" class="contact-btn" mdbBtn>
		 											<mdb-icon far icon="paper-plane" class="mr-1"></mdb-icon>{{'BUTTON.write'|translate}}
		 										</button>
		 										<div class="dropdown-menu dropdown-dark ">
													<!--<a class="dropdown-item" (click)="showAddToFightModal(fighter)">{{'BUTTON.ADD_TO_FIGHT'| translate}}</a>-->
													<a class="dropdown-item" *ngIf="fighter.id_manager_profile" (click)="showMessageForm('manager',fighter.id_fighter_profile,fighter.id_manager_profile)">{{'BUTTON.MESSAGE_MANAGER'| translate}} </a>
							            <a class="dropdown-item" *ngIf="!fighter.id_manager_profile" style="color:var(--mma-red);">{{"BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}</a>
													<a class="dropdown-item" (click)="showMessageForm('fighter', fighter.id_fighter_profile, fighter.id_manager_profile)">{{'BUTTON.MESSAGE_FIGHTER'| translate}} </a>
		 										</div>
		 									</div>
		 								</div>
						 		</div>
							<h5 class="country"><i class="fas fa-map-marker-alt"></i>&nbsp;{{fighter.label_country}} / {{fighter.living_city}} <img src="assets/flags/{{fighter.flag_country|lowercase}}.png" style="padding-bottom:3px;"/></h5>
						<div *ngIf="fighter.is_pro" class="progress">
							<div class="progress-bar bg-success" role="progressbar"
								style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory}}</div>
							<div class="progress-bar bg-danger" role="progressbar"
								style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat}}</div>
							<div class="progress-bar bg-warning" role="progressbar"
								style="width: 33.33%" aria-valuenow="{{fighter.null_percent}}">D{{fighter.number_null}}</div>
						</div>
						<div *ngIf="!fighter.is_pro" class="progress">
							<div class="progress-bar bg-success" role="progressbar"
								style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory_amateur}}</div>
							<div class="progress-bar bg-danger" role="progressbar"
								style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat_amateur}}</div>
							<div class="progress-bar bg-warning" role="progressbar"
								style="width: 33.33%">D{{fighter.number_draw_amateur}}</div>
						</div>
						<br/>
						<a href="{{fighter.tapology}}" target="_blank" *ngIf="fighter.tapology">{{'TEXT.palmares'|translate}}</a>
				    <span *ngIf="!fighter.tapology">{{'TEXT.NO_SHERDOG'|translate}}</span>

					</mdb-card-title>

					<div class="text-left">
						<ul class="list-group list-group-flush">
							<li class="list-group-item">{{'FORM.SEARCH_CLUB'|translate}} : <span *ngIf="fighter.id_club != null">{{fighter.club_label}}</span><span *ngIf="fighter.id_club == null">{{'TEXT.NOT_SPECIFIED'|translate}}</span></li>
			        <li class="list-group-item" *ngIf="fighter.birth_date"><mdb-icon far icon="calendar-alt"></mdb-icon> {{fighter.birth_date}} {{fighter.age}}</li>
			        <li class="list-group-item" *ngIf="!fighter.birth_date"><mdb-icon far icon="calendar-alt"></mdb-icon> -</li>
			        <li class="list-group-item"><img src='/assets/icone/human-male-height.svg'> <span *ngIf="fighter.height_feet">{{fighter.height_feet}}" ({{fighter.height_cm}} cm)</span><span *ngIf="!fighter.height_feet"> -</span></li>
			        <li class="list-group-item"><img src='/assets/icone/gloves-grey.svg' style="height: 20px; weight:20px;"> {{fighter.label_weight_category_1}} {{fighter.label_weight_category_2}}</li>
						</ul>
					</div>
					<div class="row mt-2 mb-3 d-flex justify-content-center">
						<!--Facebook-->
						<a href="https://www.facebook.com/{{fighter.facebook}}" target="_blank" *ngIf="fighter.facebook"
							class="fa-lg p-2 m-2 fb-ic">
							<mdb-icon fab icon="facebook" size="lg" class="grey-text"></mdb-icon>
						</a>
						<!--Twitter-->
						<a href="https://www.twitter.com/{{fighter.twitter}}" target="_blank" *ngIf="fighter.twitter"
							class="fa-lg p-2 m-2 tw-ic">
							<mdb-icon fab icon="twitter" size="lg" class="grey-text"></mdb-icon>
						</a>
						<!--Instagram +-->
						<a href="https://www.instagram.com/{{fighter.instagram}}" target="_blank"
							*ngIf="fighter.instagram" class="fa-lg p-2 m-2 gplus-ic">
							<mdb-icon fab icon="instagram" size="lg" class="grey-text"></mdb-icon>
						</a>
						<!--Youtube +-->
						<a href="https://www.youtube.com/{{fighter.youtube}}" target="_blank" *ngIf="fighter.youtube"
							class="fa-lg p-2 m-2 gplus-ic">
							<mdb-icon fab icon="youtube" size="lg" class="grey-text"></mdb-icon>
						</a>
					</div>
					<hr>
					<h5>MANAGER</h5>
					<div class="row mt-2 mb-3 d-flex justify-content-center" *ngIf="fighter.id_manager_profile && fighter.is_active">
						<div class="col-4">
							<a routerLink="/managers/{{fighter.id_manager_profile}}"><img *ngIf="fighter.manager_profile_pic"
								src="{{globals.publicPathImage}}/pictures/{{fighter.manager_profile_pic}}" class="img-fluid"></a>
							<img *ngIf="!fighter.manager_profile_pic" src="{{globals.publicPathImage}}/pictures/default.png"
								class="img-fluid">
						</div>
						<div class="col-8 text-left mt-3">
							<a routerLink="/managers/{{fighter.id_manager_profile}}"
								class="text-dark">{{fighter.manager_enterprise_name}}<br/>({{fighter.manager_name}} {{fighter.manager_firstname}})</a>
						</div>
					</div>
					<div class="row mt-2 mb-3 d-flex justify-content-center text-danger" *ngIf="!fighter.id_manager_profile || !fighter.is_active">
						{{"BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}
					</div>
				</mdb-card-body>
			</mdb-card>
			<!--Grid column-->
		</div>
	</div>

	<div class="row" *ngIf="!isLoading">
		<div class="col text-align-right">
			<button type="button" mdbBtn color="primary" (click)="addFighterModal.show()">{{'BUTTON.CREATE_FIGHTER'|translate}}</button>
		</div>
	</div>
	<!--END ROW FIGHTERS-->
	<!--raw-->
	<div *ngIf="listFighters == '' && !isLoading" class="text-center">
		<h3 class="align-middle">{{'TEXT.NO_RESULTS' | translate}}</h3>
	</div>
</div>
<!--loader-->
<div class="d-flex justify-content-center" *ngIf="isLoading">
	<div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
		<span class="sr-only">Loading...</span>
	</div>
</div>
<!-- MODAL CONTACT-->
<div mdbModal #contactModal="mdbModal" class="modal fade left" id="contactModal" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MESSAGE'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="contactModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="contactForm">
					<div class="md-form mb-5">
						<mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
						<input type="text" id="form32" class="form-control" mdbInput mdbValidate
							formControlName="subject">
						<label for="form32">{{'FORM.SUBJECT'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.subject">
							<mdb-error
								*ngIf="contactForm.get('subject').hasError(validation.type) && contactForm.get('subject').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="form8" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="form8">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="contactForm.get('message').hasError(validation.type) && contactForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn color="light" (click)='contactModal.hide()'>{{'BUTTON.CLOSE'|translate}}
				</button>
				<button mdbBtn color="dark" (click)='submitContactForm()' [disabled]="!contactForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>{{'BUTTON.SEND'|translate}}
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>
<!-- MODAL AJOUT A COMBAT-->
<div mdbModal #addToFightModal="mdbModal" class="modal fade left" id="addToFightModal" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: scroll">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold" *ngIf="selectedFighter">Ajouter
					{{selectedFighter.firstname}} {{selectedFighter.name}} à un combat</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="addToFightModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="addToFightForm">
					<div class="md-form mb-5">
						<div class="row">
							<div class="col-8">
								<mdb-select-2 formControlName="id_fightcard"
									placeholder="{{'FORM.CHOOSE_FIGHTCARD' | translate}}">
									<mdb-select-option *ngFor="let fightcard of fightcardList"
										[value]="fightcard.id_fightcard">{{ fightcard.event_name }}</mdb-select-option>
								</mdb-select-2>
								<mdb-error
									*ngIf="addToFightForm.get('id_fightcard').hasError('required') && addToFightForm.get('id_fightcard').touched">
									{{'ERROR.SELECT_FIGHTCARD'}}</mdb-error>
							</div>
							<div class="col-4">
								<button mdbBtn class="btn-mma-blue" (click)='getFightList()'
									[disabled]="!addToFightForm.valid || isSending">
									<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>
									{{'BUTTON.SEE_FIGHT'|translate}}
								</button>
							</div>
						</div>
					</div>
				</form>
				<div *ngIf="fightList">
					<p *ngIf="!fightList.length">{{'TEXT.NO_FIGHT_IN_THIS_FIGHTCARD' | translate}}</p>
					<div class="row" *ngFor="let fight of fightList">
						<app-fight [fight]='fight' [fighter]='selectedFighter' class="row"></app-fight>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

<!--DELETE FIGHTER FROM FIGHT-->

<!-- MODAL MESSAGE CONTACT-->
<div mdbModal #messageModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MESSAGE'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="messageModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="messageForm">

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="form9" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="form9">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="messageForm.get('message').hasError(validation.type) && messageForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn class="btn-mma-blue" (click)='submitMessageForm()' [disabled]="!messageForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>{{'BUTTON.SEND'|translate}}
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>
<!--MODAL ADD FIGHTER FORM-->
<div style="overflow-y: auto" mdbModal #addFighterModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-notify modal-info" role="document">
		<div class="modal-content">
			<div class="modal-header" style="box-shadow: none; border-bottom: 1px solid #dee2e6;">
				<h4 class="modal-title w-100">{{'TEXT.addFighter'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="addFighterModal.hide()">
					<span aria-hidden="true" class="white-text">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<p>{{'TEXT.addFighterInfo'|translate}}</p>
				<app-create-fighter [fightersList]="listFighters" (closeModal)="addFighterModal.hide()"></app-create-fighter>
			</div>
		</div>
	</div>
</div>
