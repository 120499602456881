import { NgModule } from '@angular/core';
import { MomentDatePipe } from './moment-date.pipe';


@NgModule({
  imports: [
  ],
  exports : [MomentDatePipe],
  declarations: [MomentDatePipe]
})
export class MomentDateModule { }
