import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Globals} from '../globals';

@Injectable({
  providedIn: 'root'
})
export class UnavailabilityDatesService {

  constructor(private httpClient : HttpClient, private globals: Globals) { }

  public add(credentials):Observable<any>{
    let httpParams = new HttpParams().set('start_date',credentials.start_date).set('end_date',credentials.end_date);
    return this.httpClient.post(this.globals.baseUrl+"taappunavailabilitydate",httpParams);
  }

  public getAll(id=null):Observable<any>{
    let params = new HttpParams().set('id',id);
    return this.httpClient.get(this.globals.baseUrl+"taappunavailabilitydate",{params});
  }

  public update(credentials,oldCredentials):Observable<any>{
    let httpParams = new HttpParams().set('start_date',credentials.start_date).set('end_date',credentials.end_date).set('old_start_date',oldCredentials.start_date).set('old_end_date',oldCredentials.end_date);
    return this.httpClient.post(this.globals.baseUrl+"taappunavailabilitydate/update",httpParams);
  }
  public delete(credentials):Observable<any>{
    return this.httpClient.delete(this.globals.baseUrl+"taappunavailabilitydate/"+credentials.start+'/'+credentials.end);
  }
}
