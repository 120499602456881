import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Country } from '../models/Country';
import { Globals } from '../globals';
import {TranslateService} from '@ngx-translate/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class TaAppOrganisationProfileService {
	private organisationUrl = this.globals.baseUrl + 'taapporganisationprofile';  // URL to web api
	httpOptions = {
	    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	  };
  	constructor(private http: HttpClient, private globals: Globals, private httpClient: HttpClient, private alertService: AlertService) { }
	getOrganisationById(id: number): Observable<any> {
    	const url = `${this.organisationUrl}/${id}`;
    	return this.http.get<any>(url);
  }

  public uploadImage(fd: FormData): Observable<any>{
	return this.httpClient.post(this.globals.baseUrl + 'register/uploadPicture', fd);
  }

	edit(credentials): Observable<any>{
		const body = JSON.stringify(credentials);
		return this.http.post(this.globals.baseUrl + 'taapporganisationprofile/update', body);
	}

	deleteImage(): Observable<any>{
		return this.httpClient.get(this.globals.baseUrl + 'taapporganisationprofile/deletePicture');
	}

	deleteAccount(): Observable<any>{
		return this.httpClient.get(this.globals.baseUrl + 'taapporganisationprofile/deleteaccount');
	}
}


