<div class="modal-content">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 modal-header-text-color" id="myModalLabel">{{'PAGE.TITLE_ADD_FIGHTER_TO_FIGHT' | translate }}</h4>
    <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
    </button>
  </div>
  <div class="modal-body">
		<form [formGroup]="searchForm">
      <div class="form-row mt-1">
      <div class="input-group md-form form-sm form-1 pl-0 pb-0">
        <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <div class="input-group-prepend">
                <input class="form-control" formControlName="name" type="text" placeholder="{{'FORM.SEARCH'
              | translate}}">
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <button type="button" class="btn filter-btn btn-sm" (click)="filters.toggle()">
            {{'BUTTON.MORE_FILTERS'|translate}}</button>
          <button type="submit" class="btn btn-primary btn-sm" (click)="getFighters()"><mdb-icon fas icon="search"></mdb-icon></button>
          <button type="button"  class="btn btn-danger btn-sm" (click)="resetFilter()"><mdb-icon fas icon="times"></mdb-icon></button>
        </div>
        </div>

      </div>
    <!--<mdb-accordion [multiple]="false">
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head>{{'TEXT.MORE_CRITERIA'|translate}}</mdb-accordion-item-head>
          <mdb-accordion-item-body>-->
          <div mdbCollapse #filters="bs-collapse">
      		  <div class="form-row">
        			<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 md-form">
                <mdb-select-2 formControlName="fighter_level"
  								placeholder="{{'FORM.CHOOSE_LEVEL' | translate}}" allowClear="true">
  								<mdb-select-option [value]="1">{{'FORM.CHOOSE_AMATEUR_LEVEL' | translate}}</mdb-select-option>
  	                <mdb-select-option [value]="2">{{'FORM.CHOOSE_PRO_LEVEL' | translate}}</mdb-select-option>
  	                  <mdb-select-option  [value]="3">{{'FORM.CHOOSE_BOTH_LEVEL' | translate}}</mdb-select-option>
  							</mdb-select-2>
        			</div>
      			<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 md-form">
      				<mdb-select-2 formControlName="id_living_country" label="{{'FORM.CHOOSE_COUNTRY' | translate}}"
      					placeholder="{{'FORM.CHOOSE_COUNTRY' | translate}}" >
                <mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{ country.label_country }}</mdb-select-option>
              </mdb-select-2>
      			</div>
      			<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 md-form">
      				<mdb-select-2 	placeholder="{{'FORM.CHOOSE_NATIONNALITY' | translate}}" formControlName="id_nationnality" label="{{'FORM.CHOOSE_NATIONNALITY' | translate}}">
                <mdb-select-option *ngFor="let nationnality of listNationnality" [value]="nationnality.id_nationnality">{{ nationnality.label_nationnality }}</mdb-select-option>
              </mdb-select-2>
      			</div>
      		</div>
      		<div class="form-row">
      			<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 md-form">
              <mdb-select-2 placeholder="{{'FORM.CHOOSE_SEX' | translate}}" formControlName="id_sex" label="{{'FORM.CHOOSE_SEX' | translate}}" (selected)="filterWeightCategories($event)">
                <mdb-select-option value="1">{{ 'FORM.LABEL_SEX_MALE' | translate }}</mdb-select-option>
                <mdb-select-option value="2">{{ 'FORM.LABEL_SEX_FEMALE' | translate }}</mdb-select-option>
              </mdb-select-2>
      			</div>
      			<div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 md-form">
           <mdb-select-2 placeholder="{{'FORM.CHOOSE_WEIGHT' | translate}}" label="{{'FORM.CHOOSE_WEIGHT' | translate}}" formControlName="id_weight_category" (click)="controlSexSelection()" [disabled]="!searchForm.get('id_sex').value">
                <mdb-select-option *ngFor="let weight of listWeights" [value]="weight.id_weight_category">{{ weight.label_weight_category }}</mdb-select-option>
              </mdb-select-2>
      			</div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 md-form">
              <input class="form-control" mdbInput formControlName="nb_min_win" type="number" placeholder="{{'FORM.VICTORY_MIN' | translate}}">
              <label>{{'FORM.VICTORY_MIN' | translate}}</label>
            </div>
      		</div>
          <div class="form-row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 md-form">
              <label>{{'FORM.FIGHT_MIN' | translate}}</label>
                <input type="number" formControlName="min_fight"
  								placeholder="{{'FORM.FIGHT_MIN'|translate}}" mdbInput class="form-control" >

  					</div>
  					<div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 md-form">
              <label>{{'FORM.FIGHT_MAX' | translate}}</label>
                <input type="number" formControlName="max_fight"
  								placeholder="{{'FORM.FIGHT_MAX'|translate}}" mdbInput class="form-control" >

  					</div>
          </div>
        <div>
     <button type="button" mdbBtn color="primary" block="true" (click)="getFighters()"><mdb-icon fas icon="search"></mdb-icon> {{'BUTTON.SEARCH'|translate}}</button>
   </div>
   </div>
  <!--</mdb-accordion-item-body>
</mdb-accordion-item>
</mdb-accordion>-->
		</form>
    <!--CREATE FIGHTER BUTTON-->
      <div class="row">
    		<div class="col text-align-right">
    			<button type="button" mdbBtn color="primary" (click)="addFighterModal.show()">{{'BUTTON.CREATE_FIGHTER'|translate}}</button>
    		</div>
    	</div>

    <!--Grid column-->
    <div style="overflow-y:scoll;" *ngIf="listFighters.length>0">
    <div *ngFor="let fighter of listFighters">
      <div class="row" style="" *ngIf="!fight || (fighter.id_fighter_profile != fight.id_fighter_profile_1 && fighter.id_fighter_profile != fight.id_fighter_profile_2)">
          <!--<app-fighter-short-description [fighter]="fighter" class="col-12 mt-5 mb-2"></app-fighter-short-description>-->
          <!--<mdb-card class="mb-3">
            <mdb-card-body>
              <div class="row">
            <div class="avatar col-3">
                <img *ngIf="fighter.profile_pic"  src="{{globals.publicPathImage}}/pictures/{{fighter.profile_pic}}" class="rounded img-fluid">
                <img *ngIf="!fighter.profile_pic" src="./assets/img/default-profile.png" class="rounded img-fluid">
            </div>
          <div class="col-9" >
            <mdb-badge *ngIf="!fighter.is_pro" info="true">AM</mdb-badge><span *ngIf="!fighter.is_pro"> {{fighter.label_exp_amateur}}</span>
            <mdb-badge *ngIf="fighter.is_pro" danger="true">PRO</mdb-badge>
            <h4><a routerLink="/fighters/{{fighter.id_fighter_profile}}">{{fighter.name}} {{fighter.firstname}}</a> <button type="button" mdbBtn color="primary" size="sm" (click)="addFighter(fighter)"><i class="fa-plus fas"></i> {{'BUTTON.ADD_TO_THIS_FIGHT'|translate}}</button></h4>
            <div><small *ngIf="fighter.label_weight_category_1">{{fighter.label_weight_category_1}}</small><small *ngIf="fighter.label_weight_category_2"> - {{fighter.label_weight_category_2}}</small></div>
              <p *ngIf="fighter.nickname">"{{fighter.nickname}}"</p>
              <p>
                <a href="{{fighter.tapology}}" target="_blank" *ngIf="fighter.tapology">{{'TEXT.palmares'|translate}}</a>
                <span *ngIf="!fighter.tapology">{{'TEXT.NO_SHERDOG'|translate}}</span>
              </p>
              <div class="alert alert-danger" role="alert" *ngIf="!fighter.id_card_file || !fighter.is_valid">
                {{'TEXT.UNVERRIFIED_PROFILE'|translate}}
             </div>
             <div class="alert alert-success" role="alert" *ngIf="fighter.id_card_file && fighter.is_valid">
               {{'TEXT.VERRIFIED_PROFILE'|translate}}
            </div>
            <div *ngIf="fighter.is_pro" class="progress">
              <div class="progress-bar bg-success" role="progressbar"
                style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory}}</div>
              <div class="progress-bar bg-danger" role="progressbar"
                style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat}}</div>
              <div class="progress-bar bg-warning" role="progressbar"
                style="width: 33.33%" aria-valuenow="{{fighter.null_percent}}">D{{fighter.number_null}}</div>
            </div>
            <div *ngIf="!fighter.is_pro" class="progress">
              <div class="progress-bar bg-success" role="progressbar"
                style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory_amateur}}</div>
              <div class="progress-bar bg-danger" role="progressbar"
                style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat_amateur}}</div>
              <div class="progress-bar bg-warning" role="progressbar"
                style="width: 33.33%">D{{fighter.number_draw_amateur}}</div>
            </div>
          </div>
          </div>
        </mdb-card-body>
</mdb-card>-->

<!--NOUVEAU-->
<div *ngFor="let fighter of listFighters" class="col-4 mt-4 mb-2">
  <!--Grid column-->
  <mdb-card class="testimonial-card">
    <!--Bacground color-->
    <div class="card-up unique-color-dark">
      <mdb-badge *ngIf="!fighter.is_pro" info="true">AM</mdb-badge><span *ngIf="!fighter.is_pro" style="color: white;"> {{fighter.label_exp_amateur}}</span>
      <mdb-badge *ngIf="fighter.is_pro" danger="true">PRO</mdb-badge>
    </div>


    <!--Avatar-->
    <div class="avatar mx-auto">
      <img *ngIf="fighter.profile_pic" src="{{globals.publicPathImage}}/pictures/{{fighter.profile_pic}}"
        class="rounded-circle">
      <img *ngIf="!fighter.profile_pic" src="./assets/img/logo-white-250.png"
        class="rounded-circle">
    </div>

    <mdb-card-body class="card-body pt-0">
      <!--Name-->
        <mdb-card-title>
          <div class="text-center mb-3">
              <h4 class="mb-0"><a routerLink="/fighters/{{fighter.id_fighter_profile}}">{{fighter.name}} {{fighter.firstname}}</a></h4>
                <span *ngIf="fighter.nickname">({{fighter.nickname}})</span>
            </div>
              <!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
              <div class="alert alert-warning" role="alert" style="padding: 0.25rem 0.75rem;" *ngIf="fighter.id_user && !fighter.id_card_file">
                {{'TEXT.UNVERRIFIED_PROFILE'|translate}}
             </div>
             <div class="alert alert-success" role="alert" style="padding: 0.25rem 0.75rem;" *ngIf="fighter.id_user && fighter.id_card_file && fighter.is_valid">
               {{'TEXT.VERRIFIED_PROFILE'|translate}}
            </div>
            <div class="alert alert-danger" role="alert" style="padding: 0.25rem 0.75rem;" *ngIf="!fighter.id_user">
              {{'TEXT.creatingProfile'|translate}}</div>
              <!--AFFICHAGE DU TYPE DE L'ETAT DU PROFIL-->
                    <button type="button" block="true" class="contact-btn" style="color: var(--mma-red) !important;
    border: 1px solid var(--mma-red) !important;" mdbBtn (click)="addFighter(fighter)">
                      +&nbsp;{{'BUTTON.ADD_TO_THIS_FIGHT'|translate}}
                    </button>
          <h5 class="country"><i class="fas fa-map-marker-alt"></i>&nbsp;{{fighter.label_country}} / {{fighter.living_city}} <img src="assets/flags/{{fighter.flag_country|lowercase}}.png" style="padding-bottom:3px;"/></h5>
        <div *ngIf="fighter.is_pro" class="progress">
          <div class="progress-bar bg-success" role="progressbar"
            style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory}}</div>
          <div class="progress-bar bg-danger" role="progressbar"
            style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat}}</div>
          <div class="progress-bar bg-warning" role="progressbar"
            style="width: 33.33%" aria-valuenow="{{fighter.null_percent}}">D{{fighter.number_null}}</div>
        </div>
        <div *ngIf="!fighter.is_pro" class="progress">
          <div class="progress-bar bg-success" role="progressbar"
            style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory_amateur}}</div>
          <div class="progress-bar bg-danger" role="progressbar"
            style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat_amateur}}</div>
          <div class="progress-bar bg-warning" role="progressbar"
            style="width: 33.33%">D{{fighter.number_draw_amateur}}</div>
        </div>
        <br/>
        <a href="{{fighter.tapology}}" target="_blank" *ngIf="fighter.tapology">{{'TEXT.palmares'|translate}}</a>
        <span *ngIf="!fighter.tapology">{{'TEXT.NO_SHERDOG'|translate}}</span>
      </mdb-card-title>
      <div class="text-left">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><img src='/assets/icone/human-male-height.svg'> <span *ngIf="fighter.height_feet">{{fighter.height_feet}}" ({{fighter.height_cm}} cm)</span><span *ngIf="!fighter.height_feet"> -</span></li>
          <li class="list-group-item"><img src='/assets/icone/gloves-grey.svg' style="height: 20px; weight:20px;"> {{fighter.label_weight_category_1}} {{fighter.label_weight_category_2}}</li>
        </ul>
      </div>
    </mdb-card-body>
  </mdb-card>
  <!--Grid column-->
</div>
<!--END NOUVEAU-->

      </div>
      </div>
    </div>
    <div style="overflow-y:scoll;" *ngIf="listFighters.length==0" class="text-center font-weight-bold">
      {{"TEXT.NO_FIGHTER_FOUND"|translate}}
    </div>
	</div>

  </div>
  <!--MODAL ADD FIGHTER FORM-->
  <div style="overflow-y: auto" mdbModal #addFighterModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
  	aria-labelledby="myModalLabel" aria-hidden="true">
  	<div class="modal-dialog modal-notify modal-info modal-lg" role="document">
  		<div class="modal-content">
  			<div class="modal-header text-center white-text">
  				<h4 class="modal-title w-100">{{'TEXT.addFighter'|translate}}</h4>
  				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
  					(click)="addFighterModal.hide()">
  					<span aria-hidden="true" class="white-text">&times;</span>
  				</button>
  			</div>
  			<div class="modal-body mx-3">
  				<p>{{'TEXT.addFighterInfo'|translate}}</p>
  				<app-create-fighter [fightersList]="listFighters" (closeModal)="addFighterModal.hide()"></app-create-fighter>
  			</div>
  		</div>
  	</div>
  </div>
