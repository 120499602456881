import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Globals} from '../globals';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient : HttpClient, private globals: Globals) { }

  cancelSubscription():Observable<any>{
    let params = new HttpParams();
    return this.httpClient.post(this.globals.baseUrl+'subscription/cancel',params);
  }
  activatePaypalSubscription() : Observable<any>{
    return this.httpClient.post(this.globals.baseUrl+'subscription/activate',{});
  }
  suspendSubscription():Observable<any>{
    let params = new HttpParams();
    return this.httpClient.post(this.globals.baseUrl+'subscription/suspend',params);
  }
  getListSubscriptions():Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'subscription/getlist');
  }
}
