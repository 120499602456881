import { Component, OnInit, Input } from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';
import {TaAppFightService} from '../../../services/ta-app-fight.service';

@Component({
  selector: 'app-modal-del-fighter-from-fight',
  templateUrl: './modal-del-fighter-from-fight.component.html',
  styleUrls: ['./modal-del-fighter-from-fight.component.scss']
})
export class ModalDelFighterFromFightComponent implements OnInit {
  @Input() id_fight;
  @Input() position;
  @Input() fighterIdentity;
  @Input() fighterId;
  public deleteButtonClicked: Subject<any> = new Subject<any>();
    constructor(public modalRef: MDBModalRef,
    private fightService : TaAppFightService) { }
  ngOnInit(): void {

  }
	deleteFighter() {
    this.fightService.removeFighterFromFight({id_fight : this.id_fight,position : this.position,id_fighter_profile:this.fighterId}).subscribe(jsonResponse=>{
      this.deleteButtonClicked.next(jsonResponse);
      this.modalRef.hide();
    },error=>{

    });

    }

}
