import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service'; // our translate service

@Pipe({
    name: 'translate',
})

export class TranslatePipe implements PipeTransform {

    constructor(private _translate: TranslateService) { }

    transform(value: string, args: any[]=null): any {
        if (!value) return;
        let s = value.split('.');
        return this._translate.instant(s[0],s[1]);
    }
}
