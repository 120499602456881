import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor( private routes: Router,
    private globals: Globals){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (localStorage.getItem('isConnected') == "true" && localStorage.getItem('mma-token-orga')) {
        this.globals.isConnected = true;
          return true;
        }
        else {
          this.globals.isConnected = false;
          this.routes.navigate(['/login']);
          return false;
        }
  }

}
