import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaAppFighterWeightHistoryService {


	private fighterweighthistoryUrl = this.globals.baseUrl+'taappfighterweighthistory/getlastweight';  // URL to web api
	constructor(private http: HttpClient, private globals: Globals ) { }
	private result;
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};
	
  /** GET last weight by id faghter. Will 404 if id not found */
  getLastWeight(id: number): Observable<any> {
    const url = `${this.fighterweighthistoryUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`fetched Language id=${id}`))
    );
  }

 private log(message: string) {

}
}
