import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '../../../translate';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-changing-email',
  templateUrl: './changing-email.component.html',
  styleUrls: ['./changing-email.component.scss']
})
export class ChangingEmailComponent implements OnInit {
  emailForm: FormGroup;
  isSaving : boolean = false;
  validation_messages = {
    'password' : null,
    'oldEmail' : null,
    'newEmail' : null,
  };
  constructor(
    private authService : AuthService,
    public translateService: TranslateService,
    private alertService : AlertService
  ) {
    this.validation_messages = {
      'password': [
        { type: 'required', message: this.translateService.instant('formControl','passwordRequired') },
        { type: 'minlength', message: this.translateService.instant('formControl','passworMinlength')}
      ],
      'oldEmail': [
        { type: 'required', message: this.translateService.instant('formControl','actualEmailRequired') },
        { type: 'email', message: this.translateService.instant('formControl','enterValidEmail') }
      ],
      'newEmail': [
        { type: 'required', message: this.translateService.instant('formControl','newEmailRequired') },
        { type: 'email', message: this.translateService.instant('formControl','enterValidEmail') }
      ]

    };

    this.emailForm = new FormGroup({
      'password': new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.required
      ])),
      'oldEmail' : new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.email,
        Validators.required
      ])),
      'newEmail' : new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.email,
        Validators.required
      ]))
    });
   }

  ngOnInit(): void {
  }
  updateEmail(){
    this.isSaving = true;
    let pwd = CryptoJS.SHA512(this.emailForm.get('password').value).toString();
    let datas = new FormData();
    datas.append('email',this.emailForm.get('oldEmail').value);
    datas.append('new_email_requested',this.emailForm.get('newEmail').value);
    datas.append('password',pwd);
    this.authService.updateEmail(datas).subscribe(jsonResponse=>{
      this.isSaving = false;
      if(jsonResponse.success){
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','emailChanging'),this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.isSaving = false;
      if(error.status == 422){
        this.alertService.showError(this.translateService.instant('error','controlInformationsTxt'),this.translateService.instant('ERROR','TITLE'));
      }
      else{
        this.alertService.showError(error.error.message,this.translateService.instant('ERROR','TITLE'));
      }
    },()=>{}/*complete*/);
  }
}
