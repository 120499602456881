<app-navbar></app-navbar>

<div class="container mt-5">
<section class="d-flex flex-column">
	<div class="p-2 text-center">
		<h1>{{'PAGE.TITLE_COMING_FIGHT_CARD_LIST'|translate}}</h1>
		<button
			mdbBtn
			(click)="showCreateModalForm()"
			class="btn btn-primary"
			size="lg"
		>
			<mdb-icon fas icon="plus"></mdb-icon> {{'BUTTON.createFightcard'|translate}}
		</button>
		<hr>
	</div>
	<div class="p-2">
		<p *ngIf="erreurMessage">{{erreurMessage}}</p>
	  <!--Grid column-->
		<div *ngIf="listFightCards != ''">
			<div class="row">
        <ng-container *ngFor="let fightCard of listFightCards">
          <div *ngIf="!passed(fightCard.datetime_event)" class="col-md-4 mt-4 mb-2">
            <mdb-card class="testimonial-card">
							<div style="position:absolute;z-index:1;">
								<mdb-badge *ngIf="fightCard.fightcard_level == 1 || fightCard.fightcard_level == 3" style="background-color:var(--mma-blue);margin-right:7px;">AM</mdb-badge>
								<mdb-badge *ngIf="fightCard.fightcard_level == 2 || fightCard.fightcard_level == 3" style="background-color:var(--mma-red);">PRO</mdb-badge>
							</div>
							<div class="view overlay waves-light" mdbWavesEffect>
								<mdb-card-img *ngIf="fightCard.logo" (click)="onClickCard(fightCard.id_fightcard)" style="height:200px;" class="card-img-top" src="{{globals.publicPathImage}}fightCardsLogo/{{fightCard.logo}}"></mdb-card-img>
              </div>
              <!--Bacground color-->
              <div *ngIf="!fightCard.logo" (click)="onClickCard(fightCard.id_fightcard)" style="height:193px;" class="card-up black lighten-1">
							</div>

              <div class="color-block-dark unique-color-dark text-light pt-2 pb-2 d-flex">
                <div class="w-100 text-center"><h4><a routerLink="/fight-list/{{fightCard.id_fightcard}}" class="text-white">{{fightCard.event_name}}</a></h4></div>
                <div class="btn-group" mdbDropdown>
                  <!--<button mdbDropdownToggle type="button" mdbBtn color="info" class="dropdown-toggle waves-light" outline="true" size="sm">
                  MENU
                  </button>-->
                  <button
                  mdbDropdownToggle
                  type="button"
                  mdbBtn
                  color="link"
                  style="padding: 5px"
                  >
                  <mdb-icon
                  fas
                  class="icon-size"
                  icon="bars"
                  style="color: #ffffff;"
                  ></mdb-icon>
                  </button>
                  <div class="dropdown-menu dropdown-primary" style="left : -190px;">
                    <a class="dropdown-item" routerLink="/create-fight/{{fightCard.id_fightcard}}">{{'BUTTON.ADD_FIGHT'| translate}}</a>
                    <a class="dropdown-item" routerLink="/fight-list/{{fightCard.id_fightcard}}">{{'BUTTON.SHOW_FIGHTLIST'| translate}}</a>
                    <a class="dropdown-item" (click)="showSendMessageModal(fightCard)">{{'BUTTON.SEND_MESSAGE_ALL_FIGHTERS'| translate}}</a>
                    <a class="dropdown-item" *ngIf="fightCard.published" (click)="showUnpublishFightcardModal(fightCard)">{{'BUTTON.UNPUBLISH'| translate}}</a>
                    <a class="dropdown-item" (click)="edit(fightCard)">{{'BUTTON.MODIFY_FIGHT_CARD'| translate}}</a>
                    <a class="dropdown-item text-danger" (click)="showDeleteFightcardModal(fightCard)">{{'BUTTON.DELETE'| translate}}</a>
                  </div>
                </div>
              </div>
            <div class="alert alert-danger" *ngIf="!fightCard.published" style="padding:0px;text-align:center;" role="alert">
            {{'TEXT.UNPUBLISHED_FIGHTCARD_TEXT'|translate}} <button type="button" mdbBtn color="link" (click)="showPublishFightcardModal(fightCard)">Publier</button>
            </div>
              <mdb-card-body class="card-body " style='padding-top:0px;'>
                <mdb-card-title class="text-left">
                </mdb-card-title>
                <p class="text-justify"  *ngIf="fightCard.description != 'null' && fightCard.description">{{fightCard.description}}</p>
                <ul class="list-group list-group-flush text-left">
                  <li *ngIf="fightCard.datetime_event" class="list-group-item"><i class="far fa-calendar-alt"></i> {{fightCard.datetime_event}}</li>
                  <li *ngIf="!fightCard.datetime_event" class="list-group-item"><i class="far fa-calendar-alt"></i> -</li>
                  <li *ngIf="fightCard.place" class="list-group-item"><i class="fas fa-map-marker-alt"></i> {{fightCard.place}} - {{fightCard.label_country}}</li>
                  <li *ngIf="!fightCard.place" class="list-group-item"><i class="fas fa-map-marker-alt"></i> -</li>
                </ul>
                <!-- <hr  *ngIf="fightCard.description"> -->
              </mdb-card-body>
            </mdb-card>
          </div>
        </ng-container>
      </div>
      <!--Grid column-->
	  </div>
	  <div *ngIf="listFightCards == ''" class="text-center">
		  <h3 class="align-middle" (click)="showCreateModalForm()">{{'TEXT.NO_FIGHTCARD' | translate}}</h3>
		</div>
  </div>

  <div class="p-2 text-center">
		<h1>{{'PAGE.TITLE_PASSED_FIGHT_CARD_LIST'|translate}}</h1>
		<hr>
	</div>
	<div class="p-2">
		<p *ngIf="erreurMessage">{{erreurMessage}}</p>
	  <!--Grid column-->
		<div *ngIf="listFightCards != ''">
			<div class="row">
        <ng-container *ngFor="let fightCard of listFightCards">
          <div *ngIf="passed(fightCard.datetime_event)" class="col-md-4 mt-4 mb-2">
            <mdb-card class="testimonial-card">

              <div class="view overlay waves-light" mdbWavesEffect>
                <mdb-card-img *ngIf="fightCard.logo" (click)="onClickCard(fightCard.id_fightcard)" style="height:200px;" class="card-img-top" src="{{globals.publicPathImage}}fightCardsLogo/{{fightCard.logo}}"></mdb-card-img>
              </div>
              <!--Bacground color-->
              <div *ngIf="!fightCard.logo" (click)="onClickCard(fightCard.id_fightcard)" style="height:230px;" class="card-up black lighten-1"></div>

              <div class="color-block-dark unique-color-dark text-light pt-2 pb-2 d-flex">
                <div class="w-100 text-center"><h4><a routerLink="/fight-list/{{fightCard.id_fightcard}}" class="text-white">{{fightCard.event_name}}</a></h4></div>
                <div class="btn-group" mdbDropdown>
                  <!--<button mdbDropdownToggle type="button" mdbBtn color="info" class="dropdown-toggle waves-light" outline="true" size="sm">
                  MENU
                  </button>-->
                  <button
                  mdbDropdownToggle
                  type="button"
                  mdbBtn
                  color="link"
                  style="padding: 5px"
                  >
                  <mdb-icon
                  fas
                  class="icon-size"
                  icon="bars"
                  style="color: #ffffff;"
                  ></mdb-icon>
                  </button>
                  <div class="dropdown-menu dropdown-primary" style="left : -190px;">
                    <a class="dropdown-item" routerLink="/create-fight/{{fightCard.id_fightcard}}">{{'BUTTON.ADD_FIGHT'| translate}}</a>
                    <a class="dropdown-item" routerLink="/fight-list/{{fightCard.id_fightcard}}">{{'BUTTON.SHOW_FIGHTLIST'| translate}}</a>
                    <a class="dropdown-item" (click)="showSendMessageModal(fightCard)">{{'BUTTON.SEND_MESSAGE_ALL_FIGHTERS'| translate}}</a>
                    <a class="dropdown-item" *ngIf="fightCard.published" (click)="showUnpublishFightcardModal(fightCard)">{{'BUTTON.UNPUBLISH'| translate}}</a>
                    <a class="dropdown-item text-danger"  (click)="showDeleteFightcardModal(fightCard)">{{'BUTTON.DELETE'| translate}}</a>
                  </div>
                </div>
              </div>
            <div class="alert alert-danger" *ngIf="!fightCard.published" style="padding:0px;text-align:center;" role="alert">
            {{'TEXT.UNPUBLISHED_FIGHTCARD_TEXT'|translate}} <button type="button" mdbBtn color="link" (click)="showPublishFightcardModal(fightCard)">Publier</button>
            </div>
              <mdb-card-body class="card-body " style='padding-top:0px;'>
                <mdb-card-title class="text-left">
                </mdb-card-title>
                <p class="text-justify"  *ngIf="fightCard.description != 'null' && fightCard.description">{{fightCard.description}}</p>
                <ul class="list-group list-group-flush text-left">
                  <li *ngIf="fightCard.datetime_event" class="list-group-item"><i class="far fa-calendar-alt"></i> {{fightCard.datetime_event}}</li>
                  <li *ngIf="!fightCard.datetime_event" class="list-group-item"><i class="far fa-calendar-alt"></i> -</li>
                  <li *ngIf="fightCard.place" class="list-group-item"><i class="fas fa-map-marker-alt"></i> {{fightCard.place}} - {{fightCard.label_country}}</li>
                  <li *ngIf="!fightCard.place" class="list-group-item"><i class="fas fa-map-marker-alt"></i> -</li>
                </ul>
                <!-- <hr  *ngIf="fightCard.description"> -->
              </mdb-card-body>
            </mdb-card>
          </div>
        </ng-container>
      </div>
      <!--Grid column-->
	  </div>
  </div>

</section>
<!--<div class="fixed-action-btn smooth-scroll" style="bottom: 45px; right: 24px;">
  <a mdbBtn floating="true" (click)="showCreateModalForm()" class="primary-color" size="lg">
    <mdb-icon fas icon="plus"></mdb-icon>
  </a>
</div>-->
</div>

<!--MODAL ALERT PUBLIER FIGHTCARD-->
<div
  mdbModal
  #modalPublishFightcard="mdbModal"
  class="modal fade top"
  id="modalPublishFightcard"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <h4 class="heading lead modal-header-text-color">{{ "TEXT.paymentTitle" | translate }} <strong *ngIf="selectedFightcard">'{{selectedFightcard.event_name}}'</strong> ?</h4>
        <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalPublishFightcard.hide()">
          <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
				<div *ngIf="!transactionSaving">
	        <div class="text-center" *ngIf="!transactionSuccess">
	          <p>{{ "TEXT.paymentTxt" | translate }}</p>
						<p>{{"TEXT.amountToPay"|translate}} : <strong *ngIf="selectedFightcard">{{selectedFightcard.price_excl}} EUR</strong></p>
	        </div>
						<div class="text-center" *ngIf="transactionSuccess == true">
		          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn text-success"></mdb-icon>
		          <p class="note note-success">{{'SUCCESS.fightcardPaid'|translate}}</p>
		        </div>
						<div class="text-center" *ngIf="transactionSuccess == false">
							<mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn text-danger"></mdb-icon>
		          <p class="note note-danger">{{'ERROR.fightcardPayment'|translate}}</p>
		        </div>
				</div>
				<div *ngIf="transactionSaving" class="text-center">
					<i class="fas fa-circle-notch fa-spin"></i><br/>
					<p>{{ 'TEXT.TRANSACTION_SAVING' | translate }}</p>
				</div>
				<app-paypal-paiement *ngIf="selectedFightcard && !transactionSuccess" (transactionSaving)="displaySaving($event)" [fightcard]="selectedFightcard" (transactionSuccess)='setResultTransaction($event)'></app-paypal-paiement>
			</div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalPublishFightcard.hide()"
          mdbBtn
          class="btn-close"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT PUBLIER FIGHTCARD-->

<!--MODAL ALERT DEPUBLIER FIGHTCARD-->
<div
  mdbModal
  #modalUnpublishFightcard="mdbModal"
  class="modal fade top"
  id="modalUnpublishFightcard"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.UNPUBLISH_FIGHTCARD_TITLE" | translate }} <strong *ngIf="selectedFightcard">'{{selectedFightcard.event_name}}'</strong> ?</p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalUnpublishFightcard.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.UNPUBLISH_FIGHTCARD_TEXT" | translate }}
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalUnpublishFightcard.hide()"
          mdbBtn
          color="danger"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button
          type="submit"
          mdbBtn
          color="danger"
          class="relative"
          (click)="confirmUnpublish()"
        >
          {{ "BUTTON.UNPUBLISH" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT DEPUBLIER FIGHTCARD-->

<!--MODAL ALERT SUPPRESSION FIGHTCARD-->
<div
  mdbModal
  #modalDeleteFightcard="mdbModal"
  class="modal fade top"
  id="modalDeleteFightcard"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.DELETE_FIGHTCARD_TITLE" | translate }}</p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalDeleteFightcard.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.DELETE_FIGHTCARD_TEXT" | translate }}
        </div>
				<form [formGroup]="deleteForm">
					<div class="form-row">
						  <div class="col">
								<div class="md-form md-outline">
									<ng-container *ngFor="let validation of validation_messages.confirm_field">
									<mdb-error *ngIf="deleteForm.get('confirm_field').hasError(validation.type) && deleteForm.get('confirm_field').touched">{{validation.message}}</mdb-error>
									</ng-container>
									<input type="text" id="confirm_field" class="form-control md-textarea" formControlName="confirm_field" required mdbInput>
									<label for="confirm_field">{{'formControl.hitDeleteWord' | translate}}</label>
								</div>
							</div>
						</div>
						<div class="form-row">
					  <div class="col">
							<div class="md-form md-outline">
								<ng-container *ngFor="let validation of validation_messages.message">
								<mdb-error *ngIf="deleteForm.get('message').hasError(validation.type) && deleteForm.get('message').touched">{{validation.message}}</mdb-error>
								</ng-container>

								<textarea formControlName="message" name="message" id="message" mdbInput class="form-control md-textarea" required></textarea>
								<label for="message">{{'FORM.deleteFightCardMessage' | translate}}</label>
							</div>
						</div>
					</div>
				</form>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalDeleteFightcard.hide()"
          mdbBtn
          color="danger"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button
          type="submit"
          mdbBtn
          color="danger"
          class="relative"
					[disabled]="!deleteForm.valid"
          (click)="confirmDelete()"
        >
          {{ "BUTTON.DELETE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION FIGHTCARD-->

<!--Modal envoyer un message à tout les combattants-->
<div
  mdbModal
  #modalSendMessage="mdbModal"
  class="modal fade top"
  id="modalSendMessage"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header" style="box-shadow: none; border-bottom: 1px solid #dee2e6;">
        <h4 class="heading lead modal-header-text-color">{{ "TEXT.SEND_MESSAGE_ALL_FIGHTERS_TITLE" | translate }}</h4>

        <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalSendMessage.hide()">
          <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
        </button>
      </div>

      <!--Body-->
      <div *ngIf="sendMessageSuccess == false" class="modal-body">
        <p>{{'TEXT.send_message_all_fighters_message' | translate}}</p>
				<form [formGroup]="sendMessageForm">
						<div class="form-row">
					  <div class="col">
							<div class="md-form md-outline">
								<ng-container>
								  <mdb-error *ngIf="sendMessageForm.status == 'INVALID' && sendMessageForm.get('message').touched">{{'FORM.NOT_EMPTY' | translate}}</mdb-error>
								</ng-container>
								<textarea formControlName="message" name="message" id="sendMessage" mdbInput class="form-control md-textarea" required></textarea>
								<label for="sendMessage">{{'FORM.YOUR_MESSAGE' | translate}}</label>
							</div>
              <!-- Partie pièce jointes -->
              <input type="file" id="file" name="picture" multiple style="display: none;" (change)="fileChange($event)">
              <label for="file" mdbBtn class="btn-black-outline">{{ 'BUTTON.ADD_ATTACHEMENT' | translate }}</label>
              <p *ngFor="let file of listFile; let index = index">
                {{ file.name }} ({{ (file.size / 1000000).toFixed(2) }}Mo)
                <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="deleteSendMessageFile(index)">
                  <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
                </button>
              </p>
              <p style="margin-top: 1rem; margin-bottom: 0rem;">{{ "TEXT.MAX_FILES_SIZE" | translate }}</p>
              <div class="progress" style="height: 0.25rem;">
                <div class="progress-bar" role="progressbar" [style.width]="((totalFilesSize/15000000)*100)+'%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
						</div>
					</div>
				</form>
      </div>
      <div *ngIf="sendMessageSuccess == true" class="modal-body">
        <div class="text-center" >
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn text-success"></mdb-icon>
          <p class="note note-success">{{'SUCCESS.mails_sent'|translate}}</p>
        </div>
      </div>


      <!--Footer-->
      <div class="modal-footer">

        <button mdbBtn class="btn-close" (click)="modalSendMessage.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>

        <button *ngIf="!sendMessageEnCour && sendMessageSuccess == false" mdbBtn type="submit" class="relative btn-mma-blue" [disabled]="!sendMessageForm.valid || totalFilesSize > 15000000" (click)="sendMessage()">
          {{ "BUTTON.SEND" | translate }}
        </button>

        <button *ngIf="sendMessageEnCour" mdbBtn type="button" class="relative btn-mma-blue" disabled>
          <!--<mdb-spinner style="border-color: white"*ngIf="sendMessageEnCour" spinnerType="small"></mdb-spinner>-->
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;{{ "BUTTON.sending" | translate }}
        </button>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END modal envoyer un message a tout les combattants-->
