import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-modal-create-fight-card',
  templateUrl: './modal-create-fight-card.component.html',
  styleUrls: ['./modal-create-fight-card.component.scss']
})
export class ModalCreateFightCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
