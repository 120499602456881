import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TranslateService} from '../../translate/translate.service';
import {AlertService} from '../../services/alert.service';
import {Globals} from '../../globals';
import {OrdersService} from '../../services/orders.service';
//generateur de carte de credit pour tester paypal : https://developer.paypal.com/developer/creditCardGenerator
import {IPayPalConfig,ICreateOrderRequest, IClientAuthorizeCallbackData } from 'ngx-paypal'; //https://github.com/Enngage/ngx-paypal
import * as moment from 'moment';
@Component({
  selector: 'app-paypal-paiement',
  templateUrl: './paypal-paiement.component.html',
  styleUrls: ['./paypal-paiement.component.scss']
})
export class PaypalPaiementComponent implements OnInit {
  public payPalConfig ? : IPayPalConfig;
  @Input('fightcard') fightcard;
  @Output('transactionSuccess') success= new EventEmitter<boolean>();
  @Output('transactionSaving') transactionSaving= new EventEmitter<boolean>();
  transactionState : string = '';
  user = JSON.parse(localStorage.getItem('user'));
  constructor(
    private translateService:TranslateService,
    public globals : Globals,
    private alertService :AlertService,
    private ordersService:OrdersService
  ) { }

  ngOnInit(): void {
    this.initPaypalConfig();
  }
  private initPaypalConfig(): void {
    let me = this;
    var price = Number.parseFloat(this.fightcard.price_excl)*1.20;
    var totalTaxAmount = Number.parseFloat(this.fightcard.price_excl)*0.20;
         this.payPalConfig = {
             currency: 'EUR',
             clientId: this.globals.paypalClientId,
             vault : "true",
              createOrderOnClient: (data) => < ICreateOrderRequest > {
                   intent: 'CAPTURE',
              purchase_units: [{
                description : "Réglement pour la publication de la fightcard "+this.fightcard.event_name,
                  amount: {
                      currency_code: 'EUR',
                      value: price.toFixed(2).toString(),//this.fightcard.price_excl, //prix avec TVA
                      breakdown: {
                          item_total: {
                              currency_code: 'EUR',
                              value: me.fightcard.price_excl
                          },
                          tax_total : {
                            currency_code : "EUR",
                            value : totalTaxAmount.toFixed(2).toString()
                          },
                          shipping : {
                            currency_code : "EUR",
                            value : "0"
                          },
                          insurance : {
                            currency_code : "EUR",
                            value : "0"
                          }
                      }
                  }/*,
                  items: [{
                      name: "Réglement pour la publication de la fightcard "+this.fightcard.event_name,
                      quantity: '1',
                      category:  'DIGITAL_GOODS',
                      unit_amount: {
                          currency_code: 'EUR',
                          value: this.fightcard.price_excl
                      },
                  }]*/
              }]
          },
          advanced: {
              commit: 'true'
          },
          style: {
            shape: 'rect',
            color: 'black',
            layout: 'vertical',
            label: 'pay',
          },
             onApprove: (data, actions) => {
               this.transactionSaving.emit(true);
                 actions.order.get().then(details => {
                     //STATUS = "APPROVED"
                     let datas = new FormData();
                     let datasToStore = {'transaction_datas':data,'transaction_result':details};
                     datas.append('paypal_transaction_json',JSON.stringify(datasToStore));
                     datas.append('paypal_order_id', data.orderID);
                     datas.append('paypal_payer_id',data.payerID);
                     //datas.append('address',((details.payer.address.address_line_1)?details.payer.address.address_line_1:null));
                     //datas.append('city',((details.payer.address.admin_area_2)?details.payer.address.admin_area_2:null));
                     datas.append('amount_paid_excl',this.fightcard.price_excl);
                     datas.append('amount_paid_incl',price.toFixed(2).toString());
                     datas.append('vat',totalTaxAmount.toFixed(2).toString());
                     datas.append('create_datetime',details.create_time);
                     datas.append('fightcardId',me.fightcard.id_fightcard);
                     datas.append('activateFightcard',"true");
                     //datas.append('firstname',(details.payer.name.surname)?details.payer.name.surname:null);
                     //datas.append('name',(details.payer.name.given_name)?details.payer.name.given_name:null);
                     //datas.append('zip_code',((details.payer.address.postal_code)?details.payer.address.postal_code:null));
                     this.ordersService.createPaypalOrder(datas).subscribe(jsonResponse=>{
                       if(jsonResponse.success == true){
                         me.fightcard.published = true;
                         me.transactionState = this.translateService.instant('paypalTranscationState','approved');
                         me.success.emit(true);
                         this.transactionSaving.emit(false);

                       }else{
                         me.success.emit(false);
                         this.transactionSaving.emit(false);
                       }
                     },error=>{
                       me.success.emit(false);
                       this.transactionSaving.emit(false);
                       me.alertService.showServerError(error.status);
                     },()=>{});
                 }).catch(error=>{
                   console.log(error);
                   this.transactionSaving.emit(false);
                 });
             },
             /*
             onClientAuthorization: (paypalDatas:IClientAuthorizeCallbackData) => {
                 console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', paypalDatas);
                 this.transactionState = this.translateService.instant('paypalTranscationState','completed');
                 //SAVE TO SERVER
                 //STATUS = "COMPLETED"
                 let datas = new FormData();
                 datas.append('paypal_transaction_json',JSON.stringify(paypalDatas));
                 datas.append('address',((paypalDatas.payer.address.address_line_1)?paypalDatas.payer.address.address_line_1:paypalDatas.purchase_units[0].shipping.address.address_line_1));
                 datas.append('city',((paypalDatas.payer.address.admin_area_2)?paypalDatas.payer.address.admin_area_2:paypalDatas.purchase_units[0].shipping.address.admin_area_2));
                 datas.append('amount_paid_excl',this.subscription.subscription_price_excl);
                 datas.append('amount_paid_incl',this.subscription.subscription_price);
                 datas.append('vat',this.subscription.vat);//paypalDatas.purchase_units['items'].tax);
                 datas.append('create_datetime',paypalDatas.create_time);
                 datas.append('firstname',paypalDatas.payer.name.surname);
                 datas.append('name',paypalDatas.payer.name.given_name);
                 datas.append('id_subscription',this.subscription.id_subscription);
                 datas.append('zip_code',((paypalDatas.payer.address.postal_code)?paypalDatas.payer.address.postal_code:paypalDatas.purchase_units[0].shipping.address.postal_code));
                 this.ordersService.createPaypalOrder(datas).subscribe(jsonResponse=>{
                   if(jsonResponse.success == true){
                     this.success.emit(true);
                   }else{
                     this.success.emit(false);
                   }
                 },error=>{
                   this.success.emit(false);
                   this.alertService.showServeurError(error.status);
                 },()=>{});
             },*/
             /*advanced: {
                 commit: 'true'
             },
             style: {
                 label: 'paypal',
                 layout: 'vertical'
             },*/
             onCancel: (data, actions) => {
               this.transactionSaving.emit(false);
                 this.transactionState = this.translateService.instant('paypalTranscationState','canceled');
             },
             onError: (err) => {
               this.transactionSaving.emit(false);
                 this.alertService.showError(this.translateService.instant('ERROR','TITLE') ,err);
             },
             onClick: (data, actions) => {
               this.transactionState = this.translateService.instant('paypalTranscationState','authorisation');
             },
         };
  }
}
