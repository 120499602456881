import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../../globals';
import { AlertService } from '../../../services/alert.service';
import { FighterService } from '../../../services/fighters.service';
import { TranslateService } from '../../../translate/';
import { TaAppFightService } from '../../../services/ta-app-fight.service';
@Component({
  selector: 'app-fight',
  templateUrl: './fight.component.html',
  styleUrls: ['./fight.component.scss']
})
export class FightComponent implements OnInit {
@Input('fight') fight;
@Input('fighter') fighter;
@Input('id_fightcard') id_fightcard;
@Input('fightList') fightList;
  constructor(
    public globals: Globals,
    private alertService : AlertService,
    private fightersService : FighterService,
    public translateService: TranslateService,
    private fightService: TaAppFightService,
  ) { }

  ngOnInit(): void {
    //calcul des pourcentage victoire, défaites, null
    this.fight.victory_percent_fighter_1 = ((this.fight.number_victory_fighter_1!=null)?(this.fight.number_victory_fighter_1/this.fight.total_fight_fighter_1*100):0);
    this.fight.defeat_percent_fighter_1 = ((this.fight.number_defeat_fighter_1!=null)?(this.fight.number_defeat_fighter_1/this.fight.total_fight_fighter_1*100):0);
    this.fight.null_percent_fighter_1 = ((this.fight.number_null_fighter_1!=null)?(this.fight.number_null_fighter_1/this.fight.total_fight_fighter_1*100):0);
    //fighter 2
    this.fight.victory_percent_fighter_2 = ((this.fight.number_victory_fighter_2!=null)?(this.fight.number_victory_fighter_2/this.fight.total_fight_fighter_2*100):0);
    this.fight.defeat_percent_fighter_2 = ((this.fight.number_defeat_fighter_2!=null)?(this.fight.number_defeat_fighter_2/this.fight.total_fight_fighter_2*100):0);
    this.fight.null_percent_fighter_2 = ((this.fight.number_null_fighter_2!=null)?(this.fight.number_null_fighter_2/this.fight.total_fight_fighter_2*100):0);
  }
  addFightertofight(fight, position) {
    let params = {
      id_fight: fight.id_fight,
      id_fighter_1: fight.id_fighter_1,
      id_fighter_2: fight.id_fighter_2,
      id_fighter_reserve: fight.id_fighter_reserve,
    };
    switch (position) {
      case 1: {
        //statements;
        params.id_fighter_1 = this.fighter.id_fighter_profile;
        //replace fighter
        this.fight.firstname_fighter_1 = this.fighter.firstname;
        this.fight.lastname_fighter_1 = this.fighter.lastname;
        this.fight.nickname_fighter_1 = this.fighter.nickname;
        this.fight.number_victory_fighter_1 = this.fighter.number_victory;
        this.fight.victory_percent_fighter_1 = ((this.fighter.number_victory!=null)?(this.fighter.number_victory/this.fighter.total_fight*100):0);
        this.fight.number_defeat_fighter_1 = this.fighter.number_defeat;
        this.fight.defeat_percent_fighter_1 = ((this.fighter.number_defeat!=null)?(this.fighter.number_defeat/this.fighter.total_fight*100):0);
        this.fight.number_null_fighter_1 = this.fighter.number_null;
        this.fight.null_percent_fighter_1 = ((this.fighter.number_null!=null)?(this.fighter.number_null/this.fighter.total_fight*100):0);
        this.fight.profile_pic_fighter_1 = this.fighter.profile_pic;
        break;
      }
      case 2: {
        params.id_fighter_2 = this.fighter.id_fighter_profile;
        //replace fighter
        this.fight.firstname_fighter_2 = this.fighter.firstname;
        this.fight.lastname_fighter_2 = this.fighter.lastname;
        this.fight.nickname_fighter_2 = this.fighter.nickname;
        this.fight.number_victory_fighter_2 = this.fighter.number_victory;
        this.fight.victory_percent_fighter_2 = ((this.fighter.number_victory!=null)?(this.fighter.number_victory/this.fighter.total_fight*100):0);
        this.fight.number_defeat_fighter_2 = this.fighter.number_defeat;
        this.fight.defeat_percent_fighter_2 = ((this.fighter.number_defeat!=null)?(this.fighter.number_defeat/this.fighter.total_fight*100):0);
        this.fight.number_null_fighter_2 = this.fighter.number_null;
        this.fight.null_percent_fighter_2 = ((this.fighter.number_null!=null)?(this.fighter.number_null/this.fighter.total_fight*100):0);
        this.fight.profile_pic_fighter_2 = this.fighter.profile_pic;
        //statements;
        break;
      }
      case 3: {
        //statements;
        params.id_fighter_reserve = this.fighter.id_fighter_profile;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.fightService.updateFighterFromFight(params).subscribe(
      (jsonResponse) => {
        if (jsonResponse.success === true) {
          this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHTER_REPLACEMENT'),this.translateService.instant('SUCCESS','TITLE'));
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }

}
