import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { TranslateService } from '../../../translate';
import { AlertService } from '../../../services/alert.service';
//import { TaAppFighterWeightHistoryService } from '../../../services/ta-app-fighter-weight-history.service';
import { TaAppCardFighterService } from '../../../services/ta-app-card-fighter.service';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { TransfereServiceService } from '../../../services/transfere-service.service';
import {FightCardProvider} from '../../../providers/fightcard.provider';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import { Globals } from '../../../globals';
import { formatDate } from '@angular/common';
import {TaRefCountryService} from '../../../services/ta-ref-country.service';
import {NgxImageCompressService,DOC_ORIENTATION} from 'ngx-image-compress'; //https://github.com/dfa1234/ngx-image-compress
import * as moment from "moment";
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
//import { TaAppOrganisationProfileService } from '../../../services/ta-app-organisation-profile.service';
@Component({
  selector: 'app-create-fight-card',
  templateUrl: './create-fight-card.component.html',
  styleUrls: ['./create-fight-card.component.scss']
})
export class CreateFightCardComponent implements OnInit {
  @Input() fightCard;
  @Input() isEdit;
	fightcardForm: FormGroup;
	public imagePath;
	imgURL: any;
	fileName: string;
	public message: string;
	selectedFile = null;
	labelsChoosePicture= '';
	datePicker: string;
	public myDatePickerOptions: IMyOptions = {
	};
	user: any;
	orga:any;
	erreur_champsVide:string;
	erreurMessage:string;
  validation_messages = {
    'event_name' : null,
    'datetime_event' :null,
    'id_country' : null
  };
  hasDeletedLogo : boolean = false;
  fightCardList: any;
  isSaving : boolean = false;
  showForm : boolean = true;
  showSuccess : boolean = false;
  public listCountries: any = [];
  minDate : string; // Date de jour, utile pour input date formulaire
  imageChangedEvent: any = null;
    croppedImage: any = null;
    SearchCountryField = SearchCountryField;
    selectedCountryISO:string;
  constructor(public fb: FormBuilder,
	public  translateService: TranslateService,
	private alertService: AlertService,
	private cardFighterService: TaAppCardFighterService,
	private authService: AuthService,
	private transfereService:TransfereServiceService,
    private router:Router,
    private fightCardProvider:FightCardProvider,
 	public modalRef: MDBModalRef,
public globals : Globals,
private imageCompress: NgxImageCompressService,
private countryService : TaRefCountryService) {

    this.validation_messages = {
      'event_name': [
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ],
      'datetime_event': [
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ],
      'id_country':[
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ]
    };

	this.fightcardForm =
    new FormGroup({
      'event_name' : new FormControl(null,Validators.compose([
        Validators.required
      ])),
      'place' : new FormControl(null),
      'description' : new FormControl(null),
      //'price' : new FormControl(null),
      'logo' : new FormControl(null),
      'datetime_event' : new FormControl(null,Validators.compose([
        Validators.required
      ])),
      'published': new FormControl(false),
      'id_country' : new   FormControl(null),
      'contact_phone' : new FormControl(null),
      'contact_email' : new FormControl(null,Validators.email),
      'accept_contact_email' : new FormControl(false),
      'accept_contact_phone' : new FormControl(false),
      'fightcard_level' : new FormControl(null)
    });
    this.minDate = moment().format('YYYY-MM-DD');
    this.countryService.getCountries().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.listCountries = jsonResponse.rows;
      }
    },error=>{},
  ()=>{})

}

/*Open file selection window and compress file for uploading*/
/*compressFile() {
    this.imageCompress.uploadFile().then(({image, orientation}) => {
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgURL = result;
        }
      );

    });

  }*/

  /*cancelSelectedFile(){
	   this.imgURL =null;//pour les images chargées quand on fait un update
     this.imageChangedEvent = null;//pour les images ajouté via le cropper
     this.croppedImage=null;
	}*/

  ngOnInit(): void {
    this.fightCardProvider.serviceListDatas.subscribe(datas=>{
      this.fightCardList = datas;
    });
  //si edition de la fightCard => remplir les champs
  if(this.isEdit){
    let eventDate = new Date(this.fightCard.datetime_event);
      this.fightcardForm.setValue({
        event_name : this.fightCard.event_name,
        place : this.fightCard.place,
        description : this.fightCard.description,
        logo : this.fightCard.logo,
        id_country : this.fightCard.id_country,
        datetime_event : formatDate(eventDate, 'yyyy-MM-dd', this.translateService.currentLang),
        published : this.fightCard.published,
        contact_phone : this.fightCard.contact_phone,
        contact_email : this.fightCard.contact_email,
        accept_contact_email : this.fightCard.accept_contact_email,
        accept_contact_phone : this.fightCard.accept_contact_phone,
        fightcard_level : this.fightCard.fightcard_level
      });
  }
  }
  async save(): Promise<void>
  {
    this.isSaving = true;
	if(this.fightcardForm.getRawValue().event_name != null && this.fightcardForm.getRawValue().datetime_event != null){
			let formData = this.toFormData(this.fightcardForm.value);
      if(this.fightcardForm.getRawValue().contact_phone != null){
				formData.append('contact_phone',this.fightcardForm.getRawValue().contact_phone.internationalNumber);
			}
			if(this.croppedImage != null ){
        //formData.append('image',this.imgURL);
        formData.append('image',await this.imageCompress.compressFile(this.croppedImage, DOC_ORIENTATION.NotDefined, 50, 50).then(
            result => {
              return result;
            }
          ));
			}
      //CREATE
      if(!this.isEdit){
		this.cardFighterService.createFightCard(formData).subscribe(jsonResponse => {
      this.isSaving = false;
					if (jsonResponse.success === true) {
							//this.alertService.showSuccess('Votre Fightcard à bien été ajoutée','Bravo !');
              //this.modalRef.hide();
              this.showForm = false;
              this.showSuccess = true;
            //récupération de la nouvelle liste => on fait un requete au serveur pour avoir le nom de l'image
            this.cardFighterService.getListFightCard().subscribe(jsonResponse=>{
              if(jsonResponse.success){
                //mise à jour de la liste via le souscripteur
                this.fightCardProvider.changeListeDatas(jsonResponse.rows);
              }
            },error=>{

            });
					}else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
					// console.log(localStorage.getItem('token'));
				}, error => {
          this.isSaving = false;
          this.alertService.alertError(error);
				}
				);
      }
      //UPDATE
      else{
        formData.append('id_fightcard',this.fightCard.id_fightcard);
        formData.append('hasDeletedLogo',this.hasDeletedLogo.toString());
        this.cardFighterService.updateFightCard(formData).subscribe(jsonResponse => {
          this.isSaving = false;
              if (jsonResponse.success === true) {
    							this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHTCARD_UPDATED'),this.translateService.instant('SUCCESS','TITLE'));
                  //récupération de la nouvelle liste
                  this.cardFighterService.getListFightCard().subscribe(jsonResponse=>{
                    if(jsonResponse.success){
                      //mise à jour de la liste via le souscripteur
                      this.fightCardProvider.changeListeDatas(jsonResponse.rows);
                    }
                  },error=>{

                  });
    						// location.href = '/home';
    					}else{
                this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
              }
    					// console.log(localStorage.getItem('token'));
    				}, error => {
              this.isSaving = false;
              this.alertService.alertError(error);
    				}
    				);
      }
        }else{
				this.alertService.showError(this.erreur_champsVide, 'Erreur');
				this.erreurMessage = this.erreur_champsVide;
			}
  }
	toFormData<T>( formValue: T ) {
	  const formData = new FormData();

	  for ( const key of Object.keys(formValue) ) {
	    const value = formValue[key];
	    formData.append(key, value);
	  }

	  return formData;
	}
  deleteLogo(){
    //supression du formulaire
    this.fightcardForm.getRawValue().logo = null;
    //supression de l'affichage
    this.fightCard.logo = null;
    this.hasDeletedLogo = true;
    this.imageChangedEvent = null;
    this.croppedImage = null;
  }
  fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
	get datetime_event() { return this.fightcardForm.get('datetime_event'); }
	get event_name() { return this.fightcardForm.get('event_name'); }
  get contact_email() { return this.fightcardForm.get('contact_email'); }
}
