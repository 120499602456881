 <div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <mdb-icon fas  red-text icon="times"></mdb-icon>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel" *ngIf="!isEdit">{{'FORM.TITLE_CREATE_FIGHT_CARD' | translate}}</h4>
    <h4 class="modal-title w-100" id="myModalLabel" *ngIf="isEdit">{{'FORM.TITLE_UPDATE_FIGHT_CARD' | translate}}</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="fightcardForm" *ngIf="showForm">
      <div class="alert alert-danger" role="alert" *ngIf="erreurMessage ">{{'ERROR.TITLE'}} :  {{erreurMessage}}</div>
				  <div class="md-form md-outline">
				    <input type="text" class="form-control" id="InputEvent_name" formControlName="event_name" placeholder="" required mdbValidate mdbInput>
            <label for="InputEvent_name">{{'FORM.EVENT_NAME' | translate}}<sup>*</sup></label>
            <ng-container *ngFor="let validation of validation_messages.event_name">
              <mdb-error *ngIf="fightcardForm.get('event_name').hasError(validation.type) && fightcardForm.get('event_name').touched">{{validation.message}}</mdb-error>
            </ng-container>
          </div>

          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" formControlName="fightcard_level" label="{{'FORM.CHOOSE_LEVEL' | translate}}*"
							placeholder="{{'FORM.CHOOSE_LEVEL' | translate}}" required>
							<mdb-select-option [value]="1">{{'FORM.CHOOSE_AMATEUR_LEVEL' | translate}}</mdb-select-option>
                <mdb-select-option [value]="2">{{'FORM.CHOOSE_PRO_LEVEL' | translate}}</mdb-select-option>
                  <mdb-select-option  [value]="3">{{'FORM.CHOOSE_BOTH_LEVEL' | translate}}</mdb-select-option>
						</mdb-select-2>
          </div>

          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" formControlName="id_country" label="{{'FORM.CHOOSE_COUNTRY' | translate}}*"
							placeholder="{{'FORM.CHOOSE_COUNTRY' | translate}}" required>
							<mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{
								country.label_country }}</mdb-select-option>
						</mdb-select-2>
            <ng-container *ngFor="let validation of validation_messages.id_country">
              <mdb-error *ngIf="fightcardForm.get('id_country').hasError(validation.type) && fightcardForm.get('id_country').touched">{{'ERROR.completeField'|translate}}</mdb-error>
            </ng-container>
          </div>
				  <div class="md-form md-outline">
				    <input type="text" class="form-control" id="inputPlace" placeholder="" formControlName="place" mdbInput>
            <label for="inputPlace">{{'FORM.PLACE' | translate}}</label>
          </div>
				  <div class="md-form md-outline">
				  	<textarea class="form-control" id="inputDescription" placeholder="" formControlName="description" rows="3" class="md-textarea form-control" mdbInput></textarea>
            <label for="inputDescription">{{'FORM.DESCRIPTION' | translate}}</label>
          </div>
				  <!--<div class="md-form md-outline">
				    <label for="inputPrice">{{'FORM.PRICE' | translate}}</label>
				  	<input type="number" class="form-control" value="10" id="inputPrice" formControlName="price" mdbInput>
				  </div>-->

				  <div class="md-form md-outline">
          			<!--<mdb-date-picker formControlName="datetime_event" name="datetime_event" [options]="myDatePickerOptions" [placeholder]='datePicker' [locale]="'fr'" required>
            		</mdb-date-picker>-->
                <input type="date" id="datetime_event" class="form-control" formControlName="datetime_event" mdbInput mdbValidate required min="{{minDate}}">
                <label for="datetime_event" style="width: auto;">{{'FORM.EVENT_DATE'|translate}}<sup>*</sup></label>
                <ng-container *ngFor="let validation of validation_messages.datetime_event">
                <mdb-error *ngIf="fightcardForm.get('datetime_event').hasError(validation.type) && fightcardForm.get('datetime_event').touched">{{validation.message}}</mdb-error>
                </ng-container>
          </div>

          <!--CONTACT Informations-->
            <div class="form-row">
              <div class="col-12">
                <div class="switch success-switch">
                  <label class="text-wrap">
                    <input type="checkbox" formControlName="accept_contact_email">
                    <span class="lever"></span>{{'FORM.CONTACT_BY_EMAIL'|translate}}
                  </label>
                </div>
              </div>
            </div>
              <!--email-->
                <div class="md-form md-outline mt-0">
                  <input type="email" id="contact-email" class="form-control" formControlName="contact_email" mdbInput mdbValidate>
                  <label for="contact-email">{{ 'FORM.EMAIL' | translate }}</label>
                  <mdb-error *ngIf="contact_email.invalid">{{ 'FORM.NOT_EMPTY_EMAIL' | translate }}</mdb-error>
                </div>
              <!--Tel-->
              <div class="form-row">
                <div class="col-12">
                  <div class="switch success-switch">
                    <label class="text-wrap">
                      <input type="checkbox" formControlName="accept_contact_phone">
                      <span class="lever"></span>{{'FORM.CONTACT_BY_PHONE'|translate}}
                    </label>
                  </div>
                </div>
              </div>
                <div class="md-form md-outline mt-0">
                <mdb-icon fas icon="phone-alt" class="prefix grey-text"></mdb-icon>
                  <!--<input type="tel" id="phone" class="form-control" formControlName="phone" mdbInput>
                  <label for="phone">{{ 'FORM.PHONE' | translate }}</label>-->
                  <ngx-intl-tel-input
                  style="margin-left:2rem;color:#757575;width:100%;"
                    enableAutoCountrySelect="true"
                    enablePlaceholder="true"
                    searchCountryFlag="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    separateDialCode="false"
                    phoneValidation="true"
                    inputId="contactPhone"
                    name="contactPhone"
                    formControlName="contact_phone"
                  ></ngx-intl-tel-input>
                </div>

          		   <div class="col-12" style="padding-bottom: 1.5rem;">
          		   <div class="containerImage">
                   <div *ngIf="isEdit && fightCard.logo!=null">
                     <img src="{{globals.publicPathImage}}fightCardsLogo/{{fightCard.logo}}" alt="thumbnail" class="img-thumbnail image" style="width: 100px;">
                     </div>
				         </div>
				  <div class="input-group">
		          <!--<div class="input-group-prepend">
		            <span class="input-group-text" id="inputGroupFileAddon01">{{ 'FORM.SELECT_IMAGE' | translate }}</span>
		          </div>-->

		          <div class="custom-file" *ngIf="(!isEdit && !fightCard) || (isEdit && !fightCard.logo)">
                <!--<button type="button" mdbBtn (click)="compressFile()" class="btn btn-black-outline" block="true" style="margin-top: auto;">{{ 'FORM.SELECT_IMAGE' | translate }}</button>-->

                <button type="button" mdbBtn color="primary" name="addPicture" (click)="photoField.click()" *ngIf="!imageChangedEvent"><mdb-icon fas icon="plus"></mdb-icon>&nbsp;{{'FORM.SELECT_IMAGE'|translate}}</button>
                <input #photoField style="display:none;" accept="image/*" type="file" (change)="fileChangeEvent($event)" />
              </div>

		        </div>
            <div *ngIf="imageChangedEvent">
              <image-cropper style="max-width:250px;max-height:250px;"
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="16/9"
                  format="png"
                  (imageCropped)="imageCropped($event)"
              ></image-cropper>
            </div>
            <button type="button" *ngIf="(isEdit && fightCard.logo!=null) || imageChangedEvent" mdbBtn (click)="deleteLogo()" color="danger" block="true">{{'BUTTON.DELETE' | translate}}</button>
		        </div>
    </form>
    <div *ngIf="!showForm && showSuccess" class="alert alert-success" role="alert">
      <h4 class="alert-heading">{{'SUCCESS.FIGHTCARD_CREATED'|translate}}</h4>
    </div>
    <div *ngIf="!showForm && showSuccess" class="alert alert-danger" role="alert">
      <p><strong><u>{{'TEXT.WARNING'|translate}}</u></strong> : <br/>{{'SUCCESS.FIGHTCARD_CREATED_TXT'|translate}}</p>
    </div>
    <div class="modal-footer">
        <button type="submit" (click)="modalRef.hide()" mdBtn class="btn btn-close">{{'BUTTON.CLOSE' | translate}}</button>
        <button type="submit" (click)="save()" mdbBtn class="btn btn-mma-blue" *ngIf="showForm" [disabled]="!fightcardForm.valid || isSaving"><i *ngIf="isSaving" class="fas fa-circle-notch fa-spin"></i>&nbsp;{{'FORM.SUBMIT' | translate}}</button>
    </div>
    </div>
</div>
