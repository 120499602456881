import { Component, OnInit, Testability, ViewChild } from "@angular/core";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { ModalDirective } from "ng-uikit-pro-standard";
import { FighterService } from "../../../services/fighters.service";
import { ReferentielService } from "../../../services/referentiel.service";
import { Globals } from "../../../globals";
import { TranslateService } from "../../../translate";
import { AlertService } from "../../../services/alert.service";
import { ContactService } from "../../../services/contact.service";
import { TaAppCardFighterService } from "../../../services/ta-app-card-fighter.service";
import { TaAppFightService } from "../../../services/ta-app-fight.service";
//TODO import { TranslateService } from '../../../translate';
import * as moment from "moment";
import { ChatRoomService } from "../../../services/chat-room.service";
import { ChatService } from '../../../services/chat.service';
import {ClubService} from '../../../services/club.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: "app-combattants",
  templateUrl: "./combattants.component.html",
  styleUrls: ["./combattants.component.scss"],
})
export class CombattantsComponent implements OnInit {
  searchClub = new Subject();
  searchForm = {
    name: null,
    id_living_country: null,
    id_sex: null,
    id_nationnality: null,
    id_weight_category_1: null,
    id_weight_category_2 : null,
    fighter_level: 3, //par défaut on chercher les 3 profils
    min_fight: null,
    max_fight :null,
    id_club : null
  };
  contactForm: FormGroup;
  messageForm: FormGroup;
  addToFightForm: FormGroup;
  isSending: boolean = false;
  @ViewChild("contactModal") contactModal: ModalDirective;
  @ViewChild("addToFightModal") addToFightModal: ModalDirective;
  @ViewChild("messageModal") messageModal: ModalDirective;
  isLoading: boolean = true;
  public listFighters: any = [];
  public listCountries: any = [];
  public listSexes: any = [];
  public listNationnality: any = [];
  public listWeights: any = [];
  public clubList:any=[];

  contact_validation_messages = {
    message: null,
    subject: null,
  };
  contactType: any;
  selectedFighter: any;
  selectedManager: any;
  errorResult: boolean;
  successResult: boolean;
  fightcardList: any;
  fightList: any;
  weightCategoryList: any;
  resultsSearchClub: Observable<any>;
  locale : string = 'en';
  constructor(
    public fb: FormBuilder,
    private fighterProfileService: FighterService,
    private referentielService: ReferentielService,
    public translateService: TranslateService,
    private fightService: TaAppFightService,
    private fightcardService: TaAppCardFighterService,
    public globals: Globals,
    private contactService: ContactService,
    //private weightHistoryService: TaAppFighterWeightHistoryService,
    private alertService: AlertService,
    private chatRoomService: ChatRoomService,
    private chatService : ChatService,
    private clubService : ClubService
  ) {
    this.contactForm = new FormGroup({
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
    });
    this.messageForm = new FormGroup({
      message: new FormControl("", Validators.required),
    });
    this.contact_validation_messages = {
      message: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
      subject: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
    };
    this.addToFightForm = new FormGroup({
      id_fightcard: new FormControl("", Validators.required),
    });
  }

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.getCountries();
    this.getWeights();
    this.getFighters();
    this.getNationnality();
    this.getSexes();
    //récupération des clubs
    this.clubService.gets(null).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        this.clubList = jsonResponse.rows;
        this.resultsSearchClub = this.searchClub.pipe(
        startWith(''),
        map((value: string) => this.filterClub(value))
      );
      }
    },error=>{},
  ()=>{});
  }
  filterClub(value: string): string[] | undefined {
   const filterValue = value.toLowerCase();
   if (this.clubList && this.clubList.length >0) {
     return this.clubList.filter((item: any) =>
       item.club_label.toLowerCase().includes(filterValue)
     );
   }
 }
  getFighters() {
    this.isLoading = true;
    this.listFighters = [];
    let datas = this.searchForm;//.getRawValue();
    datas.id_weight_category_2 =
      this.searchForm.id_weight_category_1;
    this.fighterProfileService.getList(datas).subscribe(
      (jsonResponse) => {
        this.isLoading = false;
        // si la requete est un succés.
        this.listFighters = jsonResponse.rows;
        /*this.listFighters.forEach((fighter) => {
          if(fighter.is_pro){
          fighter.victory_percent = fighter.number_victory
            ? (fighter.number_victory / fighter.total_fight) * 100
            : 0;
          fighter.defeat_percent = fighter.number_defeat
            ? (fighter.number_defeat / fighter.total_fight) * 100
            : 0;
          fighter.null_percent = fighter.number_null
            ? (fighter.number_null / fighter.total_fight) * 100
            : 0;
          }
          else{
            fighter.victory_percent = fighter.number_victory_amateur
              ? (fighter.number_victory_amateur / fighter.total_fight_amateur) * 100
              : 0;
            fighter.defeat_percent = fighter.number_defeat_amateur
              ? (fighter.number_defeat_amateur / fighter.total_fight_amateur) * 100
              : 0;
            fighter.null_percent = fighter.number_draw_amateur
              ? (fighter.number_draw_amateur / fighter.total_fight_amateur) * 100
              : 0;
            }
        });*/
        //remplacer par un Pipe dans la vue html
        this.addAgetoFihters();
        //this.getFighterLastweight();
      },
      (error) => {
        //error
      },
      () => {} //complete
    );
  }

  getCountries() {
    this.referentielService.getCountries().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listCountries = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getWeights() {
    this.referentielService.getWeightCategories().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.weightCategoryList = jsonResponse.rows;
          this.listWeights = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getSexes() {
    this.referentielService.getSex().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listSexes = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getNationnality() {
    this.referentielService.getNationnalities().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listNationnality = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }

  resetFilter() {
    this.searchForm = {
      name: null,
      id_living_country: null,
      id_sex: null,
      id_nationnality: null,
      id_weight_category_1: null,
      id_weight_category_2 : null,
      fighter_level : 3,
      min_fight: null,
      max_fight :null,
      id_club : null
    };
  }

  public CalculateAge(birthdate: Date) {
    if (birthdate) {
      let birth = moment(birthdate);
      let today = moment();
      let age = today.diff(birth, "years");

      return (
        " (" + age + " " + this.translateService.instant("LABEL", "AGE") + ")"
      );
    }
    return "";
  }
  public addAgetoFihters(): void {
    for (let i = 0; i < this.listFighters.length; i++) {
      if (this.listFighters[i].birth_date != null) {
        this.listFighters[i].birth_date =
          moment(this.listFighters[i].birth_date).format("L").toString() +
          this.CalculateAge(this.listFighters[i].birth_date);
      }
    }
  }
  /*getFighterLastweight() {
		for (let i = 0; i < this.listFighters.length; i++) {
			this.weightHistoryService.getLastWeight(this.listFighters[i].id_fighter_profile)
			.subscribe(
				jsonResponse => {
					// si la requete est un succés.
					let history = jsonResponse.rows[0];
					/*this.listFighters[i].last_weight_kg = "("+ history.weight_kg+ " kg)";
					this.listFighters[i].last_weight_lbs =  history.weight_lbs +" lbs";*/
  /*	}, error => {
					//error
				}
			);
		}

	}*/
  /*get id_living_country() {
    return this.searchForm.get("id_living_country");
  }
  get id_sex() {
    return this.searchForm.get("id_sex");
  }
  get id_nationnality() {
    return this.searchForm.get("id_nationnality");
  }
  get id_weight_category_1() {
    return this.searchForm.get("id_weight_category_1");
  }
  get name() {
    return this.searchForm.get("name");
  }*/

  showContactForm(contactType, fighterId, managerId) {
    this.selectedFighter = fighterId;
    this.selectedManager = managerId;
    this.contactType = contactType;
    this.contactModal.show();
  }
  submitContactForm() {
    this.isSending = true;
    let datas = this.contactForm.getRawValue();
    datas.id_fighter_profile = this.selectedFighter;
    datas.id_manager_profile = this.selectedManager;
    datas.contactType = this.contactType;
    this.contactService.send(datas).subscribe(
      (jsonResponse) => {
        this.isSending = false;
        if (jsonResponse.success) {
          this.successResult = true;
        } else {
          this.errorResult = true;
        }
      },
      (error) => {
        this.errorResult = true;
        this.alertService.alertError(error.status);
        this.isSending = false;
      }
    );
  }
  showAddToFightModal(fighter) {
    this.selectedFighter = fighter;
    this.addToFightModal.show();
    this.fightcardService.getListFightCard().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success == true) {
          this.fightcardList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getFightList() {
    let searchParams = {
      id_fightcard: this.addToFightForm.get("id_fightcard"),
    };
    this.fightService
      .getListFights(this.addToFightForm.getRawValue())
      .subscribe(
        (jsonResponse) => {
          if (jsonResponse.success == true) {
            this.fightList = jsonResponse.rows;
          } else {
            this.errorResult = true;
            this.alertService.showError(
              jsonResponse.msg,
              this.translateService.instant("ERROR", "TITLE")
            );
          }
        },
        (error) => {
          this.errorResult = true;
          this.alertService.alertError(error.status);
        }
      );
  }
  addFightertofight(fight, position) {
    let params = {
      id_fight: fight.id_fight,
      id_fighter_1: fight.id_fighter_1,
      id_fighter_2: fight.id_fighter_2,
      id_fighter_reserve: fight.id_fighter_reserve,
    };
    switch (position) {
      case 1: {
        //statements;
        params.id_fighter_1 = this.selectedFighter.id_fighter_profile;
        break;
      }
      case 2: {
        params.id_fighter_2 = this.selectedFighter.id_fighter_profile;
        //statements;
        break;
      }
      case 3: {
        //statements;
        params.id_fighter_reserve = this.selectedFighter.id_fighter_profile;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    this.fightService.updateFighterFromFight(params).subscribe(
      (jsonResponse) => {
        if (jsonResponse.success === true) {
          //mise a jour des données
          this.getFightList();
        } else {
          this.errorResult = true;
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.errorResult = true;
        this.alertService.alertError(error.status);
      }
    );
  }

  showMessageForm(contactType, fighterRoomId, managerRoomId) {
    if(contactType == "manager" && managerRoomId == null){
      this.alertService.showError(this.translateService.instant('ERROR','MANAGER'), this.translateService.instant('ERROR','TITLE'));
    }else{
      this.selectedFighter = fighterRoomId;
      this.selectedManager = managerRoomId;
      this.contactType = contactType;
      this.messageModal.show();
    }
  }
  submitMessageForm() {
    this.isSending = true;
    let datas = this.messageForm.getRawValue();
    datas.id_fighter_profile = this.selectedFighter;
    datas.id_manager_profile =this.selectedManager;
    datas.contactType = this.contactType;
    //Récupération de la room en fonction du contact sélectionné
    this.chatService.searchRoomForUser(datas).subscribe(jsonResponse=>{
      this.isSending = false;
    if(jsonResponse.success){
      //on envoie le message dans la room déjà créée
      if(jsonResponse.total > 0 && jsonResponse.rows[0].id_chat_room !=undefined){
        datas = {
          message : this.messageForm.get('message').value,
          user_creator_room_id : jsonResponse.rows[0].user_id_creator,
          id_chat_room : jsonResponse.rows[0].id_chat_room,
          user_id_receiver : jsonResponse.rows[0].user_id_receiver
        };
        this.sendInChatRoom(datas);
      }
      //on créée une nouvelle room et on envoie le message
      else{
        //stockage du roomId du user à contacter. On reçoit les informations sur le contact uniquement s'il n'y a pas de room existante
        let userRoomId = jsonResponse.rows[0].room_id;
        this.chatRoomService.createRoom(jsonResponse.rows[0].room_id).subscribe(jsonResponse=>{
          //succes création du message
          if(jsonResponse.success){
            datas = {
              message : this.messageForm.get('message').value,
              user_creator_room_id : jsonResponse.obj.user_creator_room_id,
              id_chat_room : jsonResponse.id,
              user_id_receiver : userRoomId
            };
            //envoi du message
            this.sendInChatRoom(datas);
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        },()=>{});
      }
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
    },error=>{
      this.isSending = false;
    this.alertService.alertError(error.status);
    },()=>{});
  }
  sendInChatRoom(datas){
    let formDatas:FormData = new FormData();
    formDatas.append('id_chat_room', datas.id_chat_room);
    formDatas.append('message', datas.message);
    formDatas.append('receiver_room_id', datas.user_id_receiver);
    this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.chatService.sendMessage(
          { roomId: datas.id_chat_room,
            message: datas.message,
            user_room_id:"",
            receiver_room_id : datas.user_id_receiver,
            create_datetime: new Date(),
            imageFromSocket : null
          }
        );
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','MESSAGE_SENT'), this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }
  //Filtre les catégories de poids de combats homme/femme
  filterWeightCategories(event){
    if(this.searchForm.id_sex == 1){
      this.listWeights =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_men == true){
            return item;
          }
      });
    }
    if(this.searchForm.id_sex == 2){
      this.listWeights =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_women == true){
            return item;
          }
      });
    }
  }
  controlSexSelection(){
    if(!this.searchForm.id_sex){
      this.alertService.showError(this.translateService.instant("ERROR","selectSex"),this.translateService.instant('ERROR','TITLE'));
    }
  }
  onClubSelect(id_club){
    this.searchForm.id_club = id_club;
  }
  clearClubSelection(){
    this.searchForm.id_club = null;
  }
}
