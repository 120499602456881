import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe {
  transform(value: string, args: string) : string {
    moment.locale(navigator.language); //set language
  		return moment(value).format(args);
  }
}
