import { Component, Input, OnChanges, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '../../../translate';
import { AlertService } from '../../../services/alert.service';
import { FormControl } from '@angular/forms';
import { ChatRoomService } from '../../../services/chat-room.service';
import { ChatService } from '../../../services/chat.service';
import { Globals } from '../../../globals';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {
  currentUser = null;
  @Input() room: any; //Room actuel
  @Output() actualize = new EventEmitter<void>(); //Actualise les discussions (composant discussion)
  messagesList: any; //Liste des messages de la room
  isLoadingMessages: boolean = true;

  //On récupère la div des messages pour pouvoir scroll automatiquement
  @ViewChild ('scroll', { static: true }) scroll: any;

  user: any; //utilisateur courant
  messageArea: FormControl; //Text area du nouveau message
  toggled: boolean = false; //Pour les smiley
  img: File = null; //Image du message
  imgPreview:any = null; //Prévisualisation image message
  modalIsOpen:boolean = false; //Si le modal d'emoji est ouvert

  //Images
  assetFolder  = "../../../../assets/icone/chatLogo";
  imgSend      = this.assetFolder + "/send.png";
  imgSmiley    = this.assetFolder + "/smiley.png";
  imgAddFile   = this.assetFolder + "/document.png";
  imgFile      = this.assetFolder + "/file.png";
  videoFile    = this.assetFolder + "/video.png";
  pdfFile      = this.assetFolder + "/pdf.png";

  @ViewChild("modalDeleteMessage") modalDeleteMessage;
  deleteMessageLoading = false;
  idMessageToDelete = null;
  receiverUserIdChatRoom: any;

  constructor(private chatService: ChatService , private chatRoomService: ChatRoomService, private authService: AuthService, private alertService: AlertService, private translateService :TranslateService, private globals: Globals) {
    this.authService.user().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.user = jsonResponse.rows[0];
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });

    this.messageArea = new FormControl('');

  }

  ngOnInit(): void {
    console.log(this.room);
    this.currentUser = localStorage.getItem('roomId');//parseInt(localStorage.getItem('id'));
    //Actualisation des messages
    this.chatService.socket.on('message', data => {
      this.actualize.emit();
      if(data.roomId == this.room.id_chat_room){
        data.image = data.imageFromSocket;
        this.messagesList.push(data);
        setTimeout(() => {  this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight; }, 0);

        //Gestion localStorage
        let chatValue = JSON.parse(localStorage.getItem('chat'));
        chatValue.rooms[this.room.id_chat_room] = data.message;
        localStorage.setItem('chat', JSON.stringify(chatValue));
      }
    });
  }

  ngOnChanges(changes){
    //On change la room de connexion dans le socket si la room du chat change et on récupère les messages
    if(changes.room){
      //On rejoint la room
      this.chatService.joinRoom(this.room.id_chat_room);

      //Gestion localStorage
      let chatValue = JSON.parse(localStorage.getItem('chat'));
      chatValue.rooms[this.room.id_chat_room] = this.room.message;
      localStorage.setItem('chat', JSON.stringify(chatValue));

      //On change les messages
      this.messagesList = null;
      this.isLoadingMessages = true;
      this.chatRoomService.getMessages(this.room.id_chat_room).subscribe(jsonResponse=>{
        this.isLoadingMessages = false;
        /*if(jsonResponse.success == true){
          this.messagesList = jsonResponse.rows;
          setTimeout(() => {  this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight; }, 0);
          this.isLoadingMessages = false;

        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          this.isLoadingMessages = false;
        }*/
        if(jsonResponse.success == true){
          this.messagesList = jsonResponse.rows;
          if(this.room.user_id_receiver_room !== this.currentUser){
            this.receiverUserIdChatRoom = this.room.user_id_receiver_room;//this.currentUser;//
          }if(this.room.user_id_creator !== this.currentUser){
            //le receveur est le créateur de la room, c'est avec lui qu'on communique
            this.receiverUserIdChatRoom = this.room.user_id_creator//roomParams.user_id_receiver;
          }
        }else{
          this.alertService.showError("Erreur lors de la récupération des messages",this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
        this.isLoadingMessages = false;
      });

    }
  }

  send(){
    let currentMessage = this.messageArea.value;
    //Message sans image
    if(this.img === null){
      if(currentMessage != null && currentMessage != ''){
        this.messageArea.setValue('');
        let formData:FormData = new FormData();
        if(this.img){
          formData.append('chatFile', this.img, this.img.name);
        }

        formData.append('id_chat_room', this.room.id_chat_room);
        formData.append('message', currentMessage);
        formData.append('receiver_room_id',this.receiverUserIdChatRoom);

        this.chatRoomService.sendMessage(formData).subscribe(jsonResponse=>{
          if(jsonResponse.success == true){

            this.chatService.sendMessage({
              message: currentMessage,
              roomId: this.room.id_chat_room,
              user_id: this.user.id_user,
              create_datetime: new Date(),
              imageFromSocket : null,
              user_room_id: this.user.room_id,
              receiver_room_id : this.receiverUserIdChatRoom
            });
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        });
      }
    //Message avec image
    }else{
      if(currentMessage === null){
        currentMessage = "";
      }
      //Création des datas à envoyer
      let formData:FormData = new FormData();
      formData.append('chatFile', this.img, this.img.name);
      formData.append('id_chat_room', this.room.id_chat_room);
      formData.append('message', currentMessage);
      formData.append('receiver_room_id', this.room.user_id_receiver_room);
      //Reset des valeurs
      this.messageArea.setValue('');
      this.img = null;
      this.imgPreview = null;
      //Envoie msg
      this.chatRoomService.sendMessageWithDocument(formData).subscribe(jsonResponse=>{
        if(jsonResponse.success == true){

          //Récupérer les infos de l'image sur le serveur
          this.chatRoomService.getMessageInfo(jsonResponse.id).subscribe(jsonResponse2=>{
            if(jsonResponse2.success == true){

              //Envoi socket
              this.chatService.sendMessage({
                message: currentMessage,
                roomId: this.room.id_chat_room,
                user_id: this.user.id_user,
                create_datetime: new Date(),
                imageFromSocket : jsonResponse2.rows[0].image,
                user_room_id: this.user.room_id,
                receiver_room_id : this.room.user_id_receiver_room
              });

            }else{
              this.alertService.showError(jsonResponse2.msg,this.translateService.instant('ERROR','TITLE'));
            }
          },error=>{
            this.alertService.alertError(error.status);
          });

        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
      });
    }

  }

  addEmoji($event) {
    this.messageArea.setValue(this.messageArea.value + $event.emoji.native );
    //this.modalIsOpen = false;
  }

  fileChange(event) {
    this.img = event.target.files[0];
    this.preview(event.target.files);
  }

  getImagePath(image){
    let imgParsed = JSON.parse(image);
    let imgName = imgParsed.fileName;
    let imgType = imgParsed.extension;
    let imgFolder = imgParsed.folder;
    if(imgType == "png" || imgType == "jpg" || imgType == "jpeg" || imgType == "gif"){
      return this.globals.publicPathImage + '/chat/' + imgFolder + '/' + imgName;
    }else if(imgType == "pdf"){
      return this.pdfFile;
    }else if(imgType == "mp4" || imgType == "mp3" || imgType == "wav" || imgType == "mpga" || imgType == "avi" || imgType == "mov"){
      return this.videoFile;
    }else{
      return this.imgFile;
    }
  }

  getType(image){
    let imgType = JSON.parse(image).extension;
    if(imgType == "png" || imgType == "jpg" || imgType == "jpeg" || imgType == "gif"){
      return "img";
    }else if(imgType == "mp4" || imgType == "avi" || imgType == "mov"){
      return "video";
    }else if(imgType == "mp3" || imgType == "wav" || imgType == "mpga"){
      return "audio";
    }else{
      return "other";
    }
  }

  getFilePath(image){
    let imgName = JSON.parse(image).fileName;
    return this.globals.publicPathImage + '/chat/' + this.room.id_chat_room + '/' + imgName;
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //Pour autre chose que les images
      this.imgPreview = this.imgFile;
    }else{
      //Pour les images
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgPreview = reader.result;
      }
    }


  }

  openFile(file){
    let imgParsed = JSON.parse(file);
    let imgName = imgParsed.fileName;
    let imgType = imgParsed.extension;
    let imgFolder = imgParsed.folder;
    window.open(this.globals.publicPathImage + '/chat/' + imgFolder + '/' + imgName, '_blank');
  }

  showDeleteMessageModal(id){
    this.idMessageToDelete = id;
    this.modalDeleteMessage.show();
  }

  deleteMessage(){
    this.deleteMessageLoading = true;
    this.chatService.deleteMessage(this.idMessageToDelete).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        for (let index = 0; index < this.messagesList.length; index++) {
          if(this.messagesList[index].id_chat_message == this.idMessageToDelete){
            this.messagesList.splice(index, 1);
            this.modalDeleteMessage.hide();
            this.deleteMessageLoading = false;
          }
        }
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }

  test(param=null){
    //console.log(param);
  }

}
