import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { TaRefCountryService } from 'src/app/services/ta-ref-country.service';
import { TranslateService } from 'src/app/translate';
import * as moment from "moment";
@Component({
  selector: 'app-short-notice',
  templateUrl: './short-notice.component.html',
  styleUrls: ['./short-notice.component.scss']
})
export class ShortNoticeComponent implements OnInit {
step : number=0;
shortnoticeForm: FormGroup;
validation_messages = {
  'event_name' : null,
  'datetime_event' :null,
  'id_country' : null,
  'id_sex':null
};
  minDate: string;
  listCountries: any;
  constructor(
    public  translate: TranslateService,
  	private alertService: AlertService,
    private countryService : TaRefCountryService
  ) {
    this.validation_messages = {
      'event_name': [
        { type: 'required', message: this.translate.instant('ERROR','FIELD_REQUIRED') },
      ],
      'datetime_event': [
        { type: 'required', message: this.translate.instant('ERROR','FIELD_REQUIRED') },
      ],
      'id_country':[
        { type: 'required', message: this.translate.instant('ERROR','FIELD_REQUIRED') },
      ],
      'id_sex':[
        { type: 'required', message: this.translate.instant('ERROR','FIELD_REQUIRED') },
      ]
    };

    this.shortnoticeForm =
      new FormGroup({
        'event_name' : new FormControl(null,Validators.compose([
          Validators.required
        ])),
        'datetime_event' : new FormControl(null,Validators.compose([
          Validators.required
        ])),
        'place' : new FormControl(null),
        'contact' : new FormControl(null),
        'id_country' : new FormControl(null),
        'id_sex': new FormControl(null),
        'is_rookie': new FormControl(null),
        'weight': new FormControl(null),
        'nb_min_fight': new FormControl(null),
        'nb_max_fight': new FormControl(null),
        'other_informations' : new FormControl(null),
        'medical_informations': new FormControl(null)
      });
      this.minDate = moment().format('YYYY-MM-DD');

      this.countryService.getCountries().subscribe(jsonResponse=>{
        if(jsonResponse.success == true){
          this.listCountries = jsonResponse.rows;
        }
      },error=>{},
    ()=>{})

   }

  ngOnInit(): void {

  }

}
