import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class ChatRoomService {  constructor(private httpClient : HttpClient, private globals: Globals/*, private transfer:FileTransfer*/) { }
  public createRoom(id:number):Observable<any>{
    let httpParams = new HttpParams().set('user_id_receiver',id.toString());
    return this.httpClient.post(this.globals.baseUrl+"create-chatroom",httpParams);
  }
  public checkRoom(id:number):Observable<any>{
    let httpParams = new HttpParams().set('user_id_receiver',id.toString());
    return this.httpClient.post(this.globals.baseUrl+"check-chatroom",httpParams);
  }
  public getMessages(roomId):Observable<any>{
    let httpParams = new HttpParams().set('roomId',roomId);
    return this.httpClient.post(this.globals.baseUrl+"chat-messages",httpParams);
  }
  public getList():Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'chatrooms');
  }
  public getOrderList():Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'chatrooms-order');
  }
  public deleteRoom(roomId):Observable<any>{
    return this.httpClient.delete(this.globals.baseUrl+"chatrooms/"+roomId);
  }

  //Envoi d'un message sans document
  public sendMessage(formdatas:FormData):Observable<any>{
    return this.httpClient.post(this.globals.baseUrl+"create-message",formdatas);
  }

  //Envoi d'un message avec document
  public sendMessageWithDocument(datas):Observable<any>{
    return this.httpClient.post(this.globals.baseUrl+"create-message", datas);
  }

  //Récupère les infos du message dont l'id est passé en paramètre
  public getMessageInfo(messageId):Observable<any>{
    let httpParams = new HttpParams().set('messageId',messageId);
    return this.httpClient.post(this.globals.baseUrl+"get-message-info",httpParams);
  }
}
