import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import {MDBModalRef, MDBModalService,ModalDirective} from 'ng-uikit-pro-standard';
import { TranslateService } from '../../../translate';
import { AlertService } from '../../../services/alert.service';
import {ReferentielService} from '../../../services/referentiel.service';
import {TaAppFightService} from '../../../services/ta-app-fight.service';
import {ModalAddFighterComponent} from '../modal-add-fighter/modal-add-fighter.component';
import * as moment from 'moment';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input() fight;
  @Input() isEdit;
  @ViewChild('modalNextStep') modalNextStep:ModalDirective;
  @ViewChild('modalSuccess') modalSuccess:ModalDirective;
  @ViewChild('modalConfirmRemoveFighter') modalConfirmRemoveFighter:ModalDirective;
  fightCardId = null;
  modalRef: MDBModalRef;
  displayMainInformations : boolean = true;
  displayOptionalInformations: boolean = false;
  fightForm: FormGroup;
  weightCategoryList: any;
  sexList: any;
  validation_messages = {
    //'weight_category' : null,
    'sex' :null,
    "type_fight" : null,
    "nb_round" : null,
    "time_round" : null
  };
  isSaving : boolean = false;
  fightTypeList: any;
  selectedFighter1 = null;
  selectedFighter2 = null;
  selectedReserveFighter = null;
  fighterToRemove: number;
  filterWeightCategoriesList: any;
  isCatchWeight: boolean;
  mealList: any;
  lastLicenseText: string = null; //Text entré dans license details avant désactivation du champs
  showCatchWeightField: boolean=false;
  showNbMinWinField: boolean = true;
  exp_rookie_list: any;
  fightId: any;
  constructor(
    private route: ActivatedRoute,
    private translateService : TranslateService,
    private router : Router,
    private alertService : AlertService,
    private referentielService : ReferentielService,
    private fightService : TaAppFightService,
    private modalService: MDBModalService
  ) {
    this.fightCardId = this.route.snapshot.paramMap.get('id');
    this.validation_messages = {
      /*'weight_category': [
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ],*/
      'type_fight': [
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ],
      'sex': [
        { type: 'required', message: this.translateService.instant('ERROR','FIELD_REQUIRED') },
      ],
      'nb_round': [
        { type: 'required', message: this.translateService.instant('ERROR','ERROR_FIELD_NB_ROUND') },
      ],
      'time_round': [
        { type: 'required', message: this.translateService.instant('ERROR','ERROR_FIELD_TIME_ROUND') },
      ]
    };

	this.fightForm =
    new FormGroup({
      /*'id_weight_category' : new FormControl(null,Validators.compose([
        Validators.required
      ])),*/
      'id_weight_category' : new FormControl(null),
      /*'id_type_fight' : new FormControl(null,Validators.compose([
        Validators.required
      ])),*/
      'id_fightcard' : new FormControl(this.fightCardId),
      'id_sex' : new FormControl(null,Validators.compose([
        Validators.required
      ])),
      'description' : new FormControl(null),
      'is_public' : new FormControl(false),
      'is_rookie' : new FormControl("false"),
      'is_reserve' : new FormControl(false),
      'is_catchweight' : new FormControl(false),
      'catchweight_lbs' : new FormControl(null, Validators.compose([Validators.min(0)])),
      'catchweight_kg' : new FormControl(null, Validators.compose([Validators.min(0)])),
      'nb_round' : new FormControl(null, Validators.compose([Validators.min(1), Validators.required])),
      'time_round_m' : new FormControl(null, Validators.compose([Validators.min(0), Validators.max(59), Validators.required])),
      //'time_round_s' : new FormControl(0, Validators.compose([Validators.min(0), Validators.max(59), Validators.required])),
      'id_exp_amateur' : new FormControl(null),
      //Partie Facultative
      'certif_medical' : new FormControl(null),
      'blood_test' : new FormControl(null),
      'brain_test' : new FormControl(null),
      'angio_mri_cerebral_test' : new FormControl(null),
      'ecg_test' : new FormControl(null),
      'stress_test' : new FormControl(null),
      'eye_test' : new FormControl(null),
      'min_cash_prize' : new FormControl(null, Validators.compose([Validators.min(0)])),
      'max_cash_prize' : new FormControl(null, Validators.compose([Validators.min(0)])),
      'hotel' : new FormControl(null),
      'transport' : new FormControl(null),
      'meal' : new FormControl(null),
      'accompanying' : new FormControl(null, Validators.compose([Validators.min(0)])),
      'license' : new FormControl(null),
      'license_informations' : new FormControl(null),
      'nb_min_win' : new FormControl(0),
      'nb_min_fight' : new FormControl(0),
      'nb_max_fight' : new FormControl(0),
      'min_age' : new FormControl(0),
      'max_age' : new FormControl(0)
    });
   }

  ngOnInit(): void {

    //Weight category
    this.referentielService.getWeightCategories().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.weightCategoryList = jsonResponse.rows;
        this.filterWeightCategoriesList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
    //Type de combat
    /*this.referentielService.getFightType().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.fightTypeList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });*/
    //Repas
    this.referentielService.getMeals().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.mealList = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });

    //Expérience amateur
    this.referentielService.getExpRookie().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.exp_rookie_list = jsonResponse.rows;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }

  //Send form to server
  submit(){
    this.isSaving = true;
    let submitDatas = this.fightForm.getRawValue();
    if(!this.fightId){
    //submitDatas.time_round = '00:' + moment(this.fightForm.get('time_round_m').value.toString() + ':' + this.fightForm.get('time_round_s').value.toString(), "mm:ss").format("mm:ss");
    submitDatas.time_round = '00:' + moment(this.fightForm.get('time_round_m').value.toString() + ':' + '00', "mm:ss").format("mm:ss");
    /*submitDatas.id_fighter_1 = ((this.selectedFighter1)?this.selectedFighter1.id_fighter_profile:null);
    submitDatas.id_fighter_2 = ((this.selectedFighter2)?this.selectedFighter2.id_fighter_profile:null);
    submitDatas.id_fighter_reserve = ((this.selectedReserveFighter)?this.selectedReserveFighter.id_fighter_profile:null);*/
    this.fightService.add(submitDatas).subscribe(jsonResponse=>{
      this.isSaving = false;
      if(jsonResponse.success == true){
        //this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHT_ADDED'),this.translateService.instant('SUCCESS','TITLE'));
        this.modalSuccess.show();
        this.fightId = jsonResponse.id;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.isSaving = false;
      this.alertService.alertError(error.status);
    });
  }else{
    this.isSaving = true;
      let datas = this.fightForm.getRawValue();
      datas.time_round = '00:' + moment(this.fightForm.get('time_round_m').value.toString() + ':' + "00", "mm:ss").format("mm:ss");
      datas.id_fight = this.fightId;
      this.fightService.update(datas).subscribe(jsonResponse=>{
        this.isSaving = false;
        if(jsonResponse.success == true){
          this.alertService.showSuccess(this.translateService.instant('SUCCESS','FIGHT_UPDATED'),this.translateService.instant('SUCCESS','TITLE'));
          //this.modalNextStep.show();
          this.goBack();
        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.isSaving = false;
        this.alertService.alertError(error.status);
      });
    }
  }
  selectFighter(fighterNumber){
    let modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-notify modal-info modal-lg',
    animated: true,
    scroll : true,
    data : {
      fightFilter : this.fightForm.getRawValue(),
      selectedFighter1 : ((this.selectedFighter1 != null)?this.selectedFighter1.id_fighter_profile:null),
      selectedFighter2 : ((this.selectedFighter2 != null)?this.selectedFighter2.id_fighter_profile:null),
    }
  }
    this.modalRef = this.modalService.show(ModalAddFighterComponent,modalOptions);
    //récupération du fighter sélectionné dans le composant ModalAddFighterComponent
    this.modalRef.content.saveButtonClicked.subscribe((fighter: any) => {
      if(fighterNumber == 1){
        this.selectedFighter1 = fighter;
      }
      if(fighterNumber == 2){
        this.selectedFighter2 = fighter;
      }
      if(fighterNumber == 3){
        this.selectedReserveFighter = fighter;
      }
    });
  }

resetForm(){
  this.fightForm.reset();
  this.fightId = null;
  this.displayMainInformations = true;
  this.displayOptionalInformations = false;
  this.modalNextStep.hide();
}
showMoreInformationsForm(){
  this.displayMainInformations = false
  this.displayOptionalInformations = true;
  this.modalSuccess.hide();
}
removeFighter(){
  if(this.fighterToRemove == 1){
      this.selectedFighter1 = null;
  }
  if(this.fighterToRemove == 2){
      this.selectedFighter2 = null;
  }
  if(this.fighterToRemove == 3){
      this.selectedReserveFighter = null;
  }
  this.modalConfirmRemoveFighter.hide();

}
goBack(){
  this.router.navigate(['fight-list/'+this.fightCardId]);
}
//Filtre les catégories de poids de combats homme/femme
filterWeightCategories(event){
  if(this.fightForm.get('id_sex').value == 1){
    this.filterWeightCategoriesList =  this.weightCategoryList.filter(function(item){
      //item trouvé
        if(item.for_men == true){
          return item;
        }
    });
  }
  if(this.fightForm.get('id_sex').value == 2){
    this.filterWeightCategoriesList =  this.weightCategoryList.filter(function(item){
      //item trouvé
        if(item.for_women == true){
          return item;
        }
    });
  }
}
onSwitch(){
  //si catchweight activé
  if(this.fightForm.get('is_catchweight').value){
    this.showCatchWeightField = true;
    //clear weight_Categorie
    this.fightForm.controls['id_weight_category'].setValue(null);
    //disabled weight_categorie
    this.fightForm.controls['id_weight_category'].disable();
    this.fightForm.controls['catchweight_lbs'].enable();
    this.fightForm.controls['catchweight_kg'].enable();
  }else{
    this.showCatchWeightField = false;
    //enable weight_categorie
    this.fightForm.controls['id_weight_category'].enable();
    //disabme and clear catchweight fields
    this.fightForm.controls['catchweight_lbs'].disable();
    this.fightForm.controls['catchweight_lbs'].setValue(null);
    this.fightForm.controls['catchweight_kg'].disable();
    this.fightForm.controls['catchweight_kg'].setValue(null);
  }
}
convertWeightTo(to){
  if(to == 'kg'){
    this.fightForm.patchValue({
      catchweight_kg : this.fightForm.get('catchweight_lbs').value *0.453592
    });
  }if(to == 'lbs'){
    this.fightForm.patchValue({
      catchweight_lbs : this.fightForm.get('catchweight_kg').value *2.20462
    });
  }
}

/**
 * Arrondie le nombre à la virgule voulue
 * @param inputName String: le champs du formulaire à arrondire
 * @param decimal Number: le nombre de chiffre après la virgule à garder
 * @param autoNull Boolean: si la valeur est égale à 0, met la valeur à null
 * @param negative Boolean: converti le chiffre en positif si il est négatif
 */
roundInput(inputName, decimal, autoNull, negative){
  let value = this.fightForm.controls[inputName].value;
  if(!negative && value < 0){
    value = value * (-1);
  }
  value = value * Math.pow(10, decimal);
  value = Math.round(value);
  value = value/Math.pow(10, decimal);
  if(autoNull && value == 0){
    this.fightForm.controls[inputName].setValue(null);
  }else{
    this.fightForm.controls[inputName].setValue(value);
  }
}
switchLicense(){
   if(this.fightForm.get('license').value){
    this.fightForm.controls['license_informations'].enable();
    this.fightForm.controls['license_informations'].setValue(this.lastLicenseText);
  }else{
    this.fightForm.controls['license_informations'].disable();
    this.lastLicenseText = this.fightForm.controls['license_informations'].value;
    this.fightForm.controls['license_informations'].setValue(null);
  }
}
/*switchNbMinWinField(event){
  //si combat amateur => pas d'affichage du champ nb min win
  if(this.fightForm.get('is_rookie').value == "true"){
    this.showNbMinWinField = false;
  }else{
    this.showNbMinWinField = true;
  }
}*/
  controlSexSelection(){
    if(!this.fightForm.get('id_sex').value){
      this.alertService.showError(this.translateService.instant("ERROR","selectSex"),this.translateService.instant('ERROR','TITLE'));
    }
  }
  setRequiredFieldForAmateur(event){
    if(this.fightForm.get('is_rookie').value == 'true'){
      this.fightForm.controls['min_age'].setValidators(Validators.min(12));
    }else{
      this.fightForm.controls['min_age'].setValidators(Validators.min(0));
    }
  }
}
