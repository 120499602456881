export const LANG_EN_NAME = 'en';
export const LANG_EN_TRANS ={
  "FORM": {
  "TITLE_CONNEXION": "Connection",
  "EMAIL":"E-Mail",
  "actualEmailRequired" : "Your current email",
  "newEmailRequired" : "Your new email",
  "MODIFY_EMAIL":"Modify the email ?",
  "PASSWORD":"Password",
  "ACTUAL_PASSWORD":"Current password",
  "NEW_PASSWORD":"New password",
  "OLD_PASSWORD":"Old password",
  "REMEMBER_ME": "Remember me",
  "NO_ACCOUNT_YET": "Don't have an account yet?",
  "SIGN_UP_NOW": "Sign up now!",
  "TITLE_SIGN_UP": "Create your MMA Connection account",
  "TITLE_EDIT": "Edit my information",
  "ORGANISATION": "Organization Name",
  "BILLING_ADDRESS" : "Billing address",
  "DESCRIPTION": "Description",
  "PHONE": "Phone number",
  "LINK_FACEBOOK": "Facebook link",
  "LINK_TWITTER": "Twitter link",
  "LINK_INSTAGRAM": "Instagram link",
  "LINK_WEBSITE": "Website link",
  "NOT_EMPTY": "This field cannot be empty.",
  "NOT_EMPTY_EMAIL": "This field cannot be empty and must be in the following form exemple@mail.com",
  "NOT_EMPTY_PASSWORD": "This field cannot be empty and must exceed 5 characters in length.",
  "LINK_VIDEO": "Video link",
  "CHOOSE_COUNTRY": "Country",
  "COUNTRY": "Country",
  "ACCEPT": "Accept the",
  "TERM_AND_CONDITIONS": "Accept the terms and conditions",
  "NEWSLETTER": "Accept the newsletter ?",
  "LOGIN": "Sign in",
  "HAVE_ACCOUNT": "Already have an account?",
  "SIGN_UP": "To register",
  "EDIT": "Save changes",
  "OR_SIGNUP_WITH": "Or register with",
  "SIGININ_WITH": "Or connect with",
  "CANCEL_UPLOAD": "remove the image",
  "UPLOAD":"import image",
  "DELETE_PICTURE":"delete profile picture",
  "LABEL_DATE_CREATION": "Date of creation of the organization",
  "LABEL_CHOOSE_PICTURE": "Choose your profile picture",
  "SEARCH": "Enter a surname, first name or all 2",
  "CHOOSE_NATIONNALITY":"Nationality",
  "CHOOSE_FIGHTCARD":"Select a fight card",
  "CHOOSE_SEX":"Gender",
  "CHOOSE_WEIGHT": "Category",
  "TYPE_FIGHT": "Type of fight",
  "LABEL_CHOOSE_NONE": "None",
  "SUBMIT": "Record",
  "TITLE_CREATE_FIGHT_CARD":"Create your fight card",
  "TITLE_UPDATE_FIGHT_CARD":"Change your fight card",
  "EVENT_NAME":"Event Name",
  "PLACE":"Event location",
  "DESCRIPTION_EVENT":"Description of the event",
  "PRICE":"Event Price",
  "REWARD":"Combat Bounty",
  "MIN_REWARD":"Minimum Combat Bounty",
  "MAX_REWARD":"Maximum Combat Bounty",
  "SELECT_IMAGE":"Choose an image",
  "PUBLISHED":"Published",
  "TEXT_DELETE":"Do you really want to delete",
  "FIGHTCARD_TITLE_FORM" : "Create your fight card",
  "HIDE_IN_APP" : "Do not display in the MMA Connection application",
  "IS_ROOKIE": "Amateur fight",
  "IS_PRO": "Professional Combat",
  "FIGHT_LEVEL" : "Combat level",
  "ROOKIE": "Amateur",
  "IS_RESERVE" : "Reserve fight",
  "SELECT_FIGHTERS" : "Choice of fighters",
  "FIGHT_INFORMATIONS" : "Main information",
  "FIGHTER" : "Fighter",
  "RESERVE_FIGHTER" : "Reserve Fighter",
  "YOUR_MESSAGE" : "Your message",
  "SUBJECT" : "Message Subject",
  "FIGHT_MIN" : "Min fight",
  "FIGHT_MAX" : "Max fight.",
  "VICTORY_MIN" : "Win(s) min.",
  "EVENT_DATE" : "Date of the event",
  "LABEL_SEX_MALE" : "Men",
  "LABEL_SEX_FEMALE" : "Women",
  "CATCHWEIGHT" : "catch-weight",
  "TITLE_PASSWORD": "Change password",
  "CHANGE_PASSWORD": "Validate password change",
  "VALIDATE_NEW_PASSWORD": "Valider le nouveau mot de passe",
  "MEDICAL_INFORMATIONS_TITLE": "Medical information",
  "COUNTERPART_TITLE": "Compensation",
  "BLOOD_TEST": "Blood test (HIV,B,C)",
  "BRAIN_MRI": "Cerebral MRI",
  "STRESS_TEST": "Stress test",
  "EYE_TEST": "Eye test",
  "ANGIO_IRM": "MRI angiography",
  "ECG_TEST": "Electrocardiogram (ECG)",
  "PREMIUM": "Premium",
  "HOTEL": "Hotel",
  "TRANSPORT": "Transport",
  "MEAL": "Meal",
  "ACCOMPANYING_NUMBER": "Number of accompanying persons supported",
  "OPTIONAL": "Optional part",
  "FORGOTTEN_PASSWORD": "Forgot your password",
  "LICENSE": "Licence",
  "LICENSE_DETAILS": "License Information",
  "contactSubject" : "Message subject",
  "contactMessage" : "Your message",
  "nbMinWin" : "Minimum number of wins required to apply",
  "nbMinFight" : "Minimum number of fights required to apply",
  "nbMaxFight" : "Maximum number of fights required to apply",
  "nbRound" : "Number of rounds",
  "timeRound" : "Round time",
  "timeRoundMinute" : "Round time (minutes)*",
  "timeRoundSeconde" : "Round time (seconds)*",
  "deleteFightCardMessage" : "Please enter a message that will be sent to managers and fighters registered for this event",
  "TITLE_CHANGING_EMAIL" : "Change your email address",
  "SEARCH_CLUB" : "Club",
  "name" : "Name",
  "firstname" : "First name",
  "nickname" : "Nickname",
  "nbVictories" : "Nb. victory",
  "nbDefeat":"Nb. defeated",
  "nbDraw":"No. draws",
  "birthDate" : "Date of birth",
  "palmaresLink" : "Link to professional track record (Tapology or Sherdog)",
  "city" : "City",
  "height" : "Height",
  "weight" : "Weight",
  "fightWeight" : "Fighting weight",
  "proFighter": "Professional fighter",
  "selectElement" : "Select",
  "minAge" : "Minimum age to participate in the fight",
  "maxAge" : "Maximum age to participate in the fight",
  "PROMO" : "Promotional code",
  "EXP_ROOKIE" : "Amateur experience",
  "CONTACT_US":"Contact us",
  "OPPONENT" : "Opponent",
  "OTHER_INFORMATIONS" : "Other informations",
  "MEDICAL_REQUIREMENTS" : "Medical requirements",
  "CONTACT_INFORMATIONS" : "To contact you (optionnal)",
  "CONTACT_BY_EMAIL" : "I accept that the managers contact me by e-mail via the e-mail below",
  "CONTACT_BY_PHONE" : "I accept that the managers contact me by phone via the phone number below",
  "CHOOSE_LEVEL": "Level",
  "CHOOSE_PRO_LEVEL": "Professionnal",
  "CHOOSE_AMATEUR_LEVEL": "Amateur",
  "CHOOSE_BOTH_LEVEL": "Amateur and professionnal",
 },
 "formControl" : {
   "emailRequired" : "Please enter your e-mail",
   "actualEmailRequired" : "Please enter your current email",
   "newEmailRequired" : "Please enter your new email address",
   "enterValidEmail" : "Enter a valid email address",
   "passwordRequired" : "Please enter a password",
   "passworMinlength" : "The password must be at least 5 characters long",
   "confirmPassword" : "confirm your password",
   "passwordAreNotEqual" : "Passwords are not identical",
   "acceptTerms" : "You must agree to our terms of use",
   "nomRequired" : "Please enter your name",
   "sujetRequired" : "Please enter the subject of your message",
   "messageRequired" : "Please enter your message",
   "fieldRequired" : "Please complete this field.",
   "telephoneError" : "This is not a valid phone number",
   "informationsRequired" : "Please enter your information",
   "hitDeleteWord" : "Please enter the word DELETE in this field",
   "hitConfirmWord" : "Please enter the word CONFIRM in this field",
   "minAge" : "The minimum age to participate is 12 years old."
 },
  "LABEL": {
    "AGE": "Years",
    "CANDIDATE" :"candidates"
  },
  "NAVBAR": {
    "TITLE_NAVBAR": "MMA CONNECTION",
    "MY_ACCOUNT": "My account",
    "CHANGE_EMAIL": "Change my email address",
    "PASSWORD": "Password",
    "PROFILE": "Profile",
    "LOG_OUT": "Log out",
    "HOME": "Home",
    "COMBATTANTS": "Fighters",
    "MANAGERS" : "Managers",
    "MESSAGE": "Messages",
    "FIGHT_CARD":"fight card",
    "CREATE_FIGHT_CARD":"Create a fight card",
    "FIGHT_CARD_LIST":"Your fight cards",
    "CONTACT_US" : "Contact MMA Connection",
    "BUG" : "Report a BUG",
    "MESSAGES" : "Messages",
    "MY_ORDERS" : "My orders",
    "MY_SUBSCRIPTION" : "My subscription"
  },
   "ERROR": {
      "TITLE" : "Error",
      "ERROR_SERVER": "Please try again later",
      "ERROR_MAIL": "Check that your email is valid and try again",
      "ERROR_MAIL_TAKEN" : "Email address already used",
      "INVALID_MAIL": "This is not a valid email address",
      "REQUIRED_MAIL": "Please enter an e-mail",
      "REQUIRED_PASSWORD": "Please enter a password",
      "PASSWORD_TOO_SHORT": "The password must contain at least 5 characters",
      "ERROR_CREDENTIALS": "Check your login information and try again",
      "ERROR_EMPTY_FIELDS": "Check that you have filled in all the required information.",
      "ERROR_TERMS_CONDITIONS_NOT_ACCEPTED": "You have not accepted the terms and conditions.",
      "ERROR_IMAGE_UPLOAD": "The image was not added correctly",
      "NO_RESULT": "No results",
      "FIELD_REQUIRED" : "Please complete this field.",
      "OUPS_MESSAGE" : "Oops... an error has occurred...",
      "ERROR_SERVER_TITLE" : "Service not available",
      "ERROR_SERVER_MESSAGE" : "The application cannot reach the service.",
      "DISCONNECTED_TITLE" : "Disconnection",
      "DISCONNECTED_MESSAGE" : "You have been disconnected from MMA Connection.",
      "SENT_MESSAGE" : "The message could not be sent. Please try again later.",
      "SELECT_FIGHTCARD" : "Select a fight card",
      "SELECT_FIGHT" : "Select a fight",
      "DELETE_FIGHTER_BEFORE_ADDING" : "You must first remove a fighter.",
      "PASSWORD_NOT_EQUAL": "The passwords do not match.",
      "USER_DATA_NOT_LOADED": "The user\'s data are not loaded",
      "IMAGE_NOT_DELETED": "The image was not deleted correctly",
      "ACCOUNT_NOT_DELETED": "The account has not been deleted correctly",
      "ERROR_CHANGE_PASSWORD": 'The password could not be changed',
      "MANAGER": "This fighter has no manager",
      "hitDeleteWord" : "Please enter the word DELETE in the confirmation field",
      "hitConfirmWord" : "Please enter the word CONFIRM in the confirmation field",
      "MEDICAL_INFORMATIONS_LOADING" : "Error: Unable to load combatant's medical files.",
      "selectSex" : "Please select the sex.",
      "completeField" : "Please fill in this field",
      "fightcardPayment" : "The payment failed. Please try again. If the error occurs again, please contact the MMA Connection team.",
      "tooLate" : "You can no longer change the fight card.",
      "ERROR_FIELD_NB_ROUND": "Please complete this field (minimum 1)",
      "ERROR_FIELD_TIME_ROUND": "Please complete this field (min 0, max 59)",
      "SUBSCRIPTION_LOADING" : "We are unable to retrieve your subscription information at this time. Please try again in a moment.",
      "orderCreationTxt" : "An error occurred while creating your invoice. Try Again.",
      "noTokenTxt" : "You don't have a valid token to access this page."
   },
   "SUCCESS": {
      "SUCCESS_IMAGE_UPLOAD": "Image successfully saved.",
      "TITLE" : "Success!",
      "FIGHT_ADDED" : "Combat created successfully.",
      "FIGHT_UPDATED" : "Combat successfully updated.",
      "FIGHTER_ADDED" : "Fighter successfully added.",
      "FIGHTER_DELETED" : "Fighter successfully removed.",
      "MESSAGE_SENT" : "Message successfully sent.",
      "FIGHTCARD_CREATED" : "Your fight card has been successfully created",
      "FIGHTCARD_CREATED_TXT" : "Remember to publish your fight card to make it visible to managers and fighters. You can already start creating your fights.",
      "FIGHTCARD_UPDATED" : "Your Fight card has been modified",
      "ACCOUNT_CREATED": "Your account has been successfully created. You'll be redirect to homepage",
      "ACCOUNT_UPDATED": "Your account has been successfully updated.",
      "IMAGE_DELETED": "The image has been deleted correctly",
      "ACCOUNT_DELETED": "The account has been deleted correctly",
      "SUCCESS_CHANGE_PASSWORD": "The password has been successfully changed",
      "SUCCESS_RESET_PASSWORD": "An email has been sent to you to change your password",
      "FIGHTER_REPLACEMENT" : 'The fighter was successfully replaced.',
      "contactSended" : "Message sent successfully",
      "deletedFightCard" : "The fight card has been successfully removed",
      "publishedFightCard" : "The fight card has been successfully published",
      "unpublishedFightCard" : "The fight card has been successfully unpublished",
      "emailChanging" : "You will receive a confirmation e-mail.",
      "fighterAdded" : "Fighter added with success.",
      "fightcardPaid" : "The payment has been accepted. Your fight card is now published and available to the entire MMA Connection community. We wish you a great event.",
      "mails_sent" : "The emails were sent successfully.",
      "managerSubscriptionTxt" : "Thanks, your subscription start now. You can close this windows by clicking the button above.",
      "unsubscribeTxt" : "Your subscription end now. You can re-activate it when you want. You can close this windows by clicking the button above."
    },
   "TEXT":{
      "WELCOME" : "Welcome to your professional event management",
      "NO_FIGHTCARD" : "Create your fight card by clicking on the '+' button",
      "NO_RESULTS" : "No results",
      "NO_FIGHTER_FOUND" : "No results for your search.",
      "NO_FIGHT" : "No registered fights. Create your first fight by clicking on the '+' button",
      "ADD_FIGHTER" : "Add directly the fighters of your choice, or let them apply and validate the applications.",
      "DELETE_FIGHTER_TITLE" : "Delete this fighter?",
      "DELETE_FIGHTER_TEXT" : "Are you sure you want to remove this fighter?",
      "DELETE_FIGHTCARD_TITLE" : "Delete this fight card ?",
      "DELETE_FIGHTCARD_TEXT" : "Are you sure you want to delete this fight card? This action will delete all the fights you have created on this fight card. This action is irreversible.",
      "DELETE_FIGHT_TITLE" : "Delete this fight?",
      "DELETE_FIGHT_TEXT" : "Are you sure you want to delete this fight?",
      "UPDATE_FIGHT_TITLE" : "Update fight",
      "NEW_MESSAGE" : "New message",
      "FROM_FIGHT" : "of this fight?",
      "MORE_CRITERIA" : "+ criteria",
      "APPLICANTS" : "APPLICANTS",
      "NOAPPLICANTS" : "No applicants",
      "ADD_TO_FIGHT" : "Add to the fight?",
      "ADD_ANOTHER_FIGHT" : "Do you want to add another fight?",
      "ABOUT_FIGHT" : "About this fight",
      "ABOUT_FIGHT_NONE" : "No additional information provided",
      "FIGHTERS" : "Fighters",
      "REWARD" : "Reward",
      "NOT_SPECIFIED" : "Not specified",
      "HIS_FIGHTERS" : "ITS FIGHTERS",
      "ADD_OTHER_FIGHT" : "Your fight informations has been successfully updated. Do you want to add a new fight offer?",
      "ADD_OTHER_INFORMATIONS" : "Your fight has been successfully created. Do you want to add more informations (medicals files, reward, ...) ?",
      "MANAGER" : "MANAGER",
      "UNAVAILABLE" : "Unavailable",
      "UNAVAILABILITY_DATES" : "Unavailability",
      "TODAY" : "Today",
      "OR" : "Or",
      "MEDICAL_INFORMATIONS" : "In order to fight the fighter must provide:",
      "COUNTERPART" : "You are in charge of:",
      "RESET_PASSWORD": "Enter your email address to reset your password.",
      "DISCUSSION": "Discussion",
      "NEW_CONVERSATION": "New conversation",
      "TITLE_FIND_SOMEONE" : "Find someone",
      "SEND_MESSAGE_TO": "Send a new message to: ",
      "MANAGERS": "Managers",
      "YOUR_CONVERSATIONS": "Your conversations",
      "START_CONVERSATION": "Start or resume a conversation with a manager or fighter",
      "SHOW_MORE": "Show more",
      "CHOOSE_SMILEY": "Choose a smiley",
      "GET_STARTED_FREE": "Start for free ",
      "START_TO_EXPLORE": "Start exploring our product for free.",
      "NEW_MESSAGE_TEXT": "You have a new message!",
      "NEW_MAIL": "New mail",
      "CHECKING_PROFILE" : "Profile being verified",
      "NO_SHERDOG" : "Sherdog/Tapology link not added",
      "deleteFighterToAdd" : "You must cancel a fighter to add this one.",
      "COMPLETE_FORM": "Please complete the form below.",
      "CHANGING_EMAIL" : "Please complete the form below. After validation, an e-mail will be sent to your new address. It will contain a validation link valid for 30 minutes. Your new e-mail address will be taken into account only after you click on the link.",
      "PUBLISH_FIGHTCARD_TITLE" : "Publish the fight card ",
      "PUBLISH_FIGHTCARD_TEXT" : "Are you sure you want to publish this fight card? Fighters and managers will be able to access and apply for fights. You can unpublish it at any time.",
      "UNPUBLISH_FIGHTCARD_TITLE" : "Unpublish the fight card ",
      "UNPUBLISH_FIGHTCARD_TEXT" : "Are you sure you want to unpublish this fight card? Fighters and managers will no longer be able to access or apply for fights, but you can continue to edit it and you can republish it at any time.",
      "WARNING" : "Warning",
      "UNPUBLISHED_FIGHTCARD_TEXT" : "Fightcard not published.",
      "LINK_SHERDOG" : "Sherdog personal track record",
      "UNVERRIFIED_PROFILE" : "Identity not verified",
      "VERRIFIED_PROFILE" : "Verified identity",
      "creatingProfile" : "Profile being created",
      "MEDICAL_FILES_TITLE" : "Medical files",
      "MEDICAL_FILES" : "Medical files of",
      "certificat" : "Medical certificate of no contraindication to the practice of mma in competition (-1 year)",
      "ecg" : "Interpreted electrocardiogram ECG (-1 year)",
      "irm" : "Brain MRI (-1 year)",
      "blood" : "Blood test (hepatitis B serology (HBsAg), hepatitis C serology (Anti HCV), HIV)",
      "eyes" : "Ophthalmological check-up with eye fundus (-2 years)",
      "angioIrm" : "Brain MRI angio (-1 year)",
      "testEffort" : "Stress test",
      "fight_folder" : "Fight folder",
      "photo_folder" : "Photos",
      "completeAsteriskFields" : "Fields with a '*' are mandatory.",
      "noPhotos" : "No photos",
      "noLicence" : "No license",
      "updatedAt" : "Updated on",
      "palmares" :"Professional track record",
      'addFighter' : "Add a fighter",
      "informations" : "Information",
      "addFighterInfo" : "You have the possibility to create a fighter's profile. However, the profile will be active only when the fighter in question creates his account via the MMA Connection application.",
      "paymentTitle" : "Pay and publish this fight card",
      "paymentTxt" : "To make your fight card available to the entire MMA Connetion community, please pay the amount indicated, either through your Paypal account or by credit card directly.",
      "amountToPay" : "Amount before tax of your fight card : ",
      "TRANSACTION_SAVING" : "Transaction in progress, please wait...",
      "completeBillingAddress" : "Please complete your billing address before proceeding with your payment.",
      "SEND_MESSAGE_ALL_FIGHTERS_TITLE" : "Grouped message",
      "send_message_all_fighters_message" : "Send a message to all the fighters and managers of your fight card except the applicants.",
      "NO_FIGHT_IN_THIS_FIGHTCARD" : "No fight in this fight card",
      "MAX_FILES_SIZE" : "File size (max 15Mo)",
      "YOUR_SUBSCRIPTION" : "Your current subscription",
      "YOUR_PAYMENT_METHOD": "Means of payment",
      "wantCancelSubscription" : "Want to cancel your subscription?",
      "wantActivateSubscription" : "Want to reactivate your subscription?",
      "noPayment" : "No payment to display",
      "subscribtionTxt" : "Your subscription will start as soon as your payment is validated. You can stop it at any time without any charge.",
      "selectPayementMode" : "Select your payment method",
      "currency" : "EUR",
      "no_subscription": "You have no subscription",
      "monthly_subscription" : "Package : Monthly subscription 49,90 € HT or 59,88 €TTC (first month free)",
      "unsubscribe" : "Cancel your subscription",
      "unsubscribe_text" : "Are you sure you want to cancel your subscription? You can re-subscribe at any time.",
      "subscribedSince" : "Subscriber since",
      "yourPayments" : "Your payments",
      "deleteAccountTitle" : "Delete account",
      "deleteAccountText" : "Are you sure you want to delete your account? This action will delete all the fights and fight cards you have created on this account. This action is irreversible.",
      "inputResult" : "Enter the result",
      "deleteMessage" : "Are you sure you want to delete this message?",
      "deleteMessageTitle" : "Delete the message",
      "1_free_mounth" : "1 month free trial",
      "free_mounth" : "months free trial",
      "per_mounth" : "per month",
      "per_year" : "per year",
      "then" : 'then',
      "cancel_without_charge" : "Cancel at any time without charge.",
      "subscribe" : "Subscribe",
      "subscribe_text" : "By subscribing now you will have access to all the features of MMA Connection!",
      "subscribe_text_2" : "By subscribing to this offer, you accept our ",
      "our_gcs" : "general conditions of sale.",
      "choose_mma_connection" : 'Why choose MMA-Connection?',
      "choose_mma_connection_title_1" : "Create your combat offers",
      "choose_mma_connection_text_1" : "Create your events and set up your fight offers in a few clicks thanks to a tool designed for MMA fight organizers.",
      "choose_mma_connection_title_2" : "Search for fighters",
      "choose_mma_connection_text_2" : "Search for the fighters you need with an advanced filter system.",
      "choose_mma_connection_title_3" : "Search for managers",
      "choose_mma_connection_text_3" : "Search for managers by refining your search with our filters.",
      "choose_mma_connection_title_4" : "Publish your fight-card",
      "choose_mma_connection_text_4" : "Broadcast your fight offers to the whole community. This way you save time and efficiency while increasing your visibility.",
      "choose_mma_connection_title_5" : "Contact whoever you want",
      "choose_mma_connection_text_5" : "Contact via our messaging system the fighters and managers of your choice",
      "choose_mma_connection_title_6" : "Accessing verified information",
      "choose_mma_connection_text_6" : "Access information that was previously unavailable on a single platform. Availability, management, email, phone, fitness weight...",
      "fighter_informations" : "Fighter informations",
      "candidate_list_title" : "List of candidates",
      "pro_fighter" : "Professional fighter",
      "rookie_fighter" : "Amateur fighter",
      "EXP_ROOKIE" : "Amateur experience",
      "reactivateSubscriptionManager":"Reactivate your subscription to unlock all features in MMA Connection app.",
      "disabledOn" : "Disabled on",
      "whyUseShortNotice" : "Why using « Short notice »",
      "shortNoticeTxt1" : "A last minute cancellation?",
      "shortNoticeTxt2" : "Don't panic, with the app's 'Short notice' solution",
      "shortNoticeTxt3" : "MMA Connection you have a quick and effective simple solution!",
      "shortNoticeTxt4" :"Complete the form below and inform the entire community present on",
      "shortNoticeTxt5" :"the MMA Connection application by email, notification and sms!",
      "completeThisForm" :"Your selection criteria",
      "COMMING_SOON" : "Coming soon.",
      "CandidateTxt" : "These fighters have applied to fight at your event. Click on the categories to display the applicants.",
      "managedFighters" : "managed fighters",
      "yourFreePeriodExpireIn" : "Your free period expire in ",
      "noSubscriptionYet" : "Not a subscriber",
      "freePeriodEnd" : "Your trial period ended on  ",
      "freeUseUntil" : "You get a trial period until",
      "selectSubscription":"Please select your subscription",
      "ourOffers" : "Our offers",
      "loadingInformations" : "Loading your informations",
      "subscriptionForManagers" : "Our offers for managers",
      "updateSubscriptionForManager" : "You are about to change your current subscription to subscribe to the following offer : ",
      "updateSubscriptionForManager2" : "If you would like to subscribe to this new subscription, please enter the word CONFIRM in the field below then hit the 'CHANGE OFFER' button.",
      "updateSubscriptionForManager3" : "Your actuel subscription will be cancel and you'l be invit to login to your Paypal account to confirm your new subscription."
    },
    "WORD" : {
      "DAYS" : "days"
    },
   "PAGE": {
      "TITLE_FIGHT_CARD_LIST": "Your Fight Cards",
      "TITLE_COMING_FIGHT_CARD_LIST": "Your upcoming fight cards",
      "TITLE_PASSED_FIGHT_CARD_LIST": "Your past fight cards",
      "TITILE_FIGHT_LIST":"Your Fight Card",
      "TITLE_ADD_FIGHTER_TO_FIGHT":"Add a fighter",
      "TITLE_DELETE_FIGHTER_FROM_FIGHT": "Remove a fighter from a fight",
      "TITLE_CREATE_FIGHT" : "Create your fight"
   },
   "FIGHTCARD":{
    "NO_FIGTER_YET": "No fighter",
    "FIGHT": "Fight"
  },
  "BUTTON": {
    "SIGN_UP" : "Sign up",
    "LOGIN": "Log in",
    "LOG_OUT": "Log out",
    "SEARCH": "Search",
    "RESET": "Reset?",
    "RESET_FILTER" : "Delete filters",
    "ADD_TO_FIGHT":"Add to a fight",
    "REMOVE_FROM_FIGHT":"Remove from fight",
    "CONTACT_MANAGER":"Contact the manager",
    "CONTACT_HAS_NO_MANAGER":"This fighter has no manager",
    "CONTACT_FIGHTER":"Contact the fighter",
    "MODIFY_FIGHT_CARD":"Edit this fight card",
    "ADD_FIGHTER_TO_FIGHT":"Add a fighter (optional)",
    "DELETE":"Delete",
    "EDIT" : "Edit",
    "CLOSE": "Close",
    "ADD_FIGHT" : "Add a fight",
    "SHOW_FIGHTLIST" : "Show the fights",
    "SAVE" : "Save",
    "YES" : "Yes",
    "NO" : "No",
    "ADD" : "Add",
    "CONTACT" : "Contact",
    "SEND_MAIL" : "Send an email",
    "SEND_MESSAGE" : "Send a message",
    "SEE_FIGHT" : "View the fights",
    "REPLACE_FIGHTER" : "Replace",
    "CANCEL" : "Cancel",
    "SEND" : "Send",
    "sending" : "Sending ...",
    "sent" : "Sent",
    "MAIL_MANAGER": "Send an e-mail to the manager",
    "MAIL_MANAGER_AND_FIGHTER": "Send an e-mail to the manager and the fighter",
    "MESSAGE_MANAGER": "Send a message to the manager",
    "MESSAGE_FIGHTER": "Send a message to the fighter",
    "CONFIRM_CHANGING" : "Validate the change",
    "PUBLISH" : "Publish",
    "UNPUBLISH" : "Unpublish",
    "ADD_YOUR_LOGO":"Add your logo",
    "FIGHT_FOLDER" : "See the combat file",
    "SHOW_LICENCE" : "View license",
    "REFUSE" : "Refuse",
    "CREATE_FIGHTER" : "Create a fighter",
    "SEND_MESSAGE_ALL_FIGHTERS" : "Grouped message",
    "ADD_ATTACHEMENT" : "Add an attachment",
    "cancelSubscription" : "Cancel my subscription",
    "unsubscribing":"Cancellation in progress ...",
    "activate" : "Activate",
    "activated" : "Activated",
    "activation" : "Activation",
    "unsubscribe" : "Unsubscribe",
    "deleteAccount" : "Delete account",
    "go_subscribe" : "Subscribe now !",
    "FILTER" : "Filter",
    "picture" : "Picture",
    "start" : "Start",
    "next":"Next",
    "prev" : "Previous",
    "write" : "Write",
    "MORE_FILTERS" : "+ criterias",
    "ADD_TO_THIS_FIGHT" : "Add to this fight",
    "viewSubscription" : "View subscriptions",
    "createFight" : "Create a fight offer",
    "selectFighter" :"Select a fighter",
    "createFightcard" : "Create a fight card",
    "updateOffer" : "Update offer"
  },
  "timeAgo": {
    "now" : "Now",
    "day" : "d",
    "minute" : "min",
    "hour" : "h",
    "yesterday" : "Yesterday",
    "tomorrow" : "Tomorrow",
  },
  "dates" : {
    "monthShortNames" : "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec",
    "jan" : "January",
    "feb" : "February.",
    "march" : "March",
    "apr" : "April",
    "may" : "May",
    "jun" : "June",
    "jully" : "July",
    "aug" : "August",
    "sept" : "September",
    "oct" : "October",
    "nov" : "November",
    "dec" : "December"
  },
  "paypalTranscationState" : {
    "authorisation" : "AUTHORIZATION IN PROGRESS...",
    "canceled" : "ABANDONED",
    "approved" : "VALIDATED TRANSACTION",
    "completed" : "REGISTRATION OF THE TRANSACTION"
  }
}
