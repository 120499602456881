import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class FighterService {
  private fighterprofileUrl = this.globals.baseUrl + 'taappfighterprofile';  // URL to web api
  constructor(private httpClient: HttpClient, private globals: Globals) { }
  public filter = {
			id_organisation : null
		  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

public getList(searchParams):Observable<any>{
    const body = JSON.stringify(searchParams);
    return this.httpClient.post(this.globals.baseUrl+"taappfighterprofile/gets",body);
  }
  public getProfile(id):Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+"taappfighterprofile/"+id);
  }
  /** POST: add a new FighterProfile to the server */
  addFighterProfile(credentials): Observable<any> {
    return this.httpClient.post(this.fighterprofileUrl+"/create", credentials, this.httpOptions);
  }

}
