<app-navbar></app-navbar>

<!--Grid row-->
<div class="container mt-5">
  <mdb-card style="margin: 0 auto;">
    <mdb-card-header class="info-color white-text py-4">
      <div class="row">
        <div class="col-1">
          <a routerLink="/fight-list/{{fightCardId}}" ><mdb-icon fas icon="arrow-left"></mdb-icon></a>
        </div>
        <div class="col text-center">
          <h5>
            <strong>{{'PAGE.TITLE_CREATE_FIGHT'|translate}}</strong>
          </h5>
        </div>
      </div>
  </mdb-card-header>
  <mdb-card-body class="px-lg-5 pt-0">
		<!-- form -->

<form [formGroup]="fightForm" class="text-left" (ngSubmit)="submit()">
<div class="row" *ngIf="displayMainInformations">
  <div class="col">
    <h3>
      <strong>{{'FORM.FIGHT_INFORMATIONS'|translate}}</strong>
    </h3>
    <h6 class="text-danger mb-4">{{'TEXT.completeAsteriskFields'|translate}}</h6>
    <!--IS ROOKIE
      <div class="switch success-switch">
          <label>
          <span>{{'FORM.IS_PRO'|translate}}</span>
          <input type="checkbox" formControlName="is_rookie" (change)="switchNbMinWinField()">
            <span class="lever"></span><span>{{'FORM.IS_ROOKIE'|translate}}</span>
          </label>
      </div>
    END IS ROOKIE-->
    <div class="form-row">
      <div class="col">
        <!--SEX LIST-->
        <div class="md-form md-outline mt-3">
          <mdb-select-2 [outline]="true" placeholder="{{'FORM.FIGHT_LEVEL'|translate}}" formControlName="is_rookie" label="{{'FORM.FIGHT_LEVEL'|translate}}*" allowClear="true" (selected)="setRequiredFieldForAmateur($event)">
            <mdb-select-option value="false">{{ 'FORM.IS_PRO' | translate }}</mdb-select-option>
            <mdb-select-option value="true">{{ 'FORM.IS_ROOKIE' | translate }}</mdb-select-option>
          </mdb-select-2>
        </div>
        <!--END SEX LIST-->
      </div>
      <div class="col">
        <!--SEX LIST-->
        <div class="md-form md-outline mt-3">
          <mdb-select-2 [outline]="true" placeholder="{{'FORM.CHOOSE_SEX' | translate}}" formControlName="id_sex" label="{{'FORM.CHOOSE_SEX' | translate}}*" (selected)="filterWeightCategories($event)" allowClear="true">
            <mdb-select-option value="1">{{ 'FORM.LABEL_SEX_MALE' | translate }}</mdb-select-option>
            <mdb-select-option value="2">{{ 'FORM.LABEL_SEX_FEMALE' | translate }}</mdb-select-option>
          </mdb-select-2>
            <ng-container *ngFor="let validation of validation_messages.sex">
              <mdb-error *ngIf="fightForm.get('id_sex').hasError(validation.type) && fightForm.get('id_sex').touched">{{validation.message}}</mdb-error>
            </ng-container>
        </div>
        <!--END SEX LIST-->
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="md-form mt-3">
            <!--WEIGHT CATEGORY LIST-->
                  <div class="md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="{{'FORM.CHOOSE_WEIGHT' | translate}}" label="{{'FORM.CHOOSE_WEIGHT' | translate}}*" formControlName="id_weight_category" allowClear="true" (click)="controlSexSelection()" [disabled]="!fightForm.get('id_sex').value">
                        <mdb-select-option *ngFor="let weight of filterWeightCategoriesList" [value]="weight.id_weight_category">{{ weight.label_weight_category }}</mdb-select-option>
                      </mdb-select-2>
                  </div>
                  <!--<ng-container *ngFor="let validation of validation_messages.weight_category">
                    <mdb-error *ngIf="fightForm.get('id_weight_category').hasError(validation.type) && fightForm.get('id_weight_category').touched">{{validation.message}}</mdb-error>
                  </ng-container>-->
            </div>
          </div>
        <div class="col">
        <!---POIDS LIBRE CATCHWEIGHT-->
        <div class="switch success-switch">
            <label>
            <u><strong>{{"TEXT.OR" |translate}}</strong></u> {{'FORM.CATCHWEIGHT'|translate}}
            <input type="checkbox" formControlName="is_catchweight" (change)="onSwitch()">
            <span class="lever"></span>
            </label>
        </div>
        </div>
      </div>
      <div class="form-row" *ngIf="showCatchWeightField">
        <div class="col">
          <!-- First name -->
          <div class="md-form md-outline">
            <input type="number" id="catchweightLbs" class="form-control" formControlName="catchweight_lbs" mdbInput (change)="convertWeightTo('kg')" [required]="showCatchWeightField">
            <label for="catchweightLbs">Lbs</label>
          </div>
        </div>
        <div class="col">
          <!-- Last name -->
          <div class="md-form md-outline">
            <input type="number" id="catchweightKg" class="form-control" formControlName="catchweight_kg" (change)="convertWeightTo('lbs')" mdbInput [required]="showCatchWeightField">
            <label for="catchweightKg">Kg</label>
          </div>
        </div>
      </div>
      <div class="form-row" *ngIf="fightForm.get('is_rookie').value == 'true'">
        <div class="col">
          <!-- First name -->
          <div class="md-form md-outline">
            <input type="number" id="min_age" class="form-control" formControlName="min_age" mdbInput mdbValidate>
            <label for="min_age">{{'FORM.minAge'|translate}}</label>
            <mdb-error *ngIf="fightForm.get('min_age').hasError && fightForm.get('min_age').touched && (fightForm.get('min_age').value < 12)">{{'formControl.minAge'|translate}}</mdb-error>
          </div>
        </div>
        <div class="col">
          <!-- Last name -->
          <div class="md-form md-outline">
            <input type="number" id="max_age" class="form-control" formControlName="max_age" mdbInput mdbValidate>
            <label for="max_age">{{'FORM.maxAge'|translate}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <!--Partie round-->
        <div class="col">
          <!-- nbRound -->
          <div class="md-form md-outline">
            <input type="number" min="1" id="nb_round" class="form-control" formControlName="nb_round" mdbInput [required]>
            <label for="nb_round">{{"FORM.nbRound" | translate}}*</label>
            <ng-container *ngFor="let validation of validation_messages.nb_round">
              <mdb-error *ngIf="fightForm.get('nb_round').hasError(validation.type) && fightForm.get('nb_round').touched">{{validation.message}}</mdb-error>
            </ng-container>
          </div>
        </div>
        <div class="col">
        <!--<div class="col-lg-3">-->
          <!-- time round -->
          <div class="md-form md-outline">
            <input type="number" min="0" max="59" id="time_round_m" class="form-control" formControlName="time_round_m" mdbInput [required]>
            <label for="time_round_m" style="width: auto;">{{"text.timeRoundMinute" | translate}}</label>
            <ng-container *ngFor="let validation of validation_messages.time_round">
              <mdb-error *ngIf="fightForm.get('time_round_m').hasError(validation.type) && fightForm.get('time_round_m').touched">{{validation.message}}</mdb-error>
            </ng-container>
          </div>
        </div>
        <!--<div class="col-lg-3">
          <\!-- time round -\->
          <div class="md-form md-outline">
            <input type="number" min="0" max="59" id="time_round_s" class="form-control" formControlName="time_round_s" mdbInput [required]>
            <label for="time_round_s" style="width: auto;">{{"FORM.timeRoundSeconde" | translate}}</label>
            <ng-container *ngFor="let validation of validation_messages.time_round">
              <mdb-error *ngIf="fightForm.get('time_round_s').hasError(validation.type) && fightForm.get('time_round_s').touched">{{validation.message}}</mdb-error>
            </ng-container>
          </div>
        </div>-->
      </div>
    <!--END WPOIDS LIBRE CATCHWEIGHT-->
    <!--FIGHT TYPE LIST
    <div class="md-form mt-3">
              <mdb-select-2 placeholder="{{'FORM.TYPE_FIGHT' | translate}}" label="{{'FORM.TYPE_FIGHT' | translate}}*" formControlName="id_type_fight" allowClear="true">
                <mdb-select-option *ngFor="let fightType of fightTypeList" [value]="fightType.id_type_fight">{{ fightType.label_type_fight }}</mdb-select-option>
              </mdb-select-2>
          <ng-container *ngFor="let validation of validation_messages.type_fight">
            <mdb-error *ngIf="fightForm.get('id_type_fight').hasError(validation.type) && fightForm.get('id_type_fight').touched">{{validation.message}}</mdb-error>
          </ng-container>
      </div>
      <!--END FIGHT TYPE LIST-->

    <!--IS PUBLIC
    <div class="md-form mt-3">
              <mdb-checkbox mdbValidate formControlName="is_public">{{'FORM.HIDE_IN_APP'|translate}}</mdb-checkbox
              >
        </div>
    <!--END IS PUBLIC-->
    <!--IS RESERVE annulé par Antho => il dit que ça sert à rien
    <div class="md-form mt-3">
              <mdb-checkbox mdbValidate formControlName="is_reserve">{{'FORM.IS_RESERVE'|translate}}</mdb-checkbox
              >
        </div>
    <!--END IS RESERVE-->

    <!--Expérience amateur-->
    <div class="form-row" *ngIf="fightForm.get('is_rookie').value == 'true'">
      <div class="col">
        <div class="md-form mt-3">
          <!--WEIGHT CATEGORY LIST-->
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" placeholder="{{'FORM.EXP_ROOKIE' | translate}}" label="{{'FORM.EXP_ROOKIE' | translate}}" formControlName="id_exp_amateur" allowClear="true">
              <mdb-select-option *ngFor="let exp of exp_rookie_list" [value]="exp.id_exp_amateur">{{ exp.label_exp_amateur }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="fightForm.get('is_rookie').value == 'false'">
    <!--Nombre minimum de combats pour participer-->
    <div class="col">
      <div class="md-form md-outline">
        <input
          type="number"
          formControlName="nb_min_fight"
          id="nb_min_fight"
          class="form-control md-textarea"
          mdbInput
        />
        <label for="nb_min_fight">{{
          "FORM.nbMinFight" | translate
        }}</label>
      </div>
    </div>
    <!--END minimum de victoire pour participer-->
    <!--Nombre maxi de combats pour participer-->
    <div class="col">
      <div class="md-form md-outline">
        <input
          type="number"
          formControlName="nb_max_fight"
          id="nb_max_fight"
          class="form-control md-textarea"
          mdbInput
        />
        <label for="nb_max_fight">{{
          "FORM.nbMaxFight" | translate
        }}</label>
      </div>
    </div>
    <!--END minimum de victoire pour participer-->
    </div>
    <!--DESCRIPTION-->
    <div class="md-form md-outline">
      <textarea
        type="text"
        formControlName="description"
        placeholder=""
        id="description"
        class="form-control md-textarea"
        mdbInput
        mdbValidate></textarea>
      <label for="description">{{'FORM.DESCRIPTION'|translate}}</label>
    </div>
  </div> <!--END COL-->
  </div> <!--END ROW-->
  <div class="row" *ngIf="displayOptionalInformations">
    <div class="col">
    <!--END DESCRIPTION-->
  <h3><strong>{{'FORM.OPTIONAL'|translate}}</strong></h3>

  <!--Début accordéon-->
  <mdb-accordion [multiple]="false">
  <!--<mdb-accordion-item [collapsed]="true">
  <mdb-accordion-item-head [customClass]="'black-text'"><h3>{{'FORM.SELECT_FIGHTERS'|translate}}</h3></mdb-accordion-item-head>
  <mdb-accordion-item-body>
    <h5 class="text-danger">{{'TEXT.ADD_FIGHTER'|translate}}</h5>
    <div class="">
      <h4 style='margin-top:25px;'>{{'FORM.FIGHTER'|translate}} 1</h4>
      <div class="row">
        <div class="col-11">
          <app-fighter-short-description *ngIf="selectedFighter1" [fighter]="selectedFighter1"></app-fighter-short-description>
        </div>
        <div class="col-1">
          <mdb-icon far icon="times-circle" *ngIf="selectedFighter1" class="red-text" (click)="fighterToRemove=1;modalConfirmRemoveFighter.show()"></mdb-icon>
        </div>
      </div>
      <button *ngIf="!selectedFighter1" type="button" (click)="selectFighter(1)" class="btn btn-block btn-primary"><mdb-icon fas icon="plus"></mdb-icon>&nbsp;{{'BUTTON.ADD_FIGHTER_TO_FIGHT'|translate}}</button>
    </div>
    <div class="mt-3">
      <h4>{{'FORM.FIGHTER'|translate}} 2</h4>
      <div class="row">
        <div class="col-11">
          <app-fighter-short-description *ngIf="selectedFighter2" [fighter]="selectedFighter2"></app-fighter-short-description>
        </div>
        <div class="col-1">
          <mdb-icon far icon="times-circle" *ngIf="selectedFighter2" class="red-text" (click)="fighterToRemove=2;modalConfirmRemoveFighter.show()"></mdb-icon>
        </div>
      </div>
      <button *ngIf="!selectedFighter2" type="button" (click)="selectFighter(2)" class="btn btn-block btn-primary"><mdb-icon fas icon="plus"></mdb-icon>&nbsp;{{'BUTTON.ADD_FIGHTER_TO_FIGHT'|translate}}</button>
    </div>
  </mdb-accordion-item-body>
  </mdb-accordion-item>-->

  <!--Infos médicales-->
  <mdb-accordion-item [collapsed]="true">
  <mdb-accordion-item-head [customClass]="'black-text'"><h3>{{'FORM.MEDICAL_INFORMATIONS_TITLE'|translate}}</h3></mdb-accordion-item-head>
  <mdb-accordion-item-body>

    <p>{{'TEXT.MEDICAL_INFORMATIONS'|translate}}</p>
    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="certif_medical">
        <span class="lever"></span>{{'TEXT.certificat'|translate}}
      </label>
    </div>

    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="blood_test">
        <span class="lever"></span>{{'FORM.BLOOD_TEST'|translate}}
      </label>
    </div>

    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="brain_test">
        <span class="lever"></span>{{'FORM.BRAIN_MRI'|translate}}
      </label>
    </div>

    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="stress_test">
        <span class="lever"></span>{{'FORM.STRESS_TEST'|translate}}
      </label>
    </div>

    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="eye_test">
        <span class="lever"></span>{{'FORM.EYE_TEST'|translate}}
      </label>
    </div>
    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="angio_mri_cerebral_test">
        <span class="lever"></span>{{'FORM.ANGIO_IRM'|translate}}
      </label>
    </div>
    <div class="md-form pb-3 switch success-switch">
      <label class="pb-2">
        <input type="checkbox" formControlName="ecg_test">
        <span class="lever"></span>{{'FORM.ECG_TEST'|translate}}
      </label>
    </div>
  </mdb-accordion-item-body>
  </mdb-accordion-item>

  <!--Licence-->
  <mdb-accordion-item [collapsed]="true">
  <mdb-accordion-item-head [customClass]="'black-text'"><h3>{{'FORM.LICENCE'|translate}}</h3></mdb-accordion-item-head>
  <mdb-accordion-item-body>
    <div style="line-height: normal;"> <!--évite que le text soit coupé par le line-height de accordion-->

      <p>{{'TEXT.MEDICAL_INFORMATIONS'|translate}}</p>

      <!-- Bool license -->
      <div class="md-form pb-3 switch success-switch">
        <label class="pb-2">
          <input type="checkbox" formControlName="license" (change)="switchLicense()">
          <span class="lever"></span>{{'FORM.LICENCE'|translate}}
        </label>
      </div>

      <!-- License details -->
      <div class="md-form md-outline">
        <textarea
          type="text"
          formControlName="license_informations"
          id="license_informations"
          class="form-control md-textarea"
          mdbInput
          mdbValidate
          disabled></textarea>
        <label for="license_informations">{{'FORM.LICENCE_DETAILS'|translate}}</label>
      </div>

    </div>
  </mdb-accordion-item-body>
  </mdb-accordion-item>

  <!--Contre partie-->
  <mdb-accordion-item [collapsed]="true">
  <mdb-accordion-item-head [customClass]="'black-text'"><h3>{{'FORM.COUNTERPART_TITLE'|translate}}</h3></mdb-accordion-item-head>
  <mdb-accordion-item-body>
    <div style="line-height: normal;"> <!--évite que le text soit coupé par le line-height de accordion-->

      <p>{{'TEXT.COUNTERPART'|translate}}</p>
      <!--<div class="md-form md-outline">
        <label for="premium">{{'FORM.PREMIUM'|translate}}</label>
        <input type="number" id="premium" class="form-control" formControlName="premium" (change)='roundInput("premium", 2, false, false)' mdbInput>
      </div>-->

      <!--CHAMP PRIME DE COMBAT-->
      <div class="form-row">
        <div class="col">
          <div class="md-form md-outline mt-3">
            <input type="number" class="form-control" placeholder="" value="0" id="inputPriceMin" formControlName="min_cash_prize" (change)='roundInput("min_cash_prize", 2, false, false)' mdbInput>
            <label for="inputPriceMin">{{'FORM.MIN_REWARD' | translate}}</label>
          </div>
        </div>

        <div class="col">
          <div class="md-form md-outline mt-3">
            <input type="number" class="form-control" placeholder="" value="0" id="inputPriceMax" formControlName="max_cash_prize" (change)='roundInput("max_cash_prize", 2, false, false)' mdbInput>
            <label for="inputPriceMax">{{'FORM.MAX_REWARD' | translate}}</label>
          </div>
        </div>
      </div>

      <div class="md-form pb-3 switch success-switch">
        <label class="pb-2">
          <input type="checkbox" formControlName="hotel">
          <span class="lever"></span>{{'FORM.HOTEL'|translate}}
        </label>
      </div>

      <div class="md-form pb-3 switch success-switch">
        <label class="pb-2">
          <input type="checkbox" formControlName="transport">
          <span class="lever"></span>{{'FORM.TRANSPORT'|translate}}
        </label>
      </div>

      <div class="md-form md-outline mt-5">
        <mdb-select-2 [outline]="true" placeholder="{{'FORM.MEAL' | translate}}" formControlName="meal" label="{{'FORM.MEAL' | translate}}" allowClear="true">
          <mdb-select-option *ngFor="let meal of mealList" [value]="meal.id_meal">{{ meal.label_meal }}</mdb-select-option>
        </mdb-select-2>
      </div>

      <div class="md-form md-outline">
        <input type="number" id="accompanying" placeholder="" class="form-control" formControlName="accompanying" (change)='roundInput("accompanying", 0, false, false)' mdbInput>
        <label for="accompanying">{{'FORM.ACCOMPANYING_NUMBER'|translate}}</label>
      </div>

    </div>
  </mdb-accordion-item-body>
  </mdb-accordion-item>
  </mdb-accordion>
  <!--Fin accordéon-->
</div> <!--END COL-->
</div> <!--END ROW-->
<button
 mdbBtn
 color="info"
 outline="true"
 rounded="true"
 block="true"
 class="z-depth-0 my-4"
 type="submit"
 [disabled]="!fightForm.valid || isSaving"><i *ngIf="isSaving" class="fas fa-circle-notch fa-spin"></i>{{'BUTTON.SAVE'|translate}}</button>
    </form>
    </mdb-card-body>
  </mdb-card>
</div>

<!--Modal: demande ajout info complémentaires-->
<div mdbModal #modalSuccess="mdbModal" class="modal fade left" id="modalSuccess" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <p class="heading lead">{{'SUCCESS.FIGHT_ADDED'|translate}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalSuccess.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>{{'TEXT.ADD_OTHER_INFORMATIONS'|translate}}</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button mdbBtn color="success" outline="true" mdbWavesEffect (click)="goBack()">{{'BUTTON.CLOSE'|translate}}</button>
        <button mdbBtn color="success" mdbWavesEffect (click)="showMoreInformationsForm();">{{'BUTTON.ADD'|translate}}</button>

      </div>
    </div>
  </div>
</div>
<!--Modal: ajout combat-->
<div mdbModal #modalNextStep="mdbModal" class="modal fade left" id="modalNextStep" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <p class="heading lead">{{'SUCCESS.FIGHT_UPDATED'|translate}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalNextStep.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>{{'TEXT.ADD_OTHER_FIGHT'|translate}}</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button mdbBtn color="success" outline="true" mdbWavesEffect (click)="goBack()">{{'BUTTON.CLOSE'|translate}}</button>
        <button mdbBtn color="success" mdbWavesEffect (click)="resetForm()">{{'BUTTON.ADD'|translate}}</button>

      </div>
    </div>
  </div>
</div>

<!--Modal: suppression combattant-->
<div mdbModal #modalConfirmRemoveFighter="mdbModal" class="modal fade left" id="modalConfirmRemoveFighter" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <p class="heading lead">{{'TEXT.DELETE_FIGHTER_TITLE'|translate}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalConfirmRemoveFighter.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <p>{{'TEXT.DELETE_FIGHTER_TEXT'|translate}}</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button mdbBtn color="danger" outline="true" mdbWavesEffect (click)="modalConfirmRemoveFighter.hide()">{{'BUTTON.NO'|translate}}</button>
        <button mdbBtn color="danger" mdbWavesEffect (click)="removeFighter()">{{'BUTTON.DELETE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
