import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '../../../translate';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  email:string;
  // organisation:any;
  constructor(private loginService: AuthService) { }

  ngOnInit(): void {
	//this.user = JSON.parse(localStorage.getItem('user'));
	//this.organisation = JSON.parse(localStorage.getItem('organisation'));
	/*let token = localStorage.getItem('token');
	token = token +"450";
	localStorage.setItem('token',token);*/
	//test promesse
	/*if(json != null){
		this.email = json.email;
	}*/

	this.loginService.user().subscribe(jsonResponse => {
		if(jsonResponse.success){
      this.email = jsonResponse.rows[0].email;
    }
		//localStorage.setItem('email',jsonResponse.email);
	    }, error => {
			console.log(error);
		});
  }

}
