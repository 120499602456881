<!--On met une div pour prendre toute la hauteur de la page-->
    <!--Header-->
    <app-navbar></app-navbar>
    <!--On met une div pour prendre toute la hauteur de la page-->
    <div style="height: 100vh;" [class] ="modalIsOpen ? 'background-modal' : null ">
    <!-- SideNav slide-out button -->
<a (click)="sidenav.toggle()" mdbBtn class="p-3 button-collapse" style="height:100vh;float:left;background:#2E2E2E"><mdb-icon fas icon="chevron-right" style="color:#FFF;"></mdb-icon></a>
<!--/. SideNav slide-out button -->

<!-- Sidebar navigation -->
<mdb-side-nav #sidenav class="fixed size" [fixed]="true">
  <!--Liste des discussions-->
  <div class="d-flex flex-column overflow-auto">
    <h3 class="text-center mt-2">{{ "TEXT.DISCUSSION" | translate }}</h3>
    <!--<ul class="list-unstyled friend-list">-->
        <!--Trouver une personne-->
        <div class="d-flex justify-content-center">
            <input type="text" [formControl]="findSomeone" style="width: 95%;" class="form-control my-2" [placeholder]="translateService.instant('FORM','SEARCH')" (input)="findController()">
        </div>

        <h3 *ngIf="foundManagers !== null && foundManagers.length > 0" class="pl-2">{{"TEXT.MANAGERS" | translate}}</h3>
        <div *ngFor='let user of displayedManagers' class="row pt-2 pb-2" (click)="newConversation(user);sidenav.toggle()" style="border : 1px solid #3E4551;">
            <div class="col-3" style="padding-right : 0px;">
                <img *ngIf="user.profile_pic" src="{{globals.publicPathImage}}/pictures/{{user.profile_pic}}" alt="avatar" class="rounded-circle img-fluid" style="max-width:80%">
                <svg *ngIf="!user.profile_pic" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="59" height="59" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5z" fill="currentColor"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm7.992 22.926A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="currentColor"/></svg>
            </div>
            <div class="col-6 text-small" style="padding-left : 0px;">
              <strong>{{ user.firstname + ' ' + user.name }}</strong>
            </div>
        </div>

        <!--Afficher plus-->
        <div *ngIf='displayedManagers.length < foundManagers.length' class="p-2 d-flex flex-column mb-2" (click)="displayedManagers = foundManagers">
            <button type="button" mdbBtn color="light"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon>{{"TEXT.SHOW_MORE" | translate}}</button>
        </div>

        <hr *ngIf="foundManagers !== null && foundManagers.length > 0" class="mt-1">

        <h3 class="mt-3" *ngIf="foundFighters !== null && foundFighters.length > 0" class="pl-2">{{"TEXT.FIGHTERS" | translate}}</h3>
        <div *ngFor='let user of displayedFighters' class="row pt-2 pb-2"  (click)="newConversation(user);sidenav.toggle()">
          <div class="col-3" style="padding-right : 0px;">
              <img *ngIf="user.profile_pic" src="{{globals.publicPathImage}}/pictures/{{user.profile_pic}}" alt="avatar" class="rounded-circle img-fluid" style="max-width:80%">
              <svg *ngIf="!user.profile_pic" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="59" height="59" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5z" fill="currentColor"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm7.992 22.926A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="currentColor"/></svg>
          </div>
          <div class="col-6 text-small" style="padding-left : 0px;">
            <strong>{{ user.firstname + ' ' + user.name }}</strong>
          </div>
        </div>


        <!--Afficher plus-->
        <div *ngIf='displayedFighters.length < foundFighters.length' class="p-2 d-flex flex-column mb-2" (click)="displayedFighters = foundFighters">
            <button type="button" mdbBtn color="light"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon>{{"TEXT.SHOW_MORE" | translate}}</button>
        </div>


        <hr *ngIf="foundFighters !== null && foundFighters.length > 0" class="mb-1">

        <!--Liste des conversations-->
        <h3 class="mt-3" class="pl-2">{{"TEXT.YOUR_CONVERSATIONS" | translate}}</h3>

        <!--Pendant le chargement des contacts-->
        <div class="d-flex justify-content-center" *ngIf='isLoadingRooms'>
            <div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    <!--</ul>-->
    <div class="container">
      <div *ngFor='let room of chatRoomList' class="row pt-2 pb-2" (click)='room.stored_message = room.message; selectedRoom = room;sidenav.toggle();' style="border-bottom : 1px solid #3E4551;">
        <div class="col-3" style="padding-right : 0px;">
            <img *ngIf="room.receiver_profile_pic" [src]="room.receiver_profile_pic" alt="avatar" class="rounded-circle img-fluid" style="max-width:80%">
            <svg *ngIf="!room.receiver_profile_pic" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="59" height="59" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5z" fill="currentColor"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm7.992 22.926A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="currentColor"/></svg>
        </div>
        <div class="col-6 text-small" style="padding-left : 0px;">
          <div *ngIf="room.user_id_creator != currentUser">
            <strong *ngIf="!room.creator_fighter_name && !room.creator_manager_name">{{room.creator_orga_label}}</strong>
            <strong *ngIf="room.creator_fighter_name">{{room.creator_fighter_name}} {{room.creator_fighter_firstname}}</strong>
            <strong *ngIf="room.creator_manager_name">{{room.creator_manager_name}} {{room.creator_manager_firstname}}</strong>
          </div>
          <div *ngIf="room.user_id_receiver != currentUser">
            <strong *ngIf="!room.receiver_fighter_name && !room.receiver_manager_name">{{room.receiver_orga_label}}</strong>
            <strong *ngIf="room.receiver_fighter_name">{{room.receiver_fighter_name}} {{room.receiver_fighter_firstname}}</strong>
            <strong *ngIf="room.receiver_manager_name">{{room.receiver_manager_name}} {{room.receiver_manager_firstname}}</strong>
          </div>
          <p class="last-message text-muted">{{ room.message}}</p>
        </div>
        <div class="col-3 chat-footer">
          <small class="text-muted">{{ room.message_datetime | timeAgo }}</small>
        </div>
      </div>
    </div><!--end container-->
  </div>
  <!--/. Side navigation links -->
  <div class="sidenav-bg mask-strong"></div>
</mdb-side-nav>
<!--/. Sidebar navigation -->

  <!--Partie chat-->
  <app-chat *ngIf='selectedRoom' [room]="selectedRoom" (actualize)="actualizeDiscussions()" style="width: 84%; background-color: #eeeeee;"></app-chat>

  <div *ngIf='!selectedRoom' class="d-flex flex-column justify-content-center" style="background-color: #eeeeee;">
      <h4 class="text-center mb-0">{{"TEXT.START_CONVERSATION" | translate}}</h4>
  </div>
</div>
<!--Modal de nouveau message-->
<div *ngIf="modalIsOpen" class="modal-zone">
    <div class="modal-content">
        <h3>{{ "TEXT.SEND_MESSAGE_TO" | translate }} <strong>{{ newReceiver.firstname + ' ' + newReceiver.name }}</strong></h3>
        <p>{{ "FORM.YOUR_MESSAGE" | translate }} : </p>
        <textarea [formControl]="newMessage" style="resize: none;"></textarea>
        <div class="d-flex justify-content-around mt-2">
            <button mdbBtn type="button" class="btn-black-outline" outline="true" mdbWavesEffect (click)="closeModal()">{{ "BUTTON.CANCEL" | translate }}</button>
            <button mdbBtn type="button" class="btn-mma-blue" outline="true" mdbWavesEffect (click)="sendNewMessage()">{{ "BUTTON.SEND" | translate }}</button>
        </div>
    </div>
</div>
