import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class FightCardProvider {

  constructor() { }

  private dataSource = new Subject<any>();
  private datasListSource = new Subject<any>();
      serviceData = this.dataSource.asObservable();
      serviceListDatas = this.datasListSource.asObservable();
      changeData(data: any) {
        this.dataSource.next(data);
      }

      changeListeDatas(datas:any){
        this.datasListSource.next(datas);
      }

}
