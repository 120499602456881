<app-navbar *ngIf='isAuthenticated'></app-navbar>

<div class="container" *ngIf='isAuthenticated'>

<!--Section: Live preview-->
<section class="form-light mt-4">

  <!--Form without header-->
  <!--<mdb-card>-->
  <mdb-card style="background : #FFFFFF;">

    <mdb-card-body class="mx-4">

      <!--Header-->
      <div class="text-center">
        <img src="../../../../assets/img/logo_black.png" style="height: 4rem;">
        <h3 class="mb-5 mt-3 text-center">
          <strong *ngIf='!isAuthenticated'>{{ 'FORM.TITLE_SIGN_UP' | translate }}</strong>
          <strong *ngIf='isAuthenticated'>{{ 'FORM.TITLE_EDIT' | translate }}</strong>
        </h3>
      </div>

      <!--Body-->

      <!--Chargement-->
      <div class="d-flex justify-content-center" *ngIf='isLoading'>
        <div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <form *ngIf='!isLoading' [formGroup]="lightForm">
        <div class="alert alert-danger" role="alert" *ngIf="erreurMessage != '' ">Erreur : {{erreurMessage}}</div>

        <!-- Photo de profile actuelle -->
        <div *ngIf="profileImgURL" class="row mb-3">
          <div class="col-12">
          <img [src]="profileImgURL" alt="thumbnail" class="img-thumbnail image" style="max-width: 250px; max-height: 250px; width: auto; height: auto;">
          <button mdbBtn color="danger" style="max-height: 3rem;" mdbWavesEffect (click)="onDeleteActualProfileImage()">{{ 'FORM.DELETE_PICTURE' | translate }}</button>
          </div>
        </div>

          <!--Ajouter photo de profil-->
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="custom-file">
                  <!--<input type="file" class="custom-file-input" id="inputGroupFile01" (change)="onFileAdd($event)" aria-describedby="inputGroupFileAddon01">
                  <label class="custom-file-label black-file" for="inputGroupFile01">{{labelsChoosePicture}}</label>-->
                  <button type="button" mdbBtn (click)="compressFile()" color="primary" block="true">{{ 'FORM.SELECT_IMAGE' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="containerImage w-50 pl-2 pt-3">
                <img [src]="imgURL" alt="thumbnail" class="img-thumbnail image" style="max-width: 250px; max-height: 250px; width: auto; height: auto;"  *ngIf="imgURL">
                <div *ngIf="imgURL">
                  <button *ngIf="imgURL" mdbBtn color="danger" style="max-height: 3rem;" mdbWavesEffect (click)="cancelSelectedFile()">{{ 'FORM.DELETE_PICTURE' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        <div class="form-row">
          <!--Nom de l'organisation-->
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="users" class="prefix grey-text"></mdb-icon>
              <input type="text" id="label" class="form-control" formControlName="label" mdbInput mdbValidate
                [validateSuccess]="false" required>
              <label for="label">{{ 'FORM.ORGANISATION' | translate }} *</label>
              <mdb-error *ngIf="label.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
            </div>
          </div>
          <!--Date de création-->
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline" style="padding-left: 2.5rem;">
              <mdb-icon fas icon="calendar-alt" class="prefix grey-text"></mdb-icon>
              <mdb-date-picker [inline]="true" formControlName="date_creation_organization"
                name="date_creation_organization" [options]="myDatePickerOptions" placeholder="{{ 'FORM.LABEL_DATE_CREATION' | translate }}" inlineIcon="far fa-calendar-alt" mdbValidate>

              </mdb-date-picker>
              <mdb-error *ngIf="date_creation_organization.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
              <mdb-error *ngIf="!date_creation_organization.invalid || !tryValidate"></mdb-error><!--Champs vide pour laisser l'icone à bonne distance-->
            </div>
          </div>
        </div>

        <div class="form-row" *ngIf='!isAuthenticated'>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
              <input type="text" id="email" class="form-control" formControlName="email" mdbInput mdbValidate required>
              <label for="email">{{ 'FORM.EMAIL' | translate }} *</label>
              <mdb-error *ngIf="email.invalid && tryValidate">{{ 'FORM.NOT_EMPTY_EMAIL' | translate }}</mdb-error>
            </div>
          </div>
          </div>

          <!--MDP-->
          <div class="form-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div *ngIf='!isAuthenticated' class="md-form md-outline">
                <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
                <input type="password" id="password" class="form-control" formControlName="password" mdbInput mdbValidate required>
                <label for="password">{{ 'FORM.PASSWORD' | translate }} *</label>
                <mdb-error *ngIf="password.invalid && !isAuthenticated && tryValidate">{{ 'FORM.NOT_EMPTY_PASSWORD' | translate }}</mdb-error>
              </div>
            </div>
          </div>

        <div class="form-row">
            <!--Pays-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="globe-europe" class="prefix grey-text"></mdb-icon>
                <!--<mdb-select-2 style="margin-left: 2rem;" [outline]="true" formControlName="idPays" placeholder="{{'FORM.selectElement'|translate}}" name="id_living_country"
                  label="{{'FORM.CHOOSE_COUNTRY' | translate}}" [allowClear]="true" >-->
                <mdb-select-2 style="margin-left: 2rem;" [outline]="true" formControlName="idPays" placeholder="{{'FORM.selectElement'|translate}} *" name="id_living_country" [allowClear]="true">
                  <mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{country.label_country }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="idPays.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
              </div>
            </div>
            <!--Tel-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="phone-alt" class="prefix grey-text"></mdb-icon>
                <ngx-intl-tel-input
                style="margin-left:2rem;color:#757575;width:100%;"
                  enableAutoCountrySelect="true"
                  enablePlaceholder="true"
                  searchCountryFlag="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectedCountryISO]="selectedCountryISO"
                  maxLength="15"
                  separateDialCode="false"
                  phoneValidation="true"
                  inputId="phone"
                  name="phone"
                  formControlName="phone"
                ></ngx-intl-tel-input>
              </div>
            </div>
            <!--address-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="home" class="prefix grey-text"></mdb-icon>
                <input type="text" id="billing_address" placeholder="" class="form-control" formControlName="billing_address" mdbInput mdbValidate required>
                <label for="billing_address">{{ 'FORM.BILLING_ADDRESS' | translate }} *</label>
                <mdb-error *ngIf="billing_address.invalid && !isAuthenticated && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
              </div>
            </div>
            <!--Description-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
              <textarea type="text" id="description" formControlName="description" placeholder="" class="md-textarea form-control"
                mdbInput></textarea>
              <label for="description">{{ 'FORM.DESCRIPTION' | translate }}</label>
            </div>
            </div>

            </div>
            <div class="form-row">
            <!--Reseaux sociaux-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="facebook" class="prefix grey-text"></mdb-icon>
                <input type="text" id="facebook" class="form-control" placeholder="https://www.facebook.com/yourpagename" formControlName="facebook" mdbInput>
                <label for="facebook">{{ 'FORM.LINK_FACEBOOK' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="twitter" size="lg" class="prefix grey-text"></mdb-icon>
                <input type="text" id="twitter" class="form-control" placeholder="https://www.twitter.com/youraccountname" formControlName="twitter" mdbInput>
                <label for="twitter">{{ 'FORM.LINK_TWITTER' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="instagram" class="prefix grey-text"></mdb-icon>
                <input type="text" id="instagram" placeholder="https://www.instagram.com/yourinstagramname" class="form-control" formControlName="instagram" mdbInput>
                <label for="instagram">{{ 'FORM.LINK_INSTAGRAM' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="link" class="prefix grey-text"></mdb-icon>
                <input type="text" id="website" placeholder="https://mydomain.com" class="form-control" formControlName="website" mdbInput>
                <label for="website">{{ 'FORM.LINK_WEBSITE' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="video" class="prefix grey-text"></mdb-icon>
                <input type="text" id="video" class="form-control" formControlName="video" placeholder="Ex : https://www.youtube.com/yourchannelname" mdbInput>
                <label for="video">{{ 'FORM.LINK_VIDEO' | translate }}</label>
              </div>
            </div>
            </div>
            <div class="form-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form pb-3 switch success-switch">
                <label class="pb-2">
                  <input type="checkbox" formControlName="accept_newsletter">
                  <span class="lever"></span>{{'FORM.NEWSLETTER'|translate}}
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div *ngIf='!isAuthenticated' class="md-form pb-3 switch success-switch pl-2">
                <!--<mdb-checkbox mdbInput class="my-4" formControlName="accept_terms">{{ 'FORM.ACCEPT' | translate }}<a href="#"
                    class="blue-text">{{ 'FORM.TERM_AND_CONDITIONS' | translate }}</a></mdb-checkbox>-->
                    <label class="pb-2">
                      <input type="checkbox" formControlName="accept_terms">
                      <span class="lever"></span>{{'FORM.TERM_AND_CONDITIONS'|translate}}
                    </label>
              </div>
            </div>

        </div>
      </form>

      <!--Grid row-->
      <div *ngIf='!isLoading' class="row d-flex justify-content-center">
        <div class="d-flex justify-content-around flex-column flex-md-row w-100">
          <button *ngIf='!isAuthenticated' type="button" mdbBtn block="true" rounded="true" color="primary" [disabled]="isLoadingConfirmation"
            (click)="register()" mdbWavesEffect><i *ngIf="isLoadingConfirmation" class="fas fa-circle-notch "></i> {{ 'FORM.SIGN_UP' | translate }}</button>
          <button *ngIf='isAuthenticated' class="mb-3 mb-0-md mx-3" type="button" mdbBtn block="true" rounded="true" color="primary" [disabled]="!lightForm.valid || isLoadingConfirmation"
            (click)="edit()" mdbWavesEffect><i *ngIf="isLoadingConfirmation" class="fas fa-circle-notch fa-spin"></i> {{ 'FORM.EDIT' | translate }}</button>
          <button *ngIf='isAuthenticated' class="mb-3 mb-0-md mx-3" type="button" mdbBtn block="true" rounded="true" color="danger"
            (click)="modalDeleteAccount.show()" mdbWavesEffect>{{ 'BUTTON.deleteAccount' | translate }}</button>
        </div>
      </div>
      <div *ngIf='!isLoading && !isAuthenticated' class="row d-flex justify-content-center mt-2">
        <p class="font-small grey-text d-flex justify-content-end">{{ 'FORM.HAVE_ACCOUNT' | translate }}
          <a href="/login" class="blue-text ml-1">{{ 'FORM.LOGIN' | translate }}</a>
        </p>
      </div>
      <!--Grid row-->
    </mdb-card-body>
  </mdb-card>
  <!--/Form without header-->

</section>
<!--Section: Live preview-->
</div>
<!--container-->


<!--REGISTER FORM-->
<div class="background-page" *ngIf="!isAuthenticated">
<div class="container-fluid h-100">
<!--  <div class="row">
<!--Section: logo + texte
<div class="form-light pt-4 ml-auto col-sm-12 col-md-12 col-lg-5 col-xl-5">
  <div class="text-center">
    <img src="../../../../assets/img/logo_mma_orga.png" width="60%" class="mb-5">
  </div>
</div>
</div>-->
<div class="row justify-content-center align-items-center h-100 pt-4">
  <!--login form : INFO ajouter ml-auto dans la class pour aligner à droite-->
<div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
  <mdb-card>
    <mdb-card-body class="mx-4">
      <div class="text-center">
        <img src="../../../../assets/img/logo_mma_orga.png" style="height: 5rem;" class="mb-5">
      </div>
      <!--Header-->
      <div class="text-center">
        <h4 class="grey-text mb-5">
          {{'TEXT.WELCOME'|translate}}
        </h4>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="erreurMessage != '' ">{{erreurMessage}}</div>
      <!--Body-->
      <form *ngIf='!isLoading' [formGroup]="lightForm" class="formulaireInscription">
        <!--<div class="alert alert-danger" role="alert" *ngIf="erreurMessage != '' ">Erreur : {{erreurMessage}}</div>-->

        <!-- Photo de profile actuelle -->
        <div *ngIf="profileImgURL" class="row mb-3">
          <div class="col-12">
          <img [src]="profileImgURL" alt="thumbnail" class="img-thumbnail image" style="max-width: 250px; max-height: 250px; width: auto; height: auto;">
          <button mdbBtn color="danger" style="max-height: 3rem;" mdbWavesEffect (click)="onDeleteActualProfileImage()">{{ 'FORM.DELETE_PICTURE' | translate }}</button>
          </div>
        </div>

          <!--Ajouter photo de profil-->
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="custom-file">
                  <!--<input type="file" class="custom-file-input" id="inputGroupFile01" (change)="onFileAdd($event)" aria-describedby="inputGroupFileAddon01">
                  <label class="custom-file-label black-file" for="inputGroupFile01">{{labelsChoosePicture}}</label>-->
                  <button type="button" mdbBtn (click)="compressFile()" color="primary">{{ 'BUTTON.ADD_YOUR_LOGO' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="containerImage w-50 pl-2 pt-3">
                <img [src]="imgURL" alt="thumbnail" class="img-thumbnail image" style="max-width: 250px; max-height: 250px; width: auto; height: auto;"  *ngIf="imgURL">
                <div *ngIf="imgURL">
                  <button *ngIf="imgURL" mdbBtn color="danger" style="max-height: 3rem;" mdbWavesEffect (click)="cancelSelectedFile()">{{ 'FORM.DELETE_PICTURE' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <!--START champs obligatoires-->
        <div class="form-row">
          <!--Nom de l'organisation-->
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="users" class="prefix grey-text"></mdb-icon>
              <input type="text" id="label" class="form-control" formControlName="label" mdbInput mdbValidate
                [validateSuccess]="false" required>
              <label for="label">{{ 'FORM.ORGANISATION' | translate }} <span class="text-color-mma-red">*</span></label>
              <mdb-error *ngIf="label.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
            </div>
          </div>
          <!--Date de création
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline" style="padding-left: 2.5rem;">
              <mdb-icon fas icon="calendar-alt" class="prefix grey-text"></mdb-icon>
              <mdb-date-picker [inline]="true" formControlName="date_creation_organization"
                name="date_creation_organization" [options]="myDatePickerOptions" placeholder="{{ 'FORM.LABEL_DATE_CREATION' | translate }} *" inlineIcon="far fa-calendar-alt" required>

              </mdb-date-picker>
              <mdb-error *ngIf="date_creation_organization.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
              <mdb-error *ngIf="!date_creation_organization.invalid || !tryValidate"></mdb-error>
            </div>
          </div>-->
        </div>

        <div class="form-row" *ngIf='!isAuthenticated'>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
              <input type="text" id="email" class="form-control" formControlName="email" mdbInput mdbValidate
                [validateSuccess]="false" required>
              <label for="email">{{ 'FORM.EMAIL' | translate }}  <span class="text-color-mma-red">*</span></label>
              <mdb-error *ngIf="email.invalid && tryValidate">{{ 'FORM.NOT_EMPTY_EMAIL' | translate }}</mdb-error>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div *ngIf='!isAuthenticated' class="md-form md-outline">
              <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
              <input type="password" id="password" class="form-control" formControlName="password" mdbInput mdbValidate
                [validateSuccess]="false" required>
              <label for="password">{{ 'FORM.PASSWORD' | translate }} <span class="text-color-mma-red">*</span></label>
              <mdb-error *ngIf="password.invalid && !isAuthenticated && tryValidate">{{ 'FORM.NOT_EMPTY_PASSWORD' | translate }}</mdb-error>
            </div>
          </div>
          </div>

        <div class="form-row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--Pays-->

            <div class="md-form md-outline">
              <mdb-icon fas icon="globe-europe" class="prefix grey-text"></mdb-icon>
              <!--<mdb-select [options]="optionsSelect" id="idPays" formControlName="idPays" style="padding-right: 40px;"
                placeholder="{{ 'FORM.CHOOSE_COUNTRY' | translate }}" required></mdb-select>-->
                <!--<mdb-select-2 id="pays" style="margin-left: 2rem;" [outline]="true" formControlName="idPays" placeholder="{{'FORM.CHOOSE_COUNTRY'|translate}}" label="{{'FORM.CHOOSE_COUNTRY' | translate}}">-->
                <mdb-select-2 id="pays" style="margin-left: 2rem;" [outline]="true" formControlName="idPays" placeholder="{{'FORM.CHOOSE_COUNTRY'|translate}} *">
                  <mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{ country.label_country }}</mdb-select-option>
    						</mdb-select-2>
                <mdb-error *ngIf="idPays.invalid && !isAuthenticated && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
            </div>
          </div>
            <!--Tel-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
              <mdb-icon fas icon="phone-alt" class="prefix grey-text"></mdb-icon>
                <!--<input type="tel" id="phone" class="form-control" formControlName="phone" mdbInput>
                <label for="phone">{{ 'FORM.PHONE' | translate }}</label>-->
                <ngx-intl-tel-input
                style="margin-left:2rem;color:#757575;width:100%;"
              		enableAutoCountrySelect="true"
              		enablePlaceholder="true"
              		searchCountryFlag="true"
              		[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectedCountryISO]="selectedCountryISO"
              		maxLength="15"
                  separateDialCode="false"
              		phoneValidation="true"
              		inputId="phone"
              		name="phone"
              		formControlName="phone"
	              ></ngx-intl-tel-input>
              </div>
            </div>
          </div>
          <!--END champs obligatoires-->
          <div class="form-row">
            <!--address-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="home" class="prefix grey-text"></mdb-icon>
                <input type="text" id="billing_address" class="form-control" formControlName="billing_address" mdbInput mdbValidate required>
                <label for="billing_address">{{ 'FORM.BILLING_ADDRESS' | translate }} <span class="text-color-mma-red">*</span></label>
                <mdb-error *ngIf="billing_address.invalid && tryValidate">{{ 'FORM.NOT_EMPTY' | translate }}</mdb-error>
              </div>
            </div>
            <!--Description-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="md-form md-outline">
              <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
              <input type="text" id="description" formControlName="description" class="form-control"
                mdbInput>
              <label for="description">{{ 'FORM.DESCRIPTION' | translate }}</label>
            </div>
            </div>

            </div>
            <div class="form-row">
            <!--Reseaux sociaux-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="facebook" class="prefix grey-text"></mdb-icon>
                <input type="text" id="facebook" class="form-control" formControlName="facebook" placeholder="https://www.facebook.com/yourpagename" mdbInput>
                <label for="facebook">{{ 'FORM.LINK_FACEBOOK' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="twitter" size="lg" class="prefix grey-text"></mdb-icon>
                <input type="text" id="twitter" class="form-control" formControlName="twitter" placeholder="https://www.twitter.com/youraccountname" mdbInput>
                <label for="twitter">{{ 'FORM.LINK_TWITTER' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fab icon="instagram" class="prefix grey-text"></mdb-icon>
                <input type="text" id="instagram" class="form-control" formControlName="instagram" placeholder="https://www.instagram.com/yourinstagramname" mdbInput>
                <label for="instagram">{{ 'FORM.LINK_INSTAGRAM' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="link" class="prefix grey-text"></mdb-icon>
                <input type="text" id="website" class="form-control" placeholder="https://mydomain.com" formControlName="website" mdbInput>
                <label for="website">{{ 'FORM.LINK_WEBSITE' | translate }}</label>
              </div>
            </div>
            </div>
            <div class="form-row">
            <!--<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="video" class="prefix grey-text"></mdb-icon>
                <input type="text" id="video" class="form-control" formControlName="video" mdbInput>
                <label for="video">{{ 'FORM.LINK_VIDEO' | translate }}</label>
              </div>
            </div>-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form pb-3 switch success-switch">
                <label class="pb-2">
                  <input type="checkbox" formControlName="accept_newsletter">
                  <span class="lever"></span>{{'FORM.NEWSLETTER'|translate}}
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="md-form md-outline">
                <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
                <input type="text" id="promo" class="form-control" formControlName="promo" mdbInput>
                <label for="promo">{{ 'FORM.PROMO' | translate }}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div *ngIf='!isAuthenticated' class="md-form pb-3 switch success-switch pl-2">
                <!--<mdb-checkbox mdbInput class="my-4" formControlName="accept_terms">{{ 'FORM.ACCEPT' | translate }}<a href="#"
                    class="blue-text">{{ 'FORM.TERM_AND_CONDITIONS' | translate }}</a></mdb-checkbox>-->
                    <label class="pb-2">
                      <input type="checkbox" formControlName="accept_terms">
                      <span class="lever"></span>{{'FORM.TERM_AND_CONDITIONS'|translate}}
                    </label>
              </div>
            </div>
        </div>
      </form>
      <!--Grid row-->
      <div *ngIf='!isLoading' class="row d-flex justify-content-center ">
        <div>
          <button *ngIf='!isAuthenticated' type="button" mdbBtn block="true" rounded="true" color="primary" [disabled]="isLoadingConfirmation"
            (click)="register()" mdbWavesEffect><i *ngIf="isLoadingConfirmation" class="fas fa-circle-notch fa-spin"></i>{{ 'FORM.SIGN_UP' | translate }}</button>
          <button *ngIf='isAuthenticated' type="button" mdbBtn block="true" rounded="true" color="primary" [disabled]="!lightForm.valid || isLoadingConfirmation"
            (click)="edit()" mdbWavesEffect>{{ 'FORM.EDIT' | translate }}</button>
        </div>
      </div>
      <div *ngIf='!isLoading && !isAuthenticated' class="row d-flex justify-content-center mt-2">
        <p class="font-small grey-text d-flex justify-content-end">{{ 'FORM.HAVE_ACCOUNT' | translate }}
          <a href="/subscription" class="blue-text ml-1">{{ 'FORM.LOGIN' | translate }}</a>
        </p>
      </div>
      <!--Grid row-->
    </mdb-card-body>
  </mdb-card>
  </div>
  </div>
  <!--/Form without header-->


<!--Section: Live preview-->
</div>
<!--container-->
</div>
<!-- Background -->

<!--MODAL ALERT SUPPRESSION COMPTE-->
<div *ngIf="isAuthenticated" mdbModal #modalDeleteAccount="mdbModal" class="modal fade top" id="modalDeleteAccount" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.deleteAccountTitle" | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalDeleteAccount.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.deleteAccountText" | translate }}
        </div>
				<form [formGroup]="deleteForm">
					<div class="form-row">
						  <div class="col">
								<div class="md-form md-outline">
									<input type="text" id="confirm_field" class="form-control md-textarea" formControlName="confirm_field" required mdbInput>
									<label for="confirm_field">{{'formControl.hitDeleteWord' | translate}}</label>
								</div>
							</div>
						</div>
				</form>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button type="button" aria-label="Close" (click)="modalDeleteAccount.hide()" mdbBtn color="danger" outline="true">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button type="submit" mdbBtn color="danger" class="relative" [disabled]="!deleteForm.valid || isLoadingDeleteAccount" (click)="deleteAccount()">
          {{ "BUTTON.DELETE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION FIGHTCARD-->
