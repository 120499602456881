<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark black" [containerInside]="false">
  <!-- Navbar brand -->
  <mdb-navbar-brand style="margin : 0 auto;">
		<img src="../../../assets/img/logo_full_white-2.png" style="max-height:50px;">
	</mdb-navbar-brand>
</mdb-navbar>

<div class="container" style="padding:0px;margin: 0px;max-width: 100%;">

  <!--Pas encore de transaction réalisée ou réalisée avec une erreur-->
    <!--Liste des abonnements-->
<div style="background-image: url(../../../assets/img/site-bg.jpg); padding-top: 4rem; min-height: 100vh;">
      <div class='pr-2 pl-2'>
        <div class="card-subscription">
          <div *ngIf="loadingUserInformation">
            <p><i *ngIf="!payPalConfig" class="fas fa-circle-notch fa-spin"></i> {{'TEXT.loadingInformations'|translate}}</p>
          </div>
          <!--ERROR-->
          <div *ngIf="!subscribing && errorMessage" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">{{'ERROR.TITLE'|translate}}</h4>
            <p>{{errorMessage}}</p>
          </div>
          <!--RESULT SUCCESS-->
          <div *ngIf="(!subscribing && !activating) && transactionSuccess === true" class="alert alert-success" role="alert">
            <h4 class="alert-heading">{{'SUCCESS.TITLE'|translate}}</h4>
            <p *ngIf="!userSubscription.subscription_end_date">{{'SUCCESS.managerSubscriptionTxt'|translate}}</p>
            <p *ngIf="userSubscription.subscription_end_date">{{'SUCCESS.unsubscribeTxt'|translate}}</p>
          </div>
          <!--Pas d'abonnement-->
          <div *ngIf="!hasSubscription">
            <!--Encore en période d'essai-->
            <div *ngIf="!user.id_subscription && !mustSubscribe && user.free_until">
              <h3  class="text-center">{{'TEXT.noSubscriptionYet'|translate}}</h3>
              <p>{{'TEXT.freeUseUntil'|translate}} <strong>{{user.free_until | momentDate:'ll'}}</strong></p>
              <h4><strong style="text-decoration: underline;">{{'TEXT.ourOffers'|translate}}</strong></h4>
            </div>
            <!--Période d'essai terminée-->
            <div *ngIf="!user.id_subscription && mustSubscribe">
              <h3  class="text-center">{{'TEXT.noSubscriptionYet'|translate}}</h3>
              <p>{{'TEXT.freePeriodEnd'|translate}} <strong>{{user.free_until | momentDate:'ll'}}</strong></p>
              <p>{{'TEXT.selectSubscription'|translate}}</p>
            </div>
            <i *ngIf="!subscriptionsList" class="fas fa-circle-notch fa-spin"></i>
            <div class="card-subscription" *ngFor="let subscription of subscriptionsList">
                <div
                  style="border-radius: 0.25rem 0 0 0.25rem;"><h2>{{subscription.subscription_label}}</h2>
                </div>
              <p *ngIf="subscription.free_period == 1" class="free-time">{{ "TEXT.1_free_mounth" | translate }}</p>
              <p *ngIf="subscription.free_period > 1" class="free-time">{{subscription.free_period}} {{ "TEXT.free_mounth" | translate }}</p>
              <p *ngIf="subscription.subscription_duration == 1" class="price">{{ "TEXT.then" | translate }} {{subscription.subscription_price}}€ {{ "TEXT.per_mounth" | translate }}</p>
              <p *ngIf="subscription.subscription_duration == 12" class="price">{{ "TEXT.then" | translate }} {{subscription.subscription_price}}€ {{ "TEXT.per_year" | translate }}</p>
              <p class="price">{{ "TEXT.cancel_without_charge" | translate }}</p>
              <div [innerHTML]="subscription.description | keepHtml" style="text-align: initial;padding : 15px;"></div>

              <button class="btn-subscribe"  type="button" mdbBtn color="primary" (click)="openPaypalModal(subscription)"><i *ngIf="subscribing" class="fas fa-circle-notch fa-spin"></i>&nbsp;{{ "BUTTON.go_subscribe" | translate }}</button>


            </div>
          </div>
          <!--ABONNÉ et en cours de validité-->
          <div *ngIf="hasSubscription && !userSubscription.subscription_end_date">
                <h4>{{'TEXT.YOUR_SUBSCRIPTION'|translate}}</h4>
                  <h4><b>{{userSubscription.subscription_label}} ({{userSubscription.subscription_price}} €/{{'TEXT.per_mounth'|translate}})</b></h4>
              <p>{{'TEXT.subscribedSince'|translate}} : {{userSubscription.subscription_start_date}}</p>
              <p>{{userSubscription.description}}</p>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="!unsubscribing && !transactionSuccess"  (click)="unsubscribe()">{{ "BUTTON.unsubscribe" | translate }}</button>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="unsubscribing" disabled><i class="fas fa-circle-notch fa-spin"></i>&nbsp;{{ "BUTTON.unsubscribing" | translate }}</button>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="!activating && transactionSuccess"  (click)="unsubscribe()">{{ "BUTTON.unsubscribe" | translate }}</button>
          </div>
          
          
          <!--ABONNÉ et abonnement en pause-->
          <div *ngIf="hasSubscription && userSubscription.subscription_end_date">
                <h4>{{'TEXT.YOUR_SUBSCRIPTION'|translate}}</h4>
                  <h4><b>{{userSubscription.subscription_label}} ({{userSubscription.subscription_price}} €/{{'TEXT.per_mounth'|translate}})</b></h4>
              <p>{{'TEXT.subscribedSince'|translate}} : {{userSubscription.subscription_start_date}}</p>
              <p>{{'TEXT.disabledOn'|translate}} : {{userSubscription.subscription_end_date}}</p>
              <p>{{'TEXT.reactivateSubscriptionManager'|translate}}</p>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="!activating && !transactionSuccess"  (click)="activate()">{{ "BUTTON.activate" | translate }}</button>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="!unsubscribing && transactionSuccess" (click)="activate()">{{ "BUTTON.activate" | translate }}</button>
              <button class="btn-subscribe"  type="button" mdbBtn color="primary" *ngIf="activating" disabled><i class="fas fa-circle-notch fa-spin"></i>&nbsp;{{ "BUTTON.activation" | translate }}</button>
          </div>
      </div>
      <!--SI ABONNÉ FIGHTER QUI SE MANAGE => affichage d'un bouton pour passer au forfait manager-->
      <div class="mt-5" *ngIf="hasSubscription && !userSubscription.subscription_end_date && user.isManagingHimself && user.multipleProfiles">
          <h3 class="text-center" style="background: #FFFFFF;
          padding: 15px;">{{'TEXT.subscriptionForManagers' |translate}}</h3>
          <div class="card-subscription mt-2" *ngFor="let subscription of subscriptionsList">
            <div
              style="border-radius: 0.25rem 0 0 0.25rem;"><h2>{{subscription.subscription_label}}</h2>
            </div>
            <p *ngIf="subscription.free_period == 1" class="free-time">{{ "TEXT.1_free_mounth" | translate }}</p>
            <p *ngIf="subscription.free_period > 1" class="free-time">{{subscription.free_period}} {{ "TEXT.free_mounth" | translate }}</p>
            <p *ngIf="subscription.subscription_duration == 1" class="price">{{ "TEXT.then" | translate }} {{subscription.subscription_price}}€ {{ "TEXT.per_mounth" | translate }}</p>
            <p *ngIf="subscription.subscription_duration == 12" class="price">{{ "TEXT.then" | translate }} {{subscription.subscription_price}}€ {{ "TEXT.per_year" | translate }}</p>
            <p class="price">{{ "TEXT.cancel_without_charge" | translate }}</p>
            <div [innerHTML]="subscription.description | keepHtml" style="text-align: initial;padding : 15px;"></div>
            <button class="btn-subscribe"  type="button" mdbBtn color="primary" (click)="showUpdateSubscriptionModal(subscription)"><i *ngIf="subscribing" class="fas fa-circle-notch fa-spin"></i>&nbsp;{{ "BUTTON.go_subscribe" | translate }}</button>
          </div>
      </div>
    </div>
  </div>
</div><!--End container with background-->
<!-- Modal Paypal-->
<div mdbModal #paypalModal="mdbModal" class="modal fade top" id="paypalModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead modal-header-text-color">{{ "TEXT.subscribe" | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="paypalModal.hide();subscribing=false;">
          <span aria-hidden="true" class="grey-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p>{{ "TEXT.subscribe_text" | translate }}</p>
          <p>{{"TEXT.subscribe_text_2"|translate}} <a href="https://www.mma-connection.com/fr/conditions-generales-de-vente" target="_blank">{{'TEXT.our_gcs'|translate}}</a></p>
          <div style="padding-top: 1rem;">
            <ngx-paypal [config]="payPalConfig" *ngIf="payPalConfig"></ngx-paypal>
            <i *ngIf="!payPalConfig" class="fas fa-circle-notch fa-spin"></i>
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button type="button" aria-label="Close" class="btn-close" (click)="paypalModal.hide();subscribing=false;" mdbBtn outline="true">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content modal-->
  </div>
</div>

<!--MODAL CONFIRM UPDATE SUBSCRIPTION-->
<!--MODAL ALERT SUPPRESSION FIGHTCARD-->
<div
  mdbModal
  #modalUpdateSubscription="mdbModal"
  class="modal fade top"
  id="modalUpdateSubscription"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "BUTTON.updateOffer" | translate }}</p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalUpdateSubscription.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p>{{ "TEXT.updateSubscriptionForManager" | translate }}</p>
          <p *ngIf="selectedSubscription"><strong>{{selectedSubscription.subscription_label}}</strong></p>
          <p>{{ "TEXT.updateSubscriptionForManager2" | translate }}</p>
          <p>{{"TEXT.updateSubscriptionForManager3" | translate}}</p>
        </div>
				<form [formGroup]="confirmForm">
					<div class="form-row">
						  <div class="col">
								<div class="md-form md-outline">
									<ng-container *ngFor="let validation of validation_messages.confirm_field">
									<mdb-error *ngIf="confirmForm.get('confirm_field').hasError(validation.type) && confirmForm.get('confirm_field').touched">{{validation.message}}</mdb-error>
									</ng-container>
									<input type="text" id="confirm_field" class="form-control md-textarea" formControlName="confirm_field" required mdbInput>
									<label for="confirm_field">{{'formControl.hitConfirmWord' | translate}}</label>
								</div>
							</div>
						</div>
				</form>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalUpdateSubscription.hide()"
          mdbBtn
          color="danger"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button
          type="submit"
          mdbBtn
          color="danger"
          class="relative"
					[disabled]="!confirmForm.valid"
          (click)="changeSubscription()"
        >
        <i *ngIf="unsubscribing" class="fas fa-circle-notch fa-spin"></i>&nbsp;{{ "BUTTON.updateOffer" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION FIGHTCARD-->