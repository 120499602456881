import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import { TranslateService } from '../../translate/translate.service';
import {AlertService} from '../../services/alert.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm : FormGroup;
  isSending : boolean = false;
  buttonText : string = this.translateService.instant('BUTTON','SEND');
  validation_messages: {sujet: { type: string; message: any; }[]; message: { type: string; message: any; }[]; };
  constructor(
    private translateService : TranslateService,
    private contactService : ContactService,
    private alertService : AlertService)
   {

    this.validation_messages = {

      'sujet': [
        { type: 'required', message: this.translateService.instant('formControl','sujetRequired') },
      ],
      'message': [
        { type: 'required', message: this.translateService.instant('formControl','messageRequired') },
      ],
    };
    this.contactForm = new FormGroup({
      'sujet': new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.required
      ])),
      'message': new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.required
      ])),
    });
   }

  ngOnInit(): void {
  }
  submit(){
    this.isSending = true;
    this.buttonText= this.translateService.instant('BUTTON','sending');
    this.contactService.contactMMaCo(this.contactForm.getRawValue()).subscribe(jsonResponse=>{
      this.isSending = false;
      this.buttonText= this.translateService.instant('BUTTON','SEND');
      if(jsonResponse.success){
        this.contactForm.reset();
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','contactSended'),this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','title'));
      }
    },error=>{
      this.isSending = false;
      this.buttonText= this.translateService.instant('BUTTON','SEND');
      this.alertService.showServerError(error);
    },()=>{}/*complete*/);
  }
}
