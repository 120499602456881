import { Component, OnInit, ViewChild } from "@angular/core";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { ModalDirective } from "ng-uikit-pro-standard";
import { ManagersService } from "../../services/managers.service";
import { ReferentielService } from "../../services/referentiel.service";
import { Globals } from "../../globals";
import { TranslateService } from "../../translate";
import { AlertService } from "../../services/alert.service";
import { ContactService } from "../../services/contact.service";
//TODO import { TranslateService } from '../../../translate';
import * as moment from "moment";
import { ChatRoomService } from "../../services/chat-room.service";
import { ChatService } from '../../services/chat.service';
@Component({
  selector: "app-managers",
  templateUrl: "./managers.component.html",
  styleUrls: ["./managers.component.scss"],
})
export class ManagersComponent implements OnInit {
  searchForm: FormGroup;
  public listManagers: any = [];
  public listCountries: any = [];
  public listSexes: any = [];
  public listNationnality: any = [];

  public options = [];
  contactForm: FormGroup;
  messageForm: FormGroup;
  isSending: boolean = false;
  contact_validation_messages = {
    message: null,
    subject: null,
  };
  errorResult: boolean;
  successResult: boolean;
  selectedManager: any;
  isLoading: boolean = true;
  @ViewChild("contactModal") contactModal: ModalDirective;
  @ViewChild("messageModal") messageModal: ModalDirective;
  constructor(
    public fb: FormBuilder,
    private managerService: ManagersService,
    private contactService: ContactService,
    private referentielService: ReferentielService,
    public translateService: TranslateService,
    public globals: Globals,
    private alertService: AlertService,
    private chatRoomService: ChatRoomService,
    private chatService : ChatService
  ) {
    this.searchForm = fb.group({
      name: [null],
      id_living_country: [null],
      id_sex: [null],
      id_nationnality: [null],
    });
    this.contactForm = new FormGroup({
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
    });
    this.messageForm = new FormGroup({
      message: new FormControl("", Validators.required),
    });
    this.contact_validation_messages = {
      message: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
      subject: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
    };
  }

  ngOnInit(): void {
    this.getCountries();
    this.getManagers();
    this.getNationnality();
    this.getSexes();
  }
  getManagers() {
    this.isLoading = true;
    let me = this;
    this.managerService.getList(this.searchForm.getRawValue()).subscribe(
      (jsonResponse) => {
        this.isLoading = false;
        if (jsonResponse.success == true) {
          this.listManagers = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }

        //this.addAgetoFihters();
        //this.getFighterLastweight();
      },
      (error) => {
        this.isLoading = false;
        this.alertService.alertError(error.status);
      },
      () => {}
    );
  }
  getCountries() {
    this.referentielService.getCountries().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listCountries = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  //permet de lancer la recherche
  searchWithFilter() {
    this.getManagers();
  }

  getSexes() {
    this.referentielService.getSex().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listSexes = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getNationnality() {
    this.referentielService.getNationnalities().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.listNationnality = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }

  resetFilter() {
    this.searchForm.reset();
  }
  showContactForm(managerId) {
    this.selectedManager = managerId;
    this.contactModal.show();
  }
  showMessageForm(manager) {
    this.selectedManager = manager;
    this.messageModal.show();
  }
  submitContactForm() {
    this.isSending = true;
    let datas = this.contactForm.getRawValue();
    datas.contactType = ["manager"];
    datas.id_manager_profile = this.selectedManager;
    this.contactService.send(datas).subscribe(
      (jsonResponse) => {
        this.isSending = false;
        if (jsonResponse.success) {
          this.contactForm.reset();
          this.successResult = true;
        } else {
          this.errorResult = true;
        }
      },
      (error) => {
        this.errorResult = true;
        this.alertService.alertError(error.status);
        this.isSending = false;
      }
    );
  }
  submitMessageForm() {
    this.isSending = true;
    let datas = this.messageForm.getRawValue();
    datas.id_manager_profile =this.selectedManager.id_manager_profile;
    datas.contactType = 'manager';
    //Récupération de la room en fonction du contact sélectionné
    this.chatService.searchRoomForUser(datas).subscribe(jsonResponse=>{
      this.isSending = false;
    if(jsonResponse.success){
      //on envoie le message dans la room déjà créée
      if(jsonResponse.total > 0 && jsonResponse.rows[0].id_chat_room !=undefined){
        datas = {
          message : this.messageForm.get('message').value,
          user_creator_room_id : jsonResponse.rows[0].user_id_creator,
          id_chat_room : jsonResponse.rows[0].id_chat_room,
          user_id_receiver : jsonResponse.rows[0].user_id_receiver
        };
        this.sendInChatRoom(datas);
      }
      //on créée une nouvelle room et on envoie le message
      else{
        //stockage du roomId du user à contacter. On reçoit les informations sur le contact uniquement s'il n'y a pas de room existante
        let userRoomId = jsonResponse.rows[0].room_id;
        this.chatRoomService.createRoom(jsonResponse.rows[0].room_id).subscribe(jsonResponse=>{
          //succes création du message
          if(jsonResponse.success){
            datas = {
              message : this.messageForm.get('message').value,
              user_creator_room_id : jsonResponse.obj.user_creator_room_id,
              id_chat_room : jsonResponse.id,
              user_id_receiver : userRoomId
            };
            //envoi du message
            this.sendInChatRoom(datas);
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        },()=>{});
      }
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
    },error=>{
      this.isSending = false;
    this.alertService.alertError(error.status);
    },()=>{});
  }
  sendInChatRoom(datas){
    let formDatas:FormData = new FormData();
    formDatas.append('id_chat_room', datas.id_chat_room);
    formDatas.append('message', datas.message);
    formDatas.append('receiver_room_id', datas.user_id_receiver);
    this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.chatService.sendMessage(
          { roomId: datas.id_chat_room,
            message: datas.message,
            user_room_id:"",
            receiver_room_id : datas.user_id_receiver,
            create_datetime: new Date(),
            imageFromSocket : null
          }
        );
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','MESSAGE_SENT'), this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }
}
