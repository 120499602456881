import {Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from "@angular/core";
import {TranslateService} from '../translate/translate.service';
import * as moment from 'moment';
@Pipe({
	name:'timeAgo',
	pure:false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
	private timer: number;
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private translateService:TranslateService) {}
	transform(value:string) {
		var a = moment();
var b = moment(value);
		moment.locale(this.translateService.currentLang); //set language
		this.removeTimer();		let seconds = a.diff(b,'seconds');		let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) *1000;
		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});		let minutes = a.diff(b,'minutes');
		let hours = a.diff(b,'hours');
		let days = a.diff(b,'days');
		let months = a.diff(b,'months');
		let years = a.diff(b,'years');
		if (seconds <= 45) {
			return this.translateService.instant('timeAgo','now');
		} else if (seconds <= 90) {
			return '1'+this.translateService.instant('timeAgo','minute');
		} else if (minutes <= 45) {
			return minutes +this.translateService.instant('timeAgo','minute');
		} else if (minutes <= 90) {
			return '1'+this.translateService.instant('timeAgo','hour');
		} else if (hours <= 22) {
			return hours+this.translateService.instant('timeAgo','hour');
		} else if (hours <= 36) {
			return this.translateService.instant('timeAgo','yesterday');
		}
		//Si < 7 jours => afficher le jour en lettre
		else if (days < 7) {
			return b.format('dddd');
		}
		//si >= 7 => afficher date dd/mm/YY
		else if (days >= 7) {
			return b.format('L');
		}
	}
	ngOnDestroy(): void {
		this.removeTimer();
	}
	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}
}