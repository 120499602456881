import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Globals} from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ReferentielService {

  constructor(private httpClient : HttpClient, private globals: Globals) {
   }

   public getCountries() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tarefcountry');
   }
   public getNationnalities() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tarefnationnality');
   }
   public getLanguages() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tareflanguage');
   }
   public getResults() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tarefresult');
   }
   public getSex() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tarefsex');
   }
   public getFightType() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tareftypefight');
   }
   public getResultType() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tareftyperesult');
   }
   public getTypeSport() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tareftypesport');
   }
   public getWeightCategories() : Observable<any>{
     return this.httpClient.get(this.globals.baseUrl+'tarefweightcategory');
   }
   public getMeals() : Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'tarefmeal');
  }
  public getExpRookie() : Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'tarefexpamateur');
  }
}
