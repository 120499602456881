import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import {AuthGuardModule} from "../../auth-guard/auth-guard.module";
import { LoginGuard } from '../../auth/login.guard';
import { CombattantsComponent } from "./combattants/combattants.component";
import { FighterDetailsComponent } from "../fighter/fighter-details/fighter-details.component";
import { ManagersComponent } from "../managers/managers.component";
import { DetailsComponent } from "../managers/details/details.component";
import { CreateFightCardComponent } from "../fight-card/create-fight-card/create-fight-card.component";
import { FormComponent } from '../fight/form/form.component';
import { FightCardListComponent } from "../fight-card/fight-card-list/fight-card-list.component";
import { FightListComponent } from "../fight/fight-list/fight-list.component";
import { RegisterComponent } from '../users/register/register.component';
import { PasswordComponent } from '../users/password/password.component';
import { DiscussionComponent } from '../chat/discussion/discussion.component';
import {ContactComponent} from '../contact/contact.component';
import {ChangingEmailComponent} from './changing-email/changing-email.component';
import {CreateFighterComponent} from '../../components/fighter/create-fighter/create-fighter.component';
import {MyOrdersComponent} from '../../components/my-orders/my-orders.component';
import {SubscriptionComponent} from '../../components/subscription/subscription.component';
import { ShortNoticeComponent } from '../short-notice/short-notice.component';
const orgaRoutes: Routes = [
  //loadChildren: () => import('./lazy-route/lazy.module').then(mod => mod.LazyModule),
    //{ path: 'home', component: HomeComponent, canActivate: [AuthGuardModule] },
  { path: 'home', component: FightCardListComponent , canActivate: [LoginGuard] },
  { path: 'fighters', component: CombattantsComponent , canActivate: [LoginGuard] },
  { path: 'fighters/:id', component: FighterDetailsComponent , canActivate: [LoginGuard] },
  { path: 'managers', component: ManagersComponent , canActivate: [LoginGuard] },
  { path: 'managers/:id', component: DetailsComponent , canActivate: [LoginGuard] },
	{ path: 'create-fightcard', component: CreateFightCardComponent , canActivate: [LoginGuard] },
  { path: 'edit-fightcard/:id', component: CreateFightCardComponent , canActivate: [LoginGuard] },
  { path: 'create-fight/:id', component: FormComponent , canActivate: [LoginGuard] },
	{ path: 'fight-card-list', component: FightCardListComponent , canActivate: [LoginGuard] },
	{ path: 'fight-list/:id', component: FightListComponent , canActivate: [LoginGuard] },
  { path: 'my-account', component: RegisterComponent , canActivate: [LoginGuard] },
  { path: 'password', component: PasswordComponent , canActivate: [LoginGuard] },
  { path: 'chat', component: DiscussionComponent , canActivate: [LoginGuard] },
  { path: 'contact', component: ContactComponent , canActivate: [LoginGuard] },
  {path:'change-email',component:ChangingEmailComponent, canActivate:[LoginGuard]},
  {path : 'my-orders', component: MyOrdersComponent,canActivate:[LoginGuard]},
  {path : 'subscription', component: SubscriptionComponent,canActivate:[LoginGuard]},
  {path : 'short-notice', component: ShortNoticeComponent,canActivate:[LoginGuard]}
];


@NgModule({
  imports: [RouterModule.forChild(orgaRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
