<app-navbar></app-navbar>
<!--Grid row-->
<div class="container mt-5">
  <div class="">
    <!--loader-->
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <div
        class="spinner-grow text-info"
        role="status"
        style="width: 4rem; height: 4rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 text-center">
        <h1 *ngIf="fightCard?.event_name" style="display: inline;">
          {{ "PAGE.TITILE_FIGHT_LIST" | translate }} -
          {{ fightCard.event_name }}
        </h1>


        <!--<div mdbDropdown class="btn-group">
          <button *ngIf="fightCard?.event_name"
            mdbDropdownToggle
            type="button"
            mdbBtn
            color="link"
            style="padding: 5px;"
            class="mt-0">
            <mdb-icon
              fas
              class="icon-size"
              icon="ellipsis-v"
            ></mdb-icon>
          </button>
          <div class="dropdown-menu dropdown-primary">
            <a class="dropdown-item" (click)="showSendMessageModal()">{{ "BUTTON.SEND_MESSAGE_ALL_FIGHTERS" | translate }}</a>
          </div>
        </div>-->

        <button *ngIf="fightCard?.event_name" mdbBtn class="btn-black-outline" style="margin-bottom:20px;" (click)="showSendMessageModal()">{{"BUTTON.SEND_MESSAGE_ALL_FIGHTERS" | translate}}</button>
        <!--BUTTON ADD FIGHT-->
        <br>
          <button
            mdbBtn
            (click)="goToAddFightPage()"
            class="btn btn-primary"
            size="lg"
          >
            <mdb-icon fas icon="plus"></mdb-icon> {{'BUTTON.createFight'|translate}}
          </button>
        <hr />
      </div>
    </div>

    <!--List des postulants à la fightcard-->
    <h3 *ngIf="candidateList && candidateList.length > 0" style="display:inline;">{{"TEXT.candidate_list_title" | translate}}</h3>
    <p *ngIf="candidateList && candidateList.length > 0">{{'TEXT.CandidateTxt'|translate}}</p>
        <!--Pour chaque catégorie de poids-->
        <mdb-accordion [multiple]="false">
    <div *ngFor="let list of candidateList">
      <mdb-accordion-item [collapsed]="true">
      <mdb-accordion-item-head [customClass]="'black-text'"><h4>{{list.label_weight_category}} {{'LABEL.CANDIDATE'|translate}})</h4></mdb-accordion-item-head>
      <mdb-accordion-item-body>
      <!--<div *ngIf="candidateList && candidateList.length > 0" class="p-2" style="position:relative;">-->
      <div class="p-2" style="position:relative;">
        <div style="overflow:auto;width: 98%;margin: auto;">
          <!--<ul class="list-group list-group-horizontal">
            <a
              *ngFor="let fighter of list"
              (click)="showCandidateDetailsModal(fighter)">

              <img
                *ngIf="!fighter.profile_pic"
                style="height: 75px; margin-right: 15px"
                src="assets/img/default-profile.png"
                class="rounded"
              />
              <img
                *ngIf="fighter.profile_pic"
                style="height: 75px; margin-right: 15px"
                src="{{globals.publicPathImage}}/pictures/{{ fighter.profile_pic }}"
                class="rounded"
              />
              </a>
              </ul>-->


              <!--<app-fighter-short-description [fighter]="fighter"></app-fighter-short-description>-->

              <ul >
              <li class="chip custom-chip float-left" style="min-width : 130px;" *ngFor="let fighter of list.fighters"
              (click)="showCandidateDetailsModal(fighter)">
                  <img *ngIf="!fighter.profile_pic" src="assets/img/default-profile.png"/>
                  <img *ngIf="fighter.profile_pic" src="{{globals.publicPathImage}}/pictures/{{ fighter.profile_pic }}"/>
                  <span>{{fighter.firstname}} {{fighter.name}}</span><br>
                  <mdb-icon fas icon="venus" *ngIf="fighter.code_sex == 'f'"></mdb-icon><mdb-icon *ngIf="fighter.code_sex == 'h'" fas icon="mars"></mdb-icon> | <span *ngIf="fighter.is_pro == true" class="text-success">{{fighter.number_victory}}</span><span *ngIf="fighter.is_pro == false" class="text-success">{{fighter.number_victory_amateur}}</span>-<span *ngIf="fighter.is_pro == true" class="text-danger">{{fighter.number_defeat}}</span><span *ngIf="fighter.is_pro == false" class="text-danger">{{fighter.number_defeat_amateur}}</span>-<span *ngIf="fighter.is_pro == true">{{fighter.number_null}}</span><span *ngIf="fighter.is_pro == false">{{fighter.number_draw_amateur}}</span> | <span *ngIf="fighter.is_pro" style="color:var(--mma-red);">PRO</span><span *ngIf="!fighter.is_pro" style="color:var(--mma-blue);">AM</span>
                  </li>
              </ul>
          <!--  </a>
          </ul>-->
        </div>
      </div>
      </mdb-accordion-item-body>
      </mdb-accordion-item>
    </div>
    </mdb-accordion>
    <!--Fin postulants fightcard-->

    <div class="row mt-5">
      <div class="alert alert-danger" role="alert"  *ngIf="erreurMessage">{{ erreurMessage }}</div>
      <div
        class="col-12 mb-2 pl-0 pr-0"
        *ngIf="listFights.length > 0 && !isLoading"
      >
        <div *ngFor="let fight of listFights" class="fight-line mb-4">
          <div
            class="unique-color-dark text-light pt-2 pb-2 d-flex row"
          >
          <div class="col-2">
            <mdb-badge *ngIf="fight.is_rookie" style="background-color : var(--mma-blue);">AM</mdb-badge>
            <mdb-badge *ngIf="!fight.is_rookie" style="background-color:var(--mma-red);">PRO</mdb-badge>
            <br>
            <br/>
            <mdb-icon fas icon="venus" *ngIf="fight.code_sex == 'f'" size="2x"></mdb-icon>
            <mdb-icon fas icon="mars" *ngIf="fight.code_sex == 'h'" size="2x"></mdb-icon>
          </div>
            <div class="col-8 text-center">
              <h3 class="">
                <span *ngIf="fight.label_weight_category">{{
                  fight.label_weight_category
                }}</span
                ><span *ngIf="fight.catchweight_lbs && fight.catchweight_kg"
                  >{{ "FORM.CATCHWEIGHT" | translate }} ({{
                    fight.catchweight_lbs
                  }}lbs-{{ fight.catchweight_kg }}kg)</span
                >
              </h3>
              <h5 *ngIf="fight.is_rookie">{{fight.label_exp_amateur}}</h5>
            </div>
            <div class="col-2 text-right">
              <mdb-icon far icon="edit" (click)="showEditFightModal(fight)" class="mr-2" style="font-size:18px;cursor:pointer;"></mdb-icon>
              <mdb-icon far icon="trash-alt" (click)="modalDeleteFight.show(); selectedFight = fight" class="mr-1" style="font-size:18px;cursor:pointer;"></mdb-icon>
            </div>
          </div>
          <!--START INFOS-->
          <div class="text-light text-center pt-2 pb-2 row d-flex" style="background: #363d4a;" (click)="moreinfos.toggle()">
            <div class="col-12"><p style="cursor:pointer;"><strong>{{ "TEXT.ABOUT_FIGHT" | translate }}</strong>&nbsp;<mdb-icon fas icon="chevron-circle-down"></mdb-icon></p></div>
          </div>
          <div mdbCollapse #moreinfos="bs-collapse" class="text-light row d-flex" style="background: #363d4a;">
          <!--<div class="text-light pt-2 pb-2 row d-flex" style="background: #363d4a;">-->
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <p>
                <u
                  ><strong>{{ "TEXT.ABOUT_FIGHT" | translate }}</strong></u>
                :
              </p>
              <p *ngIf="fight.description">{{ fight.description }}</p>
              <p *ngIf="!fight.description">
                {{ "TEXT.ABOUT_FIGHT_NONE" | translate }}
              </p>

              <p><u><strong>{{ "FORM.nbMinFight" | translate }}</strong></u> : {{fight.nb_min_fight}}</p>
              <p><u><strong>{{ "FORM.nbMaxFight" | translate }}</strong></u> : {{fight.nb_max_fight}}</p>
              <p><u><strong>{{ "FORM.nbRound" | translate }}</strong></u> : {{fight.nb_round}}</p>
              <p><u><strong>{{ "FORM.timeRound" | translate }}</strong></u> : {{displayTimeRound(fight)}}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <p>
                <u
                  ><strong>{{ "TEXT.FIGHTERS" | translate }}</strong></u
                >
                :
              </p>
              <p *ngIf="fight.label_sex">{{ fight.label_sex }}</p>
              <p *ngIf="!fight.label_sex">
                {{ "TEXT.NOT_SPECIFIED" | translate }}
              </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <p>
                <u
                  ><strong>{{ "TEXT.REWARD" | translate }}</strong></u
                >
                :
              </p>
              <p *ngIf="fight.min_cash_prize && fight.max_cash_prize">{{ fight.min_cash_prize }} - {{ fight.max_cash_prize }} €</p>
              <p *ngIf="fight.min_cash_prize && !fight.max_cash_prize">{{ fight.min_cash_prize }} €</p>
              <p *ngIf="!fight.min_cash_prize && fight.max_cash_prize">{{ fight.max_cash_prize }} €</p>
              <p *ngIf="!fight.min_cash_prize && !fight.max_cash_prize">{{ "TEXT.NOT_SPECIFIED" | translate }}</p>
            </div>
          <!--</div>-->
          </div>
          <!--END INFOS-->
          <!--Modifier le score-->
          <!--<div class="text-center pt-2 pb-2 row d-flex bg-mma-blue" (click)="this.showResultsModal()">
            <div class="col-12"><h4 style="cursor:pointer;">{{ "TEXT.inputResult" | translate }}</h4></div>
          </div>-->

          <div class="row pt-3 pb-3 d-flex" style="background: #fafafa;">
            <!--ROW COMBATANT-->
            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
              <div class="row p-2">
                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <div class="d-flex mb-2" *ngIf="fight.id_fighter_1">
                    <div class="btn-group mr-1" mdbDropdown *ngIf="fight.id_fighter_1">
                      <button  mdbDropdownToggle type="button" mdbBtn class = "contact-btn">
                        {{'BUTTON.write'|translate}} / {{'BUTTON.DELETE'|translate}}
                      </button>
                      <div class="dropdown-menu dropdown-primary">
                        <a *ngIf="fight.id_manager_profile_fighter_1" class="dropdown-item" (click)="
                            showContactForm(
                              'manager',
                              fight.id_fighter_1,
                              fight.id_manager_profile_fighter_1
                            )
                          "
                          >{{ "BUTTON.CONTACT_MANAGER" | translate }}
                        </a>
                        <a class="dropdown-item" *ngIf="!fight.id_manager_profile_fighter_1" style="color:var(--mma-red);">{{ "BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}</a>
                        <a class="dropdown-item" (click)="
                            showContactForm(
                              'fighter',
                              fight.id_fighter_1,
                              fight.id_manager_profile_fighter_1
                            )
                          "
                          >{{
                            "BUTTON.CONTACT_FIGHTER" | translate
                          }}
                        </a>
                        <a class="dropdown-item" (click)="removeFighterFromFight(fight,1,fight.id_fighter_1,fight.firstname_fighter_1,fight.name_fighter_1)"
                          >{{ "BUTTON.DELETE" | translate }}</a
                        >
                      </div>
                    </div>

                      <div class="flex-grow-1">
                        <button type="button" mdbBtn color="link" style="padding : 5px 9px; text-transform:none;" routerLink="/fighters/{{ fight.id_fighter_1 }}">{{ fight.firstname_fighter_1 }}
                        {{ fight.name_fighter_1 }} <span *ngIf="fight.nickname_fighter_1">-
                        {{ fight.nickname_fighter_1 }}</span></button>
                      <!--<h6 *ngIf="fight.id_fighter_1">
                        <a routerLink="/fighters/{{ fight.id_fighter_1 }}"
                          >{{ fight.firstname_fighter_1 }}
                          {{ fight.name_fighter_1 }} <span *ngIf="fight.nickname_fighter_1">-
                          {{ fight.nickname_fighter_1 }}</span></a
                        >
                      </h6>-->
                    </div>
                  </div>

                  <p *ngIf="!fight.id_fighter_1">
                    {{ "FIGHTCARD.NO_FIGTER_YET" | translate }}
                  </p>
                  <button
                    *ngIf="!fight.id_fighter_1"
                    type="submit"
                    (click)="addFightertofight(fight, 1)"
                    class="btn btn-block btn-primary"
                  >
                    {{'BUTTON.selectFighter'|translate}}
                  </button>
                  <!--PRO-->
                  <div
                    class="progress" *ngIf="fight.id_fighter_1 && fight.is_pro_fighter_1"
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      W{{ fight.number_victory_fighter_1 }}
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      L{{ fight.number_defeat_fighter_1 }}
                    </div>
                    <div
                      class="progress-bar bg-warning"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      D{{ fight.number_null_fighter_1 }}
                    </div>
                  </div>
                  <!--Amateur-->
                  <div
                    class="progress" *ngIf="fight.id_fighter_1 && !fight.is_pro_fighter_1"
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      W{{ fight.number_victory_amateur_fighter_1 }}
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      L{{ fight.number_defeat_amateur_fighter_1 }}
                    </div>
                    <div
                      class="progress-bar bg-warning"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      D{{ fight.number_draw_amateur_fighter_1 }}
                    </div>
                  </div>
                  <!--MEDICAL FOLDER LINK FIGHTER 1-->
                  <div *ngIf="fight.id_fighter_1">
                    <button type="button" mdbBtn color="link" (click)="showMedicalModal(fight.id_fighter_1)">{{'BUTTON.FIGHT_FOLDER'|translate}}</button>
                  </div>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right">
                  <a routerLink="/fighters/{{ fight.id_fighter_1 }}">
                    <img
                      *ngIf="fight.profile_pic_fighter_1"
                      style="height: 100px"
                      src="{{globals.publicPathImage}}/pictures/{{fight.profile_pic_fighter_1}}"
                      class="rounded img-fluid"
                    />
                    <img
                      *ngIf="fight.id_fighter_1 && !fight.profile_pic_fighter_1"
                      style="height: 100px"
                      src="./assets/img/default-profile.png"
                      class="rounded img-fluid"
                    />
                  </a>
                  <div
                    *ngIf="!fight.id_fighter_1"
                    style="height: 100px; width: 100px; border-radius: 4px"
                    class="unique-color-dark text-center"
                  >
                    <h1 class="white-text" style="line-height: 100px">?</h1>
                  </div>
                </div>
              </div>
            </div>
            <!--COL VS-->
            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center" style="color:var(--mma-red);">
              <!--masqué uniquement sur mobile-->
              <h2 style="margin-top:25%" class="d-none d-sm-block">VS</h2>
              <!--affiché uniquement sur mobile-->
              <h2 class="d-block d-sm-none">VS</h2>
            </div>
            <div
              class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
            >
              <div class="row p-2">
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left">
                  <a routerLink="/fighters/{{ fight.id_fighter_2 }}"
                    ><img
                      *ngIf="fight.profile_pic_fighter_2"
                      style="height: 100px"
                      src="{{globals.publicPathImage}}/pictures/{{fight.profile_pic_fighter_2}}"
                      class="rounded img-fluid"
                  />
                  <img
                      *ngIf="fight.id_fighter_2 && !fight.profile_pic_fighter_2"
                      style="height: 100px"
                      src="./assets/img/default-profile.png"
                      class="rounded img-fluid"
                    />
                  </a>
                  <div
                    *ngIf="!fight.id_fighter_2"
                    style="height: 100px; width: 100px; border-radius: 4px"
                    class="unique-color-dark text-center"
                  >
                    <h1 class="white-text" style="line-height: 100px">?</h1>
                  </div>
                </div>
                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">

                  <div class="d-flex mb-2" *ngIf="fight.id_fighter_2">
                    <div class="flex-grow-1">
                      <button type="button" mdbBtn color="link" style="padding : 5px 9px; text-transform:none;" routerLink="/fighters/{{ fight.id_fighter_2 }}">{{ fight.firstname_fighter_2 }}
                      {{ fight.name_fighter_2 }} <span *ngIf="fight.nickname_fighter_2">-
                      {{ fight.nickname_fighter_2 }}</span></button>
                  </div>

                    <div class="btn-group mr-1" mdbDropdown *ngIf="fight.id_fighter_2">
                      <button  mdbDropdownToggle type="button" mdbBtn class = "contact-btn">
                        {{'BUTTON.write'|translate}} / {{'BUTTON.DELETE'|translate}}
                      </button>
                      <div class="dropdown-menu dropdown-primary">
                        <a *ngIf="fight.id_manager_profile_fighter_2" class="dropdown-item" (click)="
                            showContactForm(
                              'manager',
                              fight.id_fighter_2,
                              fight.id_manager_profile_fighter_2
                            )
                          "
                          >{{ "BUTTON.CONTACT_MANAGER" | translate }}
                        </a>
                        <a class="dropdown-item" *ngIf="!fight.id_manager_profile_fighter_2" style="color:var(--mma-red);">{{ "BUTTON.CONTACT_HAS_NO_MANAGER" | translate }}</a>
                        <a class="dropdown-item" (click)="
                            showContactForm(
                              'fighter',
                              fight.id_fighter_2,
                              fight.id_manager_profile_fighter_2
                            )
                          "
                          >{{
                            "BUTTON.CONTACT_FIGHTER" | translate
                          }}
                        </a>
                        <a class="dropdown-item" (click)="removeFighterFromFight(fight,2,fight.id_fighter_2,fight.firstname_fighter_2,fight.name_fighter_2)"
                          >{{ "BUTTON.DELETE" | translate }}</a
                        >
                      </div>
                    </div>
                  </div>

                  <p *ngIf="!fight.id_fighter_2">
                    {{ "FIGHTCARD.NO_FIGTER_YET" | translate }}
                  </p>
                  <button
                    *ngIf="!fight.id_fighter_2"
                    type="submit"
                    (click)="addFightertofight(fight, 2)"
                    class="btn btn-block btn-primary"
                  >
                    {{'BUTTON.selectFighter'|translate}}
                  </button>
                  <div
                    class="progress" *ngIf="fight.id_fighter_2 && fight.is_pro_fighter_2"
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      W{{ fight.number_victory_fighter_2 }}
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      L{{ fight.number_defeat_fighter_2 }}
                    </div>
                    <div
                      class="progress-bar bg-warning"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      D{{ fight.number_null_fighter_2 }}
                    </div>
                  </div>
                  <!--Amateur-->
                  <div
                    class="progress" *ngIf="fight.id_fighter_2 && !fight.is_pro_fighter_2"
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      W{{ fight.number_victory_amateur_fighter_2 }}
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      L{{ fight.number_defeat_amateur_fighter_2 }}
                    </div>
                    <div
                      class="progress-bar bg-warning"
                      role="progressbar"
                      style="width:33.33%"
                    >
                      D{{ fight.number_draw_amateur_fighter_2 }}
                    </div>
                  </div>
                  <!--MEDICAL FOLDER LINK FIGHTER 2-->
                  <div *ngIf="fight.id_fighter_2">
                    <button type="button" mdbBtn color="link" (click)="showMedicalModal(fight.id_fighter_2)">{{'BUTTON.FIGHT_FOLDER'|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--END ROW-->
          <!--AFFICAGE DES POSTULANTS-->
          <div class="row" style="background: #fafafa;">
          <div class="col-12">
            <h5 class="pl-3">{{ "TEXT.APPLICANTS" | translate }}</h5>
            <div *ngIf="fightClaimList" class="p-2" style="position:relative;">
              <div class="navigation-fighter" style="left:0;" (click)="scrollLeft($event)">
                <i class="fas fa-chevron-left"></i>
              </div>
              <div class="" style="overflow:auto;width:98%;margin:auto">
                <ul class="list-group list-group-horizontal">
                  <a
                    *ngFor="let claim of fightClaimList"
                    (click)="showFighterDetails(fight, claim)"
                  >
                    <img
                      *ngIf="claim.id_fight == fight.id_fight && !claim.profile_pic && claim.is_refused_by_organizer != true"
                      style="height: 50px; margin-right: 10px"
                      src="assets/img/default-profile.png"
                      class="rounded"
                    />
                    <img
                      *ngIf="claim.id_fight == fight.id_fight && claim.profile_pic && claim.is_refused_by_organizer != true"
                      style="height: 50px; margin-right: 10px"
                      src="{{globals.publicPathImage}}/pictures/{{ claim.profile_pic }}"
                      class="rounded"
                    />
                  </a>
                </ul>
              </div>

              <div class="navigation-fighter" style="right:0;" (click)="scrollRight($event)">
                <i class="fas fa-chevron-right" (click)="scrollRight($event)"></i>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div *ngIf="listFights == '' && !isLoading" class="text-center">
        <h3 class="align-middle">{{ "TEXT.NO_FIGHT" | translate }}</h3>
      </div>
    </div>
    <!--END ROW-->
  </div>
  <!--END CONTAINER 2-->
  <div class="fixed-action-btn smooth-scroll" style="bottom: 45px; right: 24px;">
    <a mdbBtn floating="true" (click)="goToAddFightPage()" class="primary-color" size="lg">
      <mdb-icon fas icon="plus"></mdb-icon>
    </a>
  </div>
</div>
<!--END CONTAINER-->

<!--Modal: ajout combat-->
<div
  mdbModal
  #modalNextStep="mdbModal"
  class="modal fade left"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">
          {{ "SUCCESS.TITLE" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalNextStep.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        {{ "TEXT.ADD_ANOTHER_FIGHT" | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="light" (click)="modalNextStep.hide()">
          {{ "BUTTON.CANCEL" | translate }}
        </button>
        <button mdbBtn type="button" color="light-green">
          {{ "BUTTON.ADD" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--MODAL FIGHTER DETAILS-->
<div
  mdbModal
  #modalFighterDetails="mdbModal"
  class="modal fade left"
  id="modalFighterDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">
          {{ "TEXT.ADD_TO_FIGHT" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalFighterDetails.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3" *ngIf="claim">
        <app-fighter-short-description
          [fighter]="claim"
        ></app-fighter-short-description>
        <p class="text-danger" *ngIf="fight.id_fighter_1 !=null && fight.id_fighter_2 != null">{{'TEXT.deleteFighterToAdd'|translate}}</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="light" (click)="modalFighterDetails.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button mdbBtn
        type="button" type="button" name="button" color="danger"
        (click)="refuseFighter()"
        [disabled] = "isDeclining"
        *ngIf="fight || claim">
        <mdb-spinner
          style="border-color: white"
          *ngIf="isDeclining"
          spinnerType="small"
        ></mdb-spinner
        >
        {{'BUTTON.REFUSE'|translate}}</button>
        <button
          mdbBtn
          type="button"
          color="light-green"
          (click)="addToFight()"
          *ngIf="fight || claim"
          [disabled]="
            fight.id_fighter_1 == claim.id_fighter_profile ||
            fight.id_fighter_2 == claim.id_fighter_profile || (fight.id_fighter_1 !=null && fight.id_fighter_2 != null)
          "
        >
          {{ "BUTTON.ADD" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CONTACT-->
<div
  mdbModal
  #contactModal="mdbModal"
  class="modal fade left"
  id="contactModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">
          {{ "TEXT.NEW_MESSAGE" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="contactModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <form [formGroup]="contactForm">
          <!--<div class="md-form mb-5">
            <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
            <input
              type="text"
              id="form32"
              class="form-control"
              mdbInput
              mdbValidate
              formControlName="subject"
            />
            <label for="form32">{{ "FORM.SUBJECT" | translate }}</label>
            <ng-container
              *ngFor="let validation of contact_validation_messages.subject"
            >
              <mdb-error
                *ngIf="
                  contactForm.get('subject').hasError(validation.type) &&
                  contactForm.get('subject').touched
                "
                >{{ validation.message }}</mdb-error
              >
            </ng-container>
          </div>-->

          <div class="md-form md-outline">
            <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
            <textarea
              type="text"
              id="form8"
              class="md-textarea form-control"
              rows="1"
              mdbInput
              mdbValidate
              formControlName="message"
            ></textarea>
            <label for="form8">{{ "FORM.YOUR_MESSAGE" | translate }}</label>
            <ng-container
              *ngFor="let validation of contact_validation_messages.message"
            >
              <mdb-error
                *ngIf="
                  contactForm.get('message').hasError(validation.type) &&
                  contactForm.get('message').touched
                "
                >{{ validation.message }}</mdb-error
              >
            </ng-container>
          </div>
        </form>

        <div class="red-text" *ngIf="errorResult">
          {{ "ERROR.SENT_MESSAGE" | translate }}
        </div>
        <div class="green-text" *ngIf="successResult">
          {{ "SUCCESS.SENT_MESSAGE" | translate }}
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="btn-close" (click)="contactModal.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button
          mdbBtn
          class="btn-mma-blue"
          (click)="submitContactForm()"
          [disabled]="!contactForm.valid || isSending"
        >
          <mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner
          >{{ "BUTTON.SEND" | translate }}
          <mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<!--END MODAL CONTACT-->

<!--MODAL ALERT SUPPRESSION FIGHT-->
<div
  mdbModal
  #modalDeleteFight="mdbModal"
  class="modal fade top"
  id="modalDeleteFight"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.DELETE_FIGHT_TITLE" | translate }}</p>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalDeleteFight.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.DELETE_FIGHT_TEXT" | translate }}
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalDeleteFight.hide()"
          mdbBtn
          color="danger"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button
          type="button"
          mdbBtn
          color="danger"
          class="relative"
          (click)="deleteFight()"
          [disabled]="deleteLoading"
        >
          {{ "BUTTON.DELETE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION FIGHT-->

<!--MODAL UPDATE FIGHTER-->
<div
  mdbModal
  #modalEditFight="mdbModal"
  style="overflow-y: scroll"
  class="modal fade top"
  id="modalEditFight"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalEditFight.hide()">
          <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
        </button>
        <h4 class="heading lead modal-header-text-color">{{ "TEXT.UPDATE_FIGHT_TITLE" | translate }}</h4>

        <!--<button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalEditFight.hide()"
        >
          <span aria-hidden="true" class="modal-header-text-color">&times;</span>
        </button>-->
        <!--<button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalEditFight.hide()">
          <mdb-icon fas icon="times"></mdb-icon>
        </button>-->
      </div>

      <!--Body-->
      <div class="modal-body">
        <form [formGroup]="fightForm" class="text-left">
				<!--Début accordéon-->
              <mdb-accordion [multiple]="false">
				  <!--Infos générales-->
                <mdb-accordion-item [collapsed]="true">
                  <mdb-accordion-item-head [customClass]="'black-text'"
                    ><h3>
                      <strong>{{
                        "FORM.FIGHT_INFORMATIONS" | translate
                      }}</strong>
                    </h3></mdb-accordion-item-head>
                  <mdb-accordion-item-body>

                    <div style="line-height: normal;"> <!--évite que le text soit coupé par le line-height de accordion-->

                    <div class="form-row">
                      <div class="col-12">
                        <!--SEX LIST-->
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="{{'FORM.FIGHT_LEVEL'|translate}}" formControlName="is_rookie" label="{{'FORM.FIGHT_LEVEL'|translate}}*" allowClear="true" (selected)="setRequiredFieldForAmateur($event)">
                            <mdb-select-option value="false">{{ 'FORM.IS_PRO' | translate }}</mdb-select-option>
                            <mdb-select-option value="true">{{ 'FORM.IS_ROOKIE' | translate }}</mdb-select-option>
                          </mdb-select-2>
                        </div>
                      </div>
                        <!--END SEX LIST-->
                      <!--SEX LIST-->
                      <div class="col-12">
                      <div class="md-form md-outline mt-3">
                        <mdb-select-2
                        [outline]="true"
                          placeholder="{{ 'FORM.CHOOSE_SEX' | translate }}"
                          formControlName="id_sex"
                          label="{{ 'FORM.CHOOSE_SEX' | translate }}"
                          allowClear="true"
                          (selected)="filterWeightCategories($event)"
                        >
                          <mdb-select-option [value]="1">{{
                            "FORM.LABEL_SEX_MALE" | translate
                          }}</mdb-select-option>
                          <mdb-select-option [value]="2">{{
                            "FORM.LABEL_SEX_FEMALE" | translate
                          }}</mdb-select-option>
                        </mdb-select-2>
                        <ng-container
                          *ngFor="
                            let validation of fight_form_validation_messages.sex
                          "
                        >
                          <mdb-error
                            *ngIf="
                              fightForm
                                .get('id_sex')
                                .hasError(validation.type) &&
                              fightForm.get('id_sex').touched
                            "
                            >{{ validation.message }}</mdb-error
                          >
                        </ng-container>
                      </div>
                      </div>
                      <!--END SEX LIST-->
                      <!--WEIGHT CATEGORY LIST-->
                      <div class="col-12">
                      <div class="md-form md-outline">
                        <mdb-select-2
                        [outline]="true"
                          placeholder="{{ 'FORM.CHOOSE_WEIGHT' | translate }}"
                          label="{{ 'FORM.CHOOSE_WEIGHT' | translate }}"
                          formControlName="id_weight_category"
                          allowClear="true"
                        >
                          <mdb-select-option
                            *ngFor="let weight of filterWeightCategoriesList"
                            [value]="weight.id_weight_category"
                            >{{
                              weight.label_weight_category
                            }}</mdb-select-option
                          >
                        </mdb-select-2>
                      </div>
                      </div>
                    </div>
                    <!--END WEIGHT CATEGORY LIST-->
                    <!---POIDS LIBRE CATCHWEIGHT-->
                    <!---POIDS LIBRE CATCHWEIGHT-->
                    <div class="switch success-switch text-right">
                      <label>
                        <u
                          ><strong>{{ "TEXT.OR" | translate }}</strong></u
                        >
                        {{ "FORM.CATCHWEIGHT" | translate }}
                        <input
                          type="checkbox"
                          formControlName="is_catchweight"
                          (change)="onSwitch()"
                        />
                        <span class="lever"></span>
                      </label>
                    </div>
                    <div class="form-row" *ngIf="fightForm.get('is_catchweight').value">
                      <div class="col-12">
                        <!-- First name -->
                        <div class="md-form md-outline">
                          <input
                            type="number"
                            id="catchweightLbs"
                            class="form-control"
                            formControlName="catchweight_lbs"
                            (change)="convertWeightTo('kg')"
                            mdbInput
                            placeholder=""
                          />
                          <label for="catchweightLbs">Lbs</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <!-- Last name -->
                        <div class="md-form md-outline">
                          <input
                            type="number"
                            id="catchweightKg"
                            class="form-control"
                            formControlName="catchweight_kg"
                            placeholder=""
                            (change)="convertWeightTo('lbs')"
                            mdbInput
                          />
                          <label for="catchweightKg">Kg</label>
                        </div>
                      </div>
                    </div>
                    <!--END WPOIDS LIBRE CATCHWEIGHT-->
                    <!--partie round-->
                    <div class="form-row">
                      <!--Partie round-->
                      <div class="col-12">
                        <!-- nbRound -->
                        <div class="md-form md-outline">
                          <input type="number" min="1" id="nb_round" class="form-control" formControlName="nb_round" mdbInput [required]>
                          <label for="nb_round">{{"FORM.nbRound" | translate}}*</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <!-- time round -->
                        <div class="md-form md-outline">
                          <input type="number" min="0" max="59" id="time_round_m" class="form-control" formControlName="time_round_m" mdbInput [required]>
                          <label for="time_round_m" style="width: auto;">{{"FORM.timeRoundMinute" | translate}}</label>
                        </div>
                      </div>
                      <!--<div class="col-12">-->
                        <!-- time round -->
                        <!--<div class="md-form md-outline">
                          <input type="number" min="0" max="59" id="time_round_s" class="form-control" formControlName="time_round_s" mdbInput [required]>
                          <label for="time_round_s" style="width: auto;">{{"FORM.timeRoundSeconde" | translate}}</label>
                        </div>
                      </div>-->
                    </div>
                    <!--FIGHT TYPE LIST-
                    <div class="md-form mt-3">
                      <mdb-select-2
                        placeholder="{{ 'FORM.TYPE_FIGHT' | translate }}"
                        label="{{ 'FORM.TYPE_FIGHT' | translate }}"
                        formControlName="id_type_fight"
                        allowClear="true"
                      >
                        <mdb-select-option
                          *ngFor="let fightType of fightTypeList"
                          [value]="fightType.id_type_fight"
                          >{{ fightType.label_type_fight }}</mdb-select-option
                        >
                      </mdb-select-2>
                      <ng-container
                        *ngFor="
                          let validation of fight_form_validation_messages.type_fight
                        "
                      >
                        <mdb-error
                          *ngIf="
                            fightForm
                              .get('id_type_fight')
                              .hasError(validation.type) &&
                            fightForm.get('id_type_fight').touched
                          "
                          >{{ validation.message }}</mdb-error
                        >
                      </ng-container>
                    </div>
                    <!--END FIGHT TYPE LIST-->
                    <!--AJOUTER CHAMP PRIME DE COMBAT EN FOURCHETTE-->
                    <!--IS PUBLIC
				    <div class="md-form mt-3">
				              <mdb-checkbox mdbValidate formControlName="is_public">{{'FORM.HIDE_IN_APP'|translate}}</mdb-checkbox
				              >
				        </div>
				    <!--END IS PUBLIC-->
                    <!--CHAMP PRIME DE COMBAT-->
                    <!--<div class="md-form mt-3">
                      <label for="inputPrice">{{
                        "FORM.REWARD" | translate
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        value="0"
                        id="inputPrice"
                        formControlName="cash_prize"
                        mdbInput
                      />
                    </div>-->
                    <!--IS ROOKIE-->
                    <div class="form-row" *ngIf="fightForm.get('is_rookie').value == 'true'">
                          <div class="col-12">
                            <!-- First name -->
                            <div class="md-form md-outline">
                              <input type="number" id="min_age" class="form-control" formControlName="min_age" mdbInput mdbValidate>
                              <label for="min_age">{{'FORM.minAge'|translate}}</label>
                              <mdb-error *ngIf="fightForm.get('min_age').hasError && fightForm.get('min_age').touched && (fightForm.get('min_age').value < 12)">{{'formControl.minAge'|translate}}</mdb-error>
                            </div>
                          </div>
                          <div class="col-12">
                            <!-- Last name -->
                            <div class="md-form md-outline">
                              <input type="number" id="max_age" class="form-control" formControlName="max_age" mdbInput mdbValidate>
                              <label for="max_age">{{'FORM.maxAge'|translate}}</label>
                            </div>
                          </div>
                    </div>

                    <!--END IS ROOKIE-->
                    <div class="form-row" *ngIf="fightForm.get('is_rookie').value == 'false'">
                    <!--Nombre minimum de combats pour participer-->
                    <div class="col-12">
                      <div class="md-form md-outline">
                        <input
                          type="number"
                          formControlName="nb_min_fight"
                          id="nb_min_fight"
                          class="form-control md-textarea"
                          mdbInput
                        />
                        <label for="nb_min_fight">{{
                          "FORM.nbMinFight" | translate
                        }}</label>
                      </div>
                    </div>
                    <!--END minimum de victoire pour participer-->
                    <!--Nombre maxi de combats pour participer-->
                    <div class="col-12">
                      <div class="md-form md-outline" *ngIf="showNbMinWinField">
                        <input
                          type="number"
                          formControlName="nb_max_fight"
                          id="nb_max_fight"
                          class="form-control md-textarea"
                          mdbInput
                        />
                        <label for="nb_max_fight">{{
                          "FORM.nbMaxFight" | translate
                        }}</label>
                      </div>
                    </div>
                    <!--END minimum de victoire pour participer-->
                    </div>
                    <!--END minimum de victoire pour participer-->
                    <!--Expérience amateur-->
                    <div *ngIf="fightForm.get('is_rookie').value == 'true'" class="form-row">
                      <div class="col-12">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="{{ 'FORM.EXP_ROOKIE' | translate }}" label="{{ 'FORM.EXP_ROOKIE' | translate }}" formControlName="id_exp_amateur" allowClear="true">
                            <mdb-select-option *ngFor="let exp of exp_rookie_list" [value]="exp.id_exp_amateur">{{ exp.label_exp_amateur }}</mdb-select-option>
                          </mdb-select-2>
                        </div>
                      </div>
                    </div>
                    <!--End expérience amateur-->
                    <!--DESCRIPTION-->
                    <div class="form-row">
                      <div class="col-12">
                        <div class="md-form md-outline">
                          <textarea
                            type="text"
                            formControlName="description"
                            id="description"
                            class="form-control md-textarea"
                            mdbInput
                            mdbValidate
                          ></textarea>
                          <label for="description">{{
                            "FORM.DESCRIPTION" | translate
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <!--END DESCRIPTION-->
                    </div>
                  </mdb-accordion-item-body>
                </mdb-accordion-item>

				<!--Infos médicales-->
                <mdb-accordion-item [collapsed]="true">
                  <mdb-accordion-item-head [customClass]="'black-text'"
                    ><h3>
                      <strong>{{
                        "FORM.MEDICAL_INFORMATIONS_TITLE" | translate
                      }}</strong>
                    </h3></mdb-accordion-item-head
                  >
                  <mdb-accordion-item-body>
                    <p>{{'TEXT.MEDICAL_INFORMATIONS'|translate}}</p>
                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="certif_medical">
                        <span class="lever"></span>{{'TEXT.certificat'|translate}}
                      </label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="blood_test">
                        <span class="lever"></span>{{'FORM.BLOOD_TEST'|translate}}
                      </label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="brain_test">
                        <span class="lever"></span>{{'FORM.BRAIN_MRI'|translate}}
                      </label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="stress_test">
                        <span class="lever"></span>{{'FORM.STRESS_TEST'|translate}}
                      </label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="eye_test">
                        <span class="lever"></span>{{'FORM.EYE_TEST'|translate}}
                      </label>
                    </div>
                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="angio_mri_cerebral_test">
                        <span class="lever"></span>{{'FORM.ANGIO_IRM'|translate}}
                      </label>
                    </div>
                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="ecg_test">
                        <span class="lever"></span>{{'FORM.ECG_TEST'|translate}}
                      </label>
                    </div>

                  </mdb-accordion-item-body>
                </mdb-accordion-item>

              <!--Licence-->
              <mdb-accordion-item [collapsed]="true">
                <mdb-accordion-item-head [customClass]="'black-text'"><h3><strong>{{'FORM.LICENCE'|translate}}</strong></h3></mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div style="line-height: normal;"> <!--évite que le text soit coupé par le line-height de accordion-->

                  <p>{{'TEXT.MEDICAL_INFORMATIONS'|translate}}</p>

                  <!-- Bool license -->
                  <div class="md-form pb-3 switch success-switch">
                    <label class="pb-2">
                      <input type="checkbox" formControlName="license" (change)="switchLicense()">
                      <span class="lever"></span>{{'FORM.LICENCE'|translate}}
                    </label>
                  </div>

                  <!-- License details -->
                  <div class="md-form md-outline">
                    <textarea
                      type="text"
                      formControlName="license_informations"
                      id="license_informations"
                      class="form-control md-textarea"
                      mdbInput
                      mdbValidate></textarea>
                    <label for="license_informations">{{'FORM.LICENCE_DETAILS'|translate}}</label>
                  </div>

                  </div>
                </mdb-accordion-item-body>
                </mdb-accordion-item>

				<!--Contre partie-->
                <mdb-accordion-item [collapsed]="true">
                  <mdb-accordion-item-head [customClass]="'black-text'"
                    ><h3>
                      <strong>{{
                        "FORM.COUNTERPART_TITLE" | translate
                      }}</strong>
                    </h3></mdb-accordion-item-head
                  >
                  <mdb-accordion-item-body>
                    <div style="line-height: normal;"> <!--évite que le text soit coupé par le line-height de accordion-->
                    <p>{{'TEXT.COUNTERPART'|translate}}</p>

                    <!--CHAMP PRIME DE COMBAT-->
                    <div class="md-form md-outline mt-3">
                      <input type="number" class="form-control" value="0" id="inputPriceMin" formControlName="min_cash_prize" (change)='roundInput("min_cash_prize", 2, false, false)' mdbInput>
                      <label for="inputPriceMin">{{'FORM.MIN_REWARD' | translate}}</label>
                    </div>

                    <div class="md-form md-outline mt-3">
                      <input type="number" class="form-control" value="0" id="inputPriceMax" formControlName="max_cash_prize" (change)='roundInput("max_cash_prize", 2, false, false)' mdbInput>
                      <label for="inputPriceMax">{{'FORM.MAX_REWARD' | translate}}</label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="hotel">
                        <span class="lever"></span>{{'FORM.HOTEL'|translate}}
                      </label>
                    </div>

                    <div class="md-form pb-3 switch success-switch">
                      <label class="pb-2">
                        <input type="checkbox" formControlName="transport">
                        <span class="lever"></span>{{'FORM.TRANSPORT'|translate}}
                      </label>
                    </div>

                    <div class="md-form md-outline mt-5">
                      <mdb-select-2 [outline]="true" placeholder="{{'FORM.MEAL' | translate}}" formControlName="meal" label="{{'FORM.MEAL' | translate}}" allowClear="true">
                        <mdb-select-option *ngFor="let meal of mealList" [value]="meal.id_meal">{{ meal.label_meal }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline">
                      <input type="number" id="accompanying" class="form-control" formControlName="accompanying" (change)='roundInput("accompanying", 0, false, false)' mdbInput>
                      <label for="accompanying">{{'FORM.ACCOMPANYING_NUMBER'|translate}}</label>
                    </div>
                    </div>
                  </mdb-accordion-item-body>
                </mdb-accordion-item>
              </mdb-accordion>
        </form>
        <!--Footer-->
        <div class="modal-footer">
          <button mdbBtn class="btn-close" (click)="modalEditFight.hide()">
            {{ "BUTTON.CANCEL" | translate }}
          </button>
          <button
            mdbBtn
            class="btn-mma-blue"
            type="submit"
            (click)="editFight()"
            [disabled]="!fightForm.valid || isSaving"
          >
            <mdb-spinner
              style="border-color: white"
              *ngIf="isSaving"
              spinnerType="small"
            ></mdb-spinner
            >{{ "BUTTON.SAVE" | translate }}
          </button>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
  <!--END MODAL UPDATE FIGHTER-->
</div>


<!--MODAL ALERT SUPPRESSION FIGHTCARD-->
<div
  mdbModal
  #modalDisplayMedicalFolder="mdbModal"
  class="modal fade top"
  id="modalDisplayMedicalFolder"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  style="overflow-y: auto"
>
  <div class="modal-dialog modal-notify modal-primary" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header" style="background-color: #ffffff;">
        <h4 class="heading lead modal-header-text-color">{{ "TEXT.fight_folder" | translate }}</h4>
        <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="modalDisplayMedicalFolder.hide()">
          <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <!--loader-->
          <div class="d-flex justify-content-center" *ngIf="showMedicalLoader">
            <div
              class="spinner-grow text-info"
              role="status"
              style="width: 4rem; height: 4rem"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div *ngIf="showErrorMedicalInformation" class="alert alert-danger" role="alert">
            {{'ERROR.MEDICAL_INFORMATIONS_LOADING'|translate}}
          </div>
          <mdb-accordion [multiple]="false">
            <mdb-accordion-item [collapsed]="true">
              <mdb-accordion-item-head  style="background:#f0f0f0;">{{'TEXT.MEDICAL_FILES_TITLE'|translate}}</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="fighter && !showErrorMedicalInformation">
                  <div>{{ "TEXT.MEDICAL_FILES" | translate }} {{fighter.firstname}} {{fighter.lastname}}</div>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.certif_medical_file,'text-danger':!fighter.certif_medical_file}" target="_blank" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.certif_medical_file}}">{{"TEXT.certificat"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.ecg_file,'text-danger':!fighter.ecg_file}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.ecg_file}}" target="_blank">{{"TEXT.ecg"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.irm_cerebrale_file,'text-danger':!fighter.irm_cerebrale_file}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.irm_cerebrale_file}}" target="_blank">{{"TEXT.irm"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.bilan_sang,'text-danger':!fighter.bilan_sang}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.bilan_sang}}" target="_blank">{{"TEXT.blood"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.bilan_fond_oeil,'text-danger':!fighter.bilan_fond_oeil}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.bilan_fond_oeil}}" target="_blank">{{"TEXT.eyes"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.bilan_fond_oeil,'text-danger':!fighter.bilan_fond_oeil}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.angio_mri_cerebral_file}}" target="_blank">{{"TEXT.angioIrm"|translate}}</a>
                  <a [ngClass]="{'list-group-item':true,'text-left':true,'disabled':!fighter.bilan_fond_oeil,'text-danger':!fighter.bilan_fond_oeil}" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/m/{{fighter.test_effort_file}}" target="_blank">{{"TEXT.testEffort"|translate}}</a>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head style="background:#f0f0f0;">{{'TEXT.photo_folder'|translate}}</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="fighter && !showErrorMedicalInformation">
                  <div class="row">
                    <div class="col-3 mr-2" *ngIf="fighter.photo_bras_croises_buste">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_bras_croises_buste}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_bras_croises_pied">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_bras_croises_pied}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_garde_buste">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_garde_buste}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_garde_pied" >
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_garde_pied}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_quart_face_gauche">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_quart_face_gauche}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_quart_face_droite">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_quart_face_droite}}"/>
                    </div>
                    <div class="col-3 mr-2" *ngIf="fighter.photo_free_1">
                      <img alt="" class=" img-fluid rounded mx-2"  src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_free_1}}"/>
                    </div>
                    <div class="col-3"  *ngIf="fighter.photo_free_2">
                      <img alt="" class="rounded img-fluid" src="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/f/{{fighter.photo_free_2}}"/>
                    </div>
                  </div>



                </div>
                <div *ngIf="fighter && !showErrorMedicalInformation">


                </div>
                <div *ngIf="fighter && !showErrorMedicalInformation">


                  <p *ngIf="!fighter.photo_bras_croises_buste && !fighter.photo_bras_croises_pied && !fighter.photo_garde_buste && !fighter.photo_garde_pied && !fighter.photo_quart_face_gauche && !fighter.photo_quart_face_droite">{{'TEXT.noPhotos'|translate}}</p>
                </div>

              </mdb-accordion-item-body>
            </mdb-accordion-item>
            <mdb-accordion-item [collapsed]="true">
              <mdb-accordion-item-head  style="background:#f0f0f0;">{{'FORM.LICENCE'|translate}}</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="fighter">
                  <a *ngIf="fighter.licence_file" class="list-group-item text-left" target="_blank" href="{{globals.publicPathImage}}fighters/{{fighter.id_user}}/l/{{fighter.licence_file}}">{{"BUTTON.SHOW_LICENCE"|translate}}</a>
                  <p *ngIf="!fighter.licence_file">{{'TEXT.noLicence'|translate}}</p>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
</mdb-accordion>

        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button
          type="button"
          aria-label="Close"
          (click)="modalDisplayMedicalFolder.hide()"
          mdbBtn
          class="btn-close"
          outline="true"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION FIGHTCARD-->

<!--Modal envoyer un message à tout les combattants-->
<div
  mdbModal
  #modalSendMessage="mdbModal"
  class="modal fade top"
  id="modalSendMessage"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div style="border: 1px solid #dee2e6; padding: 1rem;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalSendMessage.hide()">
          <mdb-icon fas class="grey-text" icon="times"></mdb-icon>
        </button>
        <h4 class="modal-title w-100">{{ "TEXT.SEND_MESSAGE_ALL_FIGHTERS_TITLE" | translate }}</h4>
      </div>
      <hr>

      <!--Body-->
      <div *ngIf="sendMessageSuccess == false" class="modal-body" style="padding-top: 0;">
        <p>{{'TEXT.send_message_all_fighters_message' | translate}}</p>
				<form [formGroup]="sendMessageForm">
						<div class="form-row">
					  <div class="col">
							<div class="md-form md-outline">
								<ng-container>
								  <mdb-error *ngIf="sendMessageForm.status == 'INVALID' && sendMessageForm.get('message').touched">{{'FORM.NOT_EMPTY' | translate}}</mdb-error>
								</ng-container>
								<textarea formControlName="message" name="message" id="sendMessage" mdbInput class="form-control md-textarea" required></textarea>
								<label for="sendMessage">{{'FORM.YOUR_MESSAGE' | translate}}</label>
							</div>
              <!-- Partie pièce jointes -->
              <input type="file" id="file" name="picture" multiple style="display: none;" (change)="fileChange($event)">
              <label for="file" mdbBtn class="btn-black-outline">{{ 'BUTTON.ADD_ATTACHEMENT' | translate }}</label>
              <p *ngFor="let file of listFile; let index = index">
                {{ file.name }} ({{ (file.size / 1000000).toFixed(2) }}Mo)
                <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="deleteSendMessageFile(index)">
                  <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
                </button>
              </p>
              <p style="margin-top: 1rem; margin-bottom: 0rem;">{{ "TEXT.MAX_FILES_SIZE" | translate }}</p>
              <div class="progress" style="height: 0.25rem;">
                <div class="progress-bar" role="progressbar" [style.width]="((totalFilesSize/15000000)*100)+'%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
						</div>
					</div>
				</form>
      </div>
      <div *ngIf="sendMessageSuccess == true" class="modal-body">
        <div class="text-center" >
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn text-success"></mdb-icon>
          <p class="note note-success">{{'SUCCESS.mails_sent'|translate}}</p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">

        <button class="btn-close" mdbBtn (click)="modalSendMessage.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>

        <button *ngIf="!sendMessageEnCour && sendMessageSuccess == false" mdbBtn type="submit" class="relative btn-mma-blue" [disabled]="!sendMessageForm.valid || totalFilesSize > 15000000" (click)="sendMessage()">
          {{ "BUTTON.SEND" | translate }}
        </button>

        <button *ngIf="sendMessageEnCour" mdbBtn type="button" class="relative btn-mma-blue" disabled>
          <!--<mdb-spinner style="border-color: white"*ngIf="sendMessageEnCour" spinnerType="small"></mdb-spinner>-->
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;{{ "BUTTON.sending" | translate }}
        </button>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END modal envoyer un message a tout les combattants-->

<!--Modal résultats-->
<div mdbModal #modalResults="mdbModal" class="modal fade top" id="modalResults" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div style="border: 1px solid #dee2e6; padding: 1rem;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalResults.hide()">
          <mdb-icon fas class="grey-text" icon="times"></mdb-icon>
        </button>
        <h4 class="modal-title w-100">{{ "TEXT.inputResult" | translate }}</h4>
      </div>
      <hr>

      <!--Body-->
      <div *ngIf="resultsSuccess == false" class="modal-body" style="padding-top: 0;">
				<form [formGroup]="resultsForm">
						<div class="form-row">
					  <div class="col">
							<div class="md-form md-outline">
								<ng-container>
								  <mdb-error *ngIf="sendMessageForm.status == 'INVALID' && sendMessageForm.get('message').touched">{{'FORM.NOT_EMPTY' | translate}}</mdb-error>
								</ng-container>
								<textarea formControlName="message" name="message" id="sendMessage" mdbInput class="form-control md-textarea" required></textarea>
								<label for="sendMessage">{{'FORM.YOUR_MESSAGE' | translate}}</label>
							</div>
              <!-- Partie pièce jointes -->
              <input type="file" id="file" name="picture" multiple style="display: none;" (change)="fileChange($event)">
              <label for="file" mdbBtn class="btn-black-outline">{{ 'BUTTON.ADD_ATTACHEMENT' | translate }}</label>
              <p *ngFor="let file of listFile; let index = index">
                {{ file.name }} ({{ (file.size / 1000000).toFixed(2) }}Mo)
                <button type="button" data-dismiss="modal" class="close pull-right" aria-label="Close" (click)="deleteSendMessageFile(index)">
                  <mdb-icon fas icon="times" class="grey-text"></mdb-icon>
                </button>
              </p>
              <p style="margin-top: 1rem; margin-bottom: 0rem;">{{ "TEXT.MAX_FILES_SIZE" | translate }}</p>
              <div class="progress" style="height: 0.25rem;">
                <div class="progress-bar" role="progressbar" [style.width]="((totalFilesSize/15000000)*100)+'%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
						</div>
					</div>
				</form>
      </div>
      <div *ngIf="resultsSuccess == true" class="modal-body">
        <div class="text-center" >
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn text-success"></mdb-icon>
          <p class="note note-success">{{'SUCCESS.mails_sent'|translate}}</p> <!-- Modifier le message -->
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">

        <button class="btn-close" mdbBtn (click)="modalResults.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>

        <button *ngIf="!sendMessageEnCour && sendMessageSuccess == false" mdbBtn type="submit" class="relative btn-mma-blue" [disabled]="!sendMessageForm.valid || totalFilesSize > 15000000" (click)="sendMessage()">
          {{ "BUTTON.SEND" | translate }}
        </button>

        <button *ngIf="sendMessageEnCour" mdbBtn type="button" class="relative btn-mma-blue" disabled>
          <!--<mdb-spinner style="border-color: white"*ngIf="sendMessageEnCour" spinnerType="small"></mdb-spinner>-->
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;{{ "BUTTON.sending" | translate }}
        </button>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END résultats-->

<!--MODAL CANDIDATE DETAILS-->
<div
  mdbModal
  #modalCandidateDetails="mdbModal"
  class="modal fade left"
  id="modalCandidateDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title">
          {{ "TEXT.fighter_informations" | translate }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalCandidateDetails.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3" *ngIf="selectedFighterForDetails">
        <app-fighter-short-description
          [fighter]="selectedFighterForDetails"
        ></app-fighter-short-description>
      </div>

      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="btn btn-black-outline" (click)="modalCandidateDetails.hide()">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
