<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark black" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand" href="/home">
		<img src="../../../../../assets/img/logo_mma_orga.png" class="img-fluid flex-center" style="max-height:50px;"></a>
	</mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <ul class="navbar-nav mr-auto" *ngIf="!mustSubscribe">
      <!--<li class="nav-item active">
        <a class="nav-link waves-light" routerLink="/home">
					<mdb-icon fas
								icon="home"></mdb-icon>{{ 'NAVBAR.HOME' | translate }}</a>
      </li>-->
			<li class="nav-item">
        <a class="nav-link waves-light" routerLink="/home" routerLinkActive="active">{{ 'NAVBAR.FIGHT_CARD' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/fighters" routerLinkActive="active">{{ 'NAVBAR.COMBATTANTS' | translate}}</a>
      </li>
			<li class="nav-item">
        <a class="nav-link" routerLink="/managers" routerLinkActive="active">{{ 'NAVBAR.MANAGERS' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/chat" (click)="globals.haveNotification = false" routerLinkActive="active">{{ 'NAVBAR.MESSAGES' | translate}}</a>
        <div *ngIf="globals.haveNotification" class="rounded" style="background-color: red; width: 10px; height: 10px;"></div>
      </li>
      <li class="nav-item">
        <a class="btn btn-danger" style="padding: 0.375rem 0.75rem;" routerLink="/short-notice" routerLinkActive="active">SHORT NOTICE</a>
      </li>
      <!-- Dropdown --
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Basic dropdown<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
        </div>
      </li>-->

    </ul>
		<!-- Links -->

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <!--<li class="nav-item">
        <a class="nav-link d-flex" routerLink="/chat" (click)="globals.haveNotification = false">
          <mdb-icon far icon="paper-plane align-self-center"></mdb-icon>
          <div *ngIf="globals.haveNotification" class="rounded" style="background-color: red; width: 10px; height: 10px;"></div>
        </a>
      </li>-->
      <li class="nav-item">
        <a class="nav-link d-flex">
          <div class="btn-group" mdbDropdown>
            <mdb-icon far icon="user" mdbDropdownToggle></mdb-icon>
            <div class="dropdown-menu dropdown-menu-right dropdown-primary">
							<a class="dropdown-item" href="/my-account">{{ 'NAVBAR.MY_ACCOUNT' | translate }}</a>
              <a class="dropdown-item" href="/change-email">{{ 'NAVBAR.CHANGE_EMAIL' | translate }}</a>
              <a class="dropdown-item" href="/password">{{ 'NAVBAR.PASSWORD' | translate }}</a>
              <a class="dropdown-item" href="/subscription">{{ 'NAVBAR.MY_SUBSCRIPTION' | translate }}</a>
              <a class="dropdown-item" href="/my-orders">{{ 'NAVBAR.MY_ORDERS' | translate }}</a>
              <a class="dropdown-item" href="/contact">{{ 'NAVBAR.CONTACT_US' | translate }}</a>
              <a class="dropdown-item" (click)="logout()">{{'BUTTON.LOG_OUT' | translate }}</a>
              <!--<a class="dropdown-item" href="#">Something else here</a>
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>-->
            </div>
          </div>
        </a>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<div class="alert alert-danger text-center" role="alert" *ngIf="globals.freePeriodEnd && globals.freePeriodEnd<30">{{'TEXT.yourFreePeriodExpireIn' | translate}} <strong>{{globals.freePeriodEnd}} {{'WORD.DAYS'|translate}}</strong>. <a href="/subscription">{{'BUTTON.viewSubscription'|translate}}</a></div>
<!--/.Navbar-->

<!--
<script>
$(document).ready(function() {
	  // SideNav Button Initialization
	  $(".button-collapse").sideNav({
	    slim: true
	  });
	  // SideNav Scrollbar Initialization
	  var sideNavScrollbar = document.querySelector('.custom-scrollbar');
	  var ps = new PerfectScrollbar(sideNavScrollbar);
	})
</script>
-->
