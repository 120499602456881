import { Component, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';
//import { TranslateService } from '@ngx-translate/core';
import { TranslateService } from '../../../translate';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})

export class LoginComponent {

	loginForm: FormGroup;
	resetPasswordForm: FormGroup;
	validation_messages = {
		'email' : null,
		'password' : null
};
	public erreurMessage = '';
	public connexionRow: { email: string, password: string, remember_me: boolean };
	public errorResetMessage = '';

	@ViewChild("forgettenPasswordModal") forgottenPasswordModal: MDBModalRef;
	isLoading: boolean = false; //Validation de connexion en chargement

	constructor(public fb: FormBuilder,
		private authService: AuthService,
		private alertService: AlertService,
		public translate: TranslateService,
		private router:Router,
		public modalRef: MDBModalRef,) {
			this.validation_messages = {
				'email': [
					{ type: 'required', message: this.translate.instant('ERROR','REQUIRED_MAIL') },
					{ type: 'pattern', message: this.translate.instant('ERROR','INVALID_MAIL')}
				],
				'password': [
					{ type: 'required', message: this.translate.instant('ERROR','REQUIRED_PASSWORD') },
					{ type: 'minlength', message: this.translate.instant('ERROR','PASSWORD_TOO_SHORT')}
				]
			};

			this.loginForm = new FormGroup({
			'email': new FormControl(null, Validators.compose([
				Validators.required,
				Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
			])),
			'password': new FormControl('', Validators.compose([
				Validators.minLength(4),
				Validators.required
			])),
			'remember_me' : new FormControl(false)
			});

		/*this.loginForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			remember_me: [false],
		});*/

		this.resetPasswordForm = new FormGroup({
			reset_email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
		});
	}

	ngOnInit(): void {
		if(localStorage.getItem('mma-token-orga') && localStorage.getItem('isConnected') == "true"){
			//this.checkConnection = true;
		//check if authenticate on server side
		this.authService.user().subscribe(jsonResponse=>{
			//Connecté => redirection vers home page
			if(jsonResponse.total == 1 && jsonResponse.rows[0].email){
				if((jsonResponse.rows[0].id_subscription == null && moment().isAfter(jsonResponse.rows[0].free_until)) || (jsonResponse.rows[0].id_subscription && jsonResponse.rows[0].subscription_end_date)){
					this.router.navigate(['/subscription']);
				}else{
					this.router.navigate(['/home']);
				}
			}
		},error=>{

		},()=>{})
	}
	}

	connect() {
		this.isLoading = true;
		// fonction de connexion d'un utilisateur'
		if (this.loginForm.valid) {
			//Récupérations des données
			this.connexionRow = this.loginForm.getRawValue();
			//On remplace le mdp par sa version cryptée
			this.connexionRow.password = CryptoJS.SHA512(this.loginForm.getRawValue().password).toString();
			this.authService
				.login(this.connexionRow)
				.subscribe(jsonResponse => {
					if(jsonResponse.success){
					//stokage du token d'un utilisateur
					localStorage.setItem('roomId',jsonResponse.obj.roomId);
					localStorage.setItem('mma-token-orga', jsonResponse.obj.access_token);
					localStorage.setItem('isConnected',"true");
					//stokage du code profil de l'utilisateur
					//localStorage.setItem('code_profile', jsonResponse.obj.profile);

					//set la autentification à vrai
					//this.authguard.setConnected(true);

					//redirige vers la page d'accueil
					//this.router.navigateByUrl('/home');
					window.location.reload();

					//Le chargement de la connexion est fini
					this.isLoading = false;
				}else{
					this.erreurMessage = jsonResponse.msg;
					this.isLoading = false;
				}
				}, error => {
					//this.authguard.setConnected(false);
					let statusCode = error.status;
					switch (statusCode) {
						case 500: {
							this.erreurMessage = this.translate.instant('ERROR','ERROR_SERVER');
							break;
						}
						case 401: {
							this.erreurMessage = this.translate.instant('ERROR','ERROR_CREDENTIALS');
							break;
						}
						case 422: {
							this.erreurMessage = this.translate.instant('ERROR','ERROR_MAIL');
							break;
						}
					}
					this.isLoading = false;
				});
		} else {
			this.translate.instant('ERROR','ERROR_MAIL');
			this.isLoading = false;
		}

	}

	resetPassword(){
		if(this.resetPasswordForm.valid){
			this.authService.recoverPassword(this.resetPasswordForm.getRawValue()['reset_email'])
			.subscribe(jsonResponse => {
				if(jsonResponse.success){
					this.errorResetMessage = "";
					this.alertService.showSuccess(this.translate.instant('SUCCESS','SUCCESS_RESET_PASSWORD'),'Bravo !');
					//On actualise la page après un petit delay (pour avoir le temps de voir l'alerte de succes)
					//setTimeout(() => {  document.location.reload(); }, 3000);
					this.forgottenPasswordModal.hide();
				}else{
					this.errorResetMessage = this.translate.instant('ERROR','ERROR_MAIL');
				}
				}, error => {
					let statusCode = error.status;
					switch (statusCode) {
						case 500: {
							this.errorResetMessage = this.translate.instant('ERROR','ERROR_SERVER');
							break;
						}
						case 401: {
							this.errorResetMessage = this.translate.instant('ERROR','ERROR_CREDENTIALS');
							break;
						}
						case 422: {
							this.errorResetMessage = this.translate.instant('ERROR','ERROR_MAIL');
							break;
						}
					}
				});
		}else{
			this.errorResetMessage = this.translate.instant('ERROR','ERROR_MAIL');
		}
	}

	get email() { return this.loginForm.get('email'); }
}
