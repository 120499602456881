<app-navbar></app-navbar>
<div class="container mt-5">
<div class="row" *ngIf="manager != null" class="card-deck">
	<div class="col-md-6 col-lg-5 col-xl-5 col-sm-6 mt-4 mb-2">
  <!--Grid column-->
    <mdb-card class="testimonial-card">
  <!--  <div class="dropdown"  mdbDropdown>

		 	 <button  data-toggle="dropdown" data-boundary="window" mdbDropdownToggle mdbBtn color="black" class="waves-light float-right monbutton" style="margin-left:-30px" type="button" mdbWavesEffect><i class="fas fa-angle-down fa-2x"></i></button>

			  <div class="dropdown-menu dropdown-dark ">
			    <a class="dropdown-item" href="#">{{'BUTTON.ADD_TO_FIGHT'| translate}}</a>
			    <a class="dropdown-item" href="#">{{'BUTTON.CONTACT_MANAGER'| translate}} </a>
			    <a class="dropdown-item" href="#">{{'BUTTON.CONTACT_MANAGER_AND_FIGHTER'| translate}} </a>

			  </div>
		  </div>-->
      <!--Bacground color-->
			<div class="card-up unique-color-dark text-white pl-2">
				<strong>{{manager.nb_fighters}}</strong> {{'TEXT.managedFighters'|translate}}
			</div>
      <!--Avatar-->
      <div  class="avatar mx-auto">
	      	<img *ngIf="manager.profile_pic" src="{{globals.publicPathImage}}/pictures/{{manager.profile_pic}}" class="rounded-circle">
	      	<img *ngIf="!manager.profile_pic" src="./assets/img/logo-white-250.png" class="rounded-circle">
      </div>

      <mdb-card-body class="card-body ">
        <!--Name-->

				<mdb-card-title>
					<div class="text-center mb-3">
							<h4 class="mb-0"><a routerLink="/managers/{{manager.id_manager_profile}}">{{manager.enterprise_name}}</a></h4>
								<span *ngIf="manager.enterprise_name">({{manager.name}} {{manager.firstname}})</span>
						</div>
						<div class="d-flex">
							<div class="alert alert-danger flex-grow-1" role="alert" *ngIf="!manager.id_card_file || !manager.is_valid">
								{{'TEXT.UNVERRIFIED_PROFILE'|translate}}
							</div>
							 <div class="alert alert-success flex-grow-1" role="alert" *ngIf="manager.id_card_file && manager.is_valid">
								 {{'TEXT.VERRIFIED_PROFILE'|translate}}
							 </div>
							 <div class="ml-1">
									<div class="btn-group" mdbDropdown>
										<button mdbDropdownToggle type="button" class="contact-btn" mdbBtn>
											<mdb-icon far icon="paper-plane" class="mr-1"></mdb-icon>{{'BUTTON.write'|translate}}
										</button>
										<div class="dropdown-menu dropdown-dark ">
											<a class="dropdown-item" (click)="showContactForm()">{{'BUTTON.SEND_MAIL'|translate}}</a>
											<a class="dropdown-item" (click)="showMessageForm()">{{'BUTTON.SEND_MESSAGE'|translate}} </a>
										</div>
									</div>
								</div>
						</div>
					<h5 class="country"><i class="fas fa-map-marker-alt"></i>&nbsp;{{manager.label_country}} / {{manager.living_city}} <img src="assets/flags/{{manager.flag_country|lowercase}}.png" style="padding-bottom:3px;"/></h5>
				</mdb-card-title>
		<hr>
        <!--Quotation-->
        <p>
          <mdb-icon fas icon="quote-left"></mdb-icon> {{manager.description}}
				</p>
				<hr>
				<div class="row mt-2 mb-3 d-flex justify-content-center">
	        <!--Facebook-->
	        <a *ngIf="manager.facebook" href="{{manager.facebook}}" class="fa-lg p-2 m-2">
	          <mdb-icon fab icon="facebook" size="lg" class="grey-text" ></mdb-icon>
	        </a>
	        <!--Twitter-->
	        <a *ngIf="manager.twitter" href="{{manager.twitter}}" class="fa-lg p-2 m-2">
	          <mdb-icon fab icon="twitter" size="lg" class="grey-text"></mdb-icon>
	        </a>
					<!--Insta-->
	        <a  *ngIf="manager.instagram" href="{{manager.instagram}}" class="fa-lg p-2 m-2">
	        <mdb-icon fab icon="instagram" size="lg" class="grey-text"></mdb-icon>
	        </a>
					<a  *ngIf="manager.linkedin" href="{{manager.linkedin}}" class="fa-lg p-2 m-2">
	        <mdb-icon fab icon="linkedin" size="lg" class="grey-text"></mdb-icon>
	        </a>
      	</div>
				<ul *ngIf="manager.show_informations" style="list-style : none;padding-left:0px;" class="text-left">
						<li *ngIf="manager.phone"><a href="tel:{{manager.phone}}"><mdb-icon fas icon="phone-alt"></mdb-icon>&nbsp;{{manager.phone}}</a></li>
						<li *ngIf="manager.email"><a href="mailto:{{manager.email}}"><mdb-icon far icon="envelope"></mdb-icon>&nbsp;{{manager.email}}</a></li>
				</ul>
      </mdb-card-body>
    </mdb-card>
		</div>
    <!--COL FIGHTERS-->
    <div class="col-md-6 col-lg-7 col-xl-7 col-sm-6 mt-4 mb-2">
      <h5>{{'TEXT.HIS_FIGHTERS' | translate}}</h5>
      <div *ngIf="fightersList !=null && !isLoadingFighters">
        <div class="mb-4 p-2 z-depth-1" *ngFor="let fighter of fightersList" style="background:#FFFFFF;">
          <app-fighter-short-description [fighter]="fighter"></app-fighter-short-description>
        </div>

      </div>
      <div *ngIf="fightersList ==''"></div>
    </div>
  <!--Grid column-->
</div>
</div>

<!-- MODAL MAIL CONTACT-->
<div mdbModal #contactModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MAIL'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="contactModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="contactForm">
					<div class="md-form mb-5">
						<mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon>
						<input type="text" id="form32" class="form-control" mdbInput mdbValidate
							formControlName="subject">
						<label for="form32">{{'FORM.SUBJECT'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.subject">
							<mdb-error
								*ngIf="contactForm.get('subject').hasError(validation.type) && contactForm.get('subject').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="form8" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="form8">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="contactForm.get('message').hasError(validation.type) && contactForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn class="btn-mma-blue" (click)='submitContactForm()' [disabled]="!contactForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>Send
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>

<!-- MODAL MESSAGE CONTACT-->
<div mdbModal #messageModal="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
	aria-labelledby="myModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
				<h4 class="modal-title w-100 font-weight-bold">{{'TEXT.NEW_MESSAGE'|translate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="messageModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body mx-3">
				<form [formGroup]="messageForm">

					<div class="md-form md-outline">
						<mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
						<textarea type="text" id="form9" class="md-textarea form-control" rows="1" mdbInput mdbValidate
							formControlName="message"></textarea>
						<label for="form9">{{'FORM.YOUR_MESSAGE'|translate}}</label>
						<ng-container *ngFor="let validation of contact_validation_messages.message">
							<mdb-error
								*ngIf="messageForm.get('message').hasError(validation.type) && messageForm.get('message').touched">
								{{validation.message}}</mdb-error>
						</ng-container>
					</div>
				</form>

				<div class="red-text" *ngIf="errorResult">{{'ERROR.SENT_MESSAGE'|translate}}</div>
				<div class="green-text" *ngIf="successResult">{{'SUCCESS.SENT_MESSAGE'|translate}}</div>

			</div>

			<div class="modal-footer d-flex justify-content-center">
				<button mdbBtn class="btn-mma-blue" (click)='submitMessageForm()' [disabled]="!messageForm.valid || isSending">
					<mdb-spinner *ngIf="isSending" spinnerType="small"></mdb-spinner>{{'BUTTON.SEND'|translate}}
					<mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
				</button>
			</div>
		</div>
	</div>
</div>
