import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
/*import { TranslateModule, TranslateLoader,TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';*/
import { TRANSLATION_PROVIDERS, TranslateService }   from './translate';
import { TranslateModule } from './translate/translate.module';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
//import { RouterModule, Routes } from '@angular/router';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { Globals } from './globals';

import { HttpsRequestInterceptor } from './interceptor.module';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/users/login/login.component';
import { AlertConnexionComponent } from './components/alert/alert-connexion/alert-connexion.component';
import { RegisterComponent } from './components/users/register/register.component';
import { PasswordComponent } from './components/users/password/password.component';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from './sharedmodule/shared.module';
import { ModalAddFighterComponent } from './components/fight/modal-add-fighter/modal-add-fighter.component';
//import {FighterShortDescriptionComponent} from './components/fighter/fighter-short-description/fighter-short-description.component';
/*
const appRoutes: Routes = [
  { path: 'login', component: Login2Component },
    { path: '',   redirectTo: '/login', pathMatch: 'full' },
];*/
/*export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/
//TODO déplacer tout le module dans APP car conflit entre les 2 modules et les composants ne foncitonnent pas correctement
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/users/home/home.component';
import { UserRoutingModule } from './components/users/user-routing.module';
import { NavbarComponent } from './components/navbar/navbar.component';
//import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { CombattantsComponent } from './components/users/combattants/combattants.component';
import { FighterDetailsComponent } from './components/fighter/fighter-details/fighter-details.component';
import { CreateFighterComponent } from './components/fighter/create-fighter/create-fighter.component';
import { ManagersComponent } from './components/managers/managers.component';
import { DetailsComponent } from './components/managers/details/details.component';
import { CreateFightCardComponent } from './components/fight-card/create-fight-card/create-fight-card.component';
import { FightCardListComponent } from './components/fight-card/fight-card-list/fight-card-list.component';
import { ModalCreateFightCardComponent } from './components/fight-card/modal-create-fight-card/modal-create-fight-card.component';
import { FightListComponent } from './components/fight/fight-list/fight-list.component';
import {FightComponent} from './components/fight/fight/fight.component';
import { ModalCreateFightComponent } from './components/fight/modal-create-fight/modal-create-fight.component';
import { ModalDelFighterFromFightComponent } from './components/fight/modal-del-fighter-from-fight/modal-del-fighter-from-fight.component';
import {FormComponent} from './components/fight/form/form.component';
import {FighterShortDescriptionComponent} from './components/fighter/fighter-short-description/fighter-short-description.component';
import { ChangingEmailComponent } from './components/users/changing-email/changing-email.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

import { TimeAgoModule } from './pipes/time-ago.module';
import { DiscussionComponent } from './components/chat/discussion/discussion.component';
import { ChatComponent } from './components/chat/chat/chat.component';
import {ContactComponent} from './components/contact/contact.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {NgxImageCompressService} from 'ngx-image-compress'; //https://github.com/dfa1234/ngx-image-compress
import { NgxPayPalModule } from 'ngx-paypal';
import {PaypalPaiementComponent} from './components/paypal-paiement/paypal-paiement.component';
import {MyOrdersComponent} from './components/my-orders/my-orders.component';
import {SubscriptionComponent} from './components/subscription/subscription.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { keepHtmlModule } from './pipes/keep-html.module';
import { MobileAppSubscriptionComponent } from './components/mobile-app-subscription/mobile-app-subscription.component';
import { ShortNoticeComponent } from './components/short-notice/short-notice.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MomentDateModule } from './pipes/moment-date.module';
const globals = new Globals();
const config: SocketIoConfig = { url: globals.socketUrl, options: {} };

export function momentAdapterFactory() {
  return adapterFactory(moment);
};
registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertConnexionComponent,
    RegisterComponent,
    PasswordComponent,
    ChangingEmailComponent,
    ModalAddFighterComponent,
    HomeComponent,
    CreateFighterComponent,
    NavbarComponent,
    CombattantsComponent,
    FighterDetailsComponent,
    ManagersComponent,
    DetailsComponent,
    CreateFightCardComponent,
    FightCardListComponent,
    ModalCreateFightCardComponent,
    FightListComponent,
    FightComponent,
    FormComponent,
    ModalCreateFightComponent,
    ModalDelFighterFromFightComponent,
    FighterShortDescriptionComponent,
    ChatComponent,
    ContactComponent,
    DiscussionComponent,
    PaypalPaiementComponent,
    MyOrdersComponent,
    SubscriptionComponent,
    MobileAppSubscriptionComponent,
    ShortNoticeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
	  SharedModule,
    [FormsModule],
    AppRoutingModule,
    TranslateModule,
    CommonModule,
    UserRoutingModule,
    ToastrModule.forRoot(),
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    SocketIoModule.forRoot(config),
    TimeAgoModule,
    MomentDateModule,
    keepHtmlModule,
    PickerModule,
    NgxPayPalModule,
    NgxIntlTelInputModule,
    ImageCropperModule
     /*RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )*/

  ],
  providers: [
    MDBSpinningPreloader,
   {
      provide: HTTP_INTERCEPTORS,
      deps: [ Injector],
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    MDBModalRef,
    TranslateService,
    Globals,
    NgxImageCompressService,
    ReactiveFormsModule,
  TRANSLATION_PROVIDERS,
  TranslateService
  ],
  bootstrap: [AppComponent],
  schemas:   [ NO_ERRORS_SCHEMA ],
})
export class AppModule { }
