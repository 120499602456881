import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../../globals';
@Component({
  selector: 'app-fighter-short-description',
  templateUrl: './fighter-short-description.component.html',
  styleUrls: ['./fighter-short-description.component.scss']
})
export class FighterShortDescriptionComponent implements OnInit {
@Input() fighter:any=null;
  constructor(public globals : Globals) { }

  ngOnInit(): void {
    
  }

}
