<div class="h-100 ml-5 p-3 container" style="width:88%;">

    <!--Titre de la discussion-->

    <div class="row elegant-color pb-2 pt-2">
      <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1 pr-0">
        <img *ngIf="room.receiver_profile_pic" [src]="room.receiver_profile_pic" alt="avatar" class="rounded-circle" style="max-width:60%;">
        <svg style="color:#FFF;" *ngIf="!room.receiver_profile_pic" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="59" height="59" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5z" fill="currentColor"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm7.992 22.926A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="currentColor"/></svg>
      </div>
      <div class="col-9 col-sm-11 col-md-11 col-lg-11 col-xl-11 pl-0">
        <div *ngIf="room.user_id_creator != currentUser">
          <h6 class="text-white" *ngIf="!room.creator_fighter_name && !room.creator_manager_name">{{room.creator_orga_label}}</h6>
          <h6 class="text-white" *ngIf="room.creator_fighter_name">{{room.creator_fighter_name}} {{room.creator_fighter_firstname}}</h6>
          <h6 class="text-white" *ngIf="room.creator_manager_name">{{room.creator_manager_name}} {{room.creator_manager_firstname}}</h6>
        </div>
        <div *ngIf="room.user_id_receiver != currentUser">
          <h6 class="text-white" *ngIf="!room.receiver_fighter_name && !room.receiver_manager_name">{{room.receiver_orga_label}}</h6>
          <h6 class="text-white" *ngIf="room.receiver_fighter_name">{{room.receiver_fighter_name}} {{room.receiver_fighter_firstname}}</h6>
          <h6 class="text-white" *ngIf="room.receiver_manager_name">{{room.receiver_manager_name}} {{room.receiver_manager_firstname}}</h6>
        </div>
      </div>
    </div>

    <!--Partie avec les messages-->

    <!--Pendant le chargement des messages-->
    <div class="d-flex justify-content-center" *ngIf='isLoadingMessages'>
        <div class="spinner-grow text-info" role="status" style="width: 4rem; height: 4rem;">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="d-flex flex-column" style="overflow: auto;" [style] ="img !== null ? 'height: 60%;' : 'height: 80%;' " #scroll>
        <div *ngFor="let message of messagesList" class="d-flex flex-column my-2">
            <div class="message" [class] ="user != null && message.user_id == user.id_user ? 'sent-message' : 'received-message' ">
                <!--Supprimer message-->
                <i class="fas fa-times" style="cursor: pointer; display: flex; flex-direction: row-reverse;" (click)="showDeleteMessageModal(message.id_chat_message)"></i>
                <!--Text-->
                <div *ngIf="message.message!== null && message.message != ''" class="py-2 px-2">{{ message.message }}</div>
                <!--Images et autres fichiers-->
                <img *ngIf="message.image !== null && (getType(message.image) == 'img' || getType(message.image) == 'other')" class="img-chat" [ngClass]="{'mt-2': message.message != null && message.message !=''}" style="cursor: pointer;" [src]="getImagePath(message.image)" (click)="openFile(message.image)">
                <!--Audio file-->
                <audio *ngIf="message.image !== null && getType(message.image) == 'audio'" controls preload="metadata" [ngClass]="{'mt-2': message.message != null && message.message !=''}" [src]="getFilePath(message.image)"></audio>
                <!--Video file-->
                <video *ngIf="message.image !== null && getType(message.image) == 'video'" controls preload="metadata" class="img-chat" style="overflow: hidden;" [ngClass]="{'mt-2': message.message != null && message.message !=''}" [src]="getFilePath(message.image)"></video>
            </div>
            <div class="date-text" [class] ="user != null && message.user_id == user.id_user ? 'date-text-sent' : 'date-text-received' ">
                {{ message.create_datetime | timeAgo }}
            </div>
        </div>
    </div>

    <!--Partie envoie de message-->
    <div [style] ="img !== null ? 'height: 30%;' : 'height: 10%;'">
        <div *ngIf="imgPreview !== null" class="p-2 d-flex" style="height: 67%;">
            <img class="img-preview align-self-center" [src]="imgPreview">
            <a (click)="img = null; imgPreview = null" class="icon" *ngIf="img">
                <mdb-icon far icon="times-circle"></mdb-icon>
            </a>
        </div>
        <div class="d-flex chat-area-border" [style] ="img !== null ? 'height: 33%;' : 'height: 100%;'">
            <textarea [formControl]="messageArea" class="form-control message-area" placeholder="Message..."></textarea>
            <div class="d-flex" style="background-color: #ffffff;">
                <div (click)="send()" class="mx-2 align-self-center" style="cursor: pointer;"><img [src]="imgSend"></div>
                <div class="mx-2 align-self-center" style="cursor: pointer;">
                    <input type="file" id="file" name="picture" style="display: none;" (change)="fileChange($event)">
                    <!--declar for="file" permet d'appeler l'évement click du champ file ci-dessus-->
                    <label for="file" class="m-0" style="cursor: pointer;">
                        <img [src]="imgAddFile">
                    </label>
                </div>
                <!--Bouton smiley affiché que les ordi-->
                <div class="mx-2 align-self-center d-none d-sm-block" style="cursor: pointer;" (click)="modalIsOpen = true"><img [src]="imgSmiley"></div>
            </div>
        </div>
    </div>
</div>

<!--Modal de smiley-->
<div *ngIf="modalIsOpen" class="modal-zone">
    <a (click)="modalIsOpen = false" class="icon">
        <mdb-icon far icon="times-circle"></mdb-icon>
    </a>
    <emoji-mart
        title="{{ 'TEXT.deleteMessageTitle' | translate }}"
        emoji="point_up"
        isNative="true"
        (emojiClick)="addEmoji($event)">
    </emoji-mart>
</div>

<!--MODAL ALERT SUPPRESSION Message-->
<div mdbModal #modalDeleteMessage="mdbModal" class="modal fade top" id="modalDeleteMessage" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ "TEXT.deleteMessageTitle" | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalDeleteMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          {{ "TEXT.deleteMessage" | translate }}
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer">
        <button type="button" aria-label="Close" (click)="modalDeleteMessage.hide()" mdbBtn color="danger" outline="true">
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button type="submit" mdbBtn color="danger" class="relative" [disabled]="deleteMessageLoading" (click)="deleteMessage()">
          {{ "BUTTON.DELETE" | translate }}
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--END MODAL ALERT SUPPRESSION Message-->
