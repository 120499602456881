import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Globals } from './globals';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  headers: HttpHeaders;
  dupReq: HttpRequest<any>;

  constructor(private globals: Globals) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('mma-token-orga')) {
      this.headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('mma-token-orga'), 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
      //this.headers.set('Access-Control-Allow-Origin', 'http://localhost:8100');
      this.dupReq = req.clone({ headers: this.headers });
    } else {
      //this.headers = new HttpHeaders();
      this.dupReq = req.clone();
    }
    //const dupReq = req.clone({ headers: this.headers });
    return next.handle(this.dupReq);
  }
}
