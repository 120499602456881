import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '../translate';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastr: ToastrService,private translateService : TranslateService) { }

  showSuccess(message, title){
      this.toastr.success(message, title)
  }

  showError(message, title ){
      this.toastr.error(message, title)
  }

  showInfo(message, title){
      this.toastr.info(message, title)
  }
  showServerError(error) {
    let title = this.translateService.instant('ERROR','TITLE');
    let msg = this.translateService.instant('ERROR','OUPS_MESSAGE');
    if(error.status){
      switch(error.status){
        case 401:
          msg = "Erreur 401 : vous n'êtes pas connecté";
      }
    }
    this.toastr.error(msg, title);
  }
  showWarning(message, title){
      this.toastr.warning(message, title)
  }
  alertError(statusCode){
    let title = this.translateService.instant('ERROR','TITLE');
    let msg = this.translateService.instant('ERROR','OUPS_MESSAGE');
      switch(statusCode){
        case 500 :{
          title = this.translateService.instant('ERROR','ERROR_SERVER_TITLE');
          msg = this.translateService.instant('ERROR','ERROR_SERVER_MESSAGE');
          break;
          break;
        }
        //d�connect� du serveur
        case 401 :{
          title = this.translateService.instant('ERROR','DISCONNECTED_TITLE');
          msg = this.translateService.instant('ERROR','DISCONNECTED_MESSAGE');
          localStorage.setItem('jwt',null);
          break;
        }
      }
      this.showError(msg,title);
    }
    alertAuth(error){
      let statusCode = error.status
      switch (statusCode) {
        case 500 : {
          this.showError("Veillez réessayez ulterieurement","erreur server")
          break;
        }
        case 401 : {
          this.showError('Verifier vos identifiants et réessayez',"Mauvais identifiant")
          break;
        }
      }
    }
}


/*import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;
     constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }
/*
    alert(statusCode){
      switch(statusCode){
        case 500 :{

          break;
        }
        //d�connect� du serveur
        case 401 :{
          this.error("acc�s refuser ")
          break;
        }
      }
    }*/
    /*
    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'success', text: message });
    }

    error(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'error', text: message });
    }

    clear() {
        // clear by calling subject.next() without parameters
        this.subject.next();
    }
}*/
