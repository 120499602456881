<!--Avatar-->
<div class="row">
  <div class="avatar col-3">
      <img *ngIf="fighter.profile_pic"  src="{{globals.publicPathImage}}/pictures/{{fighter.profile_pic}}" class="rounded img-fluid">
      <img *ngIf="!fighter.profile_pic" src="./assets/img/default-profile.png" class="rounded img-fluid">
  </div>
<div class="col-9" >
  <mdb-badge *ngIf="!fighter.is_pro" info="true">AM</mdb-badge><span *ngIf="!fighter.is_pro"> {{fighter.label_exp_amateur}}</span>
  <mdb-badge *ngIf="fighter.is_pro" danger="true">PRO</mdb-badge>
  <h4><a routerLink="/fighters/{{fighter.id_fighter_profile}}">{{fighter.name}} {{fighter.firstname}}</a></h4>
  <div><small *ngIf="fighter.label_weight_category_1">{{fighter.label_weight_category_1}}</small><small *ngIf="fighter.label_weight_category_2"> - {{fighter.label_weight_category_2}}</small></div>
    <p *ngIf="fighter.nickname">"{{fighter.nickname}}"</p>
    <p>
      <a href="{{fighter.tapology}}" target="_blank" *ngIf="fighter.tapology">{{'TEXT.palmares'|translate}}</a>
      <span *ngIf="!fighter.tapology">{{'TEXT.NO_SHERDOG'|translate}}</span>
    </p>
    <div class="alert alert-danger" role="alert" *ngIf="!fighter.id_card_file || !fighter.is_valid">
      {{'TEXT.UNVERRIFIED_PROFILE'|translate}}
   </div>
   <div class="alert alert-success" role="alert" *ngIf="fighter.id_card_file && fighter.is_valid">
     {{'TEXT.VERRIFIED_PROFILE'|translate}}
  </div>
  <div *ngIf="fighter.is_pro" class="progress">
    <div class="progress-bar bg-success" role="progressbar"
      style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory}}</div>
    <div class="progress-bar bg-danger" role="progressbar"
      style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat}}</div>
    <div class="progress-bar bg-warning" role="progressbar"
      style="width: 33.33%" aria-valuenow="{{fighter.null_percent}}">D{{fighter.number_null}}</div>
  </div>
  <div *ngIf="!fighter.is_pro" class="progress">
    <div class="progress-bar bg-success" role="progressbar"
      style="width: 33.33%" aria-valuenow="{{fighter.victory_percent}}">W{{fighter.number_victory_amateur}}</div>
    <div class="progress-bar bg-danger" role="progressbar"
      style="width: 33.33%" aria-valuenow="{{fighter.defeat_percent}}">L{{fighter.number_defeat_amateur}}</div>
    <div class="progress-bar bg-warning" role="progressbar"
      style="width: 33.33%">D{{fighter.number_draw_amateur}}</div>
  </div>
</div>
</div>
