import { Component,OnInit,Renderer2 } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';
import { TranslateService } from './translate';
import { Router, NavigationStart } from '@angular/router';
import { ChatService } from './services/chat.service';
import { ChatRoomService } from './services/chat-room.service';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { Globals } from './globals'
import { SocketNotificationResult } from './models/socket-notification-result';
import * as moment from 'moment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'MMA Connection';
  isActive = true;
  previousUrl: any;
  notificationSound = new Audio('../assets/sound/notif.wav');
  thereIsNewMessage = false;
  constructor(
    private translateService: TranslateService,
    private renderer : Renderer2,
    private router: Router,
    private chatService: ChatService,
    private chatRoomService: ChatRoomService,
    private alertService: AlertService,
    private authService: AuthService,
    private globals: Globals) {
  /*this.translateService.addLangs(['en', 'fr']);
  this.translateService.setDefaultLang('en');

  const browserLang = this.translateService.getBrowserLang();
  this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');*/
  this.translateService.use(navigator.language);
  this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        let currentUrlSlug = event.url.slice(1)
        if (currentUrlSlug) {
          //ajoute l'url en tant que classe
          this.renderer.addClass(document.body, currentUrlSlug);
        }
        this.previousUrl = currentUrlSlug;
      }
    });

    //Si le user est connecté on récupère et stock ses infos
    if(localStorage.getItem('isConnected') == "true" && localStorage.getItem('mma-token-orga')){
      this.authService.user().subscribe(jsonResponse => {
				if(jsonResponse.success){
					let user = jsonResponse.rows[0];
          localStorage.setItem('roomId',jsonResponse.rows[0].room_id);
          //free_until date exceed and no subscription => redirect to subscription page
          //if(moment().isAfter(jsonResponse.rows[0].free_until) && !jsonResponse.rows[0].id_subscription){

          /*if(jsonResponse.rows[0].free_until == null || moment().isAfter(jsonResponse.rows[0].free_until)){
            if(jsonResponse.rows[0].id_subscription == null || !jsonResponse.rows[0].id_subscription){
              this.router.navigate(['/subscription']);
            }
          }*/
          let now = moment();
          //free_until date exceed and no subscription
          //si la perdiode de test se termine après aujourd'hui et que l'utilisateur n'a pas encore souscrit
          /*if(moment().isBefore(jsonResponse.rows[0].free_until) && !jsonResponse.rows[0].id_subscription){
              let endFreePeriod = moment(jsonResponse.rows[0].free_until);
              //this.timeTo = now.to(this.globals.userProfile.free_until,true);
              this.globals.freePeriodEnd = endFreePeriod.diff(now,'days');
          }*/

          //Initialisation du localstorage
          if(localStorage.getItem('chat') == null){
            let chatValue = {newMessages: false, rooms: {}};
            localStorage.setItem('chat', JSON.stringify(chatValue));
          }
          //Connexion socket (notification messages)
          this.chatService.connect();
          this.chatService.joinRoom(jsonResponse.rows[0].room_id);
          //On récupère toutes les chatRoom du user
          /*this.chatRoomService.getList().subscribe(jsonResponse=>{
            if(jsonResponse.success == true){
              //Récupération du local storage
              let chatValue = JSON.parse(localStorage.getItem('chat'));
              //On se connecte à chaque chatRoom
              jsonResponse.rows.forEach(room => {
                this.chatService.joinRoom(room.id_chat_room);
                //On met toutes les rooms dans le localstorage
                if(chatValue.rooms[room.id_chat_room] === null){
                  chatValue.rooms[room.id_chat_room] = "";
                  chatValue.newMessages = true;
                  this.thereIsNewMessage = true;
                }
                //Si un message du localStorage n'est pas le dernier message de la room
                if(chatValue.rooms[room.id_chat_room] !== room.message && room.message !== null){
                  chatValue.newMessage = true;
                  this.thereIsNewMessage = true;
                }
              });

              if(this.thereIsNewMessage == false){
                chatValue.newMessages = false;
              }else{
                this.globals.haveNotification = true;
              }

              //On met le localstrorage à jour
              localStorage.setItem('chat', JSON.stringify(chatValue));

            }else{
              this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
            }
          },error=>{
            this.alertService.alertError(error.status);
          });*/

          //Envoie de notification pour nouveau messages
          this.chatService.socket.fromEvent('notification').subscribe((notification:SocketNotificationResult) => {
            //Si le user n'est pas l'envoyeur
            if(notification.type="new-message"){
              //Notif
              this.alertService.showInfo("", notification.notification_message);
              this.notificationSound.play();
              this.globals.haveNotification = true;
            }
            let pageUrl = document.location.href.substr(document.location.href.length - 4); //4 derniers caractères de l'url
          });

				}
			},error=>{
        //non connecté=> redirection
        this.router.navigate(['/login']);

        //this.alertService.showError("ERROR" ,this.translateService.instant('ERROR','TITLE'));
			},() => {}
			)
    }

  }

  ngOnInit(){

  }

}
