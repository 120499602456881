import { Component, OnInit, ViewChild } from '@angular/core';
import {ReferentielService} from '../../services/referentiel.service';
import {AlertService} from '../../services/alert.service';
import {Globals} from '../../globals';
import {TranslateService} from '../../translate/translate.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AuthService } from '../../services/auth.service';
import { OrdersService } from '../../services/orders.service';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {IPayPalConfig,ICreateOrderRequest, IClientAuthorizeCallbackData } from 'ngx-paypal'; //https://github.com/Enngage/ngx-paypal
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  @ViewChild("modalUnsubscribe") modalUnsubscribe;
  @ViewChild("paypalModal") paypalModal;

  subscribing: boolean;
  isLoading: boolean = true;
  showError: boolean = false;
  subscription = {
    id_subscription : null,
    paypal_subscription_id : null,
    subscription_label : null,
    subscription_price : null,
    subscription_duration : null,
    subscription_price_excl : null,
    vat : null,
    free_until:null,
    subscription_start_date : null,
    subscription_end_date : null,
    subscription_renewal_date : null
  };
  errorMessage:string = null;
  ordersList: any;
  public payPalConfig ? : IPayPalConfig;
  transactionSuccess:boolean=null;
  transactionState : string = '';
  unsubscribing : boolean = false;

  listSubscription : Array<any> = [];
  selectedSubscription : any = null;

  constructor(
    private subscriptionService:SubscriptionService,
    private ordersService : OrdersService,
    private authService :AuthService,
    private referentielService:ReferentielService,
    private alertService:AlertService,
    public globals : Globals,
    private translateService:TranslateService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.authService.user().subscribe(jsonResponse => {
    this.isLoading = false;
    if(jsonResponse.success == true){
      this.showError = false;
      this.subscription.id_subscription = jsonResponse.rows[0].id_subscription;
      this.subscription.paypal_subscription_id = jsonResponse.rows[0].paypal_subscription_id;
      this.subscription.subscription_label = jsonResponse.rows[0].subscription_label;
      this.subscription.subscription_price = jsonResponse.rows[0].subscription_price;
      this.subscription.subscription_duration = jsonResponse.rows[0].subscription_duration;
      this.subscription.subscription_price_excl = jsonResponse.rows[0].subscription_price_excl;
      this.subscription.vat = jsonResponse.rows[0].vat;
      this.subscription.free_until = jsonResponse.rows[0].free_until;
      this.subscription.subscription_start_date = jsonResponse.rows[0].subscription_start_date;
      this.subscription.subscription_end_date = jsonResponse.rows[0].subscription_end_date;
      this.subscription.subscription_renewal_date = jsonResponse.rows[0].subscription_renewal_date;
      //si paypal enregistré, récupéraiton des paiements
      if(this.subscription.paypal_subscription_id != null){
        //this.ordersService.getMyOrders().subscribe(jsonResponse=>{
        this.ordersService.getMyPayments().subscribe(jsonResponse=>{
          this.isLoading = false;
          if(jsonResponse.success){
            if(jsonResponse.total > 0){
              this.ordersList = jsonResponse.rows;
              //console.log(this.ordersList);
            }
          }else{
            this.showError = true;
            this.errorMessage = jsonResponse.msg;
          }
        },error=>{
          this.showError = true;
          this.errorMessage = null;
        },()=>{})
      }else{ // Récupérer liste abonnement possibles
        this.subscriptionService.getListSubscriptions().subscribe(jsonResponse=>{
          if(jsonResponse.success){
            this.listSubscription = jsonResponse.obj;
            this.selectedSubscription = this.listSubscription[0];
            this.isLoading = false;
          }else{
            this.showError = true;
            this.errorMessage = jsonResponse.msg;
          }
        },error=>{
          this.showError = true;
          this.errorMessage = null;
        },()=>{})
      }
    }else{
      this.showError = true;
    }
  },error=>{
    this.isLoading = false;
    this.showError = true;
  },() => {}
  );
  this.initPaypalConfig();
}

  activate(){
    this.subscribing = true;
    this.subscriptionService.activatePaypalSubscription().subscribe(jsonResponse=>{
      this.subscribing = false;
      if(jsonResponse.success){
        //reload page
        window.location.reload();
      }else{
        this.alertService.showError(jsonResponse.msg, this.translateService.instant("ERROR","TITLE"));
      }
    },error=>{
      this.subscribing = false;
      this.alertService.showServerError(error.status);
    },()=>{});
  }

  async unsubscribe(){
    this.unsubscribing = true;
    this.subscriptionService.suspendSubscription().subscribe(jsonResponse=>{
      this.unsubscribing = false;
      if(jsonResponse.success){
        this.modalUnsubscribe.hide();
        //reload page
        window.location.reload();
      }else{
        this.alertService.showError(this.translateService.instant('error','title'),jsonResponse.msg);
      }
    },error=>{
      this.unsubscribing = false;
      this.alertService.showServerError(error.status);
    });
  }

  downloadPdf(orderId){
    let blob = this.ordersService.getBlob(orderId).subscribe(blobResponse=>{
      let blob:any = new Blob([blobResponse], { type: 'application/pdf; charset=utf-8' });
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  private initPaypalConfig(): void {
    let me = this;
    this.payPalConfig = {
        currency: this.translateService.instant('TEXT','currency'),
        clientId: this.globals.paypalClientId,
        vault : "true",
        createSubscription : (datas,actions)=> {
          //sandbox : 'P-9KE44033UD053412UMJYDWSQ' => gérer par le compte mmapro@mail.com => se connecter à https://www.sandbox.paypal.com/
          //prod : 'P-3FV18612G4523740JMJYDNHI'
          //TODO mettre l'id du vrai plan
          //return actions.subscription.create({plan_id : "P-9KE44033UD053412UMJYDWSQ"});
          return actions.subscription.create({plan_id : this.selectedSubscription.paypal_plan_id});
        },
    advanced: {
        commit: 'true'
    },
    style: {
        label: 'subscribe',
        layout: 'vertical',
        color : "black",
        shape : "pill"
    },
        onApprove: (data, actions) => {
          actions.subscription.get().then(details => {
              //STATUS = "APPROVED"
            let datas = new FormData();
              //datas.append('paypal_transaction_json',JSON.stringify(details));
              datas.append('paypal_subscription_id', data.subscriptionID);
              //datas.append('address',((details.payer.address.address_line_1)?details.payer.address.address_line_1:null));
              //datas.append('city',((details.payer.address.admin_area_2)?details.payer.address.admin_area_2:null));
              //datas.append('amount_paid_excl','49.90');
              //datas.append('amount_paid_incl','59.88');
              //datas.append('vat_mount','9.98');
              //datas.append('vat','20');//paypalDatas.purchase_units['items'].tax);
              datas.append('create_datetime',details.create_time);
              //datas.append('firstname',(details.payer.name.surname)?details.payer.name.surname:null);
              //datas.append('name',(details.payer.name.given_name)?details.payer.name.given_name:null);
              //datas.append('id_subscription',this.subscription.id_subscription);
              datas.append('id_subscription', this.selectedSubscription.id_subscription);
              //datas.append('zip_code',((details.payer.address.postal_code)?details.payer.address.postal_code:null));
              this.ordersService.createPaypalOrder(datas).subscribe(jsonResponse=>{
                if(jsonResponse.success == true){
                this.subscription.paypal_subscription_id = data.subscriptionID;
                  this.subscription.subscription_label = me.subscription.subscription_label;
                  this.subscription.subscription_price= me.subscription.subscription_price;
                  this.subscription.subscription_duration= me.subscription.subscription_duration;
                  this.subscription.vat= me.subscription.vat;
                  this.subscription.subscription_price_excl= me.subscription.subscription_price_excl;
                  this.subscription.subscription_start_date = moment().format('LLL');
                  //localStorage.setItem('user',JSON.stringify(me.globals.userProfile));
                  me.transactionState = this.translateService.instant('paypalTranscationState','approved');
                  me.transactionSuccess = true;
                  //reload page
                  //window.location.reload();
                  // Rediriger vers home
                  setTimeout(function(){
                    location.replace('/home');
                  },3000);
                }else{
                  me.transactionSuccess = false;
                }
              },error=>{
                me.transactionSuccess = false;
                me.alertService.showServerError(error.status);
              },()=>{});
          });
        },
        onCancel: (data, actions) => {
            this.transactionState = this.translateService.instant('paypalTranscationState','canceled');
        },
        onError: (err) => {
            this.alertService.showError(this.translateService.instant('error','title') ,err);
        },
        onClick: (data, actions) => {
          this.transactionState = this.translateService.instant('paypalTranscationState','authorisation');
        },
    };
  }

  openPaypalModal(){
    this.initPaypalConfig();
    this.paypalModal.show();
  }

}
