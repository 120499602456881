import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {FighterService} from '../../../services/fighters.service';
import { Globals } from "../../../globals";
import { TranslateService } from "../../../translate";
import { AlertService } from "../../../services/alert.service";
import { ReferentielService } from "../../../services/referentiel.service";
import {NgxImageCompressService,DOC_ORIENTATION} from 'ngx-image-compress'; //https://github.com/dfa1234/ngx-image-compress
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
@Component({
  selector: 'app-create-fighter',
  templateUrl: './create-fighter.component.html',
  styleUrls: ['./create-fighter.component.scss']
})
export class CreateFighterComponent implements OnInit {
  @Input('fightersList') fightersList;
  @Input('test') testVar;
  @Output() closeModal = new EventEmitter<any>();
searchForm = {
  name : null
}
isSaving : boolean = false;
showCreateForm : boolean = false;
  resultList: [] = null;
  sexList : []= null;
  countriesList: []=null;
  exp_amateurList: []=null;
  nationnalitiesList: any;
  fightWeightList: any;
  credentials = {
    name : null,
    firstname : null,
    nickname : null,
    birth_date : null,
    id_living_country : null,
    living_city : null,
    id_sex : null,
    language_spoken : null,
    id_nationnality : null,
    phone : null,
    profile_pic : null,
    tapology : null,
    height_feet : null,
    height_cm : null,
    is_pro : null,
    total_fight : null,
    number_defeat : null,
    number_null : null,
    number_victory : null,
    wins_ko : null,
    wins_decision : null,
    wins_submission : null,
    loss_ko : null,
    loss_decision : null,
    loss_submission : null,
    code_lang : null,
    weight_lbs : null,
    weight_kg : null,
    height_unit : "feet",
    weight_unit : "lbs",
    lastChallenger : null,
    lastResult : null,
    lastEvent : null,
    isInitProfile : false,
    idCard : null,
    img : null,
    facebook : null,
    instagram :null,
    twitter : null,
    video_link : null,
    show_informations : null,
    is_valid : null,
    id_weight_category_2 : null,
    id_weight_category_1 : null,
    hasNoProfile : true,
    isUpdatePicture : false,
    total_fight_amateur: null,
    number_victory_amateur: null,
    number_defeat_amateur: null,
    number_draw_amateur: null,
    id_exp_amateur: null,
  };
  weightCategoryList: any;
  imageChangedEvent: any = null;
    croppedImage: any = null;
  constructor(
    public translateService: TranslateService,
    private fighterProfileService: FighterService,
    private referentielService: ReferentielService,
    public globals: Globals,
  private alertService : AlertService,
  private imageCompress: NgxImageCompressService,
) {
  }

  ngOnInit(): void {
    this.getCountries();
    this.getWeights();
    this.getNationnality();
    this.getSexes();
    this.getExpAmateur();
  }
  getCountries() {
    this.referentielService.getCountries().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.countriesList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getWeights() {
    this.referentielService.getWeightCategories().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.fightWeightList = jsonResponse.rows;
          this.weightCategoryList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getSexes() {
    this.referentielService.getSex().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.sexList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getNationnality() {
    this.referentielService.getNationnalities().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.nationnalitiesList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }

  getExpAmateur() {
    this.referentielService.getExpRookie().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success) {
          this.exp_amateurList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }

  async createFighter(){
    this.isSaving = true;
    let f = new FormData();
    let credentialsToSend = this.credentials;
    if(this.credentials.is_pro == 1){
      credentialsToSend.is_pro = true;
    }else{
      credentialsToSend.is_pro = false;
    }
    f.append("datas",JSON.stringify(credentialsToSend));
    if(this.croppedImage){
    f.append("fighterPicture", await this.imageCompress.compressFile(this.croppedImage, DOC_ORIENTATION.NotDefined, 50, 50).then(
        result => {
          return result;
        }
      ));
    }
    this.fighterProfileService.addFighterProfile(f).subscribe(jsonResponse=>{
      this.isSaving = false;
      if(jsonResponse.success){
        this.resetPalmares();
        //reset form
        this.credentials = {
          name : null,
          firstname : null,
          nickname : null,
          birth_date : null,
          id_living_country : null,
          living_city : null,
          id_sex : null,
          language_spoken : null,
          id_nationnality : null,
          phone : null,
          profile_pic : null,
          tapology : null,
          height_feet : null,
          height_cm : null,
          is_pro : null,
          total_fight : null,
          number_defeat : null,
          number_null : null,
          number_victory : null,
          wins_ko : null,
          wins_decision : null,
          wins_submission : null,
          loss_ko : null,
          loss_decision : null,
          loss_submission : null,
          code_lang : null,
          weight_lbs : null,
          weight_kg : null,
          height_unit : "feet",
          weight_unit : "lbs",
          lastChallenger : null,
          lastResult : null,
          lastEvent : null,
          isInitProfile : false,
          idCard : null,
          img : null,
          facebook : null,
          instagram :null,
          twitter : null,
          video_link : null,
          show_informations : null,
          is_valid : null,
          id_weight_category_2 : null,
          id_weight_category_1 : null,
          hasNoProfile : true,
          isUpdatePicture : false,
          total_fight_amateur: null,
          number_victory_amateur: null,
          number_defeat_amateur: null,
          number_draw_amateur: null,
          id_exp_amateur: null,
        };
        this.fightersList.push(jsonResponse.obj[0]);
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','fighterAdded'),this.translateService.instant('SUCCESS','TITLE'));
        this.closeModal.emit(); // Fermer modal
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','title'));
      }
    },error=>{
      this.isSaving = false;
      this.alertService.showServerError(error);
    },()=>{});
  }

  //Filtre les catégories de poids de combats homme/femme
  filterWeightCategories(event){
    if(this.credentials.id_sex == 1){
      this.fightWeightList =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_men == true){
            return item;
          }
      });
    }
    if(this.credentials.id_sex == 2){
      this.fightWeightList =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_women == true){
            return item;
          }
      });
    }
  }
  controlSexSelection(){
    if(!this.credentials.id_sex){
      this.alertService.showError(this.translateService.instant("ERROR","selectSex"),this.translateService.instant('ERROR','TITLE'));
    }
  }
  convertWeightTo(to){
    if(to == 'kg'){
        this.credentials.weight_kg =  this.credentials.weight_lbs *0.453592
        this.credentials.weight_kg = Number.parseFloat(this.credentials.weight_kg).toFixed(2).toString();
    }if(to == 'lbs'){
        this.credentials.weight_lbs =  this.credentials.weight_kg *2.20462
        this.credentials.weight_lbs = Number.parseFloat(this.credentials.weight_lbs).toFixed(2).toString();
    }
  }
  convertHeight(to){
    if(to=="cm"){
      this.credentials.height_cm = Number.parseFloat(this.credentials.height_feet)*30.48;
      this.credentials.height_cm = Number.parseFloat(this.credentials.height_cm).toFixed(2).toString();
    }
    if(to=="ft"){
      this.credentials.height_feet = Number.parseFloat(this.credentials.height_cm)/30.48;
      this.credentials.height_feet = Number.parseFloat(this.credentials.height_feet).toFixed(2).toString();
    }
  }

  // Remet à null les champs qui n'ont pas besoin d'être remplis
  resetPalmares(){
    // Combattant pro
    if(this.credentials.is_pro == 1){
      this.credentials.id_exp_amateur = null;
      this.credentials.number_victory_amateur = null;
      this.credentials.number_defeat_amateur = null;
      this.credentials.number_draw_amateur = null;
    // Combattant amateur
    }else if(this.credentials.is_pro == 2){
      this.credentials.number_victory = null;
      this.credentials.number_defeat = null;
      this.credentials.number_null = null;
    }
  }
  fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
}
