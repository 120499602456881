<div class="background-page">
<div class="container-fluid h-100">
<!--  <div class="row">
<!--Section: logo + texte
<div class="form-light pt-4 ml-auto col-sm-12 col-md-12 col-lg-5 col-xl-5">
  <div class="text-center">
    <img src="../../../../assets/img/logo_mma_orga.png" width="60%" class="mb-5">
  </div>
</div>
</div>-->
<div class="row justify-content-center align-items-center h-100 pt-4">
  <!--login form : INFO ajouter ml-auto dans la class pour aligner à droite-->
<div class="col-sm-12 col-md-12 col-lg-5 col-xl-5">
  <mdb-card>
    <mdb-card-body class="mx-4">
      <div class="text-center">
        <img src="../../../../assets/img/logo_mma_orga.png" width="60%" class="mb-5">
      </div>
      <!--Header-->
      <div class="text-center">
        <h4 class="grey-text mb-5">
          {{'TEXT.WELCOME'|translate}}
        </h4>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="erreurMessage != '' ">{{erreurMessage}}</div>
      <!--Body-->
      <form [formGroup]="loginForm">
        <div class="md-form">
          <input type="text" id="email" class="form-control" placeholder="" formControlName="email" mdbValidate mdbInput required>
          <label for="email">{{ 'FORM.EMAIL' | translate }}</label>
          <ng-container *ngFor="let validation of validation_messages.email">
          <mdb-error *ngIf="loginForm.get('email').hasError(validation.type) && loginForm.get('email').touched">{{ validation.message }}</mdb-error>
          </ng-container>
        </div>

        <div class="md-form">
          <input type="password" id="password" class="form-control" placeholder="" formControlName="password" mdbInput mdbValidate required>
          <label for="password">{{ 'FORM.PASSWORD' | translate }}</label>
          <ng-container *ngFor="let validation of validation_messages.password">
          <mdb-error *ngIf="loginForm.get('password').hasError(validation.type) && loginForm.get('password').touched">{{ validation.message }}</mdb-error>
          </ng-container>
          <div class="grey-text mt-3 text-right" style="cursor: pointer;" (click)='forgettenPasswordModal.show()'>{{ 'FORM.FORGOTTEN_PASSWORD' | translate }} ?</div>
        </div>
        <div>
            <button type="submit" mdbBtn color="dark" block="true" rounded="true" class="z-depth-1 align-center" (click)="connect()" [disabled]="(!loginForm.valid || isLoading)">
              <i *ngIf="isLoading" class="fas fa-circle-notch fa-spin"></i>
              {{ 'BUTTON.LOGIN' | translate }}
            </button>
        </div>
      </form>

      <!--Grid row-->
      <div class="mt-4 text-center">
            <button type="button" [routerLink]="['/register']" outline="true" mdbBtn color="primary" block="true" rounded="true" class="z-depth-1 align-center">{{ 'BUTTON.SIGN_UP' | translate }}</button>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </mdb-card-body>
  </mdb-card>
  </div>
  </div>
  <!--/Form without header-->

<!--Modal-->
<div mdbModal #forgettenPasswordModal="mdbModal" class="modal fade top" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">{{'FORM.FORGOTTEN_PASSWORD' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="forgettenPasswordModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p class="m-auto pt-3">{{'TEXT.RESET_PASSWORD' | translate }}</p>
      <div class="alert alert-danger ng-star-inserted m-2" *ngIf='errorResetMessage'>{{errorResetMessage}}</div>
      <div class="modal-body mx-3">
        <div class="md-form bd-outline mb-5" [formGroup]="resetPasswordForm">
          <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
          <input id="reset-email" type="email" class="form-control" formControlName="reset_email" mdbInput mdbValidate>
          <label for="reset-email">{{'FORM.EMAIL' | translate }}</label>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="primary" rounded='true' class="waves-light" (click)="resetPassword()" mdbWavesEffect>{{'BUTTON.SEND' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!--Fin Modal-->

<!--Section: Live preview-->
</div>
<!--container-->
</div>
<!-- Background -->
