import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TranslateService} from '../../translate/translate.service';
import {AlertService} from '../../services/alert.service';
import {Globals} from '../../globals';
import {OrdersService} from '../../services/orders.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {
ordersList : null;
  constructor(
    private translateService:TranslateService,
    public globals : Globals,
    private alertService :AlertService,
    private ordersService:OrdersService,
  ) { }

  ngOnInit(): void {
    this.ordersService.getMyOrders().subscribe(jsonResponse=>{
      if(jsonResponse.success){
        this.ordersList = jsonResponse.rows;
      }else{
        this.alertService.showError(this.translateService.instant('error','title'),jsonResponse.msg);
      }
    },error=>{
      this.alertService.showServerError(error.status);
    },()=>{})
  }
  downloadPdf(orderId){
    let blob = this.ordersService.getBlob(orderId).subscribe(blobResponse=>{
      let blob:any = new Blob([blobResponse], { type: 'application/pdf; charset=utf-8' });
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });
  }



}
