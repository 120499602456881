import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals} from '../globals';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpClient : HttpClient, private globals: Globals) { }

  createPaypalOrder(datas) : Observable<any>{
    return this.httpClient.post(this.globals.baseUrl+'taapporders/createpaypalorder',datas);
  }
  createPaypalOrderForManager(datas,bearer) : Observable<any>{
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' +bearer, 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
    return this.httpClient.post(this.globals.baseUrl+'taapporders/createpaypalorder',datas,{headers : headers});
  }
  captureOrder(orderId) : Observable<any>{
    let httpParams = new HttpParams().append('orderId',orderId);
    return this.httpClient.post(this.globals.baseUrl+'paypal',httpParams);
  }
  getMyOrders(): Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'taapporders/getmyorders');
  }
  getMyPayments(): Observable<any>{
    return this.httpClient.get(this.globals.baseUrl+'taapporders/getmypayments');
  }
  getBlob(fightcardId): Observable<Blob> {
    return this.httpClient.get(this.globals.baseUrl+'taapporders/downloadpdf/'+fightcardId, {responseType: 'blob'})
        /*.subscribe(async (event: HttpEvent<Blob>) => {
          if (event.type === HttpEventType.DownloadProgress) {

            } else if (event.type === HttpEventType.Response) {
              resolve(event.body);
            }
        }, (err: HttpErrorResponse) => {
          reject(err);
        },()=>{});*/
  }
}
