import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(public socket: Socket,private httpClient: HttpClient,private globals: Globals) {}
  public connect(){
    this.socket.connect();
  }

  //Envoie un message dans une conversation privée
  public sendMessage(message: any) {
    this.socket.emit('send-to', message);
  }

  //Rejoindre une conversation privée
  public joinRoom(roomId: any) {
    this.socket.emit('join-room', roomId);
  }

  public searchRoomForUser(datas):Observable<any> {
    return this.httpClient.post(this.globals.baseUrl+"searchroom",datas);
  }

  public deleteMessage(id):Observable<any>{
    return this.httpClient.delete(this.globals.baseUrl+"delete-message/"+id);
  }
}
