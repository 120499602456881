import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(private httpClient: HttpClient, private globals: Globals) { }

  public gets(searchParams):Observable<any>{
    if(searchParams){
      return this.httpClient.get(this.globals.baseUrl+"taappclub/search",searchParams);
    }else{
      return this.httpClient.get(this.globals.baseUrl+"taappclub/search");
    }

  }
}
