import { Component, OnInit,ViewEncapsulation, ViewChild } from '@angular/core';
  import {
  CalendarEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarMonthViewBeforeRenderEvent
} from 'angular-calendar'; //https://angular-calendar.com
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../globals';
import { AlertService } from '../../../services/alert.service';
import { FighterService } from '../../../services/fighters.service';
import { TranslateService } from '../../../translate/';
import { UnavailabilityDatesService } from '../../../services/unavailability-dates.service';
import { Subject } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ContactService } from "../../../services/contact.service";
import { ChatRoomService } from "../../../services/chat-room.service";
import { ModalDirective } from "ng-uikit-pro-standard";
import { TaAppCardFighterService } from "../../../services/ta-app-card-fighter.service";
import { TaAppFightService } from "../../../services/ta-app-fight.service";
import { ChatService } from '../../../services/chat.service';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-fighter-details',
  templateUrl: './fighter-details.component.html',
   encapsulation: ViewEncapsulation.None,
  styleUrls: ['./fighter-details.component.scss']
})
export class FighterDetailsComponent implements OnInit {
  fighter: any;
  isLoading : boolean = true;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  events : CalendarEvent[] = [];
  allEvents: any;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean;
  locale : string = 'en';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  contactForm: FormGroup;
  messageForm: FormGroup;
  addToFightForm: FormGroup;
  isSending: boolean = false;
  @ViewChild("contactModal") contactModal: ModalDirective;
  @ViewChild("addToFightModal") addToFightModal: ModalDirective;
  @ViewChild("messageModal") messageModal: ModalDirective;
  contact_validation_messages = {
    message: null,
    subject: null,
  };
  contactType: any;
  errorResult: boolean;
  successResult: boolean;

  //
  fightcardList: any;
  fightList: any;
  selectedFighter: any;
  selectedManager: any;
  //

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private unavailabilityDatesService:UnavailabilityDatesService,
    public globals: Globals,
    private alertService : AlertService,
    private fightersService : FighterService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    private contactService: ContactService,
    private chatRoomService: ChatRoomService,
    private fightcardService: TaAppCardFighterService,
    private fightService: TaAppFightService,
    private chatService : ChatService
  )
  {
    this.contactForm = new FormGroup({
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
    });
    this.messageForm = new FormGroup({
      message: new FormControl("", Validators.required),
    });
    this.contact_validation_messages = {
      message: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
      subject: [
        {
          type: "required",
          message: this.translateService.instant("ERROR", "FIELD_REQUIRED"),
        },
      ],
    };
    this.addToFightForm = new FormGroup({
      id_fightcard: new FormControl("", Validators.required),
    });
   }

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    let id = this.route.snapshot.paramMap.get('id');
    this.fightersService.getProfile(id).subscribe(jsonResponse=>{
      this.isLoading = false;
      if(jsonResponse.success == true){
        this.fighter = jsonResponse.rows[0];
        /*this.fighter.victory_percent = ((this.fighter.number_victory != null)?this.fighter.number_victory/this.fighter.total_fight * 100:0);
        this.fighter.defeat_percent = ((this.fighter.number_defeat != null)?this.fighter.number_defeat/this.fighter.total_fight * 100:0);
        this.fighter.null_percent = ((this.fighter.number_null != null)?this.fighter.number_null/this.fighter.total_fight * 100:0);*/
        //récupération des indispo
        this.unavailabilityDatesService.getAll(this.fighter.id_user).subscribe(jsonResponse=>{
          if(jsonResponse.success == true){
            this.convertDateToArray(jsonResponse.rows);
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{});
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }

    },error=>{
      this.isLoading = false;
      this.alertService.alertError(error.status);
    });

  }
  convertDateToArray(datesList){
    let me = this;
    let eventArray = [];
    //construction d'un tableau de mois avec date début et date de fin
    datesList.forEach(function(value,index,array){
      //ajout au calendrier
      let event :CalendarEvent = {
        title: me.translateService.instant('TEXT','UNAVAILABLE'),
        start:  new Date(value.unavailability_date),
        end: new Date(value.unavailability_date),
        color: colors.red
      }
      eventArray.push(event);
    });
    this.events = eventArray; //NOTE : this.events.push(event) ne donne rien
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  //Changement de la couleur de fond de la case indisponibilité
beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
  //https://angular-calendar.com/#/before-view-render
    renderEvent.body.forEach((day) => {
      if (day.events.length>0) {
        day.cssClass = 'bg-not-available';
      }
    });
  }

  showContactForm(contactType,fighterId,managerId) {
    this.selectedFighter = fighterId;
    this.selectedManager = managerId;
    this.contactType = contactType;
    this.contactModal.show();
  }
  submitContactForm() {
    this.isSending = true;
    let datas = this.contactForm.getRawValue();
    datas.id_fighter_profile = this.fighter.id_fighter_profile; //A Vérifier
    datas.id_manager_profile = this.fighter.id_manager_profile; //A Vérifier
    datas.contactType = this.contactType;
    this.contactService.send(datas).subscribe(
      (jsonResponse) => {
        this.isSending = false;
        if (jsonResponse.success) {
          this.successResult = true;
        } else {
          this.errorResult = true;
        }
      },
      (error) => {
        this.errorResult = true;
        this.alertService.alertError(error.status);
        this.isSending = false;
      }
    );
  }

  showAddToFightModal() {
    this.addToFightModal.show();
    this.fightcardService.getListFightCard().subscribe(
      (jsonResponse) => {
        if (jsonResponse.success == true) {
          this.fightcardList = jsonResponse.rows;
        } else {
          this.alertService.showError(
            jsonResponse.msg,
            this.translateService.instant("ERROR", "TITLE")
          );
        }
      },
      (error) => {
        this.alertService.alertError(error.status);
      }
    );
  }
  getFightList() {
    let searchParams = {
      id_fightcard: this.addToFightForm.get("id_fightcard"),
    };
    this.fightService
      .getListFights(this.addToFightForm.getRawValue())
      .subscribe(
        (jsonResponse) => {
          if (jsonResponse.success == true) {
            this.fightList = jsonResponse.rows;
          } else {
            this.errorResult = true;
            this.alertService.showError(
              jsonResponse.msg,
              this.translateService.instant("ERROR", "TITLE")
            );
          }
        },
        (error) => {
          this.errorResult = true;
          this.alertService.alertError(error.status);
        }
      );
  }


  showMessageForm(contactType,fighterId,managerId) {
    if(contactType == "manager" && this.fighter.manager_room_id == null){
      this.alertService.showError(this.translateService.instant('ERROR','MANAGER'), this.translateService.instant('ERROR','TITLE'));
    }else{
      this.selectedFighter = fighterId;
      this.selectedManager = managerId;
      this.contactType = contactType;
      this.messageModal.show();
    }
  }

  submitMessageForm() {
    this.isSending = true;
    let datas = this.messageForm.getRawValue();
    datas.id_fighter_profile = this.selectedFighter;
    datas.id_manager_profile =this.selectedManager;
    datas.contactType = this.contactType;
    //Récupération de la room en fonction du contact sélectionné
    this.chatService.searchRoomForUser(datas).subscribe(jsonResponse=>{
      this.isSending = false;
    if(jsonResponse.success){
      //on envoie le message dans la room déjà créée
      if(jsonResponse.total > 0 && jsonResponse.rows[0].id_chat_room !=undefined){
        datas = {
          message : this.messageForm.get('message').value,
          user_creator_room_id : jsonResponse.rows[0].user_id_creator,
          id_chat_room : jsonResponse.rows[0].id_chat_room,
          user_id_receiver : jsonResponse.rows[0].user_id_receiver
        };
        this.sendInChatRoom(datas);
      }
      //on créée une nouvelle room et on envoie le message
      else{
        //stockage du roomId du user à contacter. On reçoit les informations sur le contact uniquement s'il n'y a pas de room existante
        let userRoomId = jsonResponse.rows[0].room_id;
        this.chatRoomService.createRoom(jsonResponse.rows[0].room_id).subscribe(jsonResponse=>{
          //succes création du message
          if(jsonResponse.success){
            datas = {
              message : this.messageForm.get('message').value,
              user_creator_room_id : jsonResponse.obj.user_creator_room_id,
              id_chat_room : jsonResponse.id,
              user_id_receiver : userRoomId
            };
            //envoi du message
            this.sendInChatRoom(datas);
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        },()=>{});
      }
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
    }
    },error=>{
      this.isSending = false;
    this.alertService.alertError(error.status);
    },()=>{});
  }
  sendInChatRoom(datas){
    let formDatas:FormData = new FormData();
    formDatas.append('id_chat_room', datas.id_chat_room);
    formDatas.append('message', datas.message);
    formDatas.append('receiver_room_id', datas.user_id_receiver);
    this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.chatService.sendMessage(
          { roomId: datas.id_chat_room,
            message: datas.message,
            user_room_id:"",
            receiver_room_id : datas.user_id_receiver,
            create_datetime: new Date(),
            imageFromSocket : null
          }
        );
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','MESSAGE_SENT'), this.translateService.instant('SUCCESS','TITLE'));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }

}
