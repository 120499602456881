<app-navbar></app-navbar>

<div class="container">

<!--Section: Live preview-->
<section class="form-light mt-4">

  <!--Form without header-->
  <mdb-card>

    <mdb-card-body class="mx-4">

      <!--Header-->
      <div class="text-center">
        <h3 class="text-color-mma-black mb-5">
          <strong>{{ 'FORM.TITLE_CHANGING_EMAIL' | translate }}</strong>
        </h3>
        <p>{{'TEXT.CHANGING_EMAIL'|translate}}</p>
      </div>

      <!--Body-->

      <form [formGroup]="emailForm" (ngSubmit)="updateEmail()">
        <!--Email-->
        <div class="md-form md-outline">
            <mdb-icon far icon="envelope" class="prefix grey-text"></mdb-icon>
            <input type="email" id="oldEmail" placeholder="" class="form-control" formControlName="oldEmail" mdbInput mdbValidate required>
            <label for="oldEmail">{{ 'FORM.actualEmailRequired' | translate }}</label>
            <!--<mdb-error *ngIf="password.invalid">{{ 'FORM.NOT_EMPTY_PASSWORD' | translate }}</mdb-error>-->
        </div>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.oldEmail">
            <div class="error-message-box" *ngIf="emailForm.get('oldEmail').hasError(validation.type) && emailForm.get('oldEmail').touched">
              <i class="fas fa-info-circle"></i>&nbsp;
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
        <!--New Email-->
        <div class="md-form md-outline">
            <mdb-icon far icon="envelope" class="prefix grey-text"></mdb-icon>
            <input type="email" id="newEmail" placeholder="" class="form-control" formControlName="newEmail" mdbInput mdbValidate required>
            <label for="newEmail">{{ 'FORM.newEmailRequired' | translate }}</label>
            <!--<mdb-error *ngIf="password2.invalid">{{ 'FORM.NOT_EMPTY_PASSWORD' | translate }}</mdb-error>-->
        </div>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.newEmail">
            <div class="error-message-box" *ngIf="emailForm.get('newEmail').hasError(validation.type) && emailForm.get('newEmail').touched">
              <i class="fas fa-info-circle"></i>&nbsp;
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
        <!--Password-->
        <div class="md-form md-outline">
            <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
            <input type="password" id="password" placeholder="" class="form-control" formControlName="password" mdbInput mdbValidate required>
            <label for="password">{{ 'FORM.PASSWORD' | translate }}</label>
        </div>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.password">
            <div class="error-message-box" *ngIf="emailForm.get('password').hasError(validation.type) && emailForm.get('password').touched">
              <i class="fas fa-info-circle"></i>&nbsp;
              <span>{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
        <div class="text-center">
          <button type="submit" mdbBtn rounded="true" class="z-depth-1 waves-light btn-mma-black" [disabled]="!emailForm.valid || isSaving">
            <div class="spinner-border text-light" role="status" *ngIf="isSaving">
              <span class="sr-only">Loading...</span>
            </div>
            <span *ngIf="!isSaving">{{ 'BUTTON.CONFIRM_CHANGING' | translate }}</span></button>
        </div>
      </form>

      <!--Grid row-->
      <!--<div class="row d-flex justify-content-center ">
        <div class="col-6 ">
          <button type="button" mdbBtn block="true" rounded="true" class="z-depth-1 waves-light btn-mma-black">{{ 'FORM.CHANGE_PASSWORD' | translate }}</button>
        </div>
      </div>-->
      <!--Grid row-->
    </mdb-card-body>

  </mdb-card>
  <!--/Form without header-->

</section>
<!--Section: Live preview-->
</div>
<!--container-->
