import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaAppFightService {

  constructor(private http: HttpClient, private globals: Globals) { }
  private fightUrl = this.globals.baseUrl + 'taappfight';  // URL to web api
	/** POST: add a new Country to the server */
  add(fight): Observable<any> {
    return this.http.post(this.fightUrl, fight);
  }
  public getListFights(searchParams):Observable<any>{
    const body = JSON.stringify(searchParams);
    return this.http.post(this.fightUrl+"/gets",body);
  }
  updateFighterFromFight(updateparams): Observable<any> {
	const params = JSON.stringify(updateparams);
    return this.http.post(this.fightUrl + '/updatefighters', params);
  }
  delete(id): Observable<any>{
    return this.http.delete(this.fightUrl +'/'+id);
  }
  update(fight):Observable<any>{
    return this.http.post(this.fightUrl+'/update',fight);
  }
  removeFighterFromFight(deleteParams): Observable<any>{
    const params = JSON.stringify(deleteParams);
      return this.http.post(this.fightUrl + '/deletefighters', params);
  }
  declineFighterProposal(id): Observable<any>{
    let formData = new FormData();
    formData.append('id_fight_claim',id);
      return this.http.post(this.globals.baseUrl + 'fightclaim/refuserequest', formData);
  }
	private log(message: string) {
  	}
}
