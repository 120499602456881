
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import { TaAppCardFighterService } from '../../../services/ta-app-card-fighter.service';
import { AuthService } from '../../../services/auth.service';
import { Globals } from '../../../globals';
//import { ModalCreateFightCardComponent } from '../modal-create-fight-card/modal-create-fight-card.component';
import { CreateFightCardComponent } from '../create-fight-card/create-fight-card.component';
import * as moment from 'moment';
//import { TranslateService } from '@ngx-translate/core';
import { TranslateService } from '../../../translate';
import { Router } from '@angular/router';
import { TransfereServiceService } from '../../../services/transfere-service.service';
import {FightCardProvider} from '../../../providers/fightcard.provider';
import {AlertService} from '../../../services/alert.service';



@Component({
  selector: 'app-fight-card-list',
  templateUrl: './fight-card-list.component.html',
  styleUrls: ['./fight-card-list.component.scss']
})
export class FightCardListComponent implements OnInit {
	public erreurMessage: string;
  	msgNoResult: any;
	listFightCards: any = [];
  listPastFightCards: any = [];
	user: any;
	modalRef: MDBModalRef;
  deleteForm : FormGroup;
  validation_messages: {confirm_field: { type: string; message: any; }[]; message: { type: string; message: any; }[]; };
  @ViewChild("modalDeleteFightcard") modalDeleteFightcard;
  @ViewChild("modalUnpublishFightcard") modalUnpublishFightcard;
  @ViewChild("modalPublishFightcard") modalPublishFightcard;
  @ViewChild("modalCompleteProfile") modalCompleteProfile;
  @ViewChild("modalSendMessage") modalSendMessage;
  transactionSuccess : boolean = null;
  transactionSaving : boolean = null;
	public filter = {
			id_organisation_profile : null
		  }
  selectedFightcard: any=null;
  sendMessageForm : FormGroup;
  sendMessageEnCour : boolean; // Bloque le bouton après envoie de la requête
  sendMessageSuccess = false;
  totalFilesSize : number = 0;
  listFile : Array<File> = [];

  constructor(
    private alertService : AlertService,
    public fb: FormBuilder, public translateService: TranslateService,private fightCardService: TaAppCardFighterService,
			  private authService: AuthService,public globals: Globals, private modalService: MDBModalService,
			  private transfereService:TransfereServiceService,
    		private router:Router,
        private fightCardProvider:FightCardProvider) {

          this.validation_messages = {
            'confirm_field': [
              { type: 'required', message: this.translateService.instant('formControl','hitDeleteWord') },
            ],
            'message': [
              { type: 'required', message: this.translateService.instant('formControl','messageRequired') },
            ],
          };
          this.deleteForm = new FormGroup({
            'confirm_field': new FormControl('', Validators.compose([
              Validators.minLength(1),
              Validators.required
            ])),
            'message': new FormControl('', Validators.compose([
              Validators.minLength(1),
              Validators.required
            ])),
          });
          this.sendMessageForm = new FormGroup({'message': new FormControl('', Validators.compose([Validators.minLength(1), Validators.required]))});
          this.sendMessageEnCour = false;
        }

  ngOnInit(): void {
    //création du souscripteur à la liste de fightcards
    this.fightCardProvider.serviceListDatas.subscribe(datas=>{
      this.listFightCards = datas;
    });
	//this.getMe();
	this.getFightCards();
	/*this.translateService.get(['ERROR.NO_RESULT'])
    .subscribe(translations => {
	  this.msgNoResult = translations['ERROR.NO_RESULT'];

  });*/
  }
	getFightCards(){
		this.listFightCards = [];
		this.fightCardService
			.getListFightCard(this.filter)
			.subscribe(
				jsonResponse => {
          if(jsonResponse.success){
					// si la requete est un succés.
          this.listFightCards = jsonResponse.rows;
          //mise à jour de la liste via le souscripteur
          this.fightCardProvider.changeListeDatas(this.listFightCards);
					/*let fighters = jsonResponse.rows;
					if(fighters.length != 0){
						for (let i = 0; i <= fighters.length - 1; i++) {
							fighters[i].datetime_event = moment(fighters[i].datetime_event).format('L');
							this.listFightCards.push(fighters[i]);
						}
					}else{
							this.erreurMessage = this.msgNoResult;
					}*/
        }else{
          this.erreurMessage = jsonResponse.msg;
        }
				}, error => {
					//error
				}
			);
	}
  showPublishFightcardModal(fightCard){
    this.selectedFightcard = fightCard;
    this.modalPublishFightcard.show();
  }

confirmPublishFightcard(){
  let formDatas = new FormData();
  formDatas.append('id_fightcard',this.selectedFightcard.id_fightcard);
  this.fightCardService.publishFightcard(formDatas).subscribe(jsonResponse=>{
    if(jsonResponse.success){
      let index = this.listFightCards.indexOf(this.selectedFightcard);
      this.listFightCards[index].published = true;
      this.fightCardProvider.changeListeDatas(this.listFightCards);
      this.modalPublishFightcard.hide();
      this.alertService.showSuccess(this.translateService.instant("SUCCESS","publishedFightCard"),this.translateService.instant("SUCCESS","TITLE"));
    }else{
      this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
    }
  },error=>{
    this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
  },()=>{});
}

  /*Display confirm unpublish modal*/
  showUnpublishFightcardModal(fightCard){
    this.selectedFightcard = fightCard;
    this.modalUnpublishFightcard.show();
  }
  /*Call unpublish action*/
  confirmUnpublish(){
    let formDatas = new FormData();
    formDatas.append('id_fightcard',this.selectedFightcard.id_fightcard);
    this.fightCardService.unpublishFightcard(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        let index = this.listFightCards.indexOf(this.selectedFightcard);
        this.listFightCards[index].published = false;
        this.fightCardProvider.changeListeDatas(this.listFightCards);
        this.modalUnpublishFightcard.hide();
        this.alertService.showSuccess(this.translateService.instant("SUCCESS","unpublishedFightCard"),this.translateService.instant("SUCCESS","TITLE"));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
      }
    },error=>{
      this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
    },()=>{});
  }
  /*Display confirm modal*/
  showDeleteFightcardModal(fightCard){
    this.selectedFightcard = fightCard;
    this.modalDeleteFightcard.show();
  }
  showSendMessageModal(fightCard){
    this.sendMessageSuccess = false;
    this.selectedFightcard = fightCard;
    this.modalSendMessage.show();
  }
  /*Call delete action*/
  confirmDelete(){
    if(this.deleteForm.get('confirm_field').value.toLowerCase() != "delete"){
      this.alertService.showError(this.translateService.instant("ERROR","hitDeleteWord"),this.translateService.instant("ERROR","TITLE"));
      return;
    }
    let formDatas = new FormData();
    formDatas.append('id_fightcard',this.selectedFightcard.id_fightcard);
    formDatas.append('message',this.deleteForm.get('message').value);
    this.fightCardService.deleteFightcard(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        let index = this.listFightCards.indexOf(this.selectedFightcard);
        this.listFightCards.splice(index,1);
        this.fightCardProvider.changeListeDatas(this.listFightCards);
        this.modalDeleteFightcard.hide();
        this.deleteForm.reset();
        this.alertService.showSuccess(this.translateService.instant("SUCCESS","deletedFightCard"),this.translateService.instant("SUCCESS","TITLE"));
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
      }
    },error=>{
      this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
    },()=>{});
  }
	/*getMe(){
		this.authService.user().subscribe(
				jsonResponse => {
					// si la requete est un succés.
					console.log(jsonResponse.rows);
					this.user = jsonResponse.rows[0];
					console.log(this.user);
					this.filter.id_organisation_profile=this.user.id_organisation_profile;
					this.getFightCards();
				}
			);
	}*/

  showCreateModalForm(){
    let modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: '',
    scroll : true,
    containerClass: '',
    animated: true,
    data: {
        fightCard: null,
        isEdit : false
    }
  }
    this.modalRef = this.modalService.show(CreateFightCardComponent,modalOptions);
  }
  edit(fightCard){
    let modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    scroll : true,
    ignoreBackdropClick: false,
    class: '',
    containerClass: '',
    animated: true,
    data: {
        fightCard: fightCard,
        isEdit : true
    }
  }
    this.modalRef = this.modalService.show(CreateFightCardComponent,modalOptions);
  }
	onClickCard(id: any){
		this.transfereService.setData(id);
	   	this.router.navigateByUrl('/fight-list/'+id);
	}
	onSwitch(){}
  setResultTransaction(result:boolean){
    this.transactionSuccess = result;
  }
  displaySaving(value:boolean){
    this.transactionSaving = value;
  }

  sendMessage(){
    this.sendMessageEnCour = true;
    let formDatas = new FormData();
    formDatas.append('id_fightcard',this.selectedFightcard.id_fightcard);
    formDatas.append('message',this.sendMessageForm.get('message').value);

    // Ajouter nb de fichiers
    formDatas.append('nbFiles', this.listFile.length.toString());
    // Ajouter les fichiers
    for (let i = 0; i < this.listFile.length; i++) {
      formDatas.append('messageFile'+i, this.listFile[i], this.listFile[i].name);

    }

    this.fightCardService.sendMessageToAllFighters(formDatas).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        //this.modalSendMessage.hide();
        this.sendMessageSuccess = true;
        this.sendMessageForm.reset();
        this.listFile = [];
        this.totalFilesSize = 0;
        //this.alertService.showSuccess(this.translateService.instant("SUCCESS","mails_sent"),this.translateService.instant("SUCCESS","TITLE"));
        this.sendMessageEnCour = false;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant("ERROR","TITLE"));
        this.sendMessageEnCour = false;
      }
    },error=>{
      this.alertService.showError(error.error.message,this.translateService.instant("ERROR","TITLE"));
      this.sendMessageEnCour = false;
    },()=>{});
  }

  // Ajouter les fichiers sélectionner à la liste des fichiers
  fileChange(event){
    for (var i = 0; i < event.target.files.length; i++) {
      this.listFile.push(event.target.files[i]);
      this.totalFilesSize += event.target.files[i].size;
    }
  }

  deleteSendMessageFile(index){
    this.totalFilesSize -= this.listFile[index].size;
    this.listFile.splice(index, 1);
  }

  // Si la fightcard est déjà passée
  passed(datetime_event){
    return (moment(datetime_event, "YYYY-MM-DD").isBefore(moment()) && !moment(datetime_event, "YYYY-MM-DD").isSame(moment(), 'day'));
  }

}
