<app-navbar></app-navbar>

<div class="container">
  <div class="row mt-5">
    <div class="col-8 mx-auto">
      <mdb-card>
        <mdb-card-header class="info-color white-text text-center py-4">
          <h5>
            <strong>{{'NAVBAR.CONTACT_US'|translate}}</strong>
          </h5>
        </mdb-card-header>
        <mdb-card-body class="px-lg-5 pt-0">
          <p>{{'TEXT.COMPLETE_FORM'|translate}}</p>
      <form [formGroup]="contactForm" (ngSubmit)="submit()">
        <div class="row">
      <div class="col-md-12 mx-auto">
                <div class="md-form md-outline">
                  <ng-container *ngFor="let validation of validation_messages.sujet">
                    <mdb-error *ngIf="contactForm.get('sujet').hasError(validation.type) && contactForm.get('sujet').touched">{{ validation.message }}</mdb-error>
                    </ng-container>
                  <input type="text" formControlName="sujet" placeholder="" id="sujet" class="form-control" mdbInput mdbValidate/>
                  <label for="sujet">{{'FORM.contactSubject'|translate}}</label>
                </div>
                <div class="md-form md-outline">
                  <textarea formControlName="message" placeholder=""  id="message" mdbInput mdbValidate class="form-control md-textarea"></textarea>
                  <label for="message">{{'FORM.contactMessage'|translate}}</label>
                  <ng-container *ngFor="let validation of validation_messages.message">
                  <mdb-error *ngIf="contactForm.get('message').hasError(validation.type) && contactForm.get('message').touched">{{ validation.message }}</mdb-error>
                    </ng-container>
                </div>
                <button
            mdbBtn
            color="info"
            outline="true"
            rounded="true"
            block="true"
            mdbWavesEffect
            type="submit"
            [disabled]="!contactForm.valid"
          >
            {{buttonText}}
          </button>
          </div>
          </div>
      </form>
      </mdb-card-body>
      </mdb-card>
    </div>
  </div>

  <!--<mdb-card>

    <mdb-card-header class="info-color white-text text-center py-4">
      <h5>
        <strong>Contact us</strong>
      </h5>
    </mdb-card-header>

    <mdb-card-body class="px-lg-5 pt-0">

    <form
      class="text-center"
      style="color: #757575;"
      [formGroup]="contactForm"
      (ngSubmit)="submit()"
    >
      <span>Subject</span>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="md-form md-outline">
            <mdb-error *ngIf="contactForm.get('sujet').invalid && (contactForm.get('sujet').dirty || contactForm.get('sujet').touched)"
              >Input invalid</mdb-error
            >
            <mdb-success *ngIf="contactForm.get('sujet').valid && (contactForm.get('sujet').dirty || contactForm.get('sujet').touched)"
              >Input valid</mdb-success
            >
          </div>
        </div>
      </div>

      <div class="md-form md-outline">
        <textarea
          type="text"
          formControlName="contactFormMessage"
          id="materialContactFormMessage"
          class="form-control md-textarea"
          mdbInput
          mdbValidate
        ></textarea>
        <label for="materialContactFormMessage">Message</label>
        <mdb-error *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)"
          >Input invalid</mdb-error
        >
        <mdb-success *ngIf="contactForm.get('message').valid && (contactForm.get('message').dirty || contactForm.get('message').touched)"
          >Input valid</mdb-success
        >
      </div>


      <button
        mdbBtn
        color="info"
        outline="true"
        rounded="true"
        block="true"
        class="z-depth-0 my-4 waves-effect"
        mdbWavesEffect
        type="submit"
      >
        Send
      </button>
    </form>

    </mdb-card-body>

  </mdb-card>-->

</div>
