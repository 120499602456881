import {Injectable, Inject} from '@angular/core';
import { TRANSLATIONS } from './translations'; // import our opaque token

@Injectable()
export class TranslateService {
    private _currentLang: string;
    private _codeLang : string;
    private _deviceLanguage : string;
    public get currentLang() {
        return this._currentLang;
    }
    public get codeLang() {
        return this._codeLang;
    }
    public get deviceLanguage() {
        return this._deviceLanguage;
    }
    // inject our translations
    constructor(@Inject(TRANSLATIONS) private _translations: any) {
    }

    public use(lang: string): void {
        // set current language
        //navigator sen language like this format fr-FR => get fr or other to set langue
        let splitLang = lang.split('-');
        this._deviceLanguage = lang;
        this._currentLang = splitLang[0];
        this._codeLang = splitLang[0]+'_'+splitLang[1];
    }

    private translate(key: string, value:string): string {
        // private perform translation
        let translation = key;

        if (this._translations[this.currentLang] && this._translations[this.currentLang][key][value]) {
            return this._translations[this.currentLang][key][value];
        }

        return translation;
    }

    public instant(key: string,value:string) {
        // call translation
        return this.translate(key,value);
    }
}
