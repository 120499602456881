import { Component, OnInit } from '@angular/core';
import { ChatRoomService } from '../../../services/chat-room.service'
import { TranslateService } from '../../../translate';
import { AlertService } from '../../../services/alert.service';
import { Globals } from '../../../globals';
import { FormControl } from '@angular/forms';
import { FighterService } from '../../../services/fighters.service';
import { ManagersService } from '../../../services/managers.service';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {

  //Déclaration des attributs
  currentUser= null;
  userLogo = "../../../../assets/icone/chatLogo/user.svg"; // Image de profil par défaut
  chatLogo = "../../../../assets/icone/chatLogo/chat.svg";
  plusLogo = "../../../../assets/icone/chatLogo/plus.svg";
  //mmaLogo  = "logo_mma_orga.png" //Version blanche et bleu
  mmaLogo  = "logo_black.png";     //Version noir
  chatRoomList = [];
  selectedRoom: any = null; //Conversation selectionnée
  isLoadingRooms: boolean = true;

  //Trouver des personnes
  findSomeone: FormControl;
  foundFighters: Array<any> = [];
  displayedFighters: Array<any> = [];
  foundManagers: Array<any> = [];
  displayedManagers: Array<any> = [];
  findIsLoading: number = 0; //Est ce que c'est en train de charger / 2 choses en train de charger max, 0 = rien ne charge
  waitFind = false; //Est ce qu'on attend de charger autre chose

  //Modal nouveau message
  modalIsOpen: boolean = false;
  newReceiver: any = null;
  newMessage: FormControl;


  constructor(private chatRoomService: ChatRoomService, private alertService: AlertService, public translateService :TranslateService, public globals: Globals,
    private fighterService: FighterService, private managerService: ManagersService) {
    this.actualizeDiscussions();

    //Entammer une nouvelle conversation
    this.findSomeone = new FormControl("");
    this.newMessage  = new FormControl("");
    this.globals.haveNotification = false;

   }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem('roomId');
  }

  //Actualise la liste des discussions
  actualizeDiscussions(){
    this.chatRoomService.getOrderList().subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        this.chatRoomList = jsonResponse.rows;//[];
        jsonResponse.rows.forEach(room => {
          if(room.user_id_creator != this.currentUser && room.creator_fighter_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_fighter_profile_pic;
          }
          if(room.user_id_creator != this.currentUser && room.creator_manager_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_manager_profile_pic;
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_fighter_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_fighter_profile_pic
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_manager_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_manager_profile_pic
          }
          if(room.user_id_creator != this.currentUser && room.creator_orga_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_orga_profile_pic
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_orga_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_orga_profile_pic
          }
        });
        //Traitement de la réponse
        /*jsonResponse.rows.forEach(room => {
          let element = {};

          //Elements de base
          element["id_chat_room"] = room.id_chat_room;
          element["message_datetime"] = room.message_datetime;
          element["user_id_receiver_room"] = room.user_id_receiver;

          //On limite la taille du message affiché
          if (room.message == null) {
            // !!! C'est un CARACTERE INVISIBLE pas un espace !!! On prévoie un message null sinon plus rien ne s'affiche
            // On ne peut pas mettre d'espace sinon la div est vide et la photo se décalle
            element["message"] = "ㅤ";
          }else if(room.message.toString().length > 25){
            element["message"] = room.message.toString().substring(0, 20) + ' ...';
          }else{
            element["message"] = room.message.toString();
          }

          //Infos du correspondant
          if (room.receiver_fighter_name != null) {
            element["receiver_name"] = room.receiver_fighter_name;
            element["receiver_firstname"] = room.receiver_fighter_firstname;
            if(room.receiver_fighter_profile_pic != null && room.receiver_fighter_profile_pic != ''){
              element["receiver_profile_pic"] = this.globals.publicPathImage+'/pictures/' + room.receiver_fighter_profile_pic;
            }else{
              element["receiver_profile_pic"] = null;//this.userLogo;
            }
          }else if(room.receiver_manager_name != null){
            element["receiver_name"] = room.receiver_manager_name;
            element["receiver_firstname"] = room.receiver_manager_firstname;
            if(room.receiver_manager_profile_pic != null && room.receiver_manager_profile_pic != ''){
              element["receiver_profile_pic"] = this.globals.publicPathImage+'/pictures/' + room.receiver_manager_profile_pic;
            }else{
              element["receiver_profile_pic"] = null;//this.userLogo;
            }
          }else{
            element["receiver_name"] = "Unknown correspondent";
            element["receiver_firstname"] = "";
          }

          //Storage
          element["stored_message"] = JSON.parse(localStorage.getItem('chat')).rooms[room.id_chat_room];

          this.chatRoomList.push(element);
        });*/
        this.isLoadingRooms = false;
      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        this.isLoadingRooms = false;
      }
    },error=>{
      this.alertService.alertError(error.status);
      this.isLoadingRooms = false;
    });
  }

  findController(){
    //Si rien ne charge et rien n'attends d'être chargé, alors on charge
    if(this.findIsLoading === 0 && !this.waitFind){
      this.findUser();
    }
    //Si rien n'attends d'être chargé, mais que quelque chose charge, alors on attends un chargement
    else if(!this.waitFind && this.findIsLoading > 0){
      this.waitFind = true;
    }
    //Si on attends déjà un chargement, alors on ne fait rien de plus
    //else if(this.waitFind){}
  }

  findUser(){
    //On charge 2 choses (managers et fighters)
    this.findIsLoading = 2;

    let str = this.findSomeone.value;
    if(str === null || str == ""){
      this.foundManagers = [];
      this.foundFighters = [];
      this.displayedManagers = [];
      this.displayedFighters = [];
      this.findIsLoading -= 2;
      if(this.waitFind){
        this.waitFind = false;
        this.findUser();
      }
    }else{
      //Ajout des managers
      this.managerService.getList({name: str}).subscribe(jsonResponse=>{
        if(jsonResponse.success == true){
          this.foundManagers = jsonResponse.rows;
          this.displayedManagers = this.foundManagers.slice(0, 4);
          this.findIsLoading -= 1;
          //Si on a tout chargé et qu'on attends de charger alors on charge
          if(this.findIsLoading === 0 && this.waitFind){
            this.waitFind = false;
            this.findUser();
          }
        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
      });

      //Ajout des fighters
      this.fighterService.getList({name: str, onlyValidatedProfile: true}).subscribe(jsonResponse=>{
        if(jsonResponse.success == true){
          this.foundFighters = jsonResponse.rows;
          this.displayedFighters = this.foundFighters.slice(0, 4);
          this.findIsLoading -= 1;
          //Si on a tout chargé et qu'on attends de charger alors on charge
          if(this.findIsLoading === 0 && this.waitFind){
            this.waitFind = false;
            this.findUser();
          }
        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
      });
    }
  }
  //Ouvre une modal de nouvelle discussion
  newConversation(user: any){
    this.chatRoomService.checkRoom(user.room_id).subscribe(jsonResponse=>{
      if(jsonResponse.success == true){
        //Si la discussion existe déjà on l'ouvre
        if(jsonResponse.rows.length > 0){
          let room = jsonResponse.rows[0];

          if(room.user_id_creator != this.currentUser && room.creator_fighter_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_fighter_profile_pic;
          }
          if(room.user_id_creator != this.currentUser && room.creator_manager_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_manager_profile_pic;
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_fighter_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_fighter_profile_pic
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_manager_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_manager_profile_pic
          }
          if(room.user_id_creator != this.currentUser && room.creator_orga_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.creator_orga_profile_pic
          }
          if(room.user_id_receiver != this.currentUser && room.receiver_orga_profile_pic){
            room.receiver_profile_pic = this.globals.publicPathImage+'/pictures/' +room.receiver_orga_profile_pic
          }
          /*let element = {};

          //Elements de base
          element["id_chat_room"] = room.id_chat_room;
          element["message_datetime"] = room.message_datetime;
          element["user_id_receiver_room"] = room.user_id_receiver;

          //On limite la taille du message affiché
          if (room.message == null) {
            // !!! C'est un CARACTERE INVISIBLE pas un espace !!! On prévoie un message null sinon plus rien ne s'affiche
            // On ne peut pas mettre d'espace sinon la div est vide et la photo se décalle
            element["message"] = "ㅤ";
          }else if(room.message.toString().length > 25){
            element["message"] = room.message.toString().substring(0, 20) + ' ...';
          }else{
            element["message"] = room.message.toString();
          }


          //Infos du correspondant
          if (room.receiver_fighter_name != null) {
            element["receiver_name"] = room.receiver_fighter_name;
            element["receiver_firstname"] = room.receiver_fighter_firstname;
            if(room.receiver_fighter_profile_pic != null && room.receiver_fighter_profile_pic != ''){
              element["receiver_profile_pic"] = this.globals.publicPathBaseUrl + room.receiver_fighter_profile_pic;
            }else{
              element["receiver_profile_pic"] = null;//this.userLogo;
            }
          }else if(room.receiver_manager_name != null){
            element["receiver_name"] = room.receiver_manager_name;
            element["receiver_firstname"] = room.receiver_manager_firstname;
            if(room.receiver_manager_profile_pic != null && room.receiver_manager_profile_pic != ''){
              element["receiver_profile_pic"] = this.globals.publicPathBaseUrl + room.receiver_manager_profile_pic;
            }else{
              element["receiver_profile_pic"] = null;//this.userLogo;
            }
          }else{
            element["receiver_name"] = "Unknown correspondent";
            element["receiver_firstname"] = "";
          }*/

          this.selectedRoom = room;

        //Sinon on ouvre le modal de création de discussion
        }else{
          this.newReceiver = user;
          this.modalIsOpen = true;
        }

      }else{
        this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
      }
    },error=>{
      this.alertService.alertError(error.status);
    });
  }

  closeModal(){
    this.modalIsOpen = false;
    this.newReceiver = null;
    this.newMessage.setValue('');
  }

  sendNewMessage(){
    let message = this.newMessage.value;
    if(message !== null && message !=''){
      this.newMessage.setValue('');

      let user = this.newReceiver;
      //On créé la discussion
      this.chatRoomService.createRoom(user.room_id).subscribe(jsonResponse=>{
        if(jsonResponse.success == true){
          let formDatas:FormData = new FormData();
          formDatas.append('id_chat_room', jsonResponse.id);
          formDatas.append('message', message);
          formDatas.append('receiver_room_id', user.room_id);
          //On envoie le message
          this.chatRoomService.sendMessage(formDatas).subscribe(jsonResponse=>{
            if(jsonResponse.success == true){
              this.actualizeDiscussions();
              //On récupère la room créée
              this.chatRoomService.checkRoom(user.room_id).subscribe(jsonResponse=>{
                if(jsonResponse.success == true){
                  //On ouvre la discussion
                  if(jsonResponse.rows.length > 0){
                    let room = jsonResponse.rows[0];
                    let element = {};

                    //Elements de base
                    element["id_chat_room"] = room.id_chat_room;
                    element["message_datetime"] = room.message_datetime;
                    element["user_id_receiver_room"] = room.user_id_receiver;

                    //On limite la taille du message affiché
                    if (room.message == null) {
                      // !!! C'est un CARACTERE INVISIBLE pas un espace !!! On prévoie un message null sinon plus rien ne s'affiche
                      // On ne peut pas mettre d'espace sinon la div est vide et la photo se décalle
                      element["message"] = "ㅤ";
                    }else if(room.message.toString().length > 25){
                      element["message"] = room.message.toString().substring(0, 20) + ' ...';
                    }else{
                      element["message"] = room.message.toString();
                    }


                    //Infos du correspondant
                    if (room.receiver_fighter_name != null) {
                      element["receiver_name"] = room.receiver_fighter_name;
                      element["receiver_firstname"] = room.receiver_fighter_firstname;
                      if(room.receiver_fighter_profile_pic != null && room.receiver_fighter_profile_pic != ''){
                        element["receiver_profile_pic"] = this.globals.publicPathBaseUrl + room.receiver_fighter_profile_pic;
                      }else{
                        element["receiver_profile_pic"] = null;//this.userLogo;
                      }
                    }else if(room.receiver_manager_name != null){
                      element["receiver_name"] = room.receiver_manager_name;
                      element["receiver_firstname"] = room.receiver_manager_firstname;
                      if(room.receiver_manager_profile_pic != null && room.receiver_manager_profile_pic != ''){
                        element["receiver_profile_pic"] = this.globals.publicPathBaseUrl + room.receiver_manager_profile_pic;
                      }else{
                        element["receiver_profile_pic"] = null;//this.userLogo;
                      }
                    }else{
                      element["receiver_name"] = "Unknown correspondent";
                      element["receiver_firstname"] = "";
                    }

                    this.selectedRoom = element;

                    this.closeModal();

                  }else{
                    this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
                  }

                }else{
                  this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
                }
              },error=>{
                this.alertService.alertError(error.status);
              });

            }else{
              this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
            }
          },error=>{
            this.alertService.alertError(error.status);
          });

        }else{
          this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
        }
      },error=>{
        this.alertService.alertError(error.status);
      });

    }
  }

}
