import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaAppFightClaimService {

  constructor(private http: HttpClient, private globals: Globals) { }
  private url = this.globals.baseUrl + 'taappfightclaim';  // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public getListFightClaim(searchParams):Observable<any>{
    const body = JSON.stringify(searchParams);
    return this.http.post(this.url+"/gets",body);
  }
}
