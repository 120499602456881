  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
    <div class="row">
      <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
    <p *ngIf="fight.id_fighter_1">{{fight.firstname_fighter_1}} {{fight.name_fighter_1}} -
      {{fight.nickname_fighter_1}}</p>

      <div *ngIf="fight.id_fighter_1 && fight.number_victory_fighter_1 && fight.number_defeat_fighter_1"
        class="progress">
        <div class="progress-bar bg-success" role="progressbar"
          [ngStyle]="{width: fight.victory_percent_fighter_1 + '%'}" aria-valuenow="{{fight.victory_percent_fighter_1}}"
          aria-valuemin="0" aria-valuemax="100">W{{fight.number_victory_fighter_1}}</div>
        <div class="progress-bar bg-danger" role="progressbar"
          [ngStyle]="{width: fight.defeat_percent_fighter_1 + '%'}" aria-valuenow="20"
          aria-valuemin="0" aria-valuemax="100">L{{fight.number_defeat_fighter_1}}</div>
        <div class="progress-bar bg-warning" role="progressbar"
          [ngStyle]="{width: fight.null_percent_fighter_1 + '%'}" aria-valuenow="40"
          aria-valuemin="0" aria-valuemax="100">L{{fight.number_null_fighter_1}}</div>
      </div>

    <p *ngIf="!fight.id_fighter_1">{{'FIGHTCARD.NO_FIGTER_YET'| translate}}</p>
    <button *ngIf="!fight.id_fighter_1" type="button" (click)="addFightertofight(fight, 1)"
      class="btn btn-sm btn-primary">{{'BUTTON.ADD_FIGHTER_TO_FIGHT' | translate}}</button>
    <button *ngIf="fight.id_fighter_1" type="button" (click)="addFightertofight(fight, 1)"
      class="btn btn-sm btn-primary">{{'BUTTON.REPLACE_FIGHTER' | translate}}</button>
    </div>
      <div *ngIf="fight.id_fighter_1" class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
        <img *ngIf="fight.profile_pic_fighter_1" style="height: 100px;"
          src="{{globals.publicPathImage}}/pictures/{{fight.profile_pic_fighter_1}}" class="rounded img-fluid">
        <img *ngIf="!fight.profile_pic_fighter_1" style="height: 100px;"
          src="{{globals.publicPathImage}}/pictures/default.png" class="rounded img-fluid">
      </div>
    </div>

  </div>
  <!--COL VS-->
  <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center">
    <p>VS</p>
  </div>

  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
    <div class="row">
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <img *ngIf="fight.profile_pic_fighter_2" style="height: 100px;"
        src="{{globals.publicPathImage}}/pictures/{{fight.profile_pic_fighter_2}}" class="rounded img-fluid">
      <img *ngIf="!fight.profile_pic_fighter_2" style="height: 100px;"
        src="{{globals.publicPathImage}}/pictures/default.png" class="rounded img-fluid">
    </div>
    <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
      <p *ngIf="fight.id_fighter_2"> {{fight.firstname_fighter_2}} {{fight.name_fighter_2}} -
        {{fight.nickname_fighter_2}}</p>
      <p *ngIf="!fight.id_fighter_2">{{'FIGHTCARD.NO_FIGTER_YET'| translate}}</p>
      <div *ngIf="fight.number_victory_fighter_2 && fight.number_defeat_fighter_2 && fight.id_fighter_2"
        class="progress">
        <div class="progress-bar bg-success" role="progressbar"
          [ngStyle]="{width: fight.victory_percent_fighter_2 + '%'}" aria-valuenow="30"
          aria-valuemin="0" aria-valuemax="100">W{{fight.number_victory_fighter_2}}</div>
        <div class="progress-bar bg-danger" role="progressbar"
          [ngStyle]="{width: fight.defeat_percent_fighter_2 + '%'}" aria-valuenow="20"
          aria-valuemin="0" aria-valuemax="100">L{{fight.number_defeat_fighter_2}}</div>
        <div class="progress-bar bg-warning" role="progressbar"
          [ngStyle]="{width: fight.null_percent_fighter_2 + '%'}" aria-valuenow="40"
          aria-valuemin="0" aria-valuemax="100">N{{fight.number_null_fighter_2}}</div>
        </div>
      <button *ngIf="!fight.id_fighter_2" type="button" (click)="addFightertofight(fight, 2)"
        class="btn btn-sm btn-primary">{{'BUTTON.ADD_FIGHTER_TO_FIGHT' | translate}}</button>
      <button *ngIf="fight.id_fighter_2" type="button" (click)="addFightertofight(fight, 2)"
        class="btn btn-sm btn-primary">{{'BUTTON.REPLACE_FIGHTER' | translate}}</button>

    </div>
  </div>
  </div>
