import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder,FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '../../../translate';
import { PasswordValidator } from '../../../validators/password.validator';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  isSaving : boolean = false;
  validation_messages = {
    'actual_password' : null,
    'password' : null,
    'confirm_password' : null,
    'matching_passwords' : null
  };
    matching_passwords_group: FormGroup;
    constructor(
      private authService : AuthService,
      public translateService: TranslateService,
      private alertService : AlertService
    ) {
      this.validation_messages = {
        'actual_password' : [
          { type: 'required', message: this.translateService.instant('formControl','passwordRequired') },
          { type: 'minlength', message: this.translateService.instant('formControl','passworMinlength')}
        ],
        'password': [
          { type: 'required', message: this.translateService.instant('ERROR','REQUIRED_PASSWORD') },
          { type: 'minlength', message: this.translateService.instant('ERROR','PASSWORD_TOO_SHORT')}
        ],
        'confirm_password': [
          { type: 'required', message: this.translateService.instant('ERROR','REQUIRED_PASSWORD') }
        ],
        'matching_passwords': [
          { type: 'areNotEqual', message: this.translateService.instant('ERROR','PASSWORD_NOT_EQUAL') }
        ]

      };
      this.matching_passwords_group = new FormGroup({
        'password': new FormControl('', Validators.compose([
          Validators.minLength(5),
          Validators.required
        ])),
        'confirm_password': new FormControl('', Validators.required)
      }, (formGroup: FormGroup) => {
        return PasswordValidator.areNotEqual(formGroup);
      });
      this.passwordForm = new FormGroup({
        'actual_password': new FormControl('', Validators.compose([
          Validators.minLength(5),
          Validators.required
        ])),
        'matching_passwords': this.matching_passwords_group,
      });
    }

    ngOnInit() {
    }
  updatePassword(){
    this.isSaving = true;
    let pwd = CryptoJS.SHA512(this.passwordForm.getRawValue().matching_passwords.password).toString();
    let formDatas = new FormData();
    formDatas.append('password',pwd);
    formDatas.append('actual_password',CryptoJS.SHA512(this.passwordForm.getRawValue().actual_password).toString());
    this.authService.updatePassword(formDatas).subscribe(jsonResponse=>{
      this.isSaving = false;
      if(jsonResponse.success){
        this.alertService.showSuccess(this.translateService.instant('SUCCESS','TITLE'),this.translateService.instant('SUCCESS','SUCCESS_CHANGE_PASSWORD'));
      }else{
        this.alertService.showError(this.translateService.instant('ERROR','TITLE'),jsonResponse.msg);
      }
    },error=>{
      this.isSaving = false;
      this.alertService.alertError(error.status);
    },()=>{}/*complete*/);
  }
  }
