import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FighterService } from '../../../services/fighters.service';
import { TaAppFighterWeightHistoryService } from '../../../services/ta-app-fighter-weight-history.service';
import {ReferentielService} from '../../../services/referentiel.service';
import {AlertService} from '../../../services/alert.service';
import { Globals } from '../../../globals';
import { TranslateService } from '../../../translate';
import * as moment from 'moment';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-add-fighter',
  templateUrl: './modal-add-fighter.component.html',
  styleUrls: ['./modal-add-fighter.component.scss']
})
export class ModalAddFighterComponent implements OnInit {
 @Input() fightFilter;
 @Input() selectedFighter1;
 @Input() selectedFighter2;
 @Input("fight") fight;
  searchForm: FormGroup;
  	public listFighters: any = [];
    public listCountries: any = [];
	public listSexes: any = [];
	public listNationnality: any = [];
	public listWeights: any = [];
	public labelsChooseNone:string;

	public options = [];
	public filter = {
			name : null,
		  id_living_country : null,
		  id_sex : null,
		  language_spoken : null,
		  id_nationnality : null,
      id_weight_category : null,
			id_weight_category_1: null,
			id_weight_category_2: null,
      fighter_level : 3,
      min_fight : null,
      nb_min_win : null,
      max_fight : null
		  }
  public saveButtonClicked: Subject<any> = new Subject<any>();
  weightCategoryList= [];

  constructor(public fb: FormBuilder ,private fighterProfileService : FighterService,
    private referentielService : ReferentielService,
    public translateService: TranslateService,
    private alertService : AlertService,
			public globals: Globals,private weightHistoryService: TaAppFighterWeightHistoryService, public modalRef: MDBModalRef) {
}

  ngOnInit(): void {
	this.getCountries();
	this.getWeights();
	this.getNationnality();
	this.getSexes()

  //si un filtre de combattant est passé on set le formulaire directement pour faciliter le travail à l'utiisateur
  if(this.fightFilter){
    let varIsRookie = 3;
    if(this.fightFilter.is_rookie == "true"){
      varIsRookie = 1;
    }else if(this.fightFilter.is_rookie == false){
      varIsRookie = 2;
    }
    let varNbMaxFight = this.fightFilter.nb_max_fight;
    if(this.fightFilter.nb_max_fight == 0){
      varNbMaxFight = null;
    }
    let varNbMinFight = this.fightFilter.nb_min_fight;
    if(this.fightFilter.nb_min_fight == 0){
      varNbMinFight = null;
    }
    this.searchForm =
      new FormGroup({
        'name' : new FormControl(this.fightFilter.name),
        'id_living_country' : new FormControl(this.fightFilter.id_living_country),
        'id_sex' : new FormControl(this.fightFilter.id_sex),
        'id_nationnality' : new FormControl(this.fightFilter.id_nationnality),
        'id_weight_category' : new FormControl(this.fightFilter.id_weight_category),
        'fighter_level': new FormControl(varIsRookie),//new FormControl(this.fightFilter.is_rookie),
        'is_pro' : new FormControl(!varIsRookie),//new FormControl((this.fightFilter.is_rookie)?false:true),//si rookie alors pas de recherche pro sinon pro recherché
        'min_fight' : new FormControl(varNbMinFight),
        'max_fight' : new FormControl(varNbMaxFight),
        'nb_min_win' : new FormControl(null)
      });
  }else{
    this.searchForm = new FormGroup({
      'name' : new FormControl(null),
      'id_living_country' : new FormControl(null),
      'id_sex' : new FormControl(null),
      'id_nationnality' : new FormControl(null),
      'id_weight_category' : new FormControl(null),
      'fighter_level': new FormControl(3),
      'min_fight' : new FormControl(null),
      'max_fight' : new FormControl(null),
      'nb_min_win' : new FormControl(null)
    });
  }

  if(this.fight){
    if(this.fight.is_rookie == true){
      this.searchForm.controls['fighter_level'].setValue(1);
    }
    if(this.fight.is_rookie == false){
      this.searchForm.controls['fighter_level'].setValue(2);
    }

    this.searchForm.controls['id_sex'].setValue(this.fight.id_sex.toString());
    this.searchForm.controls['id_weight_category'].setValue(this.fight.id_weight_category);
    this.searchForm.controls['min_fight'].setValue(this.fight.nb_min_fight);
    this.searchForm.controls['max_fight'].setValue(this.fight.nb_max_fight);
    this.searchForm.controls['nb_min_win'].setValue(this.fight.nb_min_win);
    if(this.fight.nb_min_fight == 0){
      this.searchForm.controls['min_fight'].setValue(null);
    }
    if(this.fight.nb_max_fight == 0){
      this.searchForm.controls['max_fight'].setValue(null);
    }
  }
  //TODO ne pas charger dès le début, on garde juste pour les tests
  this.getFighters();
  }

	getFighters() {
		this.listFighters = [];
    let filter = this.searchForm.getRawValue();
    if(this.selectedFighter1){
      filter.fighter1Excluded = this.selectedFighter1;
    }
    if(this.selectedFighter2){
      filter.fighter2Excluded = this.selectedFighter2;
    }
		this.fighterProfileService
			.getList(filter)
			.subscribe(
				jsonResponse => {
					// si la requete est un succés.
					let fighters = jsonResponse.rows;
					for (let i = 0; i <= fighters.length - 1; i++) {
						fighters[i].victory_percent = fighters[i].number_victory/fighters[i].total_fight * 100;
						fighters[i].defeat_percent = fighters[i].number_defeat/fighters[i].total_fight * 100;
						fighters[i].null_percent = fighters[i].number_null/fighters[i].total_fight * 100;
						// Vérifier que le fighter n'est pas déjà sur le combat
            if(this.fightFilter){ // Création combat
              if(this.selectedFighter1 != fighters[i].id_fighter_profile && this.selectedFighter2 != fighters[i].id_fighter_profile){
                this.listFighters.push(fighters[i]);
              }
            }else if(this.fight){ // Edition combat
              if(this.fight.id_fighter_1 != fighters[i].id_fighter_profile && this.fight.id_fighter_2 != fighters[i].id_fighter_profile
                && this.fight.id_fighter_reserve != fighters[i].id_fighter_profile){
                this.listFighters.push(fighters[i]);
              }
            }else{
              this.listFighters.push(fighters[i]);
            }
					}
					this.addAgetoFihters();
					//this.getFighterLastweight();
				}, error => {
					//error
				}
			);
	}
	getCountries() {
		this.referentielService
			.getCountries()
			.subscribe(
				jsonResponse => {
					if(jsonResponse.success){
            this.listCountries = jsonResponse.rows;
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        });
	}
	getWeights() {
		this.referentielService
			.getWeightCategories()
      .subscribe(
				jsonResponse => {
					if(jsonResponse.success){
            this.weightCategoryList = jsonResponse.rows;
            this.listWeights = jsonResponse.rows;
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        });
	}

	getSexes() {
		this.referentielService
			.getSex()
      .subscribe(
				jsonResponse => {
					if(jsonResponse.success){
            this.listSexes = jsonResponse.rows;
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        });
	}
	getNationnality() {
		this.referentielService
			.getNationnalities()
      .subscribe(
				jsonResponse => {
					if(jsonResponse.success){
            this.listNationnality = jsonResponse.rows;
          }else{
            this.alertService.showError(jsonResponse.msg,this.translateService.instant('ERROR','TITLE'));
          }
        },error=>{
          this.alertService.alertError(error.status);
        });
	}

	resetFilter() {
    this.searchForm.reset();
	}

	public CalculateAge(birthdate:Date) {
		if (birthdate) {
			let birth = moment(birthdate);
            let today = moment();
            let age = today.diff(birth, 'years');

			return  " ("+age+" "+this.translateService.instant('LABEL','AGE')+ ")";
    	}
		return "";
  	}
	public addAgetoFihters(): void {

		for (let i = 0; i < this.listFighters.length; i++) {
 		 	if(this.listFighters[i].birth_date != null){
				this.listFighters[i].birth_date =  moment(this.listFighters[i].birth_date).format('L').toString() + this.CalculateAge(this.listFighters[i].birth_date);
			}

		}
	}
	getFighterLastweight() {
		for (let i = 0; i < this.listFighters.length; i++) {
			this.weightHistoryService.getLastWeight(this.listFighters[i].id_fighter_profile)
			.subscribe(
				jsonResponse => {
					// si la requete est un succés.
					let history = jsonResponse.rows[0];
					this.listFighters[i].last_weight_kg = "("+ history.weight_kg+ " kg)";
					this.listFighters[i].last_weight_lbs =  history.weight_lbs +" lbs";
				}, error => {
					//error
				}
			);
		}

	}
	addFighter(fighter){
		this.saveButtonClicked.next(fighter);
		this.modalRef.hide();
	}
	get id_living_country() { return this.searchForm.get('id_living_country'); }
	get id_sex() { return this.searchForm.get('id_sex'); }
	get id_nationnality() { return this.searchForm.get('id_nationnality'); }
	get id_weight_category_1(){ return this.searchForm.get('id_weight_category_1'); }
	get name(){ return this.searchForm.get('name'); }
  get min_fight(){ return this.searchForm.get('min_fight'); }
  get max_fight(){ return this.searchForm.get('max_fight'); }
  //Filtre les catégories de poids de combats homme/femme
  filterWeightCategories(event){
    if(this.searchForm.get('id_sex').value == 1 && this.weightCategoryList.length>0){
      this.listWeights =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_men == true){
            return item;
          }
      });
    }
    if(this.searchForm.get('id_sex').value == 2 && this.weightCategoryList.length>0){
      this.listWeights =  this.weightCategoryList.filter(function(item){
        //item trouvé
          if(item.for_women == true){
            return item;
          }
      });
    }
  }
  controlSexSelection(){
    if(!this.searchForm.get('id_sex').value){
      this.alertService.showError(this.translateService.instant("ERROR","selectSex"),this.translateService.instant('ERROR','TITLE'));
    }
  }
}
