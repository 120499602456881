import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MobileSubscriptionService } from 'src/app/services/mobile-subscription.service';
import { TranslateService } from '../../translate';
import {IPayPalConfig,ICreateOrderRequest, IClientAuthorizeCallbackData } from 'ngx-paypal'; //https://github.com/Enngage/ngx-paypal
import { AlertService } from 'src/app/services/alert.service';
import { Globals } from 'src/app/globals';
import { OrdersService } from '../../services/orders.service';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-mobile-app-subscription',
  templateUrl: './mobile-app-subscription.component.html',
  styleUrls: ['./mobile-app-subscription.component.scss']
})
export class MobileAppSubscriptionComponent implements OnInit {
  selectedSubscription: any;
  subscriptionsList: any;
  public payPalConfig ? : IPayPalConfig;
  transactionSuccess;
  transactionState : string = '';
  subscribing: boolean;
  isLoading: boolean = true;
  showError: boolean = false;
  subscription = {
    id_subscription : null,
    paypal_subscription_id : null,
    subscription_label : null,
    subscription_price : null,
    subscription_duration : null,
    subscription_price_excl : null,
    vat : null,
    free_until:null,
    subscription_start_date : null,
    subscription_end_date : null,
    subscription_renewal_date : null,
    description : null
  };
  errorMessage:string = null;
  bearer:string = null;
  mustSubscribe = false;
  @ViewChild("paypalModal") paypalModal;
  @ViewChild("modalUpdateSubscription") modalUpdateSubscription;
  confirmForm : FormGroup;
  hasSubscription: boolean = null;
  userSubscription = {
    subscription_duration : null,
    subscription_end_date : null,
    subscription_label : null,
    subscription_price : null,
    subscription_price_excl : null,
    subscription_renewal_date : null,
    subscription_start_date : null,
    paypal_plan_id :null,
    description : null
  }
  activating: boolean;
  user: any = {
    id_subscription : null
  };
  unsubscribing: boolean;
  loadingUserInformation: boolean;
  validation_messages: {confirm_field: { type: string; message: any; }[]; };
  constructor(
    private route: ActivatedRoute,
    private translateService : TranslateService,
    private mobileSubscriptionService : MobileSubscriptionService,
    private alertService:AlertService,
    public globals : Globals,
    private ordersService : OrdersService
  ) {
    this.validation_messages = {
      'confirm_field': [
        { type: 'required', message: this.translateService.instant('formControl','hitConfirmWord') },
      ]
    };
    this.confirmForm = new FormGroup({
      'confirm_field': new FormControl('', Validators.compose([
        Validators.minLength(1),
        Validators.required
      ]))
    });

   }
/**
 * Les options possibles : 
 * - le profil est encore en période d'essai
 * - le profil est arrivé à échance de la période d'essai
 * - le profil est en cours d'abonnement
 * - le profil a suspendu son abonnement
 */
  ngOnInit(): void {
    this.loadingUserInformation = true;
    this.bearer = this.route.snapshot.paramMap.get('bearer');
    if(this.bearer != null){
    //request user information
    this.mobileSubscriptionService.getMe(this.bearer).subscribe(jsonResponse=>{
      this.loadingUserInformation = false;
      if(jsonResponse.success){
        this.user = jsonResponse.rows[0];
        let now = moment().format("Y-m-d");
        //si l'utilisateur à arrêté son abonnement
        if(jsonResponse.rows[0].id_subscription){
          this.hasSubscription = true;
          this.userSubscription = {
            subscription_duration : jsonResponse.rows[0].subscription_duration,
            subscription_end_date : jsonResponse.rows[0].subscription_end_date,
            subscription_label : jsonResponse.rows[0].subscription_label,
            subscription_price : jsonResponse.rows[0].subscription_price,
            subscription_price_excl : jsonResponse.rows[0].subscription_price_excl,
            subscription_renewal_date : jsonResponse.rows[0].subscription_renewal_date,
            subscription_start_date : jsonResponse.rows[0].subscription_start_date,
            paypal_plan_id : jsonResponse.rows[0].paypal_plan_id,
            description : jsonResponse.rows[0].subscription_description
          }
        }
        //pas encore d'abonnement
        else{
          //Vérification si utilisateur en gratuité ou non
          this.mustSubscribe =  moment().isAfter(this.user.free_until);
          this.hasSubscription = false;
        }
      }else{
        this.loadingUserInformation = false;
        this.errorMessage = jsonResponse.msg;
      }
    },error=>{
      this.errorMessage = this.translateService.instant('ERROR','noTokenTxt');
    },()=>{});
    //get subscriptions list
    this.mobileSubscriptionService.getListSubscriptions(this.bearer).subscribe(jsonResponse=>{
      if(jsonResponse.success){
        this.subscriptionsList = jsonResponse.rows;
      }
    },error=>{},()=>{});}
    else{
      this.errorMessage = this.translateService.instant('ERROR','noTokenTxt');
    }
  }
  private initPaypalConfig(subscription): void {
    let me = this;
    this.payPalConfig = {
        currency: this.translateService.instant('TEXT','currency'),
        clientId: this.globals.paypalClientId,
        vault : "true",
        createSubscription : (datas,actions)=> {
          //sandbox : 'P-9KE44033UD053412UMJYDWSQ' => gérer par le compte mmapro@mail.com => se connecter à https://www.sandbox.paypal.com/
          //prod : 'P-3FV18612G4523740JMJYDNHI'
          //TODO mettre l'id du vrai plan
          //return actions.subscription.create({plan_id : "P-9KE44033UD053412UMJYDWSQ"});
          return actions.subscription.create({plan_id : subscription.paypal_plan_id});
        },
    advanced: {
        commit: 'true'
    },
    style: {
        label: 'subscribe',
        layout: 'vertical',
        color : "black",
        shape : "pill"
    },
        onApprove: (data, actions) => {
          actions.subscription.get().then(details => {
              //STATUS = "APPROVED"
            let datas = new FormData();
              //datas.append('paypal_transaction_json',JSON.stringify(details));
              datas.append('paypal_subscription_id', data.subscriptionID);

              datas.append('create_datetime',details.create_time);

              datas.append('id_subscription', subscription.id_subscription);
               datas.append('paypal_transaction_json',JSON.stringify(details));
               //datas.append('address',((details.payer.address.address_line_1)?details.payer.address.address_line_1:null));
               //datas.append('city',((details.payer.address.admin_area_2)?details.payer.address.admin_area_2:null));
               datas.append('amount_paid_excl',subscription.subscription_price_excl);
               datas.append('amount_paid_incl',subscription.subscription_price);
               datas.append('vat',subscription.vat);//paypalDatas.purchase_units['items'].tax);
               datas.append('create_datetime',details.create_time);
               datas.append('paypal_order_id', data.orderID);
               datas.append('paypal_payer_id',data.payerID);
               //datas.append('firstname',(details.payer.name.surname)?details.payer.name.surname:null);
               //datas.append('name',(details.payer.name.given_name)?details.payer.name.given_name:null);
              this.ordersService.createPaypalOrderForManager(datas,this.bearer).subscribe(jsonResponse=>{
                this.paypalModal.hide();
                if(jsonResponse.success == true){
                  me.transactionState = this.translateService.instant('paypalTranscationState','approved');
                  me.subscribing = false;
                  me.transactionSuccess = true;
                  me.user.id_subscription = subscription.id_subscription;
                  me.mustSubscribe = false;
                  me.hasSubscription = true;
                  me.userSubscription.subscription_label = subscription.subscription_label;
                  me.userSubscription.subscription_price = subscription.subscription_price;
                  me.userSubscription.subscription_price_excl = subscription.subscription_price_excl;
                  me.userSubscription.subscription_start_date = moment().format('ll');
                  me.userSubscription.description=subscription.description
                }else{
                  me.transactionSuccess = false;
                }
              },error=>{
                me.subscribing = false;
                me.transactionSuccess = false;
                this.errorMessage = this.translateService.instant('ERROR','orderCreationTxt');
              },()=>{});
          });
        },
        onCancel: (data, actions) => {
            this.transactionState = this.translateService.instant('paypalTranscationState','canceled');
        },
        onError: (err) => {
          this.paypalModal.hide();
          this.errorMessage = err;
        },
        onClick: (data, actions) => {
          this.transactionState = this.translateService.instant('paypalTranscationState','authorisation');
        },
    };
  }

  openPaypalModal(subscription){
    this.subscribing = true;
    this.initPaypalConfig(subscription);
    this.paypalModal.show();
  }

  activate(){
    this.activating = true;
    this.mobileSubscriptionService.activatePaypalSubscription(this.bearer).subscribe(jsonResponse=>{
      this.activating = false;
      if(jsonResponse.success == true){
        this.transactionState = this.translateService.instant('paypalTranscationState','approved');
        this.activating = false;
        this.transactionSuccess = true;
        this.userSubscription.subscription_end_date =null;
      }else{
        this.transactionSuccess = false;
        this.errorMessage = jsonResponse.msg;
      }
    },error=>{
      this.activating = false;
      this.errorMessage = error.message;
    },()=>{});
  }
  unsubscribe() {
   this.unsubscribing = true;
   this.mobileSubscriptionService.suspendSubscription(this.bearer).subscribe(async jsonResponse=>{
     this.unsubscribing = false;
     if(jsonResponse.success){
       //rebuild user info
       this.userSubscription.subscription_label = null;
       this.userSubscription.subscription_price= null;
       this.userSubscription.subscription_duration= null;
       this.userSubscription.subscription_price_excl= null;
       this.userSubscription.paypal_plan_id =null;
       this.userSubscription.subscription_end_date = moment().format('ll');
       this.transactionSuccess = true;
     }else{
      this.transactionSuccess = false;
      this.errorMessage = jsonResponse.msg;
     }
   },error=>{
    this.activating = false;
    this.errorMessage = error.message;
   });
  }

  /*Display confirm modal*/
  showUpdateSubscriptionModal(subscription){
    this.selectedSubscription = subscription;
    this.modalUpdateSubscription.show();
  }
  /**
   * 
   * @param subscription Update user subscription
  TODO A TESTER 
  */
  changeSubscription(){
    //unsubscribe previous subscription and subscribe to the new
    //unsubscribe
    if(this.confirmForm.get('confirm_field').value.toLowerCase() != "confirm"){
      this.alertService.showError(this.translateService.instant("ERROR","hitConfirmWord"),this.translateService.instant("ERROR","TITLE"));
      return;
    }
    this.unsubscribing = true;
   this.mobileSubscriptionService.cancelSubscription(this.bearer).subscribe(async jsonResponse=>{
     this.unsubscribing = false;
     this.modalUpdateSubscription.hide();
     if(jsonResponse.success){
      this.subscribing = true;
      this.initPaypalConfig(this.selectedSubscription);
      this.paypalModal.show();
     }else{
      this.transactionSuccess = false;
      this.errorMessage = jsonResponse.msg;
     }
   },error=>{
    this.activating = false;
    this.errorMessage = error.message;
   });
    
  }
}
