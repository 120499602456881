<div class="modal-content">
  <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{'PAGE.TITLE_DELETE_FIGHTER_FROM_FIGHT'| translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>
  <div class="modal-body">
  <p class="modal-title w-100" id="myModalLabel">{{'FORM.TEXT_DELETE'| translate}} <strong>{{fighterIdentity}}</strong> {{'TEXT.FROM_FIGHT'| translate}} </p>
  </div>
  <div class="modal-footer">
    <button type="button" aria-label="Close"
            (click)="modalRef.hide()" mdbBtn color="danger" outline="true">{{'BUTTON.CLOSE'| translate}}
    </button>
    <button type="button" mdbBtn color="danger" class="relative" (click)="deleteFighter()">{{'BUTTON.DELETE'| translate}}
    </button>
  </div>
</div>
