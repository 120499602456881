import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';
import { TaRefCountryService } from '../../../services/ta-ref-country.service';
import { HttpClient } from '@angular/common/http';
import { SafeUrl} from '@angular/platform-browser';
import { TranslateService } from '../../../translate';//from '@ngx-translate/core';
import { TaAppOrganisationProfileService } from '../../../services/ta-app-organisation-profile.service';
import { Globals } from '../../../globals';
import { Router } from '@angular/router';
import {NgxImageCompressService,DOC_ORIENTATION} from 'ngx-image-compress'; //https://github.com/dfa1234/ngx-image-compress
import * as CryptoJS from 'crypto-js';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
//plugin pour recadrage image : https://www.itsolutionstuff.com/post/angular-10-crop-image-before-upload-with-preview-exampleexample.html
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

	lightForm: FormGroup;
	public listCountries: any = [];
	public optionsSelect = [];
	public options = [];
	errorMessage: any;
	erreurMessage: string = '';
	selectedFile = null;
	datePicker: string;
	labelsChoosePicture: string = 'Choose your profile picture';
	previewImg: SafeUrl;
	public imagePath;
	imgURL: any;
	fileName: string;
	extension: string; //Format de l'image (png, jpg, gif...)
	public message: string;
	connexionRow:any;
	tryValidate: boolean = false; //Si le user à déjà essayé de valider le formulaire (si true affiche les mdb error)
	SearchCountryField = SearchCountryField;
	selectedCountryISO:string;
	//Erreur
	erreur_server: string;
	erreur_mail:string;
	erreur_mail_taken: string;
	erreur_identifiant:string;
	erreur_champsVide:string;
	erreur_terms_conditions:string;
	erreur_image_upload:string;
	erreur_not_loaded:string;
	error_image_deleted: string;
	error_account_deleted: string;

	//Success
	success_account_updated: string;
	success_image_uploaded: string;
	success_image_deleted: string;
	success_account_deleted: string;

	public registerRow: { email: string, password: string, cgu: boolean };
	public myDatePickerOptions: IMyOptions = {
		minYear: 1900,
	};

	isAuthenticated:boolean; //Si l'utilisateur est déjà authentifié la variable sera true, sinon false

	userData: any = null;
	isLoading: boolean = true;
	profileImgURL: string; //Image de profile actuelle
	isLoadingConfirmation: boolean = false; //Attente de la confirmation d'inscription, désacativation du boutton

	@ViewChild("modalDeleteAccount") modalDeleteAccount;
	deleteForm : FormGroup;
	isLoadingDeleteAccount = false;

	constructor(public fb: FormBuilder, private authService: AuthService, private alertService: AlertService,
		private countryService: TaRefCountryService, private httpClient: HttpClient, public translateService: TranslateService,
		private TaAppOrganisationProfileService: TaAppOrganisationProfileService,
		private imageCompress: NgxImageCompressService,
		private Globals: Globals, private router: Router) {

		if(localStorage.getItem('isConnected') == "true" && localStorage.getItem('mma-token-orga')){
			this.isAuthenticated = true;
			this.authService.user().subscribe(jsonResponse => {
				if(jsonResponse.success){
					this.userData = jsonResponse.rows[0];
					this.lightForm = fb.group({
						label: [this.userData.label, Validators.required],
						description: [this.userData.description],
						date_creation_organization: [this.userData.date_creation_organization],
						phone: [this.userData.phone],
						facebook: [this.userData.facebook],
						twitter: [this.userData.twitter],
						instagram: [this.userData.instagram],
						website: [this.userData.website],
						video: [this.userData.video_link],
						profile_pic: [this.userData.profile_pic],
						idPays: [this.userData.id_country, Validators.required],
						profile: ['organisation'],
						accept_newsletter: this.userData.accept_newsletter,
						device_apptoken: null,
						billing_address : [this.userData.billing_address, Validators.required]
						/*contact_phone : [this.userData.contact_phone],
						contact_email : [this.userData.contact_email],
						accept_contact_email : [this.userData.accept_contact_email],
						accept_contact_phone : [this.userData.accept_contact_email]*/
					});
					this.isLoading = false;
					//Afficher l'image de profile actuelle
					if(this.userData.profile_pic){
						this.profileImgURL = this.Globals.publicPathImage+"/pictures/" + this.userData.profile_pic;
					}

				}
			},error=>{
				this.alertService.showError(this.erreur_not_loaded, this.translateService.instant('ERROR','TITLE'));
			},() => {}
			)
			// Création formulaire suppression de compte si on édite le compte
			this.deleteForm = new FormGroup({
				'confirm_field': new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),});
		}else{
			this.isAuthenticated = false;
			this.lightForm = fb.group({
				label: [null, Validators.required],
				email: ['', [Validators.required, Validators.email]],
				password: ['', [Validators.required, Validators.minLength(6)]],
				description: [''],
				accept_terms: ['', Validators.required],
				date_creation_organization: [null],
				phone: [''],
				facebook: [''],
				twitter: [''],
				instagram: [''],
				website: [''],
				video: [''],
				profile_pic: [],
				idPays: [null, Validators.required],
				profile: ['organisation'],
				accept_newsletter: false,
				device_apptoken: null,
				billing_address : [null, Validators.required],
				promo : [null]
			});
			this.isLoading = false;
		}
	}


	ngOnInit(): void {
		this.getCountries();

		this.getTraduction();

		/*this.translateService.get('FORM.NOT_EMPTY_PASSWORD').subscribe((text:string) => {console.log(text)});
		this.translateService.get(['FORM.LABEL_CHOOSE_PICTURE', 'FORM.LABEL_DATE_CREATION','ERROR.ERROR_SERVER','ERROR.ERROR_MAIL','ERROR.ERROR_CREDENTIALS','ERROR.ERROR_SERVER','ERROR.ERROR_MAIL','ERROR.ERROR_CREDENTIALS', 'ERROR.ERROR_EMPTY_FIELDS', 'ERROR.ERROR_TERMS_CONDITIONS_NOT_ACCEPTED', 'ERROR.ERROR_IMAGE_UPLOAD'])
    .subscribe(translations => {
	  this.labelsChoosePicture = translations['FORM.LABEL_CHOOSE_PICTURE'];
	  this.datePicker = translations['FORM.LABEL_DATE_CREATION'];
	  this.erreur_server = translations['ERROR.ERROR_SERVER'];
	  this.erreur_mail = translations['ERROR.ERROR_MAIL'];
	  this.erreur_identifiant = translations['ERROR.ERROR_CREDENTIALS'];
	  this.erreur_champsVide = translations['ERROR.ERROR_EMPTY_FIELDS'];
	  this.erreur_terms_conditions = translations['ERROR.ERROR_TERMS_CONDITIONS_NOT_ACCEPTED'];
	  this.erreur_image_upload =translations['ERROR.ERROR_IMAGE_UPLOAD'];
	});*/
	//set de la langue dans le champ phone

	}
	register() {
		this.isLoadingConfirmation = true;
		this.tryValidate = true;
		if (this.lightForm.getRawValue().accept_terms === true) {
			if (this.lightForm.getRawValue().label && this.lightForm.getRawValue().email && this.lightForm.getRawValue().password &&
			 this.lightForm.getRawValue().billing_address && this.lightForm.getRawValue().idPays) {
				/*if(this.selectedFile){
					//this.lightForm.setValue(profile_pic:this.selectedFile.name);
					const current = new Date();
					const timestamp = current.getTime();
					this.fileName = timestamp.toString()+Math.random().toString(36).substring(2);
					this.lightForm.controls['profile_pic'].setValue(this.fileName + this.extension);
				}*/

			//Récupérations des données
			this.connexionRow = this.lightForm.getRawValue();
			if(this.connexionRow.phone != null){
				this.connexionRow.phone = this.connexionRow.phone.internationalNumber;
			}
			//On remplace le mdp par sa version cryptée
			this.connexionRow.password = CryptoJS.SHA512(this.lightForm.getRawValue().password).toString();

			var form = new FormData();
			if(this.imgURL){
				form.append("profile_pic", this.imgURL);
			}
			form.append("datas", JSON.stringify(this.connexionRow));

			this.authService
				.register(form)
				.subscribe(jsonResponse => {
					//TODO envoyer un formDatat et y ajouter l'image fd.append('image', this.imgURL); et enlever l'appel à onUploadFile();
					if (jsonResponse.success == true) {
						/*if (this.imgURL !== null) {
							this.onUploadFile();
							//this.lightForm.getRawValue().profile_pic = this.selectedFile.name;
						}*/
						//stokage du token d'un utilisateur
						localStorage.setItem('roomId',jsonResponse.obj.roomId);
						localStorage.setItem('mma-token-orga', jsonResponse.obj.access_token);
						localStorage.setItem('isConnected',"true");
						//stokage du code profil de l'utilisateur
						localStorage.setItem('code_profile', jsonResponse.obj.profile);

						//redirige vers la page d'accueil
						this.alertService.showSuccess(this.translateService.instant('SUCCESS','ACCOUNT_CREATED'),this.translateService.instant('SUCCESS','TITLE'));
						setTimeout(function(){
							location.replace('/home');
						},3000);

					} else {
						if(jsonResponse.code == 3){
							this.erreurMessage = this.erreur_mail_taken;
							this.alertService.showError(this.erreur_mail_taken,this.translateService.instant('ERROR','TITLE'));
						}else{
							this.erreurMessage = this.erreur_champsVide;
							this.alertService.showError(this.erreur_champsVide,this.translateService.instant('ERROR','TITLE'));
						}

						this.isLoadingConfirmation = false;
					}
					// console.log(localStorage.getItem('token'));
				}, error => {
					this.erreurMessage = error.message;
					this.alertService.alertAuth(error);
					let statusCode = error.status
					switch (statusCode) {
						case 500: {
							this.erreurMessage = this.erreur_server;
							this.alertService.showError(this.erreur_server, this.translateService.instant('ERROR','TITLE') );
							break;
						}
						case 401: {
							this.erreurMessage = this.erreur_identifiant;
							this.alertService.showError(this.erreur_identifiant, this.translateService.instant('ERROR','TITLE') );
							break;
						}
						case 422: {
							this.erreurMessage = this.erreur_mail;
							this.alertService.showError(this.erreur_mail, this.translateService.instant('ERROR','TITLE') );
							break;
						}
					}
					this.isLoadingConfirmation = false;
				}
				);
			}else{
				this.alertService.showError(this.erreur_champsVide, this.translateService.instant('ERROR','TITLE'));
				this.isLoadingConfirmation = false;
			}
		} else {
			this.alertService.showError(this.erreur_terms_conditions, this.translateService.instant('ERROR','TITLE'));
			this.isLoadingConfirmation = false;
		}
	}
	edit() {
		this.isLoadingConfirmation = true;
		this.tryValidate = true;
		this.connexionRow=this.lightForm.getRawValue();
		if(this.connexionRow.phone != null){
			this.connexionRow.phone = this.connexionRow.phone.internationalNumber;
		}
		if (this.lightForm.getRawValue().label) {
		  this.TaAppOrganisationProfileService
			.edit(this.connexionRow)
			.subscribe(jsonResponse => {
				this.isLoadingConfirmation = false;
				if (jsonResponse.success === true) {
					if (this.imgURL !== null) {
						this.onUploadFile();
						//this.lightForm.getRawValue().profile_pic = this.selectedFile.name;
					}
					this.alertService.showSuccess(this.translateService.instant('SUCCESS','ACCOUNT_UPDATED'),this.translateService.instant('SUCCESS','TITLE'));
					//On actualise la page après un petit delay (pour avoir le temps de voir l'alerte de succes)
					//setTimeout(() => {  document.location.reload(); }, 2000);
				} else {
					this.erreurMessage = this.erreur_champsVide;
					this.alertService.showError(this.erreur_champsVide,this.translateService.instant('ERROR','TITLE'));
				}
				// console.log(localStorage.getItem('token'));
			}, error => {
				this.erreurMessage = error.message;
				this.alertService.alertAuth(error);
				let statusCode = error.status
				switch (statusCode) {
					case 500: {
						this.erreurMessage = this.erreur_server;
						this.alertService.showError(this.erreur_server, this.translateService.instant('ERROR','TITLE') );
						break;
					}
					case 401: {
						this.erreurMessage = this.erreur_identifiant;
						this.alertService.showError(this.erreur_identifiant, this.translateService.instant('ERROR','TITLE') );
						break;
					}
					case 422: {
						this.erreurMessage = this.erreur_mail;
						this.alertService.showError(this.erreur_mail, this.translateService.instant('ERROR','TITLE') );
						break;
					}
				}
				this.isLoadingConfirmation = false;
			}
			);
		}else{
			this.erreurMessage = this.erreur_champsVide;
			this.alertService.showError(this.erreur_champsVide, this.translateService.instant('ERROR','TITLE'));
			this.isLoadingConfirmation = false;
		}
	}
	getCountries() {
		this.countryService
			.getCountries()
			.subscribe(
				jsonResponse => {
					// si la requete est un succés.
					this.listCountries = jsonResponse.rows;
					//this.fullOptionCountry();
				}, error => {
					this.alertService.alertError(error);
				}
			);
	}
	/*fullOptionCountry() {
		this.optionsSelect = this.listCountries;
		for (let i = 0; i <= this.listCountries.length - 1; i++) {
			const object = { value: this.listCountries[i].id_country, label: this.listCountries[i].label_country }
			this.options.push(object);
		}
		this.optionsSelect = this.options;
	}*/


	onFileRemove() {
		this.imgURL = null;
	}
	onUploadFile() {
		if (this.imgURL != null) {
			const fd = new FormData();
			fd.append('image', this.imgURL);
			this.TaAppOrganisationProfileService.uploadImage(fd).subscribe(res => {
				this.alertService.showSuccess(this.success_image_uploaded, this.translateService.instant('SUCCESS','TITLE') );
			}, error => {
				this.alertService.showError(this.erreur_image_upload, this.translateService.instant('ERROR','TITLE') );

			}
			);
			//fd.append('email',this.lightForm.getRawValue().email)
			/*this.httpClient.post('http://localhost:80/mma-connection-backend/public/api/register/uploadPicture', fd).subscribe(res => {
				console.log(res)
				this.alertService.showSuccess('L\'image a été ajoutée correctement', 'Success' );
			}, error => {
				console.log(error);
				this.alertService.showError('L\'image n\'a pas été ajoutée correctement', this.translateService.instant('ERROR','TITLE') );

			}
			);*/
		}
	}
  cancelSelectedFile(){
	this.imgURL =''
	this.selectedFile = null;
	this.labelsChoosePicture = "Choose your profile picture";
	this.fileName ='';

	}

	onModifyEmail(){
		if (this.lightForm.controls['modify_email'].value){
			this.lightForm.controls['email'].enable();
		}else{
			this.lightForm.controls['email'].disable();
		}
	}

	onDeleteActualProfileImage(){
		this.TaAppOrganisationProfileService.deleteImage().subscribe(res => {
			this.alertService.showSuccess(this.success_image_deleted, 'Success' );
			//On actualise la page après un petit delay (pour avoir le temps de voir l'alerte de succes)
			setTimeout(() => {  document.location.reload(); }, 2000);

		}, error => {
			this.alertService.showError(this.error_image_deleted, this.translateService.instant('ERROR','TITLE') );
		}
		);
	}

	/*Open file selection window and compress file for uploading*/
	compressFile() {
	    this.imageCompress.uploadFile().then(({image, orientation}) => {
	      this.imageCompress.compressFile(image, orientation, 50, 50).then(
	        result => {
	          this.imgURL = result;
	        }
	      );

	    });

	  }

	getTraduction(){
		this.erreur_server            = this.translateService.instant('ERROR', 'ERROR_SERVER');
		this.erreur_mail              = this.translateService.instant('ERROR', 'ERROR_MAIL');
		this.erreur_mail_taken        = this.translateService.instant('ERROR', 'ERROR_MAIL_TAKEN');
		this.erreur_identifiant       = this.translateService.instant('ERROR', 'ERROR_CREDENTIALS');
		this.erreur_champsVide        = this.translateService.instant('ERROR', 'ERROR_EMPTY_FIELDS');
		this.erreur_terms_conditions  = this.translateService.instant('ERROR', 'ERROR_TERMS_CONDITIONS_NOT_ACCEPTED');
		this.erreur_image_upload      = this.translateService.instant('ERROR', 'ERROR_IMAGE_UPLOAD');
		this.erreur_not_loaded        = this.translateService.instant('ERROR', 'USER_DATA_NOT_LOADED');
		this.error_image_deleted      = this.translateService.instant('ERROR', 'IMAGE_NOT_DELETED');
		this.error_account_deleted    = this.translateService.instant('ERROR', 'ACCOUNT_NOT_DELETED');

		this.success_account_updated  = this.translateService.instant('SUCCESS', 'ACCOUNT_UPDATED');
		this.success_image_uploaded   = this.translateService.instant('SUCCESS', 'SUCCESS_IMAGE_UPLOAD');
		this.success_image_deleted    = this.translateService.instant('SUCCESS', 'IMAGE_DELETED');
		this.success_account_deleted  = this.translateService.instant('SUCCESS', 'ACCOUNT_DELETED');
	}

	get label() { return this.lightForm.get('label'); }

	get password() { return this.lightForm.get('password'); }

	get idPays() { return this.lightForm.get('idPays'); }

	get date_creation_organization() { return this.lightForm.get('date_creation_organization'); }

	get email() { return this.lightForm.get('email'); }


	get billing_address() { return this.lightForm.get('billing_address'); }

	deleteAccount(){
		this.isLoadingDeleteAccount = true;
		// Vérifier que delete est bien rentré
		if(this.deleteForm.get('confirm_field').value.toLowerCase() != "delete"){
			this.alertService.showError(this.translateService.instant("ERROR","hitDeleteWord"),this.translateService.instant("ERROR","TITLE"));
			this.isLoadingDeleteAccount = false;
			return;
		}else{
			this.TaAppOrganisationProfileService.deleteAccount().subscribe(res => {
				this.alertService.showSuccess(this.success_account_deleted, 'Success');
				//Supprimer le token de connexion et recharger la page
				localStorage.setItem('mma-token-orga',null);
      			localStorage.setItem('isConnected','false');
				this.router.navigate(['/login']);
				this.isLoadingDeleteAccount = false;
				document.location.reload();
			}, error => {
				this.alertService.showError(this.error_account_deleted, this.translateService.instant('ERROR','TITLE') );
				this.isLoadingDeleteAccount = false;
			}
			);
		}

	}

}
