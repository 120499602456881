import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpClient: HttpClient,
    private globals: Globals
  ) { }

  send(datas):Observable<any>{
    return this.httpClient.post(this.globals.baseUrl+"contactfromorga",datas);
    }
    public contactMMaCo(contactForm): Observable<any>{
      let datas = new HttpParams().set('sujet',contactForm.sujet).set('message',contactForm.message);
      return this.httpClient.post(this.globals.baseUrl+"contact",datas);
    }
}
