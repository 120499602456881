import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaAppFightcardCandidateService {

  constructor(private http: HttpClient, private globals: Globals) { }
  
  private fightcardCandidateUrl = this.globals.baseUrl + 'taappfightcardCandidate';

  getCandidates(id_fightcard): Observable<any>{
    let formData = new FormData();
    formData.append('id_fightcard', id_fightcard);
      return this.http.post(this.fightcardCandidateUrl + '/getcandidates', formData);
  }
}
