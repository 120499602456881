import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Country } from '../models/Country';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TaRefCountryService {

  constructor(private http: HttpClient, private globals: Globals) { }
  private countryUrl = this.globals.baseUrl + 'tarefcountry';  // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getCountries(): Observable<any> {
	let url = this.globals.baseUrl + "register/tarefcountry"
    return this.http.get(url);
    //.map(res => res.json().data);
  }
  /** GET Country by id. Will 404 if id not found */
  getCountry(id: number): Observable<Country> {
    const url = `${this.countryUrl}/${id}`;
    return this.http.get<Country>(url).pipe(
      tap(_ => this.log(`fetched Country id=${id}`)),
      catchError(this.handleError<Country>(`getCountry id=${id}`))
    );
  }
  /** PUT: update the Country on the server */
  updateCountry(country: Country): Observable<any> {
    return this.http.put(this.countryUrl + '/update', country, this.httpOptions).pipe(
      tap(_ => this.log(`updated Country id=${country.id_country}`)),
      catchError(this.handleError<any>('updateCountry'))
    );
  }
  /** POST: add a new Country to the server */
  addCountry(Country: Country): Observable<Country> {
    return this.http.post<Country>(this.countryUrl, Country, this.httpOptions).pipe(
      tap((newCountry: Country) => this.log(`added Country w/ id=${newCountry.id_country}`)),
      catchError(this.handleError<Country>('addCountry'))
    );
  }
  /** DELETE: delete the Country from the server */
  deleteCountry(Country: Country | number): Observable<Country> {
    const id = typeof Country === 'number' ? Country : Country.id_country;
    const url = `${this.countryUrl}/delete/${id}`;

    return this.http.delete<Country>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted Country id=${id}`)),
      catchError(this.handleError<Country>('deleteCountry'))
    );
  }
  /* GET heroes whose name contains search term */
  searchCountry(term: string): Observable<Country[]> {
    if (!term.trim()) {
      // if not search term, return empty Country array.
      return of([]);
    }
    return this.http.get<Country[]>(`${this.countryUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
        this.log(`found heroes matching "${term}"`) :
        this.log(`no heroes matching "${term}"`)),
      catchError(this.handleError<Country[]>('searchCountry', []))
    );
  }
  private log(message: string) {

  }
}
