import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private baseUrl = this.globals.baseUrl;  // URL to web api
	constructor(private http: HttpClient, private globals: Globals, /*private authguard: AuthGuardModule*/ ) { }
	private result;

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};
	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private results: any = [];

	/*private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {

			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			this.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}*/

	public login(credentials): Observable<any> {
		//fonction qui permet d'envoyer une requête au serveur pour connecter un utilisateur
		const body = JSON.stringify(credentials);
		return this.http.post(this.globals.baseUrl + 'auth/loginOrganisateur', body);
	}

	public register(credentials): Observable<any> {
		return this.http.post(this.globals.baseUrl + 'auth/register', credentials);


	}
	logout(): Observable<any> {
		//Deconnexion supprime toutes les données stockées par l'utilisateur
		return this.http.get(this.globals.baseUrl+'logout');
	}
	private log(message: string) {

	}

	user(): Observable<any> {
		return this.http.get(this.globals.baseUrl + 'me');
	}

	updatePassword(formDatas):Observable<any>{
	  return this.http.post(this.globals.baseUrl+'user/updatepassword',formDatas);
	}
	updateEmail(datas):Observable<any>{
	  return this.http.post(this.globals.baseUrl+'user/updateemail',datas);
	}

	public recoverPassword(email) : Observable<any>{
		const params = new HttpParams().set("email", email);
		return this.http.post(this.globals.baseUrl+"auth/forgotpassword",params);
	}
}
