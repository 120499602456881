import { Component, Input, OnInit } from '@angular/core';
import { SidenavModule, WavesModule, AccordionModule } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Globals } from '../../globals';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
@Input('mustSubscribe') mustSubscribe = false;
	newMessage: boolean = false;

  constructor(private loginService: AuthService, public translate: TranslateService, private router: Router, public globals: Globals) {
	/*if(localStorage.getItem('chat') != null){
		this.newMessage = JSON.parse(localStorage.getItem('chat')).newMessages;
	}*/
   }

  ngOnInit(): void {
  }

  logout() {
	this.loginService.logout()
		.subscribe(jsonResponse => {
      localStorage.setItem('mma-token-orga',null);
      localStorage.setItem('isConnected','false');
			this.router.navigate(['/login']);
		}, error => {
			// peut être géré le unauthorised
		});
	}
}
