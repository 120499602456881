<app-navbar></app-navbar>

<!--Grid row-->
<div class="container mt-5">
	<div class="row">
		<!--FORMULAIRE DE RECHERCHE-->
		<div class="col-12">
      <div class="container">
  <div class="row">
    <div class="col-md-12 mx-auto">
      <!--<div class="md-form">
        <input
          type="text"
          [(ngModel)]="searchText"
          class="form-control"
          id="search"
          mdbInput
        />
        <label for="search">Search</label>
      </div>-->
    </div>
  </div>
  <table mdbTable class="z-depth-1">
    <thead>
      <tr>
        <th>
          ID
        </th>
        <th>
          Date
        </th>
        <th>
          Montant
        </th>
        <th>
          Télécharger
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of ordersList">
        <th scope="row">{{order.id_order}}</th>
        <td class="red-text">{{order.create_datetime}}</td>
        <td>{{order.amount_paid_incl}}</td>
        <td>
          <button (click)="downloadPdf(order.id_order)">VOIR</button>
          <!--<a href="{{globals.webBaseUrl}}/taapporders/downloadpdf&id_order={{order.id_order}}" target="_blank">DL</a>--></td>
      </tr>
    </tbody>
  </table>
</div>
    </div>
  </div>
</div>
