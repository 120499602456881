<app-navbar></app-navbar>
<div class="container mt-5 text-center">
    <mdb-card *ngIf="step == 0">
        <mdb-card-body>
          <mdb-card-title>
            <h5>{{'TEXT.whyUseShortNotice'|translate}}</h5>
          </mdb-card-title>
          <mdb-card-text>
            <p>{{'TEXT.shortNoticeTxt1'|translate}}<br/>
            {{'TEXT.shortNoticeTxt2'|translate}}<br/>
            {{'TEXT.shortNoticeTxt3'|translate}}<br/>
            {{'TEXT.shortNoticeTxt4'|translate}}<br/>
            {{'TEXT.shortNoticeTxt5'|translate}}</p>
            <h5>{{'TEXT.COMMING_SOON'|translate}}</h5>
            <!--<p class="text-danger"> Cette fonctionnalité est une fonction payante</p>-->
          </mdb-card-text>
          <!--<button type="button" mdbBtn color="primary" (click)="step=1;">{{'BUTTON.start'|translate}}</button>-->
        </mdb-card-body>
      </mdb-card>

      <mdb-card *ngIf="step == 1">
          <mdb-card-body>
            <mdb-card-title>
              <h5>{{'TEXT.completeThisForm'|translate}}</h5>
            </mdb-card-title>
            <mdb-card-text>
              <div class="row">
                <form [formGroup]="shortnoticeForm">
                  <div class="md-form md-outline">
        				    <input type="text" class="form-control" id="InputEvent_name" formControlName="event_name" placeholder="" required mdbValidate mdbInput>
                    <label for="InputEvent_name">{{'FORM.EVENT_NAME' | translate}}<sup>*</sup></label>
                    <ng-container *ngFor="let validation of validation_messages.event_name">
                      <mdb-error *ngIf="shortnoticeForm.get('event_name').hasError(validation.type) && shortnoticeForm.get('event_name').touched">{{validation.message}}</mdb-error>
                    </ng-container>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
                  			<!--<mdb-date-picker formControlName="datetime_event" name="datetime_event" [options]="myDatePickerOptions" [placeholder]='datePicker' [locale]="'fr'" required>
                    		</mdb-date-picker>-->
                        <input type="date" id="datetime_event" class="form-control" formControlName="datetime_event" mdbInput mdbValidate required min="{{minDate}}">
                        <label for="datetime_event" style="width: auto;">{{'FORM.EVENT_DATE'|translate}}<sup>*</sup></label>
                        <ng-container *ngFor="let validation of validation_messages.datetime_event">
                        <mdb-error *ngIf="shortnoticeForm.get('datetime_event').hasError(validation.type) && shortnoticeForm.get('datetime_event').touched">{{validation.message}}</mdb-error>
                        </ng-container>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" formControlName="id_country" label="{{'FORM.CHOOSE_COUNTRY' | translate}}*"
        							placeholder="{{'FORM.CHOOSE_COUNTRY' | translate}}" required>
        							<mdb-select-option *ngFor="let country of listCountries" [value]="country.id_country">{{
        								country.label_country }}</mdb-select-option>
        						</mdb-select-2>
                    <ng-container *ngFor="let validation of validation_messages.id_country">
                      <mdb-error *ngIf="shortnoticeForm.get('id_country').hasError(validation.type) && shortnoticeForm.get('id_country').touched">{{'ERROR.completeField'|translate}}</mdb-error>
                    </ng-container>
                  </div>
                  <!---->
        				  <div class="md-form md-outline">
        				    <input type="text" class="form-control" id="inputPlace" placeholder="" formControlName="place" mdbInput>
                    <label for="inputPlace">{{'FORM.PLACE' | translate}}</label>
                  </div>
                  <!---->
                  <!---->
                  <h5>Profil recherché</h5>
                  <div class="md-form md-outline mt-3">
                    <mdb-select-2 [outline]="true" placeholder="{{'FORM.CHOOSE_SEX' | translate}}" formControlName="id_sex" label="{{'FORM.CHOOSE_SEX' | translate}}*">
                      <mdb-select-option value="1">{{ 'FORM.LABEL_SEX_MALE' | translate }}</mdb-select-option>
                      <mdb-select-option value="2">{{ 'FORM.LABEL_SEX_FEMALE' | translate }}</mdb-select-option>
                    </mdb-select-2>
                      <ng-container *ngFor="let validation of validation_messages.id_sex">
                        <mdb-error *ngIf="shortnoticeForm.get('id_sex').hasError(validation.type) && shortnoticeForm.get('id_sex').touched">{{validation.message}}</mdb-error>
                      </ng-container>
                  </div>
                  <!---->
                  <div class="md-form md-outline mt-3">
                    <mdb-select-2 [outline]="true" placeholder="{{'FORM.FIGHT_LEVEL'|translate}}" formControlName="is_rookie" label="{{'FORM.FIGHT_LEVEL'|translate}}*" allowClear="true">
                      <mdb-select-option value="false">{{ 'FORM.IS_PRO' | translate }}</mdb-select-option>
                      <mdb-select-option value="true">{{ 'FORM.IS_ROOKIE' | translate }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
                    <input type="number" id="catchweightKg" class="form-control" formControlName="weight" mdbInput required>
                    <label for="catchweightKg">poids</label>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
                    <input
                      type="number"
                      formControlName="nb_min_fight"
                      id="nb_min_fight"
                      class="form-control md-textarea"
                      mdbInput
                    />
                    <label for="nb_min_fight">{{
                      "FORM.nbMinFight" | translate
                    }}</label>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
                    <input
                      type="number"
                      formControlName="nb_max_fight"
                      id="nb_max_fight"
                      class="form-control md-textarea"
                      mdbInput
                    />
                    <label for="nb_max_fight">{{
                      "FORM.nbMaxFight" | translate
                    }}</label>
                  </div>
                  <!---->
                  <div class="md-form md-outline">
        				    <input type="text" class="form-control" id="oponent" placeholder="" formControlName="oponent" mdbInput>
                    <label for="oponent">{{'FORM.OPPONENT' | translate}}</label>
                  </div>
                  <!---->
        				  <div class="md-form md-outline">
        				  	<textarea class="form-control" id="contact" placeholder="" formControlName="contact" rows="3" class="md-textarea form-control" mdbInput></textarea>
                    <label for="contact">{{'FORM.OTHER_INFORMATIONS' | translate}}</label>
                  </div>
                  <!---->
        				  <div class="md-form md-outline">
        				  	<textarea class="form-control" id="medical_informations" placeholder="" formControlName="medical_informations" rows="3" class="md-textarea form-control" mdbInput></textarea>
                    <label for="medical_informations">{{'FORM.MEDICAL_REQUIREMENTS' | translate}}</label>
                  </div>
                  <!---->
        				  <div class="md-form md-outline">
        				  	<textarea class="form-control" id="other_informations" placeholder="" formControlName="other_informations" rows="3" class="md-textarea form-control" mdbInput></textarea>
                    <label for="other_informations">{{'FORM.CONTACT_US' | translate}}</label>
                  </div>

                </form>
              </div>
            </mdb-card-text>
            <button type="button" mdbBtn color="primary" (click)="step=0;">{{'BUTTON.prev'|translate}}</button>
            <button type="button" mdbBtn color="primary" (click)="step=2;">{{'BUTTON.next'|translate}}</button>
          </mdb-card-body>
        </mdb-card>

</div>
